<script>
	import { createEventDispatcher } from "svelte";
	import { generic_account_status_update } from "../components/../helpers/apiBackend";
	import { _ } from "svelte-i18n";
	import Loader from "../components/Loader.svelte";

	const dispatch = createEventDispatcher();
	export let id;
	export let name;
	let motivo = "";
	let selectedReason = "";
	let loadingDeleted = false;
	let errorMessages = false;
	let confirmationMessage = true;

	function deleted(id, statusAccount) {
		console.log("deleted");
		generic_account_status_update(id, statusAccount).then((data) => {
			console.log({ data });
			if (data.error) {
				console.log("Error");
			} else {
				selectedReason = "";
				motivo = "";
				window.location.reload();
			}
		});
	}

	function closeDeleted() {
		dispatch("closeDeleted");
	}

	const reasons = $_("DeletedPatient.select_reasons");

	const confirmDelete = () => {
		console.log("confirmDelete");
		if (selectedReason && motivo) {
			console.log("YES");
			loadingDeleted = true;
		} else {
			console.log("No");
			errorMessages = true;
			setTimeout(() => {
				errorMessages = false;
			}, 3000);
		}
	};
	const proceedDelete = () => {
		console.log("proceedDelete");
		confirmationMessage = false;
		deleted(id, reasons[selectedReason]);
	};
</script>

<main>
	{#if loadingDeleted}
		{#if confirmationMessage}
			<div class="text-center p-2">
				<p>
					{$_("DeletedPatient.tex_details1_conf")} <strong>{name}</strong>? {$_(
						"DeletedPatient.tex_details2_conf"
					)}
				</p>
				<button class="btnWhite" on:click={() => ((confirmationMessage = false), (loadingDeleted = false))}
					>{$_("DeletedPatient.button_cancel")}</button>
				<button
					class="btnDeleted"
					on:click={() => {
						proceedDelete();
					}}>{$_("DeletedPatient.button_confirm")}</button>
			</div>
		{:else}
			<div class="text-center p-2">
				<Loader text={false} />
				<p class="text-muted">{$_("DeletedPatient.tex_deleted")}...</p>
			</div>
		{/if}
	{:else}
		<h2>{$_("DeletedPatient.title")}</h2>
		<p>
			{$_("DeletedPatient.tex_details1")}<strong>{name}</strong>{$_("DeletedPatient.tex_details2")}
		</p>
		<label>
			{$_("DeletedPatient.title_tex_reason")}:
			<select bind:value={selectedReason}>
				<option value="">{$_("DeletedPatient.select_tex_reason")}</option>
				{#each Object.keys(reasons) as reason}
					<option value={reason}>{reason}</option>
				{/each}
			</select>
			{#if errorMessages && !selectedReason}
				<small id="idvalMotivo" style="color: red;">{$_("DeletedPatient.tex_reason_mandatory")}</small>
			{/if}
		</label>

		<label>
			{$_("DeletedPatient.tex_details")}:
			<textarea
				id="iddiagnostico"
				class="iddiagnostico"
				bind:value={motivo}
				placeholder=""
				name="Text1"
				cols="40"
				rows="2"
				maxlength="255" />
			{#if errorMessages && !motivo}
				<small id="idvalDetallar" style="color: red;">{$_("DeletedPatient.tex_reason_mandatory")}</small>
			{/if}
		</label>

		<button
			class="btnWhite"
			on:click={() => {
				closeDeleted();
			}}>{$_("DeletedPatient.button_cancel")}</button>
		<button
			class="btnDeleted"
			on:click={() => {
				confirmDelete();
			}}>{$_("DeletedPatient.button_accept")}</button>
	{/if}
</main>

<style>
	main {
		max-width: 400px;
		margin: 0 auto;
		text-align: center;
		padding: 20px;
		border-radius: 8px;
	}

	label {
		display: block;
		margin-top: 10px;
		text-align: left;
	}

	select {
		width: 100%;
		padding: 8px;
		margin-top: 5px;
		box-sizing: border-box;
	}

	button {
		margin-top: 20px;
		padding: 10px;
		cursor: pointer;
	}

	.btnDeleted {
		letter-spacing: 1px;
		color: #fff;
		border-radius: 10px;
		background-color: #fa0900;
	}
	textarea {
		font-family: inherit;
		font-size: inherit;
		-webkit-padding: 0.4em 0;
		padding: 0.4em;
		margin: 0 0 0.5em 0;
		width: 100%;
		box-sizing: border-box;
		border: 1px solid #ccc;
		border-radius: 0px;
	}
</style>
