<!-- 
  Campos
  https://docs.google.com/spreadsheets/d/1YqntIvLV1kA61a1R_l4sZZ23Fmyv5_dG6rDtvKJA4G8/edit#gid=0
 -->
<script>
	// Components / Layouts
	//import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import LayoutPatientHeader from "../../../components/Layouts/Layout_Patient_Header.svelte";
	import Loader from "../../../components/Loader.svelte";
	import SearchDoctorGral from "../../../components/SearchDoctorGral.svelte";
	import ModalComplete from "../../../components/ModalComplete.svelte";
	import AddDetailsMedications from "../../../components/addDetailsMedications.svelte";
	import Modal from "../../../components/Modal.svelte";

	// Helpers
	import { registerDetail, selectedSession } from "../../../helpers/storage/stores";
	import { currentSession } from "../../../helpers/storage/sessions.js";
	import {
		requestDoctor,
		utils_files_get,
		generic_medication_detail_get,
		//update_activity_detail,
	} from "../../../helpers/apiBackend";
	import { formatDate } from "../../../helpers/helpers";

	// Svelte
	import { _ } from "svelte-i18n";
	import { onMount } from "svelte";

	// Variables
	let type = "";
	let Link__c = "";
	let [modalLoading, modalSuccess, modalError] = [false, false, false];
	let dateSession = "";
	let suggestedDate = "";
	let statusS = false;
	let assignDoctorHeader = false;
	let modalComplete = false;
	let selectedDoctor = "";
	let modalSolicitar = false;
	let sendChanges = false;
	let modalPatch = false;
	let dataEdit = {};
	let modalEdit = false;
	let detailRegister = [];
	//let myBody = {};
	let myType, myScope, myParams, originalRecord, token, myRecomendedDoctor;
	//let [editRealDate, editFrecuencia, modalSugerir] = [false, false, false];
	let assignDoctor = {
		idDoctor: "",
		idActivity: "",
		suggestedDateTime: "",
	};

	// [REFACTOR]
	// [REFACTOR]
	// [REFACTOR]
	//token = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];

	// [REFACTOR]
	// [REFACTOR]
	// [REFACTOR]

	/**
	 * @description
	 */
	const btnRequestDoctor = async () => {
		let result = "";
		try {
			if (selectedDoctor.Id && detailRegister["Id"] && suggestedDate) {
				assignDoctor.idDoctor = selectedDoctor.Id;
				assignDoctor.idActivity = detailRegister["Id"];
				assignDoctor.suggestedDateTime = suggestedDate;
				result = await requestDoctor(assignDoctor, $currentSession);
				if ((result.status = 204)) {
					modalSolicitar = false;
					statusS = true;
				}
			}
		} catch (error) {}
	};

	/**
	 * @description
	 */
	const completeSession = async (e) => {
		modalComplete = false;
		if (e.detail.hasOwnProperty("body")) {
			let myBody = e.detail.body;
			myBody["EvolucionadoPor__c"] = "Paciente";
			modalLoading = true;
			//console.log(e.detail)
			// await update_activity_detail(myBody, detailRegister.Id).then((data) => {
			// 	console.log({ data });
			// 	if (data.status == 204) {
			// 		modalSuccess = true;
			// 	} else {
			// 		modalError = true;
			// 	}
			// 	modalLoading = false;
			// });
			// let myCompleteUrl = `${AWS_HOST}/generic/update_activity_detail?activity_id=${$registerDetail['detail'].Id}`
			// await fetch(myCompleteUrl, {
			//   method:'PATCH',
			//   headers:myHeaders,
			//   body:JSON.stringify(myBody)
			// })
			//   .then(patchResponse => {
			//     console.log({patchResponse})
			//     if(patchResponse.status==204){
			//       modalSuccess = true
			//     }else{
			//       modalError = true
			//     }
			//   })
			//   .catch(patchError => { console.log({patchError}) })
			//   .finally(() => { modalLoading=false })
		}
	};

	/**
	 * @description
	 */
	onMount(async () => {
		let id = $registerDetail.detailId || "";
		await generic_medication_detail_get(id).then((result) => {
			if (result.statusCode == 200) {
				detailRegister = result.data[0];
				console.log({ detailRegister });
				Link__c = detailRegister["files"];
				type = detailRegister["files"].split("/")[3];
				type = type.split(".")[1];
			}
		});

		// if (Object.keys($registerDetail).length > 0) {
		// 	//Busca si tienen adjuntos
		// 	dataAdjunto = await utils_files_get(detailRegister["Id"], $currentSession);
		// 	if (dataAdjunto.data.length > 0 && dataAdjunto.data[0].hasOwnProperty("Link__c")) {
		// 		type = dataAdjunto.data[0].Link__c.split("/")[3];
		// 		type = type.split(".")[1];
		// 		console.log({ type });
		// 	}
		// 	// Validate Parameters (RecordType / Scope)
		// 	delete $registerDetail["Actividad_Detalles__r"];
		// 	myParams = document.location.href.split("?")[1];
		// 	if (myParams.indexOf("&") > -1) {
		// 		myParams = myParams.split("&");
		// 		myType = myParams[0].split("=")[1];
		// 		myScope = myParams[1].split("=")[1];
		// 	}
		// 	if (myScope == undefined || myScope == "" || myType == undefined || myType == "") {
		// 		window.location.href = "#/GeneralError";
		// 	}

		// 	// Delete nulls
		// 	Object.keys($registerDetail["register"]).forEach((key) => {
		// 		if ($registerDetail["register"][key] == null || $registerDetail["register"][key] == undefined) {
		// 			$registerDetail["register"][key] = "";
		// 		}
		// 	});
		// 	if ($registerDetail.hasOwnProperty("detail")) {
		// 		Object.keys(detailRegister).forEach((key) => {
		// 			if (detailRegister[key] == null || detailRegister[key] == undefined) {
		// 				detailRegister[key] = "";
		// 			}
		// 		});
		// 	}

		// 	originalRecord = JSON.parse(JSON.stringify($registerDetail));
		// }
	});

	// /**
	//  * @description
	//  */
	// const updateRecord = async () => {
	// 	patching = true;
	// 	myHeaders = { Authorization: token };

	// 	// Update Header
	// 	if (myScope == "header") {

	// 	} else if (myScope == "detail") {
	// 		console.log({ myScope });

	// 	}
	// };

	$: {
		if (originalRecord != undefined) {
			if (JSON.stringify(originalRecord) != JSON.stringify($registerDetail)) {
				sendChanges = true;
			} else {
				sendChanges = false;
			}
		}
		if (myScope == "detail") {
			if (dateSession !== "") {
				detailRegister["Fecha_de_sesion__c"] = dateSession;
			}
		}
	}
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	<LayoutPatientHeader />
	{#if detailRegister.hasOwnProperty("drug")}
		<div class="conteiner-detail border rounded shadow-sm my-3 bg-white">
			<div class="Title-detail">
				<p class="title">
					{detailRegister["commertialName"]} - [{detailRegister["drug"]}]
				</p>
			</div>
			<div class="form-detail">
				<div class="row">
					<div class="column">
						<p class="Completado_p">{$_("RegisterDetailMedicationProf.text_required")}</p>
						{#if detailRegister["active"]}
							<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn/> -->
							<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{:else}
							<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
							<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{/if}
					</div>
					<div class="column">
						<p class="Completado_p">
							{$_("RegisterDetailMedicationProf.text_duplicated")}
						</p>
						{#if detailRegister["duplicated"]}
							<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
							<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{:else}
							<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
							<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{/if}
					</div>
					<div class="column">
						<p class="Creado_p">
							{$_("RegisterDetailMedicationProf.text_prolongedTreatment")}
						</p>
						{#if detailRegister["prolongedTreatment"]}
							<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
							<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{:else}
							<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
							<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{/if}
					</div>
				</div>
			</div>
			<!-- panel -->
			<div class="form-detail">
				<div class="row">
					<div class="column">
						<p class="Completado_p">
							{$_("RegisterDetailMedicationProf.text_lab")}
						</p>
						{#if detailRegister["lab"]}
							{detailRegister["lab"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
					<div class="column">
						<p class="Completado_p">
							{$_("RegisterDetailMedicationProf.text_presentation")}
						</p>
						{#if detailRegister["presentation"]}
							{detailRegister["presentation"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
					<div class="column">
						<p class="Creado_p">
							{$_("RegisterDetailMedicationProf.text_boxAmmount")}
						</p>
						{#if detailRegister["boxAmmount"]}
							{detailRegister["boxAmmount"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
				</div>
			</div>
			<div class="form-detail">
				<div class="row">
					<div class="column">
						<p class="Completado_p">
							{$_("RegisterDetailMedicationProf.text_dose")}
						</p>
						{#if detailRegister["dose"]}
							{detailRegister["dose"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
					<div class="column">
						<p class="Completado_p">
							{$_("RegisterDetailMedicationProf.text_frecuency")}
						</p>
						{#if $registerDetail.frecuency}
							{$registerDetail.frecuency}
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
					<div class="column">
						<p class="Creado_p">
							{$_("RegisterDetailMedicationProf.text_prescribedBy")}
						</p>
						{#if detailRegister["prescribedBy"]}
							{detailRegister["prescribedBy"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
				</div>
			</div>
			<div class="form-detail">
				<div class="row">
					<div class="column">
						<p class="Completado_p">
							{$_("RegisterDetailMedicationProf.text_comsumptionInstructions")}
						</p>
						{#if detailRegister["comsumptionInstructions"]}
							{detailRegister["comsumptionInstructions"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
					<div class="column">
						<p class="Completado_p">
							{$_("RegisterDetailMedicationProf.text_medicationDate")}
						</p>
						{#if detailRegister["medicationDate"]}
							<span class="text-muted"> {formatDate(detailRegister["medicationDate"].$language)}</span>
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>

					<div class="column">
						<p class="Creado_p">{$_("RegisterDetailMedicationProf.text_th_createdby")}</p>
						{#if detailRegister.hasOwnProperty("createdById")}
							{detailRegister["createdById"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
				</div>
			</div>
			<div class="form-detail">
				{#if Link__c != ""}
					<div class="attachment_div">
						<p class="adjuntos_p">{$_("RegisterDetailPatient.text_order_medical")}</p>
						{#if type == "pdf"}
							<img
								class="cursor pointer"
								on:click={() => {}}
								src="img/pdfIcon.png"
								alt="PDF"
								style="width: 32px; height: 32px;" /><a href={Link__c} download
								>{$_("RegisterDetailPatient.button_download")}</a>
						{:else}
							<img
								class="cursor pointer"
								on:click={() => {}}
								src={Link__c}
								alt="Document"
								style="width: 32px; height: 32px;" /><a href={Link__c} download
								>{$_("RegisterDetailPatient.button_download")}</a>
						{/if}
					</div>
				{/if}
				<div class="d-flex">
					<p class="diagnosis_p">
						{$_("RegisterDetailMedicationProf.text_diagnosis")}
					</p>
					{#if detailRegister["diagnosis"]}
						<p class="txt_marg">{detailRegister["diagnosis"]}</p>
					{:else}
						<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
					{/if}
				</div>
				<div class="d-flex justify-content-flex-end">
					<p class="diagnosis_p">
						{$_("RegisterDetailMedicationProf.text_justification")}
					</p>
					{#if detailRegister["justification"]}
						<p class="txt_marg">{detailRegister["justification"]}</p>
					{:else}
						<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
					{/if}
				</div>
			</div>
			<div class="form-detail">
				{#if true}
					<div class="d-flex justify-content-center">
						<button
							on:click={() => {
								dataEdit = detailRegister;
								modalEdit = true;
							}}
							class="btnEdit">{$_("RegisterDetailMedicationProf.button_Edit")}</button>
					</div>
				{/if}
			</div>
		</div>

		<div class="text-center">
			<a class="mx-2" href="#/Patient/RecordMedicationPatient"
				>{$_("RegisterDetailMedicationProf.button_return_plan")}</a>
			<div />
		</div>
	{:else}
		<div class="p-5 text-center">
			<Loader text={false} />
			<p class="text-muted">{$_("RegisterDetailMedicationProf.loading")}</p>
		</div>
	{/if}
	<!-- </LayoutNav> -->
</section>

<!-- Confirm -->
<LayoutModal isOpenModal={modalPatch} on:closeModal={(modalPatch = false)}>
	<div class="p-3 text-center">
		<p class="m-0">{$_("RegisterDetailPatient.text_request_change")}</p>
		<div class="d-flex justify-content-between my-3">
			<button
				on:click={() => {
					//updateRecord();
				}}
				class="mx-2 btnBlue py-0">{$_("RegisterDetailPatient.button_Send")}</button>
			<button
				on:click={() => {
					modalPatch = false;
				}}
				class="mx-2 btnWhite py-0">{$_("RegisterDetailPatient.button_Cancel")}</button>
		</div>
	</div>
</LayoutModal>

<LayoutModal
	tam="70"
	isOpenModal={modalSolicitar}
	on:closeModal={() => {
		modalSolicitar = false;
	}}>
	<div class="text-center">
		<div class="text-center">
			<SearchDoctorGral
				on:dateProf={(e) => {
					console.log("EVENTO RECIBIDO: ", e.detail);
					if (e.detail.hasOwnProperty("doctor")) {
						//modalSolicitar = false
						assignDoctorHeader = true;
						selectedDoctor = e.detail.doctor;
					}
				}}
				estadoCabecera={false} />
		</div>

		{#if selectedDoctor}
			<div class="border shadow-sm rounded">
				<div>
					<p>{$_("RegisterDetailPatient.text_Professional")}</p>
					<!-- svelte-ignore a11y-label-has-associated-control -->
					<label>{selectedDoctor.Name}</label>
					<p>{$_("RegisterDetailPatient.text_date_suggested")}</p>
					<input
						class="input-log"
						type="date"
						pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
						name="trip-start"
						bind:value={suggestedDate} />
				</div>
			</div>
		{/if}

		<div class="text-center">
			<br />
			<button
				on:click={() => {
					modalSolicitar = false;
				}}
				class="btnWhite">{$_("RegisterDetailPatient.button_Cancel")}</button>
			<button
				on:click={() => {
					btnRequestDoctor();
				}}
				class="btnBlue">{$_("RegisterDetailPatient.button_acept")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Modal Complete Session -->
<LayoutModal
	isOpenModal={modalComplete}
	on:closeModal={() => {
		modalComplete = false;
	}}>
	<ModalComplete
		idTypeAttachment={""}
		on:complete={(e) => {
			completeSession(e);
		}}
		on:cancel={() => {
			modalComplete = false;
		}} />
</LayoutModal>

<!-- Modal Lodaing -->
<!-- <LayoutModal
	isOpenModal={modalLoading}
	on:closeModal={() => {
		modalLoading = false;
	}}>
	<div class="text-center p-5">
		<Loader text={false} />
		<p class="text-muted">...</p>
	</div>
</LayoutModal> -->

<Modal isOpenModal={modalLoading}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div class="text-center">
			<p class="text-muted">{$_("RegisterDetailPatient.loading")}</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<LayoutModal
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	<div class="text-center p-3">
		{#if myType == "Estudios"}
			<p>{$_("RegisterDetailPatient.text_message_succes_studi")}</p>
		{:else}
			<p>{$_("RegisterDetailPatient.text_message_succes_gral")}{myType}</p>
		{/if}
		<a href="#/Patient/Records?type={myType}">{$_("RegisterDetailPatient.button_return")} {myType}</a>
	</div>
</LayoutModal>

<LayoutModal
	tam="50"
	isOpenModal={modalEdit}
	on:closeModal={() => {
		modalEdit = false;
	}}>
	{#if modalEdit && dataEdit}
		<AddDetailsMedications
			nameMed={detailRegister.drug}
			patientId={detailRegister.patientId}
			planId={detailRegister.careplanId}
			actividadCabeceraId={detailRegister.medicationId}
			actividadDetalleId={detailRegister.id}
			detailEdit={dataEdit}
			on:cancel={() => {
				console.log("edit");
				modalEdit = false;
			}} />
	{/if}
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="text-center p-3">
		<p>{$_("RegisterDetailPatient.text_message_error")}</p>
		<a href="#/Patient/Records?type=Citas/Entrevistas">{$_("RegisterDetailPatient.button_return_interviews")}</a>
	</div>
</LayoutModal>

<style>
	.Title-detail {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 25px;
		margin: 2%;
	}
	.title {
		font-size: 25px;
	}
	.diagnosis_p {
		margin-top: 1%;
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.txt_marg {
		margin: 1%;
	}
	.conteiner-detail {
		display: flex;
		flex-wrap: wrap;
		margin-left: 2%;
		width: 95%;
		max-height: 60vh;
		overflow: auto;
	}
	.form-detail {
		margin-left: 3%;
		width: 100%;
	}
	.column {
		flex-basis: 33.33%; /* Para dividir equitativamente en tres columnas */
		padding: 10px;
		box-sizing: border-box;
	}

	.btnEdit {
		margin: 10px;
		width: 10%;
		height: 3rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		color: #ffff;
		border-radius: 10px;
		background-color: #112c4a;
		font-family: "Roboto", sans-serif;
	}

	.Completado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.adjuntos_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.my-plan-dashboard {
		max-height: 35vh !important;
		overflow: auto;
	}
	.attachment_div {
		display: flex;
		margin-top: 2%;
		/* align-content: center; */
		justify-content: flex-start;
		align-items: flex-start;
	}

	.Creado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	}
</style>
