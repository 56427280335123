<script>
	import { onMount } from "svelte";
	import { currentSession } from "../../helpers/storage/sessions";
	import { user } from "../../helpers/storage/stores";

	const redirectLogin = () => {
		window.localStorage.clear();
		window.sessionStorage.clear();
		window.location.href = "/";
	};

	setInterval(redirectLogin, 5000);
</script>

<section>
	<div class="container text-center text-muted py-4">
		<h1>403</h1>
		<p>No puede acceder a esta pagina</p>
		<p>En unos segundos sera redirigido a la pantalla principal</p>
		<a
			on:click|preventDefault={() => {
				redirectLogin();
			}}
			href="/">Volver</a>
	</div>
</section>
