
import { user } from "./storage/stores.js";
import { get } from "svelte/store";
import { _ } from "svelte-i18n";

/**
 * @description
 */
export const groupedResultsFormat = (responseSet) => {
    const groupedResults = responseSet.reduce((acc, item) => {
        const { questionText, questionID, answerValues, questionDescription} = item;
        const answers = answerValues.map(av => av.answerText).filter(text => text != null && text !== "");
        //Verifico que no sea grilla
        if (questionDescription == "") {
            // Verificar si la pregunta ya está en el acumulador
            const existingQuestion = acc.find(q => q.questionID === questionID);
            if (existingQuestion) {
                // Añadir respuestas a la pregunta existente
                existingQuestion.answerTexts.push(...answers);
            } else {
                let type = "";
                if (questionText.charAt(0) == ".") {
                    type = "inform"
                } else
                {
                    type = "rta"
                }
                // Crear una nueva entrada para la pregunta
                acc.push({
                    questionText,
                    questionID,
                    answerTexts: answers,
                    questionDescription,
                    questionType: type
                });
            }
        } else {
    
            const existingQuestion = acc.find(q => q.questionDescription === questionDescription);
            if (existingQuestion) {
                // Añadir respuestas a la pregunta existente
                existingQuestion.options.push({
                    questionText,
                    questionID,
                    answerTexts: answers,
                    questionDescription,
                    questionType: "grid-option"
                });
            } else {
                acc.push({
                    questionDescription,
                    options: [{
                        questionText,
                        questionID,
                        answerTexts: answers,
                        questionDescription,
                        questionType: "grid-option"
            
                    }],
                    questionType: "grid"
                });
            }
    
        }
        
        return acc;
    }, []);
    
    return groupedResults ;
};


