<script>
	import { onMount } from "svelte";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import DoctorListHeader from "../../../components/Layouts/Layout_DoctorListHeader.svelte";
	import { patient, user, selectedSessionPatient } from "../../../helpers/storage/stores";
	//import { get_current_sessions } from "../../../helpers/apiBackend";
	import { _ } from "svelte-i18n";
	//import { currentSession } from "../../../helpers/storage/sessions.js";
	import Loader from "../../../components/Loader.svelte";

	let showListMySessions = true;
	let loadingSessions = true;
	let listOfSessions = [];
	const AWS_HOST = process.env.HOST_DEV; // Fix ENVS
	let myToken, myHeaders;

	onMount(async () => {
		// await get_current_sessions($user.Id).then((data) => {
		// 	console.log({ data });
		// 	listOfSessions = data.listOfSessions;
		// 	loadingSessions = false;
		// });
		// Get sessions List (Migrar)
		// myToken = $currentSession['signInUserSession']['accessToken']['jwtToken']
		// myHeaders = { Authorization: myToken }
		// try{
		// 	let mySessionsUrl = `${AWS_HOST}/doctors/get_current_sessions?id=${$user.Id}`
		// 	console.log({mySessionsUrl})
		// 	await fetch(mySessionsUrl, { headers:myHeaders})
		// 		.then(data=>{ console.log({data})
		// 			if(data.status == 404){
		// 			return []
		// 			}else{
		// 			return data.json()
		// 			}
		// 		})
		// 		.then(sessionsResponse => {
		// 			console.log({sessionsResponse})
		// 			if(Array.isArray(sessionsResponse)){
		// 			console.log({sessionsResponse})
		// 			}else{
		// 			listOfSessions = sessionsResponse.data
		// 			}
		// 		})
		// 		.catch(error=>{console.log({error})})
		// 		.finally(() => {
		// 			loadingSessions=false
		// 		})
		// }catch(errorGetSessions){
		// 	console.log({errorGetSessions})
		// }
	});
</script>

<section>
	<LayoutNav secure={true} rol="Profesional_de_Salud">
		<DoctorListHeader
			myPage="Pacientes"
			buttomLeft="CABECERA"
			buttomLeftHref="#/Doctor"
			buttomRigth="SESIONES"
			buttomRigthHref="#/PatientSessions"
			selected="rigth">
			<!-- My Sessions -->
			<div class="container border rounded shadow-sm bg-white my-3">
				<!-- <div class="border-bottom d-flex justify-content-between">
            <h3 class="text-muted">Sesiones</h3>
            <div 
            on:click={() => { showListMySessions=!showListMySessions }}
            class="d-flex align-items-center text-muted cursor-pointer">
                <i class="gg-details-less"></i>
            </div>
        </div> -->
				{#if showListMySessions}
					<div class="my-3">
						{#if loadingSessions}
							<div class="text-center p-2">
								<Loader text={false} />
								<p class="text-muted">{$_("PatientSessionProf.loading")}</p>
							</div>
						{:else if listOfSessions.length > 0}
							<div class="table-my-sessions">
								<table class="table table-sm table-stripped">
									<thead class="myCustom-colorBlue">
										<tr class="">
											<!-- <th>Sesion Solicitada</th>
                                <th>Fecha Sugerida</th> -->
											<th>{$_("PatientSessionProf.text_th_patient")}</th>
											<th>{$_("PatientSessionProf.text_th_age")}</th>
											<th>{$_("PatientSessionProf.text_th_action")}</th>
										</tr>
									</thead>
									<tbody class="">
										{#each listOfSessions as mySession}
											<tr>
												<!-- Sesion Solicitada -->
												<!-- <td>
                        {#if mySession.hasOwnProperty("Name")}
                          {mySession["Name"]}
                        {:else}
                          <span class="text-muted m-0">Sesion Desconocida</span>
                        {/if}
                      </td> -->
												<!-- Fecha Sugerida -->
												<!-- <td>
                        {#if mySession.hasOwnProperty("Fecha_Sugerida__c")}
                            {mySession["Fecha_Sugerida__c"].split("-").reverse().join('-')}                            
                        {:else}
                        <span class="text-muted m-0">Sin Fecha</span>
                        {/if}
                      </td> -->
												<!-- Paciente -->
												<td>
													{#if mySession.hasOwnProperty("Name")}
														{mySession["Name"]}
													{:else}
														<span class="text-muted m-0"
															>{$_("PatientSessionProf.text_not_name")}</span>
													{/if}
												</td>
												<!-- Edad -->
												<td>
													{#if mySession.hasOwnProperty("Edad__c")}
														{mySession["Edad__c"]}
													{:else}
														<span class="text-muted m-0"
															>{$_("PatientSessionProf.text_not_age")}</span>
													{/if}
												</td>
												<!-- Accion -->
												<td>
													<button
														on:click={() => {
															$selectedSessionPatient = mySession;
															window.location.href = "#/Session";
														}}
														class="py-0 btnBlue"
														>{$_("PatientSessionProf.button_show")}</button>
												</td>
											</tr>
										{/each}
									</tbody>
								</table>
							</div>
						{:else}
							<div class="">
								<p class="text-muted">{$_("PatientSessionProf.text_not_request")}...</p>
							</div>
						{/if}
						<div>
							<button
								on:click={() => {
									window.location.href = "#/Doctor";
								}}
								class="btn btn-secondary shadow"
								>{$_("PatientSessionProf.text_session_pending")}</button>
						</div>
					</div>
				{/if}
			</div></DoctorListHeader>
	</LayoutNav>
</section>

<style>
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}
</style>
