<script>
	// Components
	import List_Record_Medication_patient from "../../../components/Lists/List_Record_Medication_patient.svelte";
	import AddMedications from "../../../components/addMedications.svelte";
	import Loader from "../../../components/Loader.svelte";
	// Layouts
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutPatientHeader from "../../../components/Layouts/Layout_Patient_Header.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";

	// Helpers
	import { generic_medication_get_List } from "../../../helpers/apiBackend";
	import { user, registerDetail } from "../../../helpers/storage/stores.js";

	// Svelte

	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";

	let myRecords = [];
	let modaladd = false;
	let render = false;
	let patientId = $user.Id;
	let myUserId = $user.Id;
	let planId = $user.Careplan2__r.Id;

	onMount(async () => {
		let body = {
			patientId,
			planId,
		};
		console.log({ body });
		const res = await generic_medication_get_List(body);
		console.log({ res });
		myRecords = res.data;
		render = true;
	});
	// console.log({ $user });
	// if (document.location.href.indexOf("?") > -1) {
	// 	let patientId = $user["Id"];
	// 	let planId = $user["HealthCloudGA__CarePlan__c"];
	// 	myType = document.location.href.split("?")[1].split("=")[1];
	// 	if (myType == "Citas/Entrevistas") {
	// 		type = myType.replace("/", "");
	// 	} else {
	// 		type = myType;
	// 		if (type == "Medicamentos") {
	// 			type = "Medicacion";
	// 		}
	// 	}
	// 	await generic_activities_get(patientId, type, planId).then((res) => {
	// 		let auxRecord = [];
	// 		console.log({ res });
	// 		if (res.hasOwnProperty("data")) {
	// 			if (res.data.hasOwnProperty("actividades")) {
	// 				myRecords = res.data.actividades;

	// 				if (res.data.hasOwnProperty("archivos") && res.data.archivos.length > 0) {
	// 					let fileA = res.data.archivos;
	// 					//console.log({fileA})
	// 					myRecords.forEach((element) => {
	// 						if (element.hasOwnProperty("Actividad_Detalles__r")) {
	// 							let fileAd = element.Actividad_Detalles__r.records;
	// 							fileAd.forEach((item) => {
	// 								fileA.forEach((itemD) => {
	// 									console.log("Adjunto", itemD.Id);
	// 									if (itemD.Id == item.Id) {
	// 										let ad = itemD.Archivos_S3__r.records;
	// 										ad.forEach((element) => {
	// 											console.log("Link", element.Link__c);
	// 											item.Link__c = element.Link__c;
	// 											console.log("item", item);
	// 										});
	// 									}
	// 								});
	// 							});
	// 						}
	// 					});
	// 				}
	// 			}
	// 		} else {
	// 			myRecords = res;
	// 		}
	// 		console.log("Filtar por solicitar", myRecords);
	// 		//if(myRecords.hasOwnProperty('Actividad_Detalles__r')){
	// 		console.log("Ingreso");
	// 		myRecords.forEach((element) => {
	// 			let actividad = [];
	// 			if (element.hasOwnProperty("Actividad_Detalles__r")) {
	// 				actividad = element.Actividad_Detalles__r.records;
	// 				console.log({ actividad });
	// 			}
	// 			let aux = [];
	// 			actividad.forEach((item) => {
	// 				console.log("Estado de ststu");
	// 				console.log(item);
	// 				if (item.Solicitado__c == true) {
	// 					aux.push(item);
	// 				}
	// 			});
	// 			if (element.hasOwnProperty("Actividad_Detalles__r")) {
	// 				element.Actividad_Detalles__r.records = aux;
	// 			}

	// 			if (aux.length > 0) {
	// 				auxRecord.push(element);
	// 				console.log({ auxRecord });
	// 			}
	// 		});
	// 		if (type != "Medicacion") {
	// 			myRecords = auxRecord;
	// 		}
	// 		render = true;
	// 	});

	// } else {
	// 	window.location.href = "#/GeneralError";
	// }
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	<LayoutPatientHeader />
	{#if render}
		<div class="p-3 m-3 rounded shadow-sm bg-white">
			<div class="border-bottom">
				<h3>{$_("RecordMedicationPatient.text_title")}</h3>
				<div>
					<button
						on:click={() => {
							modaladd = true;
						}}
						class="btnWhite">{$_("RecordMedicationPatient.button_add_medicine")}</button>
				</div>
			</div>
			<div class="d-flex justify-content-between">
				<div class="d-flex text-muted" />
			</div>
			{#if myRecords.length > 0}
				<div class="my-plan-dashboard">
					{#each myRecords as record, i}
						<div class="my-1">
							<List_Record_Medication_patient
								theRecord={record}
								on:editDetail={(e) => {
									$registerDetail = e.detail.myDetail;
									window.location.href = `#/Patient/RegisterDetailMedicationPatient`;
								}} />
						</div>
					{/each}
				</div>
			{:else}
				<div class="text-center p-2">
					<p class="m-0 text-muted">{$_("RecordMedicationPatient.text_not_medic")}</p>
				</div>
			{/if}
			<div class="my-4 border-top text-center">
				<a href="#/Patient/Plan">{$_("RecordMedicationPatient.text_href_plan")}</a>
			</div>
		</div>
	{:else}
		<div class="p-3 text-center bg-white my-2 rounded shadow">
			<Loader text={false} />
			<p class="text-muted">{$_("RecordMedicationPatient.loading")}</p>
		</div>
	{/if}
	<!-- </LayoutNav> -->
</section>

<LayoutModal
	isOpenModal={modaladd}
	tam="60"
	on:closeModal={() => {
		modaladd = false;
	}}>
	<AddMedications {myUserId} {planId} {patientId} />
</LayoutModal>

<!-- CSS -->
<style>
	.my-plan-dashboard {
		max-height: 35vh !important;
		overflow: auto;
	}
</style>
