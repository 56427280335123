<script>
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";
	import FileAttach from "../components/FileAttach.svelte";
	import { user } from "../helpers/storage/stores";
	import { utils_files_add } from "../helpers/apiBackend";
	import { _ } from "svelte-i18n";

	export let idTypeAttachment;
	export let objectName;

	const objNameList = {
		E: "ExamsDetailCareplan__c",
		C: "InterviewDetailCareplan__c",
	};

	let file_type = "Documento";
	let document = "";
	let errorMessages = false;
	let profile = "P";
	let file_type_interv = "evolucion";
	let file_type_exams = "Orden";

	const dispatch = createEventDispatcher();
	let bodyCompleteSession = {
		notePatient: "",
		date: new Date().toISOString().split("T")[0],
		//Completado__c: true,
	};

	const completeSession = async () => {
		dispatch("complete", { body: bodyCompleteSession });
		if (document !== "") {
			document.detail.fileData.forEach(async (element) => {
				console.log('objectName.slice');
				console.log(objNameList[objectName.slice(0, 1)]);
				let objListName = objNameList[objectName.slice(0, 1)];
				let typeToSubMit = '';
				if (objListName === "ExamsDetailCareplan__c") {
					// E: "ExamsDetailCareplan__c",					
					typeToSubMit = file_type_exams ;
				}	else if (objListName === "InterviewDetailCareplan__c") {
					// C: "InterviewDetailCareplan__c",
					typeToSubMit = file_type_interv ;
				}
				console.log({typeToSubMit});
				await utils_files_add(element, "", objListName,typeToSubMit);
			});
		}
	};

	const closeModal = () => {
		dispatch("cancel");
		console.log({ idTypeAttachment });
	};
	onMount(async () => {
		if ($user.hasOwnProperty("Especialidades__r")) {
			profile = "D";
		}
	});
</script>

<section>
	<div>
		<div class="text-center text-muted">
			<h3>{$_("ModalComplete.text_title_note_center")}</h3>
		</div>
		<div>
			<div class="form-group">
				<label for="evolucion">{$_(`ModalComplete.text_title_note_${profile}`)}</label><small class="error"
					>*</small>

				<textarea
					bind:value={bodyCompleteSession.notePatient}
					class="form-control"
					id="evolucion"
					rows="3"
					maxlength="255" />
			</div>
			<FileAttach
				{idTypeAttachment}
				{file_type}
				multipleState={true}
				object_name={objNameList[objectName]}
				photoState={true}
				on:dataFile={async (e) => {
					document = e.detail;
					console.log({ e });
				}} />

			<!-- Slot (In case create new) -->
			<div>
				<slot />
			</div>
		</div>

		<div class="errormessageDV">
			{#if errorMessages}
				<small class="error">{$_("ModalComplete.text_errorMessages")}</small>
			{/if}
		</div>
		<br />
		{#if profile == "P"}
			<div class="alert">
				<p>{$_("ModalComplete.text_messages_alert")}</p>
			</div>
		{/if}
		<div class="text-center">
			{#if bodyCompleteSession.date !== "" && bodyCompleteSession.notePatient != ""}
				<button
					on:click={() => {
						completeSession();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("ModalComplete.button_to_complete")}</button>
			{:else}
				<button
					on:click={() => {
						errorMessages = !errorMessages;
					}}
					class="py-0 mx-2 py-2 btnBlue disable">{$_("ModalComplete.button_to_complete")}</button>
			{/if}
			<button
				on:click={() => {
					closeModal();
				}}
				class="py-0 mx-2 py-2 btnWhite">{$_("ModalComplete.button_Cancel")}</button>
		</div>
	</div>
</section>

<style>
	.error {
		color: red;
	}
	.errormessageDV {
		text-align: center;
	}
	.alert {
		background-color: #a6c8de; /* Color de fondo rojo */
		color: #112c4a; /* Texto en color blanco */
		padding: 10px; /* Espacio interno */
		border-radius: 5px; /* Bordes redondeados */
		margin-top: 10px; /* Margen superior para separar el mensaje de otros elementos */
		text-align: center; /* Alineación centrada del texto */
	}

	.alert p {
		margin: 0; /* Elimina el margen predeterminado del párrafo */
	}
</style>
