<script>
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { _ } from "svelte-i18n";
	import { currentSession } from "../../../helpers/storage/sessions";
	import { registerDetail } from "../../../helpers/storage/stores";

	let loading = true;
	let myRecords = [];
</script>

<section>
	<LayoutNav secure={true} rol="Profesional_de_Salud">
		<div class="border p-3 bg-white">
			<div class="border-bottom">
				<h3 class="text-muted">Sesion</h3>
			</div>
			<div class="d-flex justify-content-between">
				<!-- <div>
                    <small class="text-muted">Nombre (Modalidad)</small>
                </div>
                <div>
                    <small class="text-muted">Frecuencia-Activo-Acciones</small>
                </div> -->
			</div>
			<div class="records-list">
				{#if loading}
					<div class="p-3 text-center">
						<Loader text={false} />
						<p class="text-muted">{$_("PatientSessionProf.loading")}</p>
					</div>
				{:else if myRecords.length > 0}
					{#each myRecords as record, i}
						<div class="shadow-sm my-1 border">
							<!-- Record Header -->
							<div
								class="cursor-pointer p-1 d-flex justify-content-between cursor-hover"
								data-toggle="collapse"
								data-target="#{record.Id}"
								aria-expanded="false"
								aria-controls={record.Id}>
								<div>
									<p class="m-0">
										{#if record.hasOwnProperty("Name")}
											{#if record.hasOwnProperty("Modalidad__c")}
												{record["Name"]}&nbsp;<small class="text-muted"
													>({record["Modalidad__c"]})</small>
											{:else}
												{record["Name"]}
											{/if}
										{/if}
									</p>
								</div>

								<div class="d-flex">
									<p class="m-0 mx-2 border">
										{#if record.hasOwnProperty("Frecuencia__c")}
											{#if record["Frecuencia__c"] == 1}
												Semanal
											{:else if record["Frecuencia__c"] == 2}
												Mensual
											{:else if record["Frecuencia__c"] == 3}
												Anual
											{:else if record["Frecuencia__c"] == 4}
												Trianual
											{:else if record["Frecuencia__c"] == 5}
												Bianual
											{:else if record["Frecuencia__c"] == 6}
												Segun Clinica
											{:else if record["Frecuencia__c"] == 7}
												Semanal A demanda medico
											{:else if record["Frecuencia__c"] == 8}
												Peso Semanal / Talla Anual
											{:else if record["Frecuencia__c"] == 9}
												A pedido profesional
											{:else if record["Frecuencia__c"] == 10}Cada 2 años
											{:else if record["Frecuencia__c"] == 11}Trianual/ Segun clinica
											{:else if record["Frecuencia__c"] == 12}Ingreso/ Segun clinica
											{:else if record["Frecuencia__c"] == 13}Bianual/ Segun clinica
											{:else if record["Frecuencia__c"] == 14}Obesidad (IMC mayor 30) Una vez por
												año/ Segun clinica
											{:else if record["Frecuencia__c"] == 15}Al nacer/ Ingreso
											{/if}
										{/if}
									</p>
									<p class="m-0 mx-2">
										{#if record.hasOwnProperty("Activo__c")}
											{#if record["Activo__c"]}
												<i style="color:#88FF78;" class="mt-1 gg-toggle-off" />
											{:else}
												<i class="mt-1 gg-toggle-off" />
											{/if}
										{/if}
									</p>
								</div>
							</div>

							<!-- Detail Header -->
							<div class="collapse my-detail" id={record.Id}>
								<table class="table table-sm table-hover">
									<thead>
										<tr>
											<th scope="col">Profesional</th>
											<th scope="col">Profesional Sugerido</th>
											<th scope="col">Fecha Sugerida</th>
											<th scope="col">Acciones</th>
										</tr>
									</thead>

									<!-- Details List -->
									<tbody>
										{#if record.Actividad_Detalles__r != null || record.Actividad_Detalles__r != undefined}
											{#if record.Actividad_Detalles__r.records != null}
												{#if record.Actividad_Detalles__r.records.length != 0}
													{#each record.Actividad_Detalles__r.records as recordDetail}
														<tr class="cursor-pointer">
															<td>{recordDetail["Creado_Por__r"]["Name"]}</td>
															<td>...</td>
															<td>{recordDetail["Fecha_Sugerida__c"]}</td>
															<td>{recordDetail["nueva_Accion_sugerida__c"]}</td>
															<td
																><i
																	class="mt-1 gg-shape-circle {recordDetail.Estado_Medico_de_cabecera__c}" /></td>
														</tr>
													{/each}
												{:else}<p class="m-0 text-muted">Sin detalles</p>{/if}
											{:else}<p class="m-0 text-muted">Sin detalles</p>{/if}
										{:else}<p class="m-0 text-muted">Sin detalles</p>{/if}
									</tbody>
								</table>
							</div>
						</div>
					{/each}
				{:else}
					<div class="text-center p-2">
						<p class="m-0 text-muted">No contiene actividades</p>
					</div>
				{/if}
			</div>
			<div class="d-flex m-3 border rounded justify-content-center">
				<!-- <p class="mx-2 m-0">Total: {myRecords.length}</p> -->
				<!-- <a class="mx-2" href="#/Plan">Plan</a>
                <a class="mx-2" href="#/Doctor">Mis pacientes</a>
                <a class="mx-2" href="#/Altas">Mis Altas</a> -->
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Plan";
					}}>
					Plan
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Doctor";
					}}>
					Mis pacientes
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Altas";
					}}>
					Mis Altas
				</p>
			</div>
		</div>
	</LayoutNav>
</section>

<style>
	.texlink {
		color: #215273;
	}
</style>
