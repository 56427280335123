<script>
	import { onMount } from "svelte";
	//import Select from "svelte-select";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { _ } from "svelte-i18n";
	//import { filterArray } from "../../../helpers/helpers.js";
	//import { patient } from "../../../helpers/storage/stores";
	import { currentSession } from "../../../helpers/storage/sessions";

	//let loading = true;
	//let patientFilter = [];
	//let listOfPatients = [];
	//let list = [];
	let type = "";
	let dataAdjunto = [];
	let myDiagnosisList = [];
	let questionOption = "";
	let myToken = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
	//let myHeaders = { Authorization: myToken };
	let selectedEvolution = "";
	let modalEvolution = false;
	let errorMessage = "";
	const complexItems = [
		{ value: "M", label: "Masculino" },
		{ value: "F", label: "Femenino" },
	];
	const AWS_HOST = process.env.HOST_DEV;
	onMount(async () => {});
</script>

<section>
	<LayoutNav secure={true} rol="Profesional_de_Salud">
		<LayoutPatient>
			<div class="container">
				<!-- <div class="border-bottom text-muted">
                    <h3>Historia Clinica</h3>
                </div> -->
				<!-- Filtro -->
				<div class="d-flex justify-content-between form-group shadow p-4 rounded my-4 myCustom-colorBlue">
					<div class="container">
						<div class="border-bottom text-muted">
							<h3 class="title">{$_("DiagnosisByProf.text_title")}</h3>
						</div>
						<br />
						<div class="d-flex justify-content-between">
							<span>{$_("DiagnosisByProf.text_message")}</span>
							<button class="btnBlue">{$_("DiagnosisByProf.button_title_diagnostic_engine")}</button>
						</div>
						<div class="option d-flex justify-content-between">
							<p>
								<input type="radio" name="opciones" bind:value={questionOption} />
								{$_("DiagnosisByProf.button_yes")}
							</p>
							<p>
								<input type="radio" name="opciones" bind:value={questionOption} />
								{$_("DiagnosisByProf.button_not")}
							</p>
							<p>
								<input type="checkbox" name="opciones" bind:value={questionOption} />
								{$_("DiagnosisByProf.button_doubts")}
							</p>
						</div>
					</div>
				</div>
				<!-- list -->
				{#if myDiagnosisList.length > 0}
					<div class="d-flex justify-content-between">
						<div>
							<small class="text-muted">{$_("DiagnosisByProf.text_activity")}</small>
						</div>
						<div>
							<small class="text-muted">{$_("DiagnosisByProf.text_Subcategory")}</small>
						</div>
						<div>
							<small class="text-muted">{$_("DiagnosisByProf.text_date")}</small>
						</div>
						<div>
							<small class="text-muted">{$_("DiagnosisByProf.text_Professional")}</small>
						</div>
						<div>
							<small class="text-muted">{$_("DiagnosisByProf.text_Comments")}</small>
						</div>
						<div>
							<small class="text-muted">{$_("DiagnosisByProf.text_attach")}</small>
						</div>
					</div>
				{/if}
				<div>
					<div class="py-3 text-center">
						<p class="m-0 text-muted">{$_("DiagnosisByProf.text_not_diagnoses")}</p>
						<p class="m-0 text-muted">{errorMessage}</p>
					</div>
				</div>
				<br />
				<div class="text-center">
					<button class="newPat">
						<svg
							width="95"
							height="95"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
							class="feather feather-plus-circle">
							<circle cx="12" cy="12" r="10" />
							<line x1="12" y1="8" x2="12" y2="16" />
							<line x1="8" y1="12" x2="16" y2="12" />
						</svg>
						<p>{$_("DiagnosisByProf.text_new_pathology")}</p>
					</button>
				</div>
			</div>
		</LayoutPatient>
	</LayoutNav>
</section>

<!-- Loading Modal -->
<LayoutModal
	tam="40"
	isOpenModal={modalEvolution}
	on:closeModal={() => {
		modalEvolution = false;
	}}>
	<div class="p-3">
		{#if type !== "" && dataAdjunto}
			<h4>{$_("DiagnosisByProf.text_title_Evolution")}:</h4>
			<small>{$_("DiagnosisByProf.text_Made_by_the")} {selectedEvolution.EvolucionadoPor__c}:</small>
			<div class="container bg-white border p-2 rounded shadow">
				<p class="m-0">{selectedEvolution.Evolucion__c}</p>
				<!-- <p class="m-0">{selectedEvolution.Id}</p> -->
			</div>
			<br />
			<div class="container bg-white border p-2 rounded shadow">
				<h4>{$_("DiagnosisByProf.text_attach")}:</h4>
				{#if type !== "pdf" && dataAdjunto.data.length > 0 && dataAdjunto.data[0].Link__c}
					<img
						class="cursor pointer"
						src={dataAdjunto.data[0].Link__c}
						alt="Document"
						width="20%"
						height="20%" /><a href={dataAdjunto.data[0].Link__c} download
						>{$_("DiagnosisByProf.button_download")}</a>
				{/if}
				{#if type == "pdf" && dataAdjunto.data.length > 0 && dataAdjunto.data[0].Link__c}
					<img class="cursor pointer" src="img/pdfIcon.png" alt="PDF" width="5%" height="5%" /><a
						href={dataAdjunto.data[0].Link__c}
						download>{$_("DiagnosisByProf.button_download")}</a>
				{/if}
				{#if type == "none"}
					<small>{$_("DiagnosisByProf.text_not_attach")}</small>
				{/if}
			</div>

			<div>
				<br />
				<button
					on:click={() => {
						modalEvolution = false;
					}}
					class="btnWhite py-2">{$_("DiagnosisByProf.button_close")}</button>
			</div>
		{:else}
			<div class="p-2 text-center">
				<Loader text={false} />
				<p class="text-muted">{$_("DiagnosisByProf.loading")}</p>
			</div>
		{/if}
	</div>
</LayoutModal>

<style>
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}
	.option {
		width: 15%;
	}
	.newPat {
		border-bottom: none;
		border: none;
		color: #215273;
		background-color: white;
	}
	.title {
		color: #215273;
		width: 100%;
		margin: initial;
	}
</style>
