<script>
	import { onMount } from "svelte";
	import Modal from "../../../components/Modal.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import { language, patient, registerDetail, user } from "../../../helpers/storage/stores";
	import AddMedications from "../../../components/addMedications.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { generic_medication_get_List, utils_resources_get } from "../../../helpers/apiBackend";
	import { _ } from "svelte-i18n";
	import List_Records_medication_Prof from "../../../components/Lists/List_Records_medication_Prof.svelte";
	//TODODELETE import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	//TODODELETE import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	//TODODELETE generic_account_get,
	//TODODELETE ROLES,

	let myRecords = [];
	let dataPatient = [];
	let select_activity = [];
	let myType, modalEditHeader, type;
	let headerToEdit = {};
	let frequencies = {};
	let loading = true;
	let patientId = $patient.Id;
	//let actividadCabeceraId = "";
	let myUserId = $user.Id;
	let planId = "";
	let [modalLoading, modalError, modalSuccess, modaladd] = [false, false, false, false];

	onMount(async () => {
		try {
			//const patient = await generic_account_get("", patientId, ROLES.patient);
			modalSuccess = false;
			//dataPatient = patient;
			console.log("utils_resources_get - RecordsMedications");
			frequencies = await utils_resources_get("frecuencies", $language);
			select_activity = $_("RecordDoctor.select_activity");
			patientId = $patient.Id;
			planId = $patient.careplan_id;
			$registerDetail = {};
			myType = "Medicamentos";
			type = "Medicacion";

			// console.log({ patientId });
			// console.log({ planId });
			// console.log({ myUserId });

			let body = {
				patientId,
				planId,
			};
			// console.log({ body });
			const res = await generic_medication_get_List(body);
			// console.log({ res });
			myRecords = [];

			//let auxRecord = [];
			console.log("Medication Data",{ res });
			if (res.data.hasOwnProperty("data") && res.data.totalItems > 0) {
				myRecords = res.data.data;
				// console.log("Medication",{ myRecords });
				if (res.data.hasOwnProperty("archivos")) {
					const fileA = res.data.archivos;
					//console.log({ fileA });

					myRecords.forEach((element) => {
						if (element.hasOwnProperty("Actividad_Detalles__r")) {
							const fileAd = element.Actividad_Detalles__r.records;
							fileAd.forEach((item) => {
								fileA.forEach((itemD) => {
									if (itemD.Id == item.Id) {
										//console.log({ itemD });
										const ad = itemD.Archivos_S3__r.records;
										ad.forEach((element) => {
											item.Link__c = element.Link__c;
										});
									}
								});
							});
						}
					});
				}
			}
			loading = false;
			console.log({ myRecords });
		} catch (error) {
			console.error(error);
		}
	});

	// Edit Header
	const editHeader = async () => {
		console.log("Llamado a UPDATE");
		let bodyPatchHeader = {
			Picklist_Frequency__c: headerToEdit["Frecuencia__c"],
			Activo__c: headerToEdit["Activo__c"],
		};
		modalLoading = true;
		modalEditHeader = false;

		// update_activity(headerToEdit["Id"], bodyPatchHeader)
		// 	.then((dataPacht) => {
		// 		if (dataPacht.status == 204) {
		// 			modalSuccess = true;
		// 		} else {
		// 			modalError = true;
		// 		}
		// 		console.log({ dataPacht });
		// 	})
		// 	.catch((errorPatch) => {
		// 		console.log({ errorPatch });
		// 		modalError = true;
		// 	})
		// 	.finally(() => {
		// 		modalLoading = false;
		// 	});
	};

	// Handle Modal Edit Header
	let originalHeader;
	let diffChanges = false;
	$: {
		if (modalEditHeader) {
			if (originalHeader == undefined) {
				originalHeader = JSON.parse(JSON.stringify(headerToEdit));
				console.log({ originalHeader });
			} else {
				if (JSON.stringify(originalHeader) != JSON.stringify(headerToEdit)) {
					diffChanges = true;
				} else {
					diffChanges = false;
				}
			}
		} else {
			if (originalHeader != undefined) {
				originalHeader = undefined;
			}
		}
	}
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<!-- <LayoutPatient> -->
	<div class="container">
		<div>
			<div class="border-bottom d-flex" style="justify-content: space-between;">
				{#if myType == "Citas/Entrevistas"}
					<h3 class="text-muted">{select_activity[myType]}</h3>
				{/if}
				{#if myType == "Medicamentos"}
					<div style="text-align: end;">
						<button
							on:click={() => {
								console.log({ patientId });
								console.log({ planId });
								console.log({ myUserId });
								modaladd = true;
							}}
							class="btnWhite"
							>{$_("RecordDoctor.textadd")}
							{select_activity[myType]}</button>
					</div>
				{:else}
					<div style="text-align: end;">
						{#if myType}
							<button
								on:click={() => {
									window.location.href = `#/CreateActivity/${type}`;
								}}
								class="btnWhite"
								>{$_("RecordDoctor.textCreate")}
								{select_activity[myType]}</button>
							<!-- create -->
						{/if}
					</div>
				{/if}
			</div>

			<div class="d-flex justify-content-between" />
			<div class="records-list">
				{#if loading}
					<div class="p-3 text-center">
						<Loader text={false} />
						<p class="text-muted">{$_("RecordDoctor.loading")}</p>
					</div>
				{:else if myRecords.length > 0}
					{#each myRecords as record, i}
						<!-- New Component - Start -->
						<div class="my-2">
							<List_Records_medication_Prof
								theRecord={record}
								on:editHeader={() => {
									headerToEdit = record;
									modalEditHeader = true;
								}}
								on:editDetail={(e) => {
									console.log({ e });
									$registerDetail = e.detail.myDetail;
									//$registerDetail["detail"] = e.detail.myDetail;
									window.location.href = `#/RegisterDetailMedicationProf?type=${myType}&scope=detail`;
								}} />
						</div>
						<!-- New Component - End -->
					{/each}
				{:else}
					<div class="text-center p-2">
						<p class="m-0 text-muted">
							{$_("RecordDoctor.textNotcontain")}
							{select_activity[myType]}
						</p>
					</div>
				{/if}
			</div>
			<div class="d-flex m-3 border rounded justify-content-center">
				<!-- <p class="mx-2 m-0">Total: {myRecords.length}</p> -->
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Plan";
					}}>
					{$_("RecordDoctor.button_plan")}
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Doctor";
					}}>
					{$_("RecordDoctor.button_patient")}
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Altas";
					}}>
					{$_("RecordDoctor.button_Reception")}
				</p>
			</div>
		</div>
	</div>
	<!-- </LayoutPatient> -->
	<!-- </LayoutNav> -->
</section>

<!-- Modal Editar Header -->
<LayoutModal
	isOpenModal={modalEditHeader}
	on:closeModal={() => {
		modalEditHeader = false;
	}}>
	<div class="">
		{#if headerToEdit.hasOwnProperty("Name") || headerToEdit.hasOwnProperty("Farmaco__c")}
			<div class="p-3 text-center">
				{#if !headerToEdit.hasOwnProperty("Farmaco__c")}
					<h2>Editar {headerToEdit["Name"]}</h2>
				{:else}
					<h2>Editar {headerToEdit["Farmaco__c"]}</h2>
				{/if}
				<div class="p-2">
					<div class="d-flex">
						<p class="m-0">Activo</p>
						<p class="m-0 mx-2">
							{#if headerToEdit.hasOwnProperty("Activo__c")}
								{#if headerToEdit["Activo__c"]}
									<!-- <i
										on:click={() => {
											headerToEdit["Activo__c"] = false;
										}}
										class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->

										<button
												on:click={() => {
													headerToEdit["Activo__c"] = false;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleon.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
								{:else}
									<!-- <i
										on:click={() => {
											headerToEdit["Activo__c"] = true;
										}}
										class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
										<button
												on:click={() => {
													headerToEdit["Activo__c"] = true;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleoff.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
								{/if}
							{/if}
						</p>
					</div>
					{#if !headerToEdit.hasOwnProperty("Farmaco__c")}
						<div class="form-group d-flex">
							<label for="frecuencia-edit">{$_("RecordDoctor.text_frequency")} &nbsp; &nbsp;</label>
							<select
								bind:value={headerToEdit["Frecuencia__c"]}
								class="form-control form-control-sm"
								name="frecuencia-edit"
								id="">
								{#each frequencies as frecuencia}
									<option value={frecuencia.id}>
										{frecuencia.label}
									</option>
								{/each}
								<!-- <option value="Al nacer/ Ingreso">Al nacer/ Ingreso</option> -->
							</select>
						</div>
					{/if}
				</div>
			</div>
		{/if}
		<div class="d-flex justify-content-center">
			{#if diffChanges}
				<button
					on:click={() => {
						editHeader();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("RecordDoctor.button_Send")}</button>
			{/if}
			<button
				class="py-0 mx-2 py-2 btnWhite"
				on:click={() => {
					modalEditHeader = false;
				}}>{$_("RecordDoctor.button_cancel")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Layout Modal -->
<Modal isOpenModal={modalLoading}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">{$_("RecordDoctor.loading")}</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<Modal isOpenModal={modalSuccess}>
	<div class="p-2 text-center">
		<p>{$_("RecordDoctor.text_message_succes")}</p>
		<button
			on:click={() => {
				window.location.reload();
			}}
			class="btnBlue py-0">{$_("RecordDoctor.button_close")}</button>
	</div>
</Modal>
<!-- <LayoutModal isOpenModal={modalSuccess}>
	<div class="p-2 text-center">
		<p>Tu solicitud ha sido enviada</p>
		<button
			on:click={() => {
				window.location.reload();
			}}
			class="btnBlue py-0">Cerrar F</button>
	</div>
</LayoutModal> -->

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="p-3">
		<div>
			<p class="m-0">{$_("RecordDoctor.text_message_error")}</p>
		</div>
		<div>
			<br />
			<div class="text-alingt-center" style="text-align: center">
				<button
					class="btnWhite"
					on:click={() => {
						window.location.reload();
					}}>{$_("RecordDoctor.button_close")}</button>
			</div>
		</div>
	</div>
</LayoutModal>

<!-- Modal add medicines -->
<LayoutModal
	tam="30"
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	{#if myUserId && planId && patientId}
		<AddMedications {myUserId} myPlan={planId} myPatient={patientId} />
	{/if}
</LayoutModal>

<!-- 
    [
    {
        "attributes": {
            "type": "RDCom_ActividadDetail__c",
            "url": "/services/data/v55.0/sobjects/RDCom_ActividadDetail__c/a1K8D000000I7ydUAC"
        },
        "Actividad__c": "a1M8D000000HmkFUAS",
        "Id": "a1K8D000000I7ydUAC",
        "Name": "Psiquiatra",
        "Paciente__c": "0018D00000FwAGhQAN",
        "Fecha_Sugerida__c": "2022-07-30",
        "Completado__c": false,
        "Creado_Por__c": "0018D00000Fw787QAB",
        "Solicitado__c": false,
        "nueva_Accion_sugerida__c": "Solicitar",
        "Etapa__c": "1",
        "Estado_Medico_de_cabecera__c": "Rojo",
        "Archivar__c": false,
        "Expirado__c": false,
        "Creado_Por__r": {
            "attributes": {
                "type": "Account",
                "url": "/services/data/v55.0/sobjects/Account/0018D00000Fw787QAB"
            },
            "Id": "0018D00000Fw787QAB",
            "Name": "Ignacio Salimeno"
        }
    }
]
 -->
<style>
	.records-list {
		max-height: 35vh;
		overflow: auto;
	}
	.texlink {
		color: #215273;
	}

	.myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #ffffff;
		background-color: #6a91ab;
	}
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}
	@media only screen and (min-width: 960px) {
		/* styles for browsers larger than 960px; */
		.records-list {
			max-height: 35vh;
			overflow: auto;
		}
	}
	@media only screen and (min-width: 1440px) {
		/* styles for browsers larger than 1440px; */
		.records-list {
			max-height: 45vh;
			overflow: auto;
		}
	}
</style>
