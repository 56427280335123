<script>
	// Components / Layout
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../../components/Loader.svelte";
	import AddPatient from "../../../components/Register/AddPatient.svelte";
	import DeletedPatient from "../../../components/DeletedPatient.svelte";
	import DoctorListHeader from "../../../components/Layouts/Layout_DoctorListHeader.svelte";

	// Helpers
	import { user, patient, language, CantAlta, patientLayout } from "../../../helpers/storage/stores";
	import { professional_patients_get, professional_patients_pending_get } from "../../../helpers/apiBackend";
	import { formatDate } from "../../../helpers/helpers";

	// Svelte
	import { _ } from "svelte-i18n";
	import { onMount } from "svelte";

	let patientFilter = "";
	let sortingParameter = "Apellido";
	let idpatient = "";
	let patientName = "";
	let asc = true;
	let modalSuccess = false;
	let langSelect = "es";
	let modalDeleted = false;
	let complexItems = [];
	let [listOfPatients, list] = [[], []];
	let clientX = 0;
	let clientY = 0;
	let loadingPatient = true;
	let showListMyPatients = true;
	if ($language == "es" || $language == "en") {
		langSelect = $language;
	} else {
		$language = "en";
	}
	localStorage.setItem("language", JSON.stringify($language));

	function closeDeleted() {
		modalDeleted = false;
	}

	let isOpen = false;

	const closeSession = () => {
		//$currentSession = {};
		$patient = {};
		$patientLayout = {};
	};

	function toggleMenu(alt) {
		isOpen = !isOpen;
		// if (isOpen) {
		// 	clientX = event.clientX;
		// 	clientY = event.clientY;
		// }
	}

	function closeMenu() {
		isOpen = false;
	}

	/**
	 * @description
	 */
	function sortyBy(lista, sortingParameter, asc) {
		if (sortingParameter == "Fecha") {
			lista.sort(function (a, b) {
				let fecha1 = a.birthDate;
				let fecha2 = b.birthDate;
				let fechaA = new Date(fecha1);
				let fechaB = new Date(fecha2);
				if (asc) {
					return fechaA - fechaB;
				} else {
					return fechaB - fechaA;
				}
			});
		}
		if (sortingParameter == "Apellido") {
			lista.sort((a, b) => {
				let nameA = a.name.split(" ");
				let nameB = b.name.split(" ");
				nameA = nameA[nameA.length - 1];
				nameB = nameB[nameB.length - 1];
				if (asc) {
					return nameA.split("")[0].localeCompare(nameB.split("")[0]);
				} else {
					return nameB.split("")[0].localeCompare(nameA.split("")[0]);
				}
			});
		}
		if (sortingParameter == "Pais") {
			lista.sort((a, b) => {
				if (asc) {
					a.country.localeCompare(b.country);
				} else {
					b.country.localeCompare(a.country);
				}
			});
		}
		return lista;
	}

	/**
	 * @description
	 */
	function filterA(searchterm, myList) {
		let newLsit = [];
		if (searchterm == "") {
			newLsit = myList;
		} else {
			try {
				newLsit = myList.filter((record) => {
					return record.name.toLowerCase().includes(searchterm.toLowerCase());
				});
				// console.log("filtrando: ", newLsit);
			} catch (error) {
				newLsit = [];
			}
		}
		return newLsit;
	}

	function checkUserLogged() {
		// console.log("CheckUserLogged", $user.Id);
		try {
			if ($user) {
				if ($user.Id) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} catch {
			return false;
		}
	}

	/**
	 * @description
	 */
	onMount(async () => {
		if (!checkUserLogged()) {
			window.location.href = "/";
		}

		// console.log("Recarga de doctor");
		$patient = {};
		$patientLayout = {};
		if (Object.keys($CantAlta).length === 0 || $CantAlta == 0) {
			await professional_patients_pending_get($user.Id, langSelect).then((data) => {
				if ($CantAlta != data.length && data.length > 0) {
					localStorage.setItem("CantAlta", data.length);
					window.location.reload();
				}
			});
		}

		window.localStorage.removeItem("patient");
		window.localStorage.removeItem("registerDetail");
		window.localStorage.removeItem("doctor");
		window.localStorage.removeItem("alta");
		window.localStorage.removeItem("CantAltas");

		complexItems = $_("ViewDoctor.select_filter_order");

		//await professional_patients_get("001O100000CYDn7IAH", langSelect).then((result) => {
		await professional_patients_get($user.Id, langSelect).then((result) => {
			// console.log("Listado pacientes", result);
			result.data.forEach((element) => {
				//result.forEach((element) => {
				listOfPatients.push(element);
			});
			list = listOfPatients;
			loadingPatient = false;
		});
	});
</script>

<!-- HTML -->
<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<DoctorListHeader
		myPage={$_("ViewDoctor.text_patient")}
		buttonLeft=""
		buttonLeftHref="#/ClinicHistory"
		buttonRight=""
		buttonRightHref="#/Plan"
		selected="plan">
		<!-- My Patients -->
		<div class="btnOption">
			<button
				on:click={() => {
					window.location.href = "#/Altas";
				}}
				class="btnBlue">{$_("ViewDoctor.button_altas")}</button>
			<button
				on:click={() => {
					modalSuccess = true;
				}}
				class="btnBlue">{$_("ViewDoctor.button_patient_add")}</button>
		</div>

		<div class="container py-2 border rounded shadow-sm my-3 bg-white">
			<!-- <div class="border-bottom d-flex justify-content-between">
			<h3 class="text-muted">Mis Pacientes</h3>
			<div
			on:click={() => { showListMyPatients=!showListMyPatients }}
			class="d-flex align-items-center text-muted cursor-pointer">
			<i class="gg-details-less"></i>
			</div>
		</div> -->

			{#if showListMyPatients}
				{#if !loadingPatient}
					<!-- Filtro -->
					<div class="d-flex justify-content-between form-group shadow p-4 rounded my-4 myCustom-colorBlue">
						<label for="filtro-altas">{$_("ViewDoctor.text_filter_name")}</label>
						<input
							bind:value={patientFilter}
							on:input={() => {
								listOfPatients = filterA(patientFilter, list);
							}}
							style="width:50%"
							placeholder=""
							class="form-control form-control-sm myCustom-inputRounded"
							type="text"
							name="filtro-altas"
							id="" />

						<label for="sortBy" class="">{$_("ViewDoctor.text_filter_order")}</label>
						<select
							bind:value={sortingParameter}
							on:change={() => {
								listOfPatients = sortyBy(list, sortingParameter, asc);
							}}
							style="width:25%"
							class="form-control form-control-sm myCustom-inputRounded"
							name="sortBy"
							id="">
							<option value="Apellido">{complexItems[0].label}</option>
							<option value="Fecha">{complexItems[1].label}</option>
							<option value="Pais">{complexItems[2].label}</option>
						</select>
						{#if !asc}
							<svg
								class="cursor-pointer"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								title="Desc"
								on:click={() => {
									asc = !asc;
									listOfPatients = sortyBy(list, sortingParameter, asc);
								}}>
								<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
							</svg>
						{:else}
							<svg
								class="cursor-pointer"
								xmlns="http://www.w3.org/2000/svg"
								title="Asc"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								on:click={() => {
									asc = !asc;
									listOfPatients = sortyBy(list, sortingParameter, asc);
								}}>
								<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
							</svg>
						{/if}
					</div>
					<!-- list -->
					<div class="barraName border rounded">
						<span class="spName">{$_("ViewDoctor.spName")}</span>
						<span class="spPathologi">{$_("ViewDoctor.spPathologi")}</span>
						<div class="date_country">
							<span class="spType">{$_("ViewDoctor.spDate")}</span>
							<span class="spCountry">{$_("ViewDoctor.spCountry")}</span>
						</div>
						<span class="spAction">{$_("ViewDoctor.spAction")}</span>
					</div>
					{#if listOfPatients.length > 0}
						<div class="rdcom-table-wrapper my-1">
							<div class="record svelte-g3a30z">
								{#each listOfPatients as alt}
									<div
										class="searched-doctor d-flex justify-content-between border px-2 svelte-g3a30z">
										<!-- <div class="headDetail_cont">
												{#if alt.head}
													<small class="headDetailC py-0"
														>{$_("ViewDoctor.text_Headboard")}</small>
												{:else}
													<small class="headDetailS py-0"
														>{$_("ViewDoctor.text_Session")}</small>
												{/if}
												<p class="variableA m-0 svelte-g3a30z">{alt.name}</p>
											</div> -->
										<div class="headDetail_cont">
											{#if alt.head}
												<img
													src="./img/medical.png"
													alt="Icono de Sesión"
													class="icono-session"
													style="width: 16px; height: 16px; margin-right: 2%;"
													title={$_("ViewDoctor.text_Headboard")} />
											{:else}
												<small class="headDetailS py-0" title={$_("ViewDoctor.text_Session")}
													>{$_("ViewDoctor.text_Session")}</small>
												<!-- <img
														src="./img/medical.png"
														alt="Icono de Sesión"
														class="icono-session"
														style="width: 16px; height: 16px;" title={$_("ViewDoctor.text_Session")}/> -->
											{/if}
											<p class="variableA m-0 svelte-g3a30z">{alt.name}</p>
										</div>

										{#if alt.hasOwnProperty("pathologies") && alt.pathologies[0] != null}
											<p class="variablepathologi m-0 svelte-g3a30z" title={alt.pathologies}>
												{alt.pathologies}
											</p>
										{:else}
											<p class="variablepathologi m-0 svelte-g3a30z">
												{$_("ViewDoctor.notPathologi")}
											</p>
										{/if}
										<div class="birthDate_cont">
											<p class="variableA m-0 svelte-g3a30z">
												{formatDate(alt.birthDate, $language)}
											</p>
											<p class="variablecountry m-0 svelte-g3a30z">{alt.country}</p>
										</div>
										<div>
											<div class="dropdown">
												<button
													on:click={() => {
														idpatient = alt.Id;
														patientName = alt.name;
														toggleMenu();
													}}
													class="menu_button py-0">
													<div class="icono-con-fondo"><img src="./img/more-vertical-o.svg" alt="Menú"></div>
												</button>
												<div
													class={isOpen ? "dropdown-content show" : "dropdown-content"}
													on:mouseleave={closeMenu}>
													<!-- Opción de Acceder -->
													<!-- svelte-ignore a11y-missing-attribute -->
													{#if alt.Id == idpatient}
														<a
															style="cursor: pointer;"
															on:click={() => {
																isOpen = false;
																$patient = alt;
																$patientLayout = alt;
																window.location.href = "#/Plan";
															}}>
															{$_("ViewDoctor.button_Access")}
														</a>
													{/if}
													<!-- Opción de Eliminar -->
													<!-- svelte-ignore a11y-missing-attribute -->
													{#if alt.Id == idpatient}
														<a
															style="cursor: pointer;"
															on:click={() => {
																modalDeleted = true;
																isOpen = false;
															}}>
															{$_("ViewDoctor.button_Delete")}
														</a>
													{/if}
												</div>
											</div>

											<!-- {#if alt.head}
													<button
														on:click={() => {
															$patient = alt;
															modalDeleted = true;
														}}
														class="btnDeleted py-0"><i class="gg-trash"></i></button>
												{:else}
													<button
														disabled="true"
														on:click={() => {
															$patient = alt;
															modalDeleted = true;
														}}
														class="btnDeletedDisabled py-0"
														><i class="gg-trash"></i></button>
												{/if}
												<button
													on:click={() => {
														$patient = alt;
														window.location.href = "#/Plan";
													}}
													class="btnBlue-access py-0"
													>{$_("ViewDoctor.button_Access")}</button> -->
										</div>
									</div>
								{/each}
							</div>
						</div>
					{/if}
				{/if}
				{#if loadingPatient}
					<div class="text-center p-2">
						<Loader text={false} />
						<p class="text-muted">{$_("ViewDoctor.loading")}</p>
					</div>
				{:else if listOfPatients.length == 0}
					<div class="messageSin">
						<p class="text-muted" style="margin: 10%;text-align: center;">
							{$_("ViewDoctor.notPatient")}...
						</p>
					</div>
				{/if}
			{/if}
		</div>
		<!-- <div class="container py-2 border rounded shadow-sm my-3 bg-white">
				<div class="messageSin" style="text-align: center;">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						style="width: {50}px; height: {50}px;">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
					</svg>
					<p class="text-muted cursor-pointer" style="text-align: center;" on:click={() => {}}>
						Agregar paciente
					</p>
				</div>
			</div> -->
	</DoctorListHeader>
	<!-- Pacientes Session (End) -->
	<!-- </LayoutNav> -->
</section>
{#if modalSuccess }
<LayoutModal
	tam="70"
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	{#if modalSuccess}
		<AddPatient />
	{/if}
</LayoutModal>
{/if}
{#if modalDeleted}
<LayoutModal
	tam="40"
	isOpenModal={modalDeleted}
	on:closeModal={() => {
		modalDeleted = false;
	}}>
	{#if modalDeleted}
		<DeletedPatient id={idpatient} name={patientName} on:closeDeleted={closeDeleted} />
	{/if}
</LayoutModal>
{/if}
<!-- CSS -->
<style>
	.rdcom-table-wrapper {
		min-height: 22vh;
		max-height: 35vh;
		overflow: auto;
	}

	/* .headDetailC {
		background-color: cornflowerblue;
		border: none;
		color: azure;
		border-radius: 10px;
		height: 20%;
		width: 8%;
		text-align: center;
	} */
	.headDetailS {
		background-color: rgb(255, 255, 255);
		border: none;
		color: azure;
		border-radius: 10px;
		height: 20%;
		width: 8%;
		text-align: center;
	}
	.dropdown-content {
		display: none;
		left: -100px;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 120px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.dropdown-content a {
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}

	.dropdown-content a:hover {
		background-color: #f1f1f1;
	}

	.show {
		display: block;
	}

	/* Center Placeholder */
	::placeholder {
		text-align: center;
	}

	.myCustom-inputRounded {
		border-radius: 15px;
	}
	.btnOption {
		margin: 1%;
	}
	.container {
		max-width: 98%;
		height: auto;
	}
	.variableA {
		width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.variablecountry {
		width: 120px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		/* text-align: center; */
	}
	.date_country {
		width: 300px;
		display: flex;
	}

	.variablepathologi {
		width: 500px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.menu_button {
		border: none;
		background: transparent;
	}

	/* .myCustom-table {
		width: 100%;
	}

	.myCustom-row {
		border: solid 1px #112c4a;
		color: #112c4a;
	} */
	.messageSin {
		width: 100%;
	}
	.headDetail_cont {
		width: 20%;
		display: flex;
		align-items: center;
	}
	.birthDate_cont {
		display: flex;
	}
	.barraName {
		background-color: #215273;
		height: 45px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
	}
	.spName {
		width: 20%;
		margin-left: 1%;
	}
	.spAction {
		margin-left: 3%;
	}
	.spPathologi {
		width: 510px;
	}
	.spCountry {
		width: 50%;
	}
	/* .spStatus {
		margin-right: 2%;
	} */
	.spType {
		width: 100%;
	}

	/* Estilos para pantallas de celular (hasta 768px) */
	@media (max-width: 768px) {
		.container {
			padding: 10px;
		}
		/* Agregar estilos específicos para pantallas de celular */
	}

	/* Estilos para pantallas de tablet (769px a 1024px) */
	@media (min-width: 769px) and (max-width: 1024px) {
		.container {
			padding: 20px;
		}
		/* Agregar estilos específicos para pantallas de tablet */
	}

	/* Estilos para pantallas de computadora (más de 1024px) */
	@media (min-width: 1325px) {
		.container {
			padding: 30px;
		}
		.barraName {
			background-color: #215273;
			height: 45px;
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-wrap: nowrap;
		}
		/* Agregar estilos específicos para pantallas de computadora */
	}

	/* .myCustom-buttom {
    background-color: #112c4a;
    border-color: #112c4a;
    color: #ffffff;
  } */

	/* .table-my-sessions {
    max-height: 35vh;
    overflow: auto;
  } */
</style>
