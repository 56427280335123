<script>
	//Components
	import MultiSelect from "../../components/MultiSelect.svelte";
	import FileAttach from "../../components/FileAttach.svelte";
	import Loader from "../../components/Loader.svelte";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import ConfirmationProfessional from "../../components/ConfirmationProfessional.svelte";

	// Helpers
	import { Auth } from "aws-amplify";
	//import data from "../../helpers/prov.json";
	import awsconfig from "../../aws-exports";
	import { currentSession } from "../../helpers/storage/sessions";
	import {
		professional_professional_add,
		utils_resources_get,
		utils_files_add,
		generic_account_get,
	} from "../../helpers/apiBackend";
	import { user, doctor, language } from "../../helpers/storage/stores";
	import { getUserInfo, getErrorsMessages } from "../../helpers/helpers.js";
	import { validateOnlyLetters, validateOnlyNumbers, validarUrl } from "../../helpers/validation";
	import { profesional_register_schema, profesional_register_schema_referente_patologia } from "../schema";

	// Svelte
	import { onMount } from "svelte";
	import { replace } from "svelte-spa-router";
	import { openModal } from "svelte-modals";
	import Select from "svelte-select";
	import { _ } from "svelte-i18n";

	Auth.configure(awsconfig);

	let langSelect = "es";
	if ($language == "es" || $language == "en") {
		console.log("ingreso");
		langSelect = $language;
	}
	let modalLoadingAnimation = false;
	let modalError = false;
	let modalViewProf = false;
	let urlError = false;
	let urlErrorPublic = false;
	let errorMessages = null;
	let numberaddress = "";
	let nameaddress = "";
	let billingState = "";
	//let countryMedical = "";
	let countryList = [];
	let countryId = "";
	let countryName = "";
	let stateName = "";
	let detailaddress = "";
	let [rol, username] = ["", ""];
	let errors = {};
	let provincias = [];
	//let typeAttachment = "Account__c";
	let idTypeAttachment = "";
	let especiality = [];
	let file_type = "Matricula";
	let imagStatus = true;
	let codA1 = "";
	let Phone1 = "";
	let codA2 = "";
	let Phone2 = "";

	let posibles = [
		"Matricula__c",
		"PersonTitle",
		"specializations",
		"Obra_social_Medicina_prepaga__c",
		"diseases",
		"Cuantos_pacientes_atendio__c",
	];
	//let value;
	const typeFile = ["fileAttMatricula", "fileAttFotoPerfil", "fileAttFirma"];

	// const EPSItems = [
	// 	{ value: "M", label: "Masculino" },
	// 	{ value: "F", label: "Femenino" },
	// ];
	// const complexItems = [
	// 	{ value: "M", label: "Masculino" },
	// 	{ value: "F", label: "Femenino" },
	// ];
	// const coverageItems = [
	// 	{ value: "OSDE", label: "OSDE" },
	// 	{ value: "SWISS MEDICAL", label: "SWISS MEDICAL" },
	// ];
	// const rolItems = [
	// 	{ value: "Madre", label: "MADRE" },
	// 	{ value: "Padre", label: "PADRE" },
	// 	{ value: "Enfermero", label: "ENFERMER@" },
	// ];
	let especialidadesList = [];
	let coberturasDeSalud = [];
	let patologias = [];
	// Update User Status
	/**
	 * @description
	 */
	async function updateUserStatus(status) {
		//console.log("updateUserStatus");
		let user;
		await Auth.currentAuthenticatedUser()
			.then(async (resultado) => {
				user = resultado;
				await Auth.updateUserAttributes(user, {
					"custom:StatusReg": status,
				});
				//console.log('Resultado bueno: ',user);
			})
			.catch((err) => {
				//console.log('error', err);
				user = $currentSession;
			});
	}
	function findSpecialtiesById(especiality, data) {
		console.log({ especiality });
		console.log({ data });
		const especialidadesEncontradas = [];

		for (const id of especiality) {
			const especialidad = data.find((item) => item.code === id);
			if (especialidad) {
				especialidadesEncontradas.push(especialidad);
			}
		}

		return especialidadesEncontradas;
	}

	function findPatologyById(pathology, pathologies) {
		console.log("findPatologyById");
		console.log({ pathology });
		let pathologyR = pathologies.find((item) => item.code === pathology);
		console.log({ pathologyR });
		return pathologyR;
	}
	/**
	 * @description
	 */
	function updateutils_files_add(doctor, idFileName, id) {
		console.log("updateutils_files_add", doctor, idFileName, id);
		if (doctor.hasOwnProperty(idFileName)) {
			if (doctor[idFileName].hasOwnProperty("detail")) {
				let fileAtt = doctor[idFileName].detail.fileData;
				console.log({ fileAtt });
				fileAtt.forEach(async (element) => {
					element.creator = id;
					element.attachment = id;
					console.log({ element });
					await utils_files_add(element).then((data) => {
						// if (!data.hasOwnProperty("message")) {
						if (!data.statusCode === 201) {
							imagStatus = false;
						} else {
							console.log('ProfessionalRecord - updateutils_files_add - status carga imgs data:  ', data);
						}
					});
				});
			}
		}
	}
	var expanded = false;

	// Show Checkboxes
	// function showCheckboxes() {
	// 	var checkboxes = document.getElementById("checkboxes");
	// 	if (!expanded) {
	// 		checkboxes.style.display = "block";
	// 		expanded = true;
	// 	} else {
	// 		checkboxes.style.display = "none";
	// 		expanded = false;
	// 	}
	// }
	function setSelectDisabled(disabled) {
		console.log({ disabled });
		const selectElement = document.getElementById("billingState");
		if (selectElement) {
			selectElement.disabled = disabled;
		}
	}
	async function loadStates(countryId) {
		provincias = [];
		setSelectDisabled(true);
		const resultado = await utils_resources_get("state", langSelect, countryId);
		const filteredData = resultado.filter(item => item.Id_country === countryId);
		provincias = filteredData;
		setSelectDisabled(false);
	}

	async function onCountrySelect(event) {
		console.log({ event });
		$doctor.BillingStateMedical = null;
		const selectedCountry = event.target.value;
		console.log({ selectedCountry });
		const selectedCountryData = countryList.find((c) => c.label === selectedCountry);
		console.log({ selectedCountryData });
		if (selectedCountryData) {
			$doctor.countryMedical = selectedCountryData;
			countryId = selectedCountryData.Id;
			countryName = selectedCountryData.label;
			await loadStates(countryId);
		}
	}

	async function onStateSelect(event) {
		console.log({ event });
		const selectedState = event.target.value;
		console.log({ selectedState });
		const selectedStateData = provincias.find((c) => c.label === selectedState);
		console.log({ selectedStateData });
		if (selectedStateData) {
			$doctor.BillingStateMedical = selectedStateData;
		}
	}

	// Finalizar
	/**
	 * @description
	 */
	async function btnConfirm() {
		try {
			$doctor.especiality = especiality;
			$doctor.Telefono_consultorio__c = codA1 + "-" + Phone1;
			$doctor.Celular_consultorio__c = codA2 + "-" + Phone2;
			$doctor.specializations = findSpecialtiesById($doctor.especiality, especialidadesList);
			$doctor.pathology = findPatologyById($doctor.patologia, patologias);

			let credentials = {
				Matricula__c: $doctor.Matricula__c,
				PersonTitle: $doctor.PersonTitle,
				specializations: $doctor.especiality,
				Obra_social_Medicina_prepaga__c:
					$doctor.BillingCountry.code == "AR" ? $doctor.coberturas : ["Particular"],
				Cuantos_pacientes_atendio__c: $doctor.cantPaciente ? $doctor.cantPaciente : 0,
				diseases: $doctor.refPatologia ? $doctor.patologia : {},
				refPatologia: $doctor.refPatologia,
			};
			console.log({ $doctor });
			await profesional_register_schema.validate(credentials, { abortEarly: false }).then(async () => {
				//modalLoadingAnimation = true;
				if ($currentSession.attributes.profile == "Profesional_de_Salud") {
					$doctor.rol = "Professional";
				}
				
				$doctor.username = $currentSession.attributes.email;
				modalViewProf = true;
			});
		} catch (error) {
			console.log({ error });
			console.log("Error en la catch");
			window.scroll(0, 0);
			errorMessages = getErrorsMessages(error.errors, posibles);
		}
	}
	async function btnfinaliza() {
		(async () => {
			let saveData = await professional_professional_add($doctor, $currentSession);
			return saveData;
		})().then(async (result) => {
			console.log("btnfinaliza result",result)
			if (result.statusCode == 201) {
				console.log('btnfinaliza result.data.data ', result.data.data);
				let Id = result.data.Id;
				$user.Id = Id; 
				typeFile.forEach((item) => {
					console.log({ item });
					updateutils_files_add($doctor, item, Id);
				});
				
				await updateUserStatus("true");
				//let resul = await getUserInfo(rol, username);
				$user = await generic_account_get("", Id, "Professional", "");
				if ($user != {}) {
					replace("/Home");
				} else {
					window.location.href = "#/LoginError";
				}
				console.log("finalizo");
			} else {
				errorMessages = result.message || "Error al guardar el usuario";
				modalLoadingAnimation = false;
				modalError = true;
			}
		});
	}

	// On Mount
	/**
	 * @description
	 */
	onMount(async () => {
		window.scroll(0, 0);
		especialidadesList = await utils_resources_get("specialties", $language);
		countryList = await utils_resources_get("country", $language);
		console.log("CountryList",countryList[0].Id);
		coberturasDeSalud = await utils_resources_get("prepaidMedicine", $language);
		patologias = await utils_resources_get("pathologies", $language);
		console.log({ patologias });

		//provincias = await utils_resources_get("state", langSelect, countryList[0].Id);
		//$doctor.Universidad__c = credentials.Universidad__c
		$doctor.realizado_publicaciones = "No";
		$doctor.medicoCabecera = true;
		$doctor.refPatologia = false;
		$doctor.fileAttFirma = "";
		$doctor.fileAttMatricula = "";
		countryName = $doctor.countryMedical?.label;
		stateName = $doctor.BillingStateMedical?.label;
		console.log({ $doctor });
		console.log(especialidadesList[0].label);
		if (typeof especialidadesList[0].label === "undefined" || especialidadesList[0].label == "Undefined") {
			//localStorage.clear();
			//window.location.href = "/";
		}
		//await loadStates(countryList[0].Id);
		//$doctor.patologia = { value: "", label: "" };
	});
</script>

<div>
	<!-- svelte-ignore a11y-missing-attribute -->
	<img class="imgBack" src="/img/backrount.jpg" />

	<div class="content">
		<div class="content-wrappe">
			<div class="contact-form">
				<div
					class="conteiner-img"
					style="
    display: inline-flex;
    text-align: center;
    align-items: center;
">
					<!-- <img src="./img/Frame 106.png" alt="logo" /> -->
					<img src="img/new_logo_redondo.png" alt="logo" width="117" height="117" />
					<div class="col-sm-10">
						<div class="title">
							<h4 style="color: #112C4A;">
								{$_("ProfessionalRecord.title")}:
							</h4>
						</div>
					</div>
				</div>
				{#if especialidadesList.length > 0}
					<form class="register-form" on:submit|preventDefault={btnConfirm}>
						<div class="border shadow-sm p-4 rounded bg-white" style="width: 100%;">
							<h1 class="title">{$_("ProfessionalRecord.text_title_formation")}</h1>
							<div class="dataFormationdiv row">
								<div class="col-sm-6">
									<div class="titulo" style="d-flex justify-content-center">
										<small class="text-muted">{$_("ProfessionalRecord.text_title_education")}</small
										><small class="error">*</small>
										<input
											class="form-control"
											type="text"
											placeholder={$_("ProfessionalRecord.text_title_education")}
											bind:value={$doctor.PersonTitle} />
										{#if (errorMessages != null && $doctor.PersonTitle == "") || (errorMessages != null && $doctor.PersonTitle == undefined)}
											<div class="conteiner-error">
												<small class="messageError"
													>{$_("ProfessionalRecord.text_mandatory")}</small>
											</div>
										{/if}
									</div>
								</div>
								<div class="col-sm-6">
									<div class="universidad" style="d-flex justify-content-center">
										<small class="text-muted">{$_("ProfessionalRecord.text_Universidad")}</small>
										<input
											class="form-control"
											type="text"
											placeholder={$_("ProfessionalRecord.text_Universidad")}
											bind:value={$doctor.Universidad__c} />
									</div>
								</div>
								<div class="col-sm-12">
									<!-- ESPECIALIDADES -->
									{#if especialidadesList.length > 0}
										<div class="cobertura" style="d-flex justify-content-center">
											<small class="text-muted">{$_("ProfessionalRecord.text_Specialties")}</small
											><small class="error">*</small>
											<MultiSelect id="lang" bind:value={especiality}>
												{#each especialidadesList as especiality}
													<!-- <option value={JSON.stringify(especiality)}
														>{especiality.label}</option> -->
													<option value={especiality.code}>{especiality.label}</option>
												{/each}
											</MultiSelect>

											{#if errorMessages != null && especiality.length == 0}
												<div class="conteiner-error">
													<small class="messageError"
														>{errorMessages.specializations
															? errorMessages.specializations
															: $_("ProfessionalRecord.text_mandatory")}</small>
												</div>
											{/if}
										</div>
									{/if}
								</div>
								<div class="col-sm-12">
									<div class="postgrado" style="d-flex justify-content-center">
										<small class="text-muted">{$_("ProfessionalRecord.text_doctorate")}</small>
										<textarea
											class="input-log"
											bind:value={$doctor.Postgrado__c}
											placeholder=""
											maxlength="255"
											name="Text1"
											cols="40"
											rows="2" />
									</div>
								</div>
								<div class="col-sm-6">
									<div class="matriculaN" style="d-flex justify-content-center">
										<small class="text-muted">{$_("ProfessionalRecord.text_Tuition")}</small><small
											class="error">*</small>
										<input
											class="form-control"
											type="text"
											placeholder={$_("ProfessionalRecord.text_Tuition")}
											bind:value={$doctor.Matricula__c} />
										{#if (errorMessages != null && $doctor.Matricula__c == "") || (errorMessages != null && $doctor.Matricula__c == undefined)}
											<div class="conteiner-error">
												<small class="messageError"
													>{$_("ProfessionalRecord.text_mandatory")}</small>
											</div>
										{/if}
									</div>
								</div>
								<div class="col-sm-6">
									<div class="matriculaP" style="d-flex justify-content-center">
										<small class="text-muted">{$_("ProfessionalRecord.text_Tuition_prov")}</small>
										<input
											class="form-control"
											type="text"
											placeholder={$_("ProfessionalRecord.text_Tuition_prov")}
											bind:value={$doctor.Matricula_provincial__c} />
									</div>
								</div>
								<div class="col-sm-12">
									<div class="cargarMatricula" style="d-flex justify-content-center">
										<small class="text-muted">{$_("ProfessionalRecord.text_Upload_license")}</small>
										<FileAttach
											{idTypeAttachment}
											multipleState={true}
											photoState={true}
											file_type="Matricula"
											on:dataFile={async (e) => {
												$doctor.fileAttMatricula = e.detail;
											}} />
										<!-- // -->
									</div>
								</div>
								<div class="col-sm-12">
									<div class="linkedin" style="d-flex justify-content-center">
										<small class="text-muted"
											>{$_("ProfessionalRecord.text_networks_Linkedin")}</small>
										<input
											class="form-control"
											type="text"
											placeholder="https://www.example.com"
											on:change={() => {
												urlError = validarUrl($doctor.linkedin);
												$doctor.linkedin = urlError ? "" : $doctor.linkedin;
											}}
											bind:value={$doctor.linkedin} />
										{#if urlError}
											<span class="error">{$_("ProfessionalRecord.text_networks_error")} </span>
										{/if}
									</div>
								</div>
								<div class="col-sm-12">
									<div class="cargarfirma" style="d-flex justify-content-center">
										<small class="text-muted"
											>{$_("ProfessionalRecord.text_Upload_Signature")}</small>
										<FileAttach
											{idTypeAttachment}
											multipleState={false}
											photoState={true}
											formatState={true}
											file_type="Firma"
											on:dataFile={async (e) => {
												$doctor.fileAttFirma = e.detail;
											}} />
									</div>
									{#if $doctor.fileAttFirma == ""}
										<span style="font-style: italic;" class="text-muted"
											>{$_("ProfessionalRecord.text_message_Signature")}</span>
									{/if}
								</div>
							</div>
						</div>
						<br />
						<div class="border shadow-sm p-4 rounded bg-white" style="width: 100%;">
							<h1 class="title">{$_("ProfessionalRecord.text_title_consulting_room")}</h1>
							<div class="dataConsultordiv row">
								<div class="col-sm-12">
									<div class="nombre" style="margin: 2%;d-flex justify-content-center">
										<small class="text-muted"
											>{$_("ProfessionalRecord.text_title_consulting_room_name")}</small>
										<input
											class="form-control"
											type="text"
											placeholder={$_("ProfessionalRecord.text_title_consulting_room_name")}
											bind:value={$doctor.Nombre_de_consultorio__c} />
									</div>
									<div>
										<div class="domicilioID d-flex">
											<!-- Street -->
											<div class="col-sm-7">
												<small class="text-muted">{$_("ProfessionalRecord.text_addres")}</small>
												<input
													type="text"
													class="form-control"
													bind:value={$doctor.R1_consultorioStreetName__c} />
											</div>
											<!-- Number -->
											<div class="col-sm-2">
												<small class="text-muted"
													>{$_("ProfessionalRecord.text_addres_number")}</small>
												<input
													type="text"
													class="form-control"
													bind:value={$doctor.R1_consultorioStreetNumber__c}
													on:keypress={validateOnlyNumbers} />
											</div>
											<!-- Aparment -->
											<div class="col-sm-3">
												<small class="text-muted"
													>{$_("ProfessionalRecord.text_addresApartment")}</small>
												<input
													type="text"
													class="form-control"
													bind:value={$doctor.R1_consultorioStreetDetail__c} />
											</div>
										</div>
									</div>
									<div>
										<div class="row">
											<div class="data-domicilio" style=" width: 100%; margin: 2%;">
												<div
													class="paisID"
													style="
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
">
													<div
														class=""
														style="
    display: flex;
    flex-direction: column;
    width: 47%;
">
														<small class="text-muted"
															>{$_("PersonalData.text_country")}</small>
														<select
															class="gender"
															bind:value={countryName}
															on:change={onCountrySelect}>
															{#each countryList as country}
																<option value={country.label}>
																	{country.label}
																</option>
															{/each}
														</select>
														<!-- <input type="text" class="form-control" bind:value={credentials.country} /> -->
														{#if errors.country}
															<p class="error">{errors.country}</p>
														{/if}
													</div>
													<!-- City / State -->
													<div
														class=""
														style="
    display: flex;
    flex-direction: column;
    width: 47%;
">
														<small class="text-muted"
															>{$_("ProfessionalRecord.text_BillingState")}</small>
														<select
															id="billingState"
															class="gender"
															bind:value={stateName}
															on:change={onStateSelect}
															on:blur={onStateSelect}>
															{#each provincias as provincia}
																<option value={provincia.label}>
																	{provincia.label}
																</option>
															{/each}
														</select>
														<!-- <select
															class="city"
															style="width: 100%;"
															bind:value={billingState}>
															{#each provincias as provincia}
																<option value={provincia.label}>
																	{provincia.label}
																</option>
															{/each}
														</select> -->
													</div>
													<!-- Country -->
													<!-- <div class="col-sm-6">
														<small class="text-muted"
															>{$_("ProfessionalRecord.text_Country")}</small>
														<input type="text" class="form-control" bind:value={country} />
													</div> -->
												</div>
												<div class="telefonoID d-flex">
													<div class="col-sm-6">
														<small class="text-muted"
															>{$_("ProfessionalRecord.text_phone")}</small>
														<div>
															<input
																class="codigoArea"
																type="text"
																bind:value={codA1}
																on:keypress={validateOnlyNumbers}
																onpaste="return false;" />
															<small class="text-muted"> - </small>
															<input
																type="text"
																class="form-control-cel"
																bind:value={Phone1}
																on:keypress={validateOnlyNumbers}
																onpaste="return false;" />
														</div>
														<!-- Celudar Consultorio -->
														<!-- <div class="celular" style="d-flex justify-content-center">
										<small class="text-muted">Celular del consultorio</small>
										<input
											class="input-log"
											type="text"
											placeholder="Celular"
											bind:value={$doctor.Celular_consultorio__c}
											on:keypress={validateOnlyNumbers} />
									</div> -->
													</div>
													<div class="col-sm-6">
														<!-- Telefono Consultorio -->

														<small class="text-muted"
															>{$_("ProfessionalRecord.text_cell_phone")}</small>
														<div>
															<input
																class="codigoArea"
																type="text"
																bind:value={codA2}
																on:keypress={validateOnlyNumbers}
																onpaste="return false;" />
															<small class="text-muted"> - </small>
															<input
																type="text"
																class="form-control-cel"
																bind:value={Phone2}
																on:keypress={validateOnlyNumbers}
																onpaste="return false;" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- <div class="col-sm-6">
									<div class="apellido" style="d-flex justify-content-center">
										<small class="text-muted">Direccion del consultorio</small>
										<input
											class="input-log"
											type="text"
											placeholder="Direccion"
											bind:value={$doctor.Direccion_consultorio__c} />
									</div>
								</div> -->

								<div class="row">
									<div class="data-domicilio d-flex" />
								</div>
							</div>
						</div>
						<br />
						{#if $doctor.BillingCountry && $doctor.BillingCountry.code == "AR"}
							<div class="border shadow-sm p-4 rounded bg-white" style="width: 100%;">
								<h1 class="title">{$_("ProfessionalRecord.text_coverage")}</h1>
								<div class="col-sm-12">
									{#if coberturasDeSalud.length !== 0}
										<!-- Cobertura de Salud -->
										<div class="cobertura" style="d-flex justify-content-center">
											<!-- <small class="text-muted">{$_("ProfessionalRecord.text_coverage")}</small><small
											class="error">*</small> -->
											<MultiSelect id="lang" bind:value={$doctor.coberturas}>
												{#each coberturasDeSalud as cobertura}
													<option value={cobertura.value}>{cobertura.label}</option>
												{/each}
											</MultiSelect>
											{#if errorMessages != null && $doctor.coberturas.length == 0}
												<div class="conteiner-error">
													<small class="messageError"
														>{errorMessages.Obra_social_Medicina_prepaga__c
															? errorMessages.Obra_social_Medicina_prepaga__c
															: $_("ProfessionalRecord.text_mandatory")}</small>
												</div>
											{/if}
										</div>
									{/if}
								</div>
							</div>
						{/if}
						<br />
						<div class="border shadow-sm p-4 rounded bg-white" style="width: 100%;">
							<!-- Referente en Patologia -->

							<div class="block">
								<h1 class="title">{$_("ProfessionalRecord.text_title_referent")}</h1>
								<!-- <h4 class="title-welcome">REFERENTE EN PATOLOGÍA</h4> -->
								<!-- svelte-ignore a11y-label-has-associated-control -->
								<label class="cbox1">
									{$_("ProfessionalRecord.text_message_referent")}
									<label class="cbox1"
										><br />
										<input
											name="refPatologia"
											type="radio"
											value="first_checkbox"
											on:click={() => {
												$doctor.refPatologia = true;
											}} />{$_("ProfessionalRecord.button_yes")}
									</label>
									<label class="cbox1"
										><input
											name="refPatologia"
											type="radio"
											value="first_checkbox"
											on:click={() => {
												$doctor.refPatologia = false;
											}}
											checked />{$_("ProfessionalRecord.button_not")}
									</label>
								</label>
							</div>

							<!-- Patologias referentes -->
							{#if $doctor.refPatologia}
								<div class="col-sm-12">
									<small class="text-muted">{$_("ProfessionalRecord.text_select_referent")}</small
									><small class="error">*</small>
									<select bind:value={$doctor.patologia} class="form-control" name="" id="patologID">
										{#if Array.isArray(patologias)}
											{#each patologias as myDisease}
												<option value={myDisease.code}>{myDisease.label}</option>
											{/each}
										{/if}
									</select>
									<!-- <Select
										class="input-log"
										id="select-rol"
										items={patologias}
										bind:value={$doctor.patologia}
										placeholder="Seleccione..." /> -->

									{#if $doctor.refPatologia == true && errorMessages != null && $doctor.patologia == undefined}
										<div class="conteiner-error">
											<small class="messageError"
												>{errorMessages.diseases
													? errorMessages.diseases
													: $_("ProfessionalRecord.text_mandatory")}</small>
										</div>
									{/if}
								</div>
								<br />
								<div class="col-sm-12">
									<!-- Pacientes Atendidos -->

									<small class="text-muted">{$_("ProfessionalRecord.text_cant_pathology")}</small
									><small class="error">*</small>

									<input
										class="input-log"
										type="Number"
										placeholder="N°"
										min="0"
										bind:value={$doctor.cantPaciente} />
									{#if (errorMessages != null && $doctor.cantPaciente == null) || (errorMessages != null && $doctor.cantPaciente == "")}
										<div class="conteiner-error">
											<small class="messageError"
												>{errorMessages.Cuantos_pacientes_atendio__c
													? errorMessages.Cuantos_pacientes_atendio__c
													: $_("ProfessionalRecord.text_mandatory")}</small>
										</div>
									{/if}
								</div>
								<br />
								<!-- Investigacion -->
								<div class="dni" style="d-flex justify-content-center">
									<!-- <p>INVESTIGACIÓN</p> -->
									<!-- svelte-ignore a11y-label-has-associated-control -->
									<label>{$_("ProfessionalRecord.text_investigation")}</label>
									<label class="cbox1"
										><input
											name="investigacines"
											type="radio"
											value="first_checkbox"
											on:click={() => {
												$doctor.Realiza_investigacion = "Si";
											}} />{$_("ProfessionalRecord.button_yes")}</label>
									<label class="cbox1"
										><input
											name="investigacines"
											type="radio"
											value="first_checkbox"
											on:click={() => {
												$doctor.Realiza_investigacion = "No";
											}}
											checked />{$_("ProfessionalRecord.button_not")}</label>
								</div>

								<!-- Publicaciones -->
								<div class="dni" style="d-flex justify-content-center">
									<!-- <p>PUBLICACIONES</p> -->
									<!-- svelte-ignore a11y-label-has-associated-control -->
									<label>{$_("ProfessionalRecord.text_publications")}</label>
									<label class="cbox1"
										><input
											name="publicaciones"
											type="radio"
											value="first_checkbox"
											on:click={() => {
												$doctor.realizado_publicaciones = "Si";
											}} />
										{$_("ProfessionalRecord.button_yes")}</label>
									<label class="cbox1"
										><input
											name="publicaciones"
											type="radio"
											value="first_checkbox"
											on:click={() => {
												$doctor.realizado_publicaciones = "No";
											}}
											checked />{$_("ProfessionalRecord.button_not")}</label>
								</div>

								{#if $doctor.realizado_publicaciones == "Si"}
									<div class="col-sm-12">
										<!-- Link Publicaciones -->
										<div class="block" style="d-flex justify-content-center">
											<small class="text-muted"
												>{$_("ProfessionalRecord.text_link_publications")}</small>
											<input
												class="input-log"
												type="text"
												placeholder="https://www.example.com"
												on:change={() => {
													urlErrorPublic = validarUrl($doctor.Link_de_publicaciones);
													$doctor.Link_de_publicaciones = urlErrorPublic
														? ""
														: $doctor.Link_de_publicaciones;
												}}
												bind:value={$doctor.Link_de_publicaciones} />
											{#if urlErrorPublic}
												<span class="error"
													>{$_("ProfessionalRecord.text_networks_error")}
												</span>
											{/if}
										</div>
									</div>
								{/if}
							{/if}
						</div>

						<!-- <div class="block">
							<h1 class="title-welcome">Formacion</h1>
						</div>

						<div class="nombre" style="d-flex justify-content-center">
							<small class="titulo">TITULO (*)</small>
							<input
								class="input-log"
								type="text"
								placeholder="Titulo"
								bind:value={$doctor.PersonTitle} />
							{#if (errorMessages != null && $doctor.PersonTitle == "") || (errorMessages != null && $doctor.PersonTitle == undefined)}
								<div class="conteiner-error">
									<small class="messageError">{errorMessages.PersonTitle}</small>
								</div>
							{/if}
						</div>
						<div class="apellido" style="d-flex justify-content-center">
							<small class="titulo">UNIVERSIDAD</small>

							<input
								class="input-log"
								type="text"
								placeholder="Universidad"
								bind:value={$doctor.Universidad__c} />
						</div>

						
						{#if especialidadesList.length > 0}
							<div class="cobertura" style="d-flex justify-content-center">
								<small class="titulo">ESPECIALIDADES (*)</small>
								<MultiSelect id="lang" bind:value={$doctor.especiality}>
									{#each especialidadesList as especiality}
										<option value={JSON.stringify(especiality)}>{especiality.label}</option>
									{/each}
								</MultiSelect>
								{#if errorMessages != null && $doctor.especiality.length == 0}
									<div class="conteiner-error">
										<small class="messageError"
											>{errorMessages.specializations
												? errorMessages.specializations
												: $_("ProfessionalRecord.text_mandatory")}</small>
									</div>
								{/if}
							</div>
						{/if} -->

						<!-- <div class="pais" style="d-flex justify-content-center">
							<small class="titulo">PAIS</small>
							<input class="input-log" type="text" placeholder="Pais" bind:value={$doctor.pais} />
						</div> -->

						<!-- </div> -->

						<!-- Medico Cabecera -->

						<!-- <div class="border shadow-sm rounded" style="width: 100%;">
							<div class="messageMedical">
								<span style="font-style: italic;" class="text-muted"
									>Ser médico de cabecera permite que un paciente lo seleccione para administrar su
									plan de seguimiento</span>
							</div>
							<div class="dni" style="d-flex justify-content-center">
								<small class="titulo">MÉDICO DE CABECERA</small>
								
								<label>¿Desea ser medico de cabera?</label>
								<label class="cbox1"
									><input
										name="MedicoC"
										type="radio"
										value="first_checkbox"
										on:click={() => {
											$doctor.medicoCabecera = true;
										}}
										checked /> SI</label>
								<label class="cbox1"
									><input
										name="MedicoC"
										type="radio"
										value="first_checkbox"
										on:click={() => {
											$doctor.medicoCabecera = false;
										}} /> NO</label>
							</div>
						</div> -->

						<!-- </div> -->
					</form>
				{:else}
					<div class="text-center">
						<br />
						<Loader text={false} />
						<div>
							<p class="text-muted">{$_("ProfessionalRecord.loading")}</p>
						</div>
					</div>
				{/if}
				<div class="block2">
					<br />
					<button
						on:click={() => {
							$doctor = $doctor;
							replace("/PersonalInformation/Profesional_de_Salud");
						}}
						id="nextButton"
						class="btnWhite">{$_("ProfessionalRecord.button_return")}</button>
					<button
						on:click={() => {
							btnConfirm();
						}}
						id="nextButton"
						class="btnBlue">{$_("ProfessionalRecord.button_Finish")}</button>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Modal Cargando -->
<LayoutModal
	tam="30"
	isOpenModal={modalLoadingAnimation}
	on:closeModal={() => {
		modalLoadingAnimation = false;
	}}>
	<div class="text-center p-3">
		<Loader text={false} />
		<p class="text-muted">{$_("ProfessionalRecord.save")}</p>
	</div>
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="p-3 text-center">
		<p>{errorMessages}</p>
		<button
			on:click={() => {
				modalError = false;
			}}
			class="btnBlue">{$_("ProfessionalRecord.button_Cancel")}</button>
	</div>
</LayoutModal>

<!-- Modal Previsualizacion -->
<LayoutModal
	tam="70"
	isOpenModal={modalViewProf}
	on:closeModal={() => {
		modalViewProf = false;
	}}>
	{#if modalViewProf}
		<ConfirmationProfessional body={$doctor} />
	{/if}
	<div class="text-center">
		<button
			on:click={() => {
				modalViewProf = false;
				replace("/PersonalInformation/Profesional_de_Salud");
			}}
			class="btnWhite">{$_("ProfessionalRecord.button_update")}</button>
		<button
			class="btnBlue"
			on:click={() => {
				//deleteHeadM(theRecord.Id);
				modalLoadingAnimation = true;
				modalViewProf = false;
				btnfinaliza();
			}}>{$_("ProfessionalRecord.button_confirm")}</button>
	</div>
</LayoutModal>

<style>
	.content {
		max-width: 1170px;
		margin-left: auto;
		margin-right: auto;
		padding: 1.5em;
	}
	.imgBack {
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 100%;
	}
	.form-control-cel {
		width: 60%;
	}
	.content-wrappe {
		width: 900px;
		position: relative;
		height: max-content;
		padding: 17px;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		justify-content: space-between;
		overflow: hidden;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}
	.contact-form {
		padding: 0.7em;
	}

	.contact-form form {
		margin: 0;
		padding: 1em;
	}
	.codigoArea {
		width: 30%;
	}
	.contact-form input {
		padding: 0.7em;
	}
	.input-log {
		width: 100%;
	}
	.contact-form form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: baseline;
		padding-top: 0.5rem;
	}
	.btnBlue {
		height: 45px;
		width: 90px;
		text-align-last: center;
	}
	.telefonoID {
		margin-top: 2%;
	}
	.btnWhite {
		height: 45px;
		width: 90px;
		text-align-last: center;
	}
	.block {
		text-align-last: left;
		width: 94%;
		grid-column: 1/3;
	}
	.block2 {
		text-align-last: end;
		width: 94%;
		grid-column: 1/3;
	}
	/* .next {
		height: 3.2rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #f7fafc;
		border-radius: 5px;
		background-color: #112c4a;
	} */
	.error {
		color: red;
	}
	/* .title-welcome {
		color: #112c4a;
		width: 100%;
		margin: initial;
	} */
	.title {
		font-size: 20px;
		text-align: center;
		color: #112c4a;
	}
	.titulo {
		color: #112c4a;
		font-weight: bold;
	}
	.postgrado {
		/* width: 95%; */
		color: #112c4a;
		font-weight: 600;
		margin-block: 2%;
	}
	/* .telefono {
		width: 95%;
		color: #112c4a;
		font-weight: 600;
	} */
	.linkedin {
		/* width: 95%; */
		color: #112c4a;
		font-weight: 600;
		margin-block: 2%;
	}
	/* .pais {
		width: 95%;
		color: #112c4a;
		font-weight: 600;
		margin-block: 2%;
	} */
	.nombre {
		width: 95%;
		color: #112c4a;
		font-weight: 600;
		margin-block: 2%;
	}

	/* .apellido {
		width: 95%;
		color: #112c4a;
		font-weight: 600;
		margin-block: 2%;
	} */
	/* .genero {
		width: 95%;
		color: #112c4a;
		font-weight: 600;
		margin-block: 2%;
	} */
	.cobertura {
		/* width: 95%; */
		color: #112c4a;
		/* font-weight: 600; */
		margin-block: 2%;
		margin-left: 2%;
	}
	.dni {
		width: 95%;
		color: #112c4a;
		font-weight: 600;
		margin-block: 2%;
		text-align: center;
	}
	.error {
		color: red;
		font-size: 15px;
		font-style: italic;
	}

	/* .multiselect {
    width: 420px;
  }

  .selectBox {
    width: 100%;
    position: relative;
  }

  .selectBox select {
    width: 420px;
    
  }

  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  #checkboxes {
    display: none;
  }

  #checkboxes label {
    display: block;
  }

  #checkboxes label:hover {
    background-color: #1e90ff;
    width: auto;
  }
  .selectAltura {
    display: block;
    height: 2.5rem;
  } */

	.conteiner-error {
		color: red !important;
	}
	@media screen and (max-width: 1000px) {
		.contact-form form {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
	}
</style>
