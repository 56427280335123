<script>
    export let loadingText = 'Cargando'
    export let text = true
</script>

<div class="d-flex justify-content-center" >
    <div class="spinner-grow" role="status">
      <span class="sr-only"></span>
    </div>
</div>
{#if text}
    <div class="text-center mt-2">
        <p class="text-muted">{loadingText}</p>
    </div>
{/if}