/**
 * @description
 */
export const validateOnlyLetters = (e) => {
	let key = e.keyCode || e.which;
	let teclado = String.fromCharCode(key).toLowerCase();
	let letras = " áéíóúabcdefghijklmnñopqrstuvwxyz";
	let especiales = [8, 37, 39, 46];
	let teclado_especial = false;
	for (let i in especiales) {
		if (key == especiales[i]) {
			teclado_especial = true;
			break;
		}
	}
	if (letras.indexOf(teclado) == -1 && !teclado_especial) {
		e.preventDefault();
	}
};

/**
 * @description
 */
export const validateOnlyNumbers = (e) => {
	let key = e.keyCode || e.which;
	let teclado = String.fromCharCode(key).toLowerCase();
	let letras = "0123456789+-";
	let especiales = " áéíóúabcdefghijklmnñopqrstuvwxyz";
	let teclado_especial = false;

	for (let i in especiales) {
		if (key == especiales[i]) {
			teclado_especial = true;
			break;
		}
	}
	if (letras.indexOf(teclado) == -1 && !teclado_especial) {
		e.preventDefault();
	}
};

/**
 * @description
 */
export const validateOnlyDate = (birthdate) => {
	let myDate = document.getElementById("startPersonBirthdate");
	let today = new Date();
	let dd = today.getDate();
	let mm = today.getMonth() + 1;
	let yyyy = today.getFullYear();
	if (dd < 10) dd = "0" + dd;

	if (mm < 10) mm = "0" + mm;

	today = yyyy + "-" + mm + "-" + dd;
	myDate.setAttribute("max", today);

	let date = myDate.value;
	if (date == "") {
		myDate.style.color = "red";
		myDate.value = "";
		birthdate = "";
	}
	if (Date.parse(date)) {
		if (date > today) {
			myDate.style.color = "red";
			myDate.value = "";
			birthdate = "";
		}
	}
	console.log({ date });
	setTimeout(function () {
		myDate.style.color = "black";
		//myDate.value = "";
	}, 3000);
	return birthdate;
};

/**
 * @description
 */
export const validarUrl = (url) => {
	console.log("validarUrl");
	// Expresión regular que valida la sintaxis de una URL
	var regex = /^(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;
	// Se devuelve verdadero si la URL coincide con la expresión regular
	return !regex.test(url);
};
