<script>
	import { Auth } from "aws-amplify";
	import { replace } from "svelte-spa-router";
	import Modal from "../../components/Modal.svelte";
	import { user } from "../../helpers/storage/stores";
	import awsconfig from "../../aws-exports";
	import { Modals, closeModal, openModal, modals } from "svelte-modals";
	import { fade } from "svelte/transition";
	import { verification_code_schema } from "../schema";
	import { getErrorsMessages } from "../../helpers/helpers";
	import { validateOnlyNumbers } from "../../helpers/validation";
	import { _ } from "svelte-i18n";

	// Variables
	export let params;
	Auth.configure(awsconfig);
	let username = params.email;
	let code = "";
	let rol = params.rol;
	let posibles = ["codigo"];
	let error_messages = null;

	/**
	 *@description
	 */
	async function confirmSignUp() {
		try {
			await verification_code_schema.validate({ code }, { abortEarly: false }).then(async () => {
				let result = await Auth.confirmSignUp(username, code);
				replace("/");
			});
		} catch (error) {
			//TODO: AGREGAR MENSAJE SI TIRA ERROR
			//CodeMismatchException
			let message;
			switch (error.name) {
				case "CodeMismatchException":
					message = "Codigo invalido , por favor intentelo de nuevo.";
					break;

				default:
					message = "Error en la peticion , intentelo de nuevo.";
					break;
			}
			error.errors
				? (error_messages = getErrorsMessages(error.errors, posibles))
				: openModal(() => import("../../components/ModalValidationsForm.svelte"), {
						title: "Error",
						message: message,
					});
		}
	}
</script>

<svelte:head>
	<title>RDCOM - {$_("EmailCode.text_title")}</title>
	<link rel="icon" href="/img/new_favicon.png">
</svelte:head>
<div>
	<!-- svelte-ignore a11y-missing-attribute -->
	<img class="imgBack" src="/img/backrount.jpg" />
	<div class="logo">
		<!-- <img src="img/Frame 106.png" alt="logo" /> -->
		<img src="img/new_logo_redondo.png" alt="logo" width="117" height="117" />
	</div>
	<div class="register-conteiner">
		<div class="register-info-conteiner">
			<form class="register-form" on:submit|preventDefault={confirmSignUp}>
				<div class="text-center">
					<h1 class="title-welcome">{$_("EmailCode.text_title")}</h1>
				</div>

				<div class="email" style="d-flex justify-content-center">
					<p>{$_("EmailCode.text_email")}</p>
					<input disabled class="input-log" type="text" placeholder="name@domain.com" value={params.email} />
				</div>
				<div class="confirmar-email" style="d-flex justify-content-center">
					<p>{$_("EmailCode.text_code")}</p>
					<input
						class="input-log"
						type="text"
						maxlength="6"
						placeholder={$_("EmailCode.text_placeholder_input")}
						bind:value={code}
						on:keypress={validateOnlyNumbers} />
					{#if error_messages != null && code == ""}
						<div class="conteiner-error">
							<small class="messageError">{error_messages.codigo}</small>
						</div>
					{/if}
				</div>
				<button class="btnRegister">{$_("EmailCode.button_confirm")}</button>
				<div class="text-center">
					<br />
					<p class="span_register">{$_("EmailCode.text_message")}</p>
				</div>
			</form>
			<!-- <p class="span_register">Mejor en otro momento</p> -->
			<a href="/Login" on:click={() => replace("/")}>{$_("EmailCode.button_login")}</a>
		</div>
		<div class="conteiner-img">
			<!-- <h4 class="title-welcome">Ya falta poco para terminar el proceso de registro!</h4> -->
			<h3 class="title-welcome">{$_("EmailCode.text_message_send")}</h3>

			<img class="image-conteiner" src="/img/Frame 92.png" alt="login" />
			<br />
		</div>
	</div>
</div>

<!-- <Modal isOpenModal={confirmModal} on:closeModal={()=>replace("/")}> 
  <p>{message}</p>
</Modal> -->
<Modals>
	<div slot="backdrop" class="backdrop" transition:fade on:click={closeModal} />
</Modals>

<style>
	.imgBack {
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 100%;
	}
	.logo {
		width: max-content;
		height: max-content;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
	}
	.register-conteiner {
		width: max-content;
		height: max-content;
		padding: 17px;
		position: relative;
		background: #fff;
		border-radius: 15px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}
	.register-info-conteiner {
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 0.5rem;
	}
	.register-form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.conteiner-img {
		text-align: center;
	}
	.title-welcome {
		color: #112c4a;
		text-align: center;
		padding-right: 15px;
		padding-left: 30px;
		width: 300px;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 18px;
	}
	.email {
		width: 90%;
		color: #112c4a;
		font-weight: 600;
	}
	.confirmar-email {
		width: 90%;
		color: #112c4a;
		font-weight: 600;
	}
	.input-log {
		width: 100%;
		height: 3.125rem;
		padding-left: 9px;
		border-radius: 5px;
		letter-spacing: 1px;
	}

	.btnRegister {
		margin: 10px;
		width: 90%;
		height: 3.2rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #f7fafc;
		border-radius: 20px;
		background-color: #112c4a;
	}
	small {
		color: red;
	}
	@media screen and (max-width: 1000px) {
		.conteiner-img {
			display: none;
		}
		.register-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
	}
</style>
