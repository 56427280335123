<script>
    import { _ } from 'svelte-i18n';

    export let dataPdfIcon = {};  // Inicializa dataPdfIcon como un objeto vacío
    export let onClick;
    export let translate = _;  // Default translate

    $: console.log('Received dataPdfIcon in PdfIcon:', dataPdfIcon);

    $: if (!dataPdfIcon || !dataPdfIcon.Link__c) {
        console.warn('dataPdfIcon or dataPdfIcon.Link__c is not defined');
    }
</script>

<button
    class="cursor pointer"
    on:click={onClick}
    on:keydown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClick();
        }
    }}
    style="background: none; border: none; padding: 0; margin: 0; cursor: pointer;">
    <img
        src="img/pdfIcon.png"
        style="width:50px; height:50px;"
        alt="PDF"
    />
</button>

<!-- Manejo de un enlace por defecto si `Link__c` no está definido -->
<a href={dataPdfIcon?.Link__c || '#'} download>{translate('GralInformation.button_download')}</a>

