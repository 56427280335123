<script>
	// Component / Layouts
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import DoctorListHeader from "../../../components/Layouts/Layout_DoctorListHeader.svelte";
	import Modal from "../../../components/Modal.svelte";
	import Loader from "../../../components/Loader.svelte";

	// Helpers
	import {
		professional_patients_pending_get,
		//update_activity_detail
	} from "../../../helpers/apiBackend";
	import { user, patient, language, CantAlta, patientLayout } from "../../../helpers/storage/stores";
	import { formatDate } from "../../../helpers/helpers";

	// Svelte
	import { _ } from "svelte-i18n";
	import { onMount } from "svelte";

	localStorage.setItem("language", JSON.stringify($language));

	// Variables
	let originalList = [];
	let selectedSession;
	let patientFilter = "";
	let sortingParameter = "Apellido";
	let langSelect = $language != {} ? $language : "es";
	let list = [];
	let asc = true;
	let loadingModal = false;
	let modalConfirm = false;
	let modalInterconsulta = false;
	let complexItems = [];
	complexItems = $_("ViewAltas.select_filter_order");
	let [finishRequest, finishRequestPending, showListMyAltas, showListMyPendingSessions] = [true, true, true, true];

	//import { mySessionPending } from "../../../helpers/mock/sessionParaAceptar.js";

	// Handle Session Solicitude (Migrar)

	function addZeroToDate(fecha) {
		// Divide la fecha en día, mes y año
		var partes = fecha.split("/");
		var dia = partes[0];
		var mes = partes[1];
		var año = partes[2];

		// Agrega un "0" al día si es un solo dígito
		if (dia.length === 1) {
			dia = "0" + dia;
		}

		// Agrega un "0" al mes si es un solo dígito
		if (mes.length === 1) {
			mes = "0" + mes;
		}

		// Formatea la fecha con los ceros agregados
		var fechaFormateada = dia + "/" + mes + "/" + año;

		return fechaFormateada;
	}

	/**
	 * @description
	 */
	const handleSessionSolicitude = async (val, sessionSelect) => {
		let bodyResponse = {};
		if (val) {
			bodyResponse.Respuesta_profesional_asignado__c = "Aprobado";
			bodyResponse.Profesional_Asignado__c = $user.Id;
		} else {
			bodyResponse.Respuesta_profesional_asignado__c = "Rechazado";
		}
		// await update_activity_detail(bodyResponse, sessionSelect["id"]).then((data) => {
		// 	if (data.status !== 204) {
		// 		alert("Error", data.status);
		// 	} else {
		// 		window.location.href = "#/Doctor";
		// 	}
		// });
	};

	function checkUserLogged() {
		console.log("CheckUserLogged", $user.Id);
		try {
			if ($user) {
				if ($user.Id) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} catch {
			return false;
		}
	}

	/**
	 * @description
	 */
	function sortyBy(lista, sortingParameter, asc, estructura) {
		if (sortingParameter == "Fecha") {
			lista.sort(function (a, b) {
				let fecha1 = a.birthDate;
				let fecha2 = b.birthDate;
				let fechaA = new Date(fecha1);
				let fechaB = new Date(fecha2);
				if (asc) {
					return fechaA - fechaB;
				} else {
					return fechaB - fechaA;
				}
			});
		}
		if (sortingParameter == "Apellido") {
			lista.sort((a, b) => {
				let nameA = a.name.split(" ");
				let nameB = b.name.split(" ");
				nameA = nameA[nameA.length - 1];
				nameB = nameB[nameB.length - 1];
				if (asc) {
					return nameA.split("")[0].localeCompare(nameB.split("")[0]);
				} else {
					return nameB.split("")[0].localeCompare(nameA.split("")[0]);
				}
			});
		}
		if (sortingParameter == "Pais") {
			lista.sort((a, b) => {
				if (asc) {
					a.country.localeCompare(b.country);
				} else {
					b.country.localeCompare(a.country);
				}
			});
		}
		return lista;
	}

	/**
	 * @description
	 */
	function filterA(searchterm, myList) {
		let newLsit = [];
		if (searchterm == "") {
			newLsit = myList;
		} else {
			try {
				newLsit = myList.filter((record) => {
					return record.name.toLowerCase().includes(searchterm.toLowerCase());
				});
			} catch (error) {
				newLsit = [];
			}
		}
		return newLsit;
	}

	/**
	 * @description
	 */
	onMount(async () => {
		if (!checkUserLogged()) {
			window.location.href = "/";
		}
		$patient = {};
		$patientLayout = {};
		await professional_patients_pending_get($user.Id, langSelect).then((data) => {
			originalList = data;
			if ($CantAlta != data.length && data.length > 0) {
				localStorage.setItem("CantAlta", data.length);
				window.location.reload();
			}
			console.log({ $CantAlta });
		});
		list = originalList;
		finishRequest = false;
	});
</script>

<!-- HTML -->
<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<DoctorListHeader
		myPage={$_("ViewAltas.text_patient")}
		buttomLeft=""
		buttomLeftHref=""
		buttomRigth=""
		buttomRigthHref=""
		selected="left">
		<div class="btnOption">
			<button
				on:click={() => {
					window.location.href = "#/Doctor";
				}}
				class="btnBlue">
				{$_("ViewAltas.button_patient")}</button>
		</div>

		<div class="container py-2 border rounded shadow-sm my-3 bg-white">
			<div class="d-flex justify-content-between form-group shadow p-4 rounded my-4 myCustom-colorBlue">
				<label for="filtro-altas">{$_("ViewAltas.text_filter_name")}</label>
				<input
					bind:value={patientFilter}
					on:input={() => {
						list = filterA(patientFilter, originalList);
					}}
					style="width: 50%;border-radius: 15px;text-align: center;"
					placeholder=""
					class="form-control form-control-sm myCustom-inputRounded"
					type="text"
					name="filtro-altas"
					id="" />

				<label for="sortBy" class="">{$_("ViewAltas.text_filter_order")}</label>
				<select
					bind:value={sortingParameter}
					on:change={() => {
						list = sortyBy(originalList, sortingParameter, asc);
					}}
					style="width: 25%;/* width: 50%; */border-radius: 15px;"
					class="form-control form-control-sm myCustom-inputRounded"
					name="sortBy"
					id="">
					<option value="Apellido">{complexItems[0].label}</option>
					<option value="Fecha">{complexItems[1].label}</option>
					<option value="Pais">{complexItems[2].label}</option>
				</select>
				{#if !asc}
					<svg
						class="cursor-pointer"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						title="Desc"
						on:click={() => {
							asc = !asc;
							list = sortyBy(originalList, sortingParameter, asc);
						}}>
						<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
					</svg>
				{:else}
					<svg
						class="cursor-pointer"
						xmlns="http://www.w3.org/2000/svg"
						title="Asc"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						on:click={() => {
							asc = !asc;
							list = sortyBy(originalList, sortingParameter, asc);
						}}>
						<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
					</svg>
				{/if}
			</div>
			<div class="border-bottom d-flex justify-content-between">
				<!-- <h3 class="text-muted">Mis Altas</h3> -->
				<div
					on:click={() => {
						showListMyAltas = !showListMyAltas;
					}}
					class="d-flex align-items-center">
					<i class="gg-details-less text-muted cursor-pointer" />
				</div>
			</div>
			{#if showListMyAltas}
				{#if finishRequest}
					<div class="text-center py-3">
						<Loader text={false} />
						<p class="text-muted">{$_("ViewAltas.loading")}</p>
					</div>
				{:else}
					<div class="rdcom-table-wrapper my-3">
						{#if list.length > 0}
							<div class="barraName d-flex border rounded justify-content-between">
								<span class="spName">{$_("ViewAltas.spName")}</span>
								<span class="spType">{$_("ViewAltas.spPathologi")}</span>
								<div class="date_country">
									<span class="spStatus">{$_("ViewAltas.spDate")}</span>
									<span class="spCountry">{$_("ViewAltas.spCountry")}</span>
								</div>

								<span class="spAction">{$_("ViewAltas.spAction")}</span>
							</div>
							<div class="record svelte-g3a30z">
								{#each list as alt}
									<div
										class="searched-doctor d-flex justify-content-between border px-2 svelte-g3a30z">
										<div class="headDetail_cont">
											{#if alt.head}
												<img
													src="./img/medical.png"
													alt="Icono de Sesión"
													class="icono-session"
													style="width: 16px; height: 16px; margin-right: 2%;"
													title={$_("ViewDoctor.text_Headboard")} />
											{:else}
												<small class="headDetailS py-0" title={$_("ViewDoctor.text_Session")}
													>{$_("ViewDoctor.text_Session")}</small>
											{/if}
											<p class="variableA m-0 svelte-g3a30z">{alt.name}</p>
										</div>

										{#if alt.hasOwnProperty("pathologies") && alt?.pathologies != null}
											<p class="variablepathologi m-0 svelte-g3a30z" title={alt.pathologies}>
												{alt.pathologies}
											</p>
										{:else}
											<p class="variablepathologi m-0 svelte-g3a30z">
												{$_("ViewAltas.notPathologi")}
											</p>
										{/if}

										<p class="variableA m-0 svelte-g3a30z">
											{formatDate(alt.birthDate, $language)}
										</p>
										<p class="variableA m-0 svelte-g3a30z">{alt.country}</p>
										<button
											class="btnBlue py-0"
											on:click={() => {
												//alt.Id = alt.id;
												$patient = alt;
												console.log("Data atl",alt);
												$patientLayout = alt;
												window.location.href = "#/Patient";
											}}>{$_("ViewAltas.button_Access")}</button>
									</div>
								{/each}
							</div>
						{:else}
							<div class="messageSin">
								<p class="text-muted" style="margin: 10%;text-align: center;">
									{$_("ViewAltas.text_not_register")}...
								</p>
							</div>
						{/if}
					</div>
				{/if}
			{/if}
		</div>
	</DoctorListHeader>
	<!-- </LayoutNav> -->
</section>

<!-- Confirm -->
<LayoutModal
	isOpenModal={modalConfirm}
	on:closeModal={() => {
		modalConfirm = false;
	}}>
	<div class="p-2 text-center">
		<p>Desea rechazar o aceptar esta solicitud?</p>
		<div class="d-flex justify-content-center">
			<button
				class="btnBlue"
				on:click={() => {
					handleSessionSolicitude(true, selectedSession);
				}}>Aceptar</button>
			<button
				class="btnWhite"
				on:click={() => {
					handleSessionSolicitude(false, selectedSession);
				}}>Rechazar</button>
		</div>
	</div>
</LayoutModal>

<!-- Loading Modal -->

<Modal isOpenModal={loadingModal}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">{$_("ViewAltas.loading")}</p>
		</div>
	</div>
</Modal>

<LayoutModal
	isOpenModal={modalInterconsulta}
	on:closeModal={() => {
		modalConfirm = false;
	}}>
	<div class="p-2 text-center">
		<p>Desea rechazar o acceptar esta interconsulta?</p>
		<div class="d-flex justify-content-between">
			<button
				class="btnBlue"
				on:click={() => {
					handleSessionSolicitude(true, selectedSession);
				}}>Acceptar</button>
			<button
				class="btnWhite"
				on:click={() => {
					handleSessionSolicitude(false, selectedSession);
				}}>Rechazar</button>
			<button
				class="btnWhite"
				on:click={() => {
					modalInterconsulta = false;
				}}>Cancelar</button>
		</div>
	</div>
</LayoutModal>

<style>
	.rdcom-table-wrapper {
		min-height: 22vh;
		max-height: 42vh;
		overflow: auto;
	}

	.btnOption {
		margin: 1%;
	}
	.barraName {
		background-color: #215273;
		height: 45px;
		color: #ffffff;
		align-items: center;
	}
	.spName {
		width: 18%;
		margin-left: 1%;
	}
	.spAction {
		margin-right: 3%;
	}
	.spStatus {
		margin-right: 2%;
	}
	.spCountry {
		width: 50%;
	}
	.spType {
		width: 40%;
	}
	.messageSin {
		width: 100%;
	}

	.variablepathologi {
		width: 500px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.headDetail_cont {
		width: 20%;
		display: flex;
		align-items: center;
	}
	.headDetailS {
		background-color: rgb(255, 255, 255);
		border: none;
		color: azure;
		border-radius: 10px;
		height: 20%;
		width: 8%;
		text-align: center;
	}
	.date_country {
		width: 300px;
		display: flex;
		justify-content: space-between;
	}
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}
	.variableA {
		width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
</style>
