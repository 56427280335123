<script>
	/**
	 * Pass Component as parameter
	 * https://linguinecode.com/post/how-to-pass-a-svelte-component-to-another-svelte-component
	 *
	 * Modal Source Original Code
	 * https://blog.hdks.org/Modal-Window-in-Svelte/
	 */

	import { createEventDispatcher } from "svelte";
	export let isOpenModal;
	export let block = false;
	export let tam = "20";

	// Close Modal
	//console.log({ tam });
	const dispatch = createEventDispatcher();
	function closeModal() {
		if (!block) {
			isOpenModal = false;
			dispatch("closeModal", {
				isOpenModal,
				confirm: false,
			});
		}
	}
</script>

<!-- <div id="background" style="--display: {isOpenModal ? 'block' : 'none'};" on:click={closeModal}></div> -->
<div id="background" style="--display: {isOpenModal ? 'block' : 'none'};" />
<div id="modal" style="--display: {isOpenModal ? 'block' : 'none'} " class="p-5 rounded">
	<slot />
	<!-- This is like the DOM Prop -->
</div>

<style>
	#background {
		background-color: rgba(0, 0, 0, 0.8);
		display: var(--display);
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	#modal {
		display: var(--display);
		position: fixed;
		z-index: 2;
		top: 50%;
		left: 50%;
		/* width: 40vw; */
		height: 20vh;
		border-radius: 5px;
		transform: translate(-50%, -50%);
		background: #ffffff;
		text-align: center;
	}
</style>
