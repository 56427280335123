<script>
	import Cropper from "cropperjs";
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";

	export let imageSrc;
	export let dataFileAd;

	let img, cropper, url;
	let finish = false;

	const dispatch = createEventDispatcher();

	onMount(() => {
		// Espera a que la imagen se cargue completamente
		img.addEventListener("load", () => {
			initCropper();
		});

		// Si la imagen ya está cargada, inicializa el cropper de inmediato
		if (img.complete) {
			initCropper();
		}
	});

	function initCropper() {
		cropper = new Cropper(img, {
			aspectRatio: 9 / 9,
			viewMode: 1,
			ready() {
				// Forzar un recorte inicial
				cropper.crop();
			},
			crop(event) {
				const croppedCanvas = cropper.getCroppedCanvas();
				croppedCanvas.toBlob((blob) => {
					url = URL.createObjectURL(blob);
					dataFileAd[0].urlPhoto = url;
				});
			}
		});
	}

	function save() {
		const croppedImage = cropper.getCroppedCanvas().toDataURL();
		dataFileAd[0].data = croppedImage.replace(/^data:image\/[a-z]+;base64,/, "");
		dataFileAd[0].urlPhoto = url;
		finish = true;
		dispatch("dataFileAd", dataFileAd);
	}
</script>

<svelte:head>
	<link href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/2.0.0-alpha.2/cropper.css" rel="stylesheet" />
	<script src="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/2.0.0-alpha.2/cropper.min.js"></script>
</svelte:head>

<form on:submit|preventDefault={save}>
	<div>
		<div class="viewEdit">
			<h2 class="text_title">Ajustar imagen</h2>
			<img bind:this={img} src={imageSrc} alt="Preuaba" />
		</div>
		<div class="modal-footer">
			<button
				class="btnBlue"
				on:click={() => {
					console.log({ dataFileAd });
				}}>Guardar</button>
		</div>
	</div>
</form>

<style>
	img {
		display: block;
		max-width: 100%;
	}
	.text_title {
		font-weight: bold;
		font-size: 30px;
		text-align: center;
		color: #112c4a;
	}
</style>
