<script>
	import { onMount } from "svelte";
	onMount(() => {
		window.localStorage.clear();
		window.sessionStorage.clear();
	});
</script>

<section>
	<div class="p-3 text-center">
		<h3
			style="
    font-size: 65px;
    color: cornflowerblue;
">
			Error 404
		</h3>
		<p>
			Hubo un error al cargar sus datos. Por favor, inténtelo nuevamente más tarde o comuníquese con su
			administrador.
		</p>
		<img src=".\img\error-404.png" alt="Imagen de error" />
		<br />
		<a href="#/">Pantalla Principal</a>
	</div>
</section>
