<script>
	import { onMount } from "svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Modal from "../../../components/Modal.svelte";
	import { _ } from "svelte-i18n";
	import { registerDetail, user, language } from "../../../helpers/storage/stores";
	import LayoutPatientHeader from "../../../components/Layouts/Layout_Patient_Header.svelte";
	import AddMedications from "../../../components/addMedications.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { generic_activities_get } from "../../../helpers/apiBackend";
	import List_Question_Patients from "../../../components/Lists/List_Question_Patients.svelte";

	//import { hasContext, onMount } from "svelte";
	//import { currentSession } from "../../../helpers/storage/sessions";
	//import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	//utils_resources_get

	// Get Datatext_message_succes
	let myRecords = [];
	let myType, modalEditHeader, type;
	let headerToEdit = {};
	let loading = true;
	let myPatient = $user.Id;
	let myUserId = $user.Id;
	let account_id = $user.Id;
	let myPlan = $user.Careplan2__r.Id;
	let [modalLoading, modalError, modalSuccess, modaladd] = [false, false, false, false];
	let frequencies = [];
	//const AWS_HOST = process.env.HOST_DEV; // Fix ENVS

	onMount(async () => {
		$registerDetail = {};
		myType = $_("ViewCuestionarioPatient.text_title");
		//frequencies = await utils_resources_get("frecuencies", $language);
		console.log(myType);
		type = "Cuestionarios";
		console.log(myPatient, type, myPlan);
		await generic_activities_get(myPatient, type, myPlan, account_id, $language).then((res) => {
			console.log({ res });
			console.log("entro por ", type);
			myRecords = res.data;
			loading = false;
		});
	});

	// Edit Header
	const editHeader = async () => {
		// let bodyPatchHeader = {
		// 	Frecuencia__c: headerToEdit["Frecuencia_texto__c"],
		// 	Activo__c: headerToEdit["Activo__c"],
		// };
		// console.log({ bodyPatchHeader });
		// modalLoading = true;
		// modalEditHeader = false;
		// let myToken = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
		// let myHeaders = { Authorization: myToken };
		// let myEditUrlHeader = `${AWS_HOST}/generic/update_activity?activity_id=${headerToEdit["Id"]}`;
		// await fetch(myEditUrlHeader, {
		// 	headers: myHeaders,
		// 	method: "PATCH",
		// 	body: JSON.stringify(bodyPatchHeader),
		// })
		// 	.then((dataPacht) => {
		// 		console.log({ dataPacht });
		// 		modalSuccess = true;
		// 	})
		// 	.catch((errorPatch) => {
		// 		console.log({ errorPatch });
		// 		modalError = true;
		// 	})
		// 	.finally(() => {
		// 		modalLoading = false;
		// 	});
	};

	// Handle Modal Edit Header
	let originalHeader;
	let diffChanges = false;
	$: {
		if (modalEditHeader) {
			if (originalHeader == undefined) {
				originalHeader = JSON.parse(JSON.stringify(headerToEdit));
				console.log({ originalHeader });
			} else {
				if (JSON.stringify(originalHeader) != JSON.stringify(headerToEdit)) {
					diffChanges = true;
				} else {
					diffChanges = false;
				}
			}
		} else {
			if (originalHeader != undefined) {
				originalHeader = undefined;
			}
		}
	}
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	<LayoutPatientHeader />
	<div class="container">
		<div class="border p-3 bg-white">
			<div class="border-bottom">
				<h3 class="text-muted">{myType}</h3>
			</div>
			<div class="barraName d-flex border rounded justify-content-between">
				<span class="spName">{$_("ViewCuestionarioPatient.spName")}</span>
				<span class="spArea">{$_("ViewCuestionarioPatient.text_frequency")}</span>
				<span class="spStatus">{$_("ViewCuestionarioPatient.spStatus")}</span>
				<span class="spAction">{$_("ViewCuestionarioPatient.spAction")}</span>
			</div>

			<div class="d-flex justify-content-between" />
			<div class="records-list">
				{#if loading}
					<div class="p-3 text-center">
						<Loader text={false} />
						<p class="text-muted">{$_("ViewCuestionarioPatient.loading")}</p>
					</div>
				{:else if myRecords.length > 0}
					{#each myRecords as record, i}
						<div class="my-2">
							<List_Question_Patients
								theRecord={record}
								on:editHeader={() => {
									headerToEdit = record;
									modalEditHeader = true;
								}}
								on:editDetail={(e) => {
									$registerDetail["register"] = record;
									$registerDetail["detail"] = e.detail.myDetail;
									window.location.href = `#/Patient/RegisterDetailQuestion?type=${myType}&scope=detail`;
								}} />
						</div>
					{/each}
				{:else}
					<div class="text-center p-2">
						<p class="m-0 text-muted">{$_("ViewCuestionarioPatient.textNotcontain")} {myType}</p>
					</div>
				{/if}
			</div>
			<div class="d-flex m-3 border rounded justify-content-center">
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Patient/Plan";
					}}>
					{$_("ViewCuestionarioPatient.button_plan")}
				</p>
			</div>
		</div>
	</div>
	<!-- </LayoutNav> -->
</section>

<!-- Modal Editar Header -->
<LayoutModal
	isOpenModal={modalEditHeader}
	on:closeModal={() => {
		modalEditHeader = false;
	}}>
	<div class="">
		{#if headerToEdit.hasOwnProperty("Name") || headerToEdit.hasOwnProperty("Farmaco__c")}
			<div class="p-3 text-center">
				{#if !headerToEdit.hasOwnProperty("Farmaco__c")}
					<h2>{$_("ViewCuestionarioPatient.button_edit")} {headerToEdit["Name"]}</h2>
				{:else}
					<h2>{$_("ViewCuestionarioPatient.button_edit")} {headerToEdit["Farmaco__c"]}</h2>
				{/if}
				<div class="p-2">
					<div class="d-flex">
						<p class="m-0">{$_("ViewCuestionarioPatient.text_status")}</p>
						<p class="m-0 mx-2">
							{#if headerToEdit.hasOwnProperty("Activo__c")}
								{#if headerToEdit["Activo__c"]}
									<!-- <i
										on:click={() => {
											headerToEdit["Activo__c"] = false;
										}}
										class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->

										<button
												on:click={() => {
													headerToEdit["Activo__c"] = false;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleon.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
								{:else}
									<!-- <i
										on:click={() => {
											headerToEdit["Activo__c"] = true;
										}}
										class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->

										<button
												on:click={() => {
													headerToEdit["Activo__c"] = true;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleoff.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
								{/if}
							{/if}
						</p>
					</div>
					{#if !headerToEdit.hasOwnProperty("Farmaco__c")}
						<div class="form-group d-flex">
							<label for="frecuencia-edit"
								>{$_("ViewCuestionarioPatient.text_frequency")} &nbsp; &nbsp;</label>
							<select
								bind:value={headerToEdit["Frecuencia_texto__c"]}
								class="form-control form-control-sm"
								name="frecuencia-edit"
								id="">
								{#each frequencies as opcion}
									<option value={opcion.value}>{opcion.label}</option>
								{/each}
							</select>
						</div>
					{/if}
				</div>
			</div>
		{/if}
		<div class="d-flex justify-content-center">
			{#if diffChanges}
				<button
					on:click={() => {
						editHeader();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("ViewCuestionarioPatient.button_Send")}</button>
			{/if}
			<button
				class="py-0 mx-2 py-2 btnWhite"
				on:click={() => {
					modalEditHeader = false;
				}}>{$_("ViewCuestionarioPatient.button_cancel")}</button>
		</div>
	</div>
</LayoutModal>

<Modal isOpenModal={modalLoading}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">{$_("ViewCuestionarioPatient.loading")}</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<LayoutModal
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	<div class="p-2 text-center">
		<p>{$_("ViewCuestionarioPatient.text_message_succes")}</p>
		<button
			on:click={() => {
				window.location.reload();
			}}
			class="btnBlue py-0">{$_("ViewCuestionarioPatient.button_close")}</button>
	</div>
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="border p-3">
		<div>
			<p class="m-0">{$_("ViewCuestionarioPatient.text_message_error")}</p>
		</div>
		<div>
			<button
				class="btn btn-outline-info py-0"
				on:click={() => {
					window.location.reload();
				}}>{$_("ViewCuestionarioPatient.button_close")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Modal add medicines -->
<LayoutModal
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<AddMedications {myUserId} {myPlan} {myPatient} />
</LayoutModal>

<style>
	.texlink {
		color: #215273;
	}
	.myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	}
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}
	.barraName {
		background-color: #215273;
		height: 45px;
		color: #ffffff;
		align-items: center;
	}
	.spName {
		width: 535px;
		margin-left: 2%;
	}
	.spArea {
		width: 190px;
	}

	.spAction {
		margin-right: 2%;
	}
	.spStatus {
		margin-right: 2%;
	}

	@media only screen and (min-width: 960px) {
		.records-list {
			max-height: 45vh;
			min-height: 40vh;
			overflow: auto;
		}
	}
	@media only screen and (min-width: 1440px) {
		.records-list {
			max-height: 75vh;
			min-height: 45vh;
			overflow: auto;
		}
	}
</style>
