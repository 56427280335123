<script>
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../../components/Loader.svelte";
	import ModalComplete from "../../../components/ModalComplete.svelte";
	import { selectedSession, selectedSessionPatient } from "../../../helpers/storage/stores";
	import { utils_files_get } from "../../../helpers/apiBackend";
	import { currentSession } from "../../../helpers/storage/sessions.js";
	import Modal from "../../../components/Modal.svelte";
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";
	import { each } from "svelte/internal";

	// Vars
	let dateSession = "";
	let type = "";
	let dataAdjunto = [];
	let [modalComplete, modalLoading, modalSuccess, modalError, createNewSession] = [false, false, false, false, false];
	const AWS_HOST = process.env.HOST_DEV;
	let myToken = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
	let myHeaders = { Authorization: myToken };

	// Complete Session
	const completeSession = async (e) => {
		modalComplete = false;
		if (e.detail.hasOwnProperty("body")) {
			let myBody = e.detail.body;
			myBody["EvolucionadoPor__c"] = "Profesional";
			modalLoading = true;
			// let myCompleteUrl = `${AWS_HOST}/generic/update_activity_detail?activity_id=${$selectedSession["detail"]["Id"]}`;
			// await fetch(myCompleteUrl, {
			// 	method: "PATCH",
			// 	headers: myHeaders,
			// 	body: JSON.stringify(myBody),
			// })
			// 	.then((patchResponse) => {
			// 		console.log({ patchResponse });
			// 		if (patchResponse.status == 204) {
			// 			if (createNewSession) {
			// 				generateNewSession();
			// 			} else {
			// 				modalSuccess = true;
			// 			}
			// 		} else {
			// 			modalError = true;
			// 		}
			// 	})
			// 	.catch((patchError) => {
			// 		console.log({ patchError });
			// 		modalError = true;
			// 	})
			// 	.finally(() => {
			// 		modalLoading = false;
			// 	});
		}
	};

	// Generate new Session
	const generateNewSession = async () => {
		let myCreateDetailUrl = `${AWS_HOST}/generic/create_activity_detail?actividadDetalleId=${$selectedSession["detail"]["Id"]}`;
		console.log({ myCreateDetailUrl });
		await fetch(myCreateDetailUrl, {
			method: "POST",
			headers: myHeaders,
		})
			.then((createSessionData) => {
				console.log({ createSessionData });
				return createSessionData.json();
			})
			.then((createSessionBody) => {
				console.log({ createSessionBody });
			})
			.catch((createSessionError) => {
				console.log({ createSessionError });
			})
			.finally(() => {});
	};
	onMount(async () => {
		//Busca si tienen adjuntos
		dataAdjunto = await utils_files_get($selectedSession["detail"]["Id"], $currentSession);
		if (dataAdjunto.data.length > 0 && dataAdjunto.data[0].hasOwnProperty("Link__c")) {
			type = dataAdjunto.data[0].Link__c.split("/")[3];
			type = type.split(".")[1];
			console.log({ type });
		}
	});
</script>

<section>
	<LayoutNav secure={true} rol="Profesional_de_Salud">
		<div class="p-2 my-2 border rounded shadow-sm bg-white">
			<div class="">
				<div class="my-record">
					<!-- Detail -->
					<!-- Fecha Real / Completar / Estado / Ativar-Desactivar -->
					<!-- Al solicitar una sesion, editar profesional sugerido -->
					<div class="my-2">
						<div class="text-muted text-center">
							<h3>{$_("MySessionProf.text_Session")}</h3>
						</div>

						<table class="table table-sm table-stripped">
							<tr class="my-tr text-muted">
								<th colspan="2" class="text-center">{$selectedSession["register"]["Name"]}</th>
							</tr>
							<!-- <tr class="m-0 p-0">
                          <td class="m-0 p-0">Activo:</td>
                          <td class="m-0 p-0">
                              {#if $selectedSession['register']["Activo__c"]}
                                <i style="color:#88FF78;" class="cursor-pointer mt-1 gg-toggle-off"></i>
                              {:else}
                                <i class="cursor-pointer mt-1 gg-toggle-on"></i>
                              {/if}
                          </td>

                        </tr> -->
							<tr class="m-0 p-0">
								<td class="m-0 p-0">{$_("MySessionProf.text_frequency")}:</td>
								{#if $selectedSession["register"]["Frecuencia__c"] == 1}Semanal
								{:else if $selectedSession["register"]["Frecuencia__c"] == 2}<td class="m-0 p-0"
										>Mensual</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 3}<td class="m-0 p-0"
										>Anual</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 4}<td class="m-0 p-0"
										>Trianual</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 5}<td class="m-0 p-0"
										>Bianual</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 6}<td class="m-0 p-0"
										>Segun Clinica</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 7}<td class="m-0 p-0"
										>Semanal A demanda medico</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 8}<td class="m-0 p-0"
										>Peso Semanal / Talla Anual</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 9}<td class="m-0 p-0"
										>A pedido profesional</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 10}<td class="m-0 p-0"
										>Cada 2 años</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 11}<td class="m-0 p-0"
										>Trianual/ Segun clinica</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 12}<td class="m-0 p-0"
										>Ingreso/ Segun clinica</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 13}<td class="m-0 p-0"
										>Bianual/ Segun clinica</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 14}<td class="m-0 p-0"
										>Obesidad (IMC mayor 30) Una vez por año/ Segun clinica</td>
								{:else if $selectedSession["register"]["Frecuencia__c"] == 15}<td class="m-0 p-0"
										>Al nacer/ Ingreso</td>
								{/if}
							</tr>
							<tr class="m-0 p-0">
								<td class="m-0 p-0">{$_("MySessionProf.text_modality")}:</td>
								<td class="m-0 p-0">{$selectedSession["register"]["Modalidad__c"]}</td>
							</tr>
							<tr class="m-0 p-0">
								<td class="m-0 p-0">{$_("MySessionProf.text_type_activities")}:</td>
								<td class="m-0 p-0">{$selectedSession["register"]["Tipo_de_Actividad__c"]}</td>
							</tr>

							<!-- Detail Session -->
							{#if $selectedSession.hasOwnProperty("detail")}
								{#if $selectedSession["detail"].hasOwnProperty("Creado_Por__r")}
									{#if $selectedSession["detail"]["Creado_Por__r"].hasOwnProperty("Name")}
										<tr class="m-0 p-0">
											<td class="m-0 p-0">{$_("MySessionProf.text_th_createdby")}:</td>
											<td class="m-0 p-0"
												>{$selectedSession["detail"]["Creado_Por__r"]["Name"]}</td>
										</tr>
									{/if}
								{/if}

								<tr class="m-0 p-0">
									<td class="m-0 p-0">{$_("MySessionProf.text_required")}:</td>
									<td class="m-0 p-0">
										{#if $selectedSession["detail"]["Solicitado__c"]}
											<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{:else}
											<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{/if}
									</td></tr>

								<tr class="m-0 p-0">
									<td class="m-0 p-0">{$_("MySessionProf.text_complete")}:</td>
									<td class="m-0 p-0">
										{#if $selectedSession["detail"]["Completado__c"]}
											<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{:else}
											<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{/if}
									</td>
								</tr>

								<!-- <tr class="m-0 p-0">
                                  <td class="m-0 p-0">Expirado</td>
                                  <td class="m-0 p-0">
                                    {#if $selectedSession['detail']["Expirado__c"]}
                                      <i style="color:#88FF78;" class="cursor-pointer mt-1 gg-toggle-off"></i>
                                    {:else}
                                      <i class="cursor-pointer mt-1 gg-toggle-on"></i>
                                    {/if}
                                  </td>
                                </tr> -->

								<tr class="m-0 p-0">
									<td class="m-0 p-0">{$_("MySessionProf.text_date_suggested")}:</td>
									<td class="m-0 p-0"
										>{$selectedSession["detail"]["Fecha_Sugerida__c"]
											.split("-")
											.reverse()
											.join("-")}</td>
								</tr>
								{#if $selectedSession["detail"]["Completado__c"] == true}
									<tr class="m-0 p-0">
										<td class="m-0 p-0">{$_("MySessionProf.text_th_session_date")}:</td>
										<td class="m-0 p-0">{$selectedSession["detail"]["Fecha_de_sesion__c"]}</td>
									</tr>

									<tr class="m-0 p-0">
										<td class="m-0 p-0">{$_("MySessionProf.text_title_Evolution")}:</td>
										<td class="m-0 p-0">{$selectedSession["detail"]["Evolucion__c"]}</td>
									</tr>

									<tr class="m-0 p-0">
										<td class="m-0 p-0">{$_("MySessionProf.text_evolved_by")}:</td>
										<td class="m-0 p-0">{$selectedSession["detail"]["EvolucionadoPor__c"]}</td>
									</tr>

									<tr class="m-0 p-0">
										<td class="m-0 p-0">{$_("MySessionProf.text_attach")}: </td>
										{#each dataAdjunto.data as file}									
											<!-- {#if file.Link__c.slice(-3) !== "pdf"} -->
											{#if file.Link__c.includes(".pdf") }
												<td class="m-0 p-0"
													><img
														class="cursor pointer"
														on:click={() => {}}
														src={file.Link__c}
														alt="Document"
														width="20%"
														height="20%" /><a href={file.Link__c} download
														>{$_("MySessionProf.button_download")}</a
													></td>
											{:else}
												<td class="m-0 p-0"
													><img
														class="cursor pointer"
														on:click={() => {}}
														src="img/pdfIcon.png"
														alt="PDF"
														width="5%"
														height="5%" /><a href={file.Link__c} download
														>{$_("MySessionProf.button_download")}</a
													></td>
											{/if}
										{:else}
											<span class="text-muted">{$_("MySessionProf.text_not_attach")}</span>
										{/each}
									</tr>
								{/if}
							{/if}
						</table>
						{#if $selectedSession["detail"]["Completado__c"] != true}
							<div class="text-center">
								<button
									on:click={() => {
										modalComplete = true;
									}}
									class="btnBlue">{$_("MySessionProf.button_to_complete")}</button>
							</div>
						{/if}
					</div>
				</div>
				<div class="my-3 d-flex justify-content-center">
					<p
						class="texlink mx-2 cursor-pointer"
						on:click={() => {
							window.location.href = "#/Session";
						}}>
						{$_("MySessionProf.text_my_sessions")}
					</p>
					<p
						class="texlink mx-2 cursor-pointer"
						on:click={() => {
							window.location.href = "#/Doctor";
						}}>
						{$_("MySessionProf.text_my_patients")}
					</p>
					<p
						class="texlink mx-2 cursor-pointer"
						on:click={() => {
							window.location.href = "#/Altas";
						}}>
						{$_("MySessionProf.text_my_discharges")}
					</p>
					<!-- <a class="mx-2" href="#/Session">Mis Sesiones</a>
                <a class="mx-2" href="#/Doctor">Mis Pacientes</a>
                <a class="mx-2" href="#/Altas">Mis Altas</a> -->
				</div>
			</div>
		</div>
	</LayoutNav>
</section>

<!-- Modal Complete Session -->
<LayoutModal
	isOpenModal={modalComplete}
	on:closeModal={() => {
		createNewSession = false;
		modalComplete = false;
	}}>
	<ModalComplete
		idTypeAttachment={$selectedSession["detail"]["Id"]}
		on:complete={(e) => {
			completeSession(e);
		}}
		on:cancel={() => {
			modalComplete = false;
			createNewSession = false;
		}}>
		<div>
			<div class="">
				<input bind:checked={createNewSession} type="checkbox" name="" id="" />
				<label for="">{$_("MySessionProf.text_new_session")}</label>
			</div>
		</div>
	</ModalComplete>
</LayoutModal>

<Modal isOpenModal={modalLoading}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">{$_("MySessionProf.loading")}</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<LayoutModal
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	<div class="text-center p-3">
		<p>{$_("MySessionProf.text_message_succes")}</p>
		<a href="#/Doctor">{$_("MySessionProf.text_return_my_session")}</a>
	</div>
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="text-center p-3">
		<p>{$_("MySessionProf.text_message_error")}</p>
		<a href="#/Doctor">{$_("MySessionProf.text_return_my_session")}</a>
	</div>
</LayoutModal>

<style>
	.myCustom-toggle {
		transform: scale(1.25);
	}
	.texlink {
		color: #215273;
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	}
</style>
