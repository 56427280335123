<!-- 
    IMPORTANT - TO USE ON DOCTORS PLATFORM
 -->
<script>
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";
	import AddDetailsMedications from "../../components/addDetailsMedications.svelte";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	//import { addInterviewDetail, professional_exam_pdf_add } from "../../helpers/apiBackend";
	import { language } from "../../helpers/storage/stores";
	import { formatDate } from "../../helpers/helpers";
	import { _ } from "svelte-i18n";
	import Loader from "../Loader.svelte";
	const dispatch = createEventDispatcher();
	export let theRecord = undefined;

	let collapsed = true;
	let modalSuccess = false;
	let modaladd = false;
	let modaladdCita = false;
	let name = "";
	let detailMedication = [];
	let patientId;
	let planId;
	let actividadCabeceraId;
	let loader = false;
	let flagError = false;
	let modaladdStudy = false;
	let profesionalSugerido;
	let CentroMedicoSugerido;
	let aclaraciones = "";
	let diagnostico = "";

	//const statusbutton = $_("List_Records_prof.statusbutton");
	onMount(async () => {
		console.log({ theRecord });
		detailMedication = theRecord.details;
		actividadCabeceraId = theRecord.medicationId;
		planId = detailMedication.careplanId;
		patientId = detailMedication.patientId;
		name = theRecord.drug;
		console.log({ detailMedication });
	});
</script>

<section>
	<div class="myCustom-box">
		<div
			class="{collapsed
				? `myCustom-header-closed-${theRecord['Activo__c']}`
				: 'myCustom-header-open'} d-flex justify-content-between align-items-center p-2">
			<div class="d-flex myCustom-name">
				<p class="m-0 variableA" title={theRecord.drug}>{theRecord.drug}</p>
				{#if theRecord.CommentsRdcom}
					<svg
						class="cursor-pointer"
						viewBox="0 0 24 24"
						width="24"
						height="24"
						on:click={() => {
							modalSuccess = true;
						}}>
						<path
							d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
					</svg>
				{/if}
			</div>

			<div class="statusID d-flex" style="align-items: center;">
				{#if theRecord.active}
					<div id="circle" class="circle" style="background-color: green;" />
				{:else}
					<div id="circle" class="circle" style="background-color:grey;" />
				{/if}
			</div>
			{#if theRecord.active || theRecord.Farmaco__c}
				<div>
					
					<!-- <p class="m-0 mx-2">Mas</p> -->
					<!-- <i
						on:click={() => {
							dispatch("editHeader");
							collapsed = false;
						}}
						data-toggle="tooltip"
						data-placement="top"
						title="Editar"
						class="text-muted mx-2 gg-pen cursor-pointer" /> -->

						<button
						on:click={() => {
							dispatch("editHeader");
							collapsed = false;
						}}
						style="background: none; border: none; padding: 0;">
						<img src="./img/pen.svg" alt="Flecha hacia abajo" style="width: 24px; height: 24px;">
						</button>
					<!-- <i
						class="gg-arrow-down-o"
						on:click={() => {
							console.log("Mas", collapsed);
							collapsed = !collapsed;
							console.log("Mas", collapsed);
						}} /> -->
					</div>
					<button
					on:click={() => {
						collapsed = !collapsed;
					}}
					class="d-flex myCustom-collapse"
					data-toggle="collapse"
					data-target="#{theRecord.medicationId}"
					aria-expanded="false"
					aria-controls={theRecord.medicationId}
					style="align-items: center; background: none; border: none; padding: 0;">
					<img src="./img/arrow-down-o.svg" alt="Flecha hacia abajo" style="width: 24px; height: 24px;">	
					</button>
			{:else}
				<div
					on:click={() => {
						console.log("Boton de request");
						dispatch("editHeader");
					}}
					class="d-flex myCustom-collapse"
					style="width: 4%;">
					<button class="btnActivar">{$_("List_Records_prof.button_Request")}</button>
				</div>
			{/if}
		</div>

		<!-- Detail -->
		<div class="collapse" id={theRecord.medicationId}>
			<table class="myCustom-table">
				<thead>
					<tr>
						<th>{$_("List_Records_prof.txt_date")}</th>
						<th>{$_("List_Records_prof.txt_dose")}</th>
						<th>{$_("List_Records_prof.txt_frecuency")}</th>
						<th>{$_("List_Records_prof.txt_orden")}</th>
						<th />
						{#if theRecord.active}
							<th
								><button
									on:click={() => {
										modaladd = true;
									}}
									class="btnWhite">{$_("List_Records_prof.button_add")}</button
								></th>
						{/if}
					</tr>
				</thead>
				<tbody class="py-2">
					{#if detailMedication.length > 0}
						{#each detailMedication as myDetail}
							<tr class="bg-white py-2" style="height:35px;">
								<td>
									{#if myDetail.hasOwnProperty("detailDate")}
										<span>{formatDate(myDetail["detailDate"], $language)}</span>
									{:else}
										<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
									{/if}
								</td>

								<td>
									{#if myDetail.hasOwnProperty("dose")}
										<span>{myDetail["dose"]}</span>
									{:else}
										<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
									{/if}
								</td>

								<td>
									{#if myDetail.hasOwnProperty("frecuency") && myDetail["frecuency"] != null}
										<span>{myDetail["frecuency"]}</span>
									{:else}
										<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
									{/if}
								</td>
								<td>
									{#if myDetail.hasOwnProperty("files") && myDetail.files != ""}
										<a href={myDetail.files} download>{$_("List_Records_prof.button_download")}</a>
									{:else}
										<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
									{/if}
								</td>
								<td />

								<td>
									<button
										on:click={() => {
											//console.log("Boton de request", myDetail);
											myDetail.medicationId = theRecord.medicationId;
											//console.log("Boton de request", myDetail);
											dispatch("editDetail", { myDetail });
										}}
										class="btnBlue">{$_("List_Records_prof.button_detail")}</button>
								</td>
							</tr>
						{:else}
							<tr>
								<td>
									<span>Sin medicamento</span>
								</td>
							</tr>
						{/each}
					{/if}
				</tbody>
			</table>
		</div>
	</div>
</section>
<LayoutModal
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	<div class="text-center">
		<p>{theRecord.CommentsRdcom}</p>
		<button
			on:click={() => {
				modalSuccess = false;
			}}
			class="btnWhite">Cerrar</button>
	</div>
</LayoutModal>

<LayoutModal
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<div class="medication-d">
		<AddDetailsMedications
			nameMed={name}
			{patientId}
			{planId}
			{actividadCabeceraId}
			on:cancel={() => {
				console.log("modaladd");
				modaladd = false;
			}} />
	</div>
</LayoutModal>
<LayoutModal
	isOpenModal={modaladdCita}
	on:closeModal={() => {
		modaladdCita = false;
	}}>
	<div class="interview-d">
		{#if loader == false}
			<h3>{name}</h3>
			<label for="profesionalSugerido">Profesional sugerido:</label>
			<input id="profesionalSugerido" type="text" bind:value={profesionalSugerido} />
			<br />
			<button
				class="btn btn-info py-1 myCustom-button"
				on:click={() => {
					loader = true;
					// addInterviewDetail(
					// 	profesionalSugerido,
					// 	theRecord.medicationId,
					// 	theRecord.Paciente__c,
					// 	JSON.parse(localStorage.getItem("user"))["Id"]
					// ).then((response) => {
					// 	response.statusCode == 201 ? window.location.reload() : (flagError = true);
					// });
				}}>enviar</button>
		{:else}
			<Loader text={false} />
			<p>
				{flagError == false
					? "Agregando actividad..."
					: "La acción solicitada no se pudo completar, por favor intente nuevamente."}
			</p>
		{/if}
	</div>
</LayoutModal>
<LayoutModal
	isOpenModal={modaladdStudy}
	on:closeModal={() => {
		modaladdStudy = false;
	}}>
	<div class="interview-d">
		{#if loader == false}
			<h3>Solicitar {name}</h3>
			<br />

			<label for="profesionalSugerido">Sugerir Centro Medicos:</label>
			<input id="profesionalSugerido" type="text" bind:value={CentroMedicoSugerido} />
			<br />
			<label for="profesionalSugerido">Aclaraciones:</label>
			<input id="profesionalSugerido" type="text" bind:value={aclaraciones} />
			<span class="text-muted">Este campo es obligatorio</span>
			<br />
			<label for="profesionalSugerido">Diagnostico M:</label>
			<input id="profesionalSugerido" type="text" bind:value={diagnostico} />
			<span class="text-muted">Este campo es obligatorio</span>
			<br />
			{#if aclaraciones != "" && diagnostico != ""}
				<button
					class="btnBlue text-center"
					on:click={() => {
						loader = true;
						// addStudyDetail(
						// 	CentroMedicoSugerido,
						// 	aclaraciones,
						// 	diagnostico,
						// 	theRecord.medicationId,
						// 	theRecord.Paciente__c,
						// 	JSON.parse(localStorage.getItem("user"))["Id"]
						// ).then(async (response) => {
						// 	if (response.statusCode == 201) {
						// 		//window.location.reload();
						// 		await professional_exam_pdf_add(
						// 			response.data,
						// 			JSON.parse(localStorage.getItem("user"))["Id"],
						// 			aclaraciones,
						// 			diagnostico
						// 		).then(async (data) => {
						// 			console.log({ data });
						// 			if (data.statusCode == 200) {
						// 				console.log("Se cargo algo");
						// 				//window.location.reload();
						// 			} else {
						// 				flagError = true;
						// 			}
						// 		});
						// 	} else {
						// 		flagError = true;
						// 	}
						// });
					}}>Solicitar</button>
			{:else}
				<button class="btnWhite text-center">Solicitar</button>
			{/if}
		{:else}
			<Loader text={false} />
			<p>
				{flagError == false
					? "Agregando actividad..."
					: "La acción solicitada no se pudo completar, por favor intente nuevamente."}
			</p>
		{/if}
	</div>
</LayoutModal>

<style>
	:root {
		--color1: #112c4a;
	}
	.interview-d {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	/* CONTAINER CSS */
	.myCustom-box {
		border: solid 1px var(--color1);
		border-radius: 5px;
	}

	/* .btnBlue{
    margin-left: 55%;
} */

	/* HEADER CSS */
	.myCustom-header-closed-false {
		color: var(--color1);
	}
	.medication-d {
		margin-top: 0%;
	}

	.myCustom-header-open {
		background-color: #6a7e93;
		color: #ffffff;
	}

	.myCustom-name {
		justify-content: inherit;
		min-width: 30vw;
	}

	/* .myCustom-toggle {
		transform: scale(1.25);
	} */
	.variableA {
		width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	/* .modalidad {
		padding-left: 30%;
	} */

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}
	.btn-info {
		width: 80%;
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	}

	.myCustom-collapse:hover {
		cursor: pointer;
	}

	/* DETAIL CSS */
	.collapse {
		padding: 5px;
		/* background-color: #6A91AB; */
	}
	.btnActivar {
		padding: 1%;
		border: none;
		background-color: transparent;
	}
	.gg-pen {
		border-color: #6c757d;
		background-color: antiquewhite;
	}

	.myCustom-table {
		width: 100%;
		color: var(--color1);
	}

	.myCustom-table tbody tr {
		width: 100%;
	}

	.myCustom-table thead tr th {
		font-size: 15px;
		font-weight: 700;
	}
	.myCustom-header-closed-true {
		color: var(--color1);
		background-color: #fbfbfb;
	}

	.myCustom-button {
		background-color: var(--color1);
		border: none;
	}
	.circle {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: green;
	}
	.statusRegister {
		margin: 0%;
	}
	.statusID {
		align-items: center;
		justify-content: space-around;
	}
</style>
