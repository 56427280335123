import * as yup from "yup";
import { _ } from "svelte-i18n";

// Login
export const login_schema = yup.object().shape({
	username: yup.string().required({ username: "Ingrese el email" }).email("Email invalido"),
	password: yup.string().required({ password: "Ingrese la contraseña" }),
});

// Registro
export const register_schema = yup.object().shape({
	username: yup.string().required("Ingrese Email").email("Email invalido"),
	confirmUsername: yup.string().oneOf([yup.ref("username"), null], "El email debe coincidir"),
	password: yup.string().required("Ingrese password"),
	dni: yup.string().required("Ingrese DNI"),
	confirmPassword: yup.string().oneOf([yup.ref("password"), null], "La contraseña debe coincidir"),
	rol: yup.object().shape({
		value: yup.string().required("Debe especificar el rol de su cuenta"),
	}),
	countryCode: yup.string().required("Debe especificar un Pais"),
});

// Completar datos personales
export const personal_information_schema = yup.object().shape({
	name: yup.string().required("Debe especificar un nombre"),
	lastName: yup.string().required("Debe especificar un apellido"),
	gender: yup
		.object()
		.shape({
			label: yup.string().required("Debe especificar el genero"),
		})
		.nullable()
		.required("Debe especificar el genero"),
	personBirthdate: yup.string().required("Debe especificar una fecha de nacimiento"),
	dni: yup.string().required("Debe especificar su DNI"),
	phone: yup.string(),
	mobile: yup.string().required("Debe especificar un celular"),
	billingState: yup
		.object()
		.shape({
			label: yup.string().required("Debe especificar su ciudad/provincia").nullable(),
		})
		.nullable()
		.required("Debe especificar su ciudad/provincia"),
	billingStreet: yup.string().required("Debe especificar una dirección"),
	cobertura: yup
		.object()
		.shape({
			value: yup.string().required("Debe especificar su cobertura").nullable(),
		})
		.nullable()
		.required("Debe especificar su cobertura"),
	rol_cuidador: yup
		.object()
		.shape({
			value: yup.string().required("Debe especificar su rol-cuidador").nullable(),
		})
		.nullable()
		.required("Debe especificar su rol-cuidador"),
	nAfiliado: yup.string(),
});

// Registro Paciente
export const patient_register_schema = yup.object().shape({
	//diseases: yup.array().min(1).required('diseases')
});

// Registro Profesional
export const profesional_register_schema = yup.object().shape({
	Matricula__c: yup.string().required("Matricula__c"),
	PersonTitle: yup.string().required("PersonTitle"),
	specializations: yup.array().min(1).required("specializations"),
	Obra_social_Medicina_prepaga__c: yup.array().min(1).required("Obra_social_Medicina_prepaga__c"),
	refPatologia: yup.boolean().required(),
	Cuantos_pacientes_atendio__c: yup
		.string()
		.when("refPatologia", { is: true, then: yup.string().required("Cuantos_pacientes_atendio__c") }),
});

// Registro Profesional - Referente Patologia
export const profesional_register_schema_referente_patologia = yup.object().shape({
	// cantPaciente: yup.number().required('Obra_social_Medicina_prepaga__c'),
	// patologia: yup.array().min(1).required('diseases')
});

//verification code schema
export const verification_code_schema = yup.object().shape({
	code: yup.string().required("Debe ingresar un codigo enviado a su correo"),
});
