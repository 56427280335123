<script>
	import Loader from "../components/Loader.svelte";
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";
	import { generic_medication_add } from "../helpers/apiBackend";
	import { user } from "../helpers/storage/stores";

	export let myUserId;
	export let myPlan;
	export let myPatient;
	//export let nameMedi;

	let nameMedi = "";
	let doctor_id = "";
	let messageResult;
	let modalLoading = false;
	let modalError = false;
	let modalSucces = false;
	let body = {};

	const addHeader = async () => {
		if ($user.HealthCloudGA__CarePlan__c) {
			console.log("add medicamento patient", myUserId, myPlan, myPatient);
			doctor_id = $user.Medico_de_cabecera__c;
			myPatient = $user.Id;
			myPlan = $user.HealthCloudGA__CarePlan__c;
		} else {
			console.log("add medicamento Profesional", myUserId, myPlan, myPatient);
			doctor_id = $user.Id;
			//myPatient = $user.Id,
		}
		body = {
			cpsr: {
				farmaco: nameMedi,
				doctorId: doctor_id,
				patientId: myPatient,
				planId: myPlan,
			},
		};
		console.log({ body });

		if (nameMedi != "") {
			modalLoading = true;
			await generic_medication_add(body).then((result) => {
				console.log(result);

				if (result.statusCode == 201) {
					messageResult = $_("AddMedication.text_succes") + result.data.data.farmaco;
					modalSucces = true;
				} else {
					console.log(result.message);
					messageResult = result.message;
					modalError = true;
				}
				modalLoading = false;
			});
		} else {
			document.getElementById("idnamemedic").focus();
		}
	};

	onMount(async () => {
		try {
		} catch (error) {
			console.log("Error addMedical", error);
		}
	});
</script>

<!-- HTML init-->
<section>
	<div class="rounded">
		{#if modalLoading == false && modalError == false && modalSucces == false}
			<div>
				<h3 class="Title">{$_("AddMedication.text_title")}</h3>
			</div>
			<div>
				<div class="justify-content-between flex-wrap p-2 mt-3 rounded">
					<div class="fg form-group mr-2">
						<label for="medic-name" class="m-0">{$_("AddMedication.text_name_medicine")}:</label>
						<input
							id="idnamemedic"
							placeholder={$_("AddMedication.text_principle")}
							bind:value={nameMedi}
							type="text"
							class="form-control form-control-sm"
							name="doctor-name" />
					</div>
				</div>
				<!-- <AddDetailsMedications nameMed = {nameMedi}/> -->
				<div class="d-flex justify-content-center">
					<button
						on:click={() => {
							window.location.reload();
						}}
						class="btnAcpetar btnWhite">{$_("AddMedication.button_cancel")}</button>
					<button
						on:click={() => {
							addHeader();
						}}
						class="btnAcpetar btnBlue">{$_("AddMedication.button_accept")}</button>
				</div>
			</div>
		{:else if modalError != true && modalSucces == false}
			<div class="text-center p-3">
				<Loader text={false} />
				<p class="text-muted">{$_("AddMedication.loading")}</p>
			</div>
		{/if}
		{#if modalError == true || modalSucces == true}
			<div class="text-center p-2 mt-3 rounded">
				<div>
					<small>{messageResult}</small>
					<br />
					<div class="d-flex justify-content-center">
						<br />
						<button
							class="btnBlue py-0 text-center"
							on:click={() => {
								window.location.reload();
							}}>{$_("AddMedication.button_close")}</button>
					</div>
				</div>
			</div>
		{/if}
	</div>
</section>

<!-- HTML end-->
<style>
	.Title {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 25px;
		text-align: center;
	}
	.btnBlue {
		margin: 5%;
	}
	.btnWhite {
		margin: 5%;
	}
</style>
