<script>
	// Components
	import Loader from "../../components/Loader.svelte";
	import Pagination from "../../components/Pagination.svelte";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";

	// Helpers
	//import { careplan_questionnaire } from "../../helpers/apiBackend";
	import awsconfig from "../../aws-exports";

	// Svelte
	import { element } from "svelte/internal";
	import { onMount } from "svelte";
	import { user, registerDetail } from "../../helpers/storage/stores";
	import { Auth } from "aws-amplify";

	// import { claim_component, each } from "svelte/internal";
	Auth.configure(awsconfig);

	let nameQuest = "";
	let listQuest = [];
	let loadingModal = false;
	let opcRespondidas = [];
	let fin = false;
	let values = [];
	let activitiID = window.location.href.split("Detail=")[1];
	let datatest = [];
	$: listH = [];
	$: listM = [];
	/**
	 * @description
	 */
	async function validarRes(res) {
		let existe = [];
		opcRespondidas.map((item) => {
			if (item.name == res.name) {
				existe.push(item);
			}
		});
		console.log({ existe });
		if (existe.length == 0) {
			opcRespondidas.push(res);
		} else {
			opcRespondidas.forEach((elemnt) => {
				if (elemnt.name == existe[0].name) {
					elemnt.name = res.name;
					elemnt.category = res.category;
					elemnt.optionid = res.optionid;
					elemnt.points = res.points;
				}
			});
		}
		if (listQuest.length == opcRespondidas.length) {
			fin = true;
		}
	}

	function timeOut(listQuest) {
		setTimeout(() => {
			if (listQuest.length == 0)
				return (document.querySelector("#preguntas-div").innerHTML =
					'<p class="text-muted">EL CUESTIONARIO NO TIENE PREGUNTAS ASIGNADAS. </br> POR FAVOR CONTACTE AL ADMINISTRADOR.</p>');
		}, 3000);
	}

	// Finalizar
	/**
	 * @description
	 */
	async function btnfinaliza() {
		loadingModal = true;
		console.log({ opcRespondidas });
		console.log(opcRespondidas.length);
		let data = {
			cpsr: {
				actividad_detalle: activitiID,
				respuestas: [],
			},
		};

		opcRespondidas.forEach((opcion) => {
			let object = {};
			if (nameQuest == "Figure Rating Scale") {
				(object.pregunta_id = opcion.name),
					(object.pregunta_categoria = opcion.category),
					(object.opcion_name = opcion.points),
					(object.opcion_valor = 0),
					(object.opcion_id = opcion.optionid);
			} else {
				(object.pregunta_id = opcion.name),
					(object.pregunta_categoria = opcion.category),
					(object.opcion_name = opcion.name),
					(object.opcion_valor = opcion.points),
					(object.opcion_id = opcion.optionid);
			}
			data["cpsr"]["respuestas"].push(object);
		});
		// await careplan_questionnaire_complete(data).then((res) => {
		// 	if (res.statusCode == 200) {
		// 		let detail = {
		// 			Id: activitiID,
		// 			Name: res.data[0].Categor_a__c,
		// 		};
		// 		$registerDetail = { detail };
		// 		if ($user.hasOwnProperty("Especialidades__r")) {
		// 			window.location.href = "#/RegisterDetailQuestion?type=Cuestionarios&scope=detail";
		// 			//window.location.href = "#/ViewCuestionarioProf?type=Cuestionarios";
		// 		} else {
		// 			window.location.href = "#/RegisterDetailQuestion?type=Cuestionarios&scope=detail";
		// 			//#/RegisterDetailQuestion?type=Cuestionarios&scope=detail
		// 		}
		// 	} else {
		// 		console.log("Algo esta mal: ", res);
		// 	}
		// });
	}

	// On Mount
	onMount(async () => {
		let idDetail = window.location.href.split("Detail=")[1];
		// await careplan_questionnaire(idDetail).then((resutl) => {
		// 	console.log({ resutl });
		// 	nameQuest = resutl.data.actividad_detalle.Name;
		// 	listQuest = resutl.data.preguntas;
		// });

		if (nameQuest == "Figure Rating Scale") {
			let h = [];
			let m = [];
			listQuest.forEach((element) => {
				console.log(element);
				let op = element.Opciones_de_Preguntas__r.records;
				op.forEach((item) => {
					console.log(item);
					if (item.Orden__c >= 10) {
						h.push(item);
					} else {
						m.push(item);
					}
				});
			});
			listH = h;
			listM = m;
		}
		timeOut(listQuest);
	});
</script>

<div>
	<!-- svelte-ignore a11y-missing-attribute -->
	<img class="imgBack" src="/img/backrount.jpg" />

	<div class="content">
		<div class="content-wrappe">
			<div class="contact-form">
				<div class="conteiner-img">
					<!-- <img src="./img/Frame 106.png" alt="logo" /> -->
					<div id="nameQuest">
						<h1 class="title-welcome">{nameQuest}</h1>
					</div>
				</div>
				<div class="register-form">
					{#if listQuest.length > 0 && values}
						{#each values as value}
							<!-- {#each listQuest as record, i} -->
							<p class="title">{value.Pregunta__c}</p>
							{#if nameQuest == "Figure Rating Scale"}
								<div class="contentH">
									<div class="text-center">
										<img src="./img/hombreF.png" alt="male" />
									</div>
									<div class="contextHm">
										{#each listH as { Id, Name, Opci_n_c__c }, i}
											<label class="option">
												<input
													type="radio"
													name={value.Id}
													category={value.Categor_a__c}
													points={Opci_n_c__c}
													optionid={Id}
													on:click={() => {
														validarRes({
															name: value.Id,
															category: value.Categor_a__c,
															points: Opci_n_c__c,
															optionid: Id,
														});
													}} />
												<p>{Name}</p>
											</label>
										{/each}
									</div>
								</div>

								<div class="contentM">
									<div class="text-center">
										<img src="./img/mujerF.png" alt="male" />
									</div>
									<div class="contextM">
										{#each listM as op}
											<label class="option">
												<input
													type="radio"
													name={value.Id}
													category={value.Categor_a__c}
													points={op.Opci_n_c__c}
													optionid={op.Id}
													on:click={() => {
														validarRes({
															name: value.Id,
															category: value.Categor_a__c,
															points: op.Opci_n_c__c,
															optionid: op.Id,
														});
													}} />
												<p>{op.Name}</p>
											</label>
										{/each}
									</div>
								</div>
							{:else}
								<!-- <p class="title-welcome">Ingreso no listH</p> -->
								<!-- Inicio -->
								{#if value.Tipo__c == "Opción" && value.Opciones_de_Preguntas__r}
									<!-- Tipo__c == "Opción" -->
									<div
										class={nameQuest == "Escala de la severidad de la fatiga" ? "contextResp" : ""}>
										{#each value.Opciones_de_Preguntas__r.records as recordRes, i}
											<!-- svelte-ignore a11y-label-has-associated-control -->
											<label
												class={nameQuest == "Escala de la severidad de la fatiga"
													? "option"
													: "response"}>
												<!-- <p>{recordRes.Id}</p> -->
												<input
													type="radio"
													name={value.Id}
													category={value.Categor_a__c}
													points={recordRes.Valor__c}
													optionid={recordRes.Id}
													on:click={() => {
														validarRes({
															name: value.Id,
															category: value.Categor_a__c,
															points: recordRes.Valor__c,
															optionid: recordRes.Id,
														});
													}} />

												<p>{recordRes.Name}</p>
											</label>
										{/each}
									</div>
									<!-- Tipo__c == "text" -->
								{:else if !value.Opciones_de_Preguntas__r}
									<input name={value.Id} type="text" />
								{/if}
								<!-- Fin -->
							{/if}
						{/each}

						<div
							on:click={() => {
								let radios = document.querySelectorAll("input[type=radio]");
								radios.forEach((radio) => {
									let res = opcRespondidas.map((x) => x.optionid);
									if (res.indexOf(radio.attributes.optionid.nodeValue) === -1) {
										radio.checked = false;
									} else {
										radio.checked = true;
									}
								});
								window.scroll(0, 0);
							}}>
							<Pagination
								rows={listQuest}
								resPage={opcRespondidas.length}
								perPage={6}
								bind:trimmedRows={values} />
						</div>
					{:else}
						<div id="preguntas-div" class="text-center p-3">
							<Loader text={false} />
							<p class="text-muted">CARGANDO ENCUESTA...</p>
						</div>
					{/if}

					<div class="block2">
						{#if opcRespondidas.length != listQuest.length ? true : false}
							<div class="message">
								<br />
								<p class="alertM">Para poder finalizar debe contestar todas las preguntas</p>
							</div>
						{/if}
						<br />
						<button
							on:click={() => {
								window.location.href = $user?.Especialidades__r
									? "#/ViewCuestionarioProf?type=Cuestionarios"
									: "#/Patient/ViewCuestionarioPatient?type=Cuestionarios";
							}}
							id="nextButton"
							class="next">Volver</button>
						{#if fin}
							<button on:click={() => btnfinaliza()} id="nextButton" class="next">Finalizar</button>
						{/if}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Modal Cargando -->

<LayoutModal
	isOpenModal={loadingModal}
	on:closeModal={() => {
		loadingModal = false;
	}}>
	<div class="p-3">
		<Loader text={false} />
		<p class="text-muted">Guardando {nameQuest}...</p>
	</div>
</LayoutModal>

<style>
	.response {
		padding-left: 2%;
		margin: 2%;
		display: flex;
		width: 35%;
		justify-content: flex-start;
		align-items: baseline;
	}

	.option {
		padding-left: 2%;
		margin: 2.8%;
		justify-content: flex-start;
		align-items: baseline;
	}
	.response input {
		margin-right: 1rem;
	}
	.content {
		max-width: 1170px;
		margin-left: auto;
		margin-right: auto;
		padding: 1.5em;
	}
	.message {
		align-items: center;
		text-align: center;
	}
	.alertM {
		color: #686363;
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		text-align: center;
	}
	.imgBack {
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 100%;
	}
	.register-form {
		width: 100%;
	}
	.content-wrappe {
		width: 80%;
		position: relative;
		height: max-content;
		padding: 17px;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		justify-content: space-between;
		overflow: hidden;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}
	.contact-form {
		padding: 0.7em;
	}

	.contact-form input {
		padding: 0.7em;
	}

	.contextM {
		text-align: center;
	}
	.contextHm {
		text-align: center;
	}

	.block2 {
		width: 100%;
		text-align-last: center;
		grid-column: 1/3;
	}
	.next {
		height: 3.2rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #f7fafc;
		border-radius: 5px;
		background-color: #112c4a;
	}
	.title-welcome {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 22px;
		text-align: center;
	}
	.title {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 20px;
	}

	/* .multiselect {
      width: 420px;
    }
  
    .selectBox {
      width: 100%;
      position: relative;
    }
  
    .selectBox select {
      width: 420px;
      
    }
  
    .overSelect {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  
    #checkboxes {
      display: none;
    }
  
    #checkboxes label {
      display: block;
    }
  
    #checkboxes label:hover {
      background-color: #1e90ff;
      width: auto;
    }
    .selectAltura {
      display: block;
      height: 2.5rem;
    } */

	@media screen and (max-width: 1000px) {
		/* .contact-form form {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		} */
	}
</style>
