// Login
import Login from "./Routes/Login/Login.svelte";
import ResetPassword from "./Routes/Login/ResetPassword.svelte";
import NewPassword from "./Routes/Login/NewPassword.svelte";
import Register from "./Routes/Register/Register.svelte";
import EmailCodeVerfy from "./Routes/Register/EmailCodeVerify.svelte";
import LoginError from "./Routes/Login/LoginError.svelte";

// Register
import PatientRecord from "./Routes/Register/PatientRecord.svelte";
import ProfessionalRecord from "./Routes/Register/ProfessionalRecord.svelte";
import PersonalInformation from "./Routes/Register/PersonalInformation.svelte";

// Home
import Home from "./Routes/Home/Home.svelte";
import Profile from "./Routes/Home/Doctor/Profile.svelte";
import Forbidden from "./Routes/Home/Forbidden.svelte";
import GeneralError from "./Routes/Home/Error.svelte";
import Success from "./Routes/Home/Success.svelte";
import FormInit from "./Routes/Questionnaires/FormInit.svelte";

// Doctor
import Dashboard_Doctor from "./Routes/Home/Doctor/Doctor.svelte";
import Dashboard_Altas from "./Routes/Home/Doctor/Altas.svelte";
import Patient from "./Routes/Home/Doctor/Patient.svelte";
import AssignPlan from "./Routes/Home/Doctor/AssignPlan.svelte";
import Records from "./Routes/Home/Doctor/Records.svelte";
import RecordsMedicationProf from "./Routes/Home/Doctor/RecordsMedicationProf.svelte";
import ViewCuestionarioProf from "./Routes/Home/Doctor/ViewCuestionarioProf.svelte";
import ViewCuestionarioPatient from "./Routes/Home/Patient/ViewCuestionarioPatient.svelte";
import SelectPlan from "./Routes/Home/Doctor/SelectPlan.svelte";
import Plan from "./Routes/Home/Doctor/Plan.svelte";
import RegisterDetail from "./Routes/Home/Doctor/RegisterDetail.svelte";
import RegisterDetailMedicationProf from "./Routes/Home/Doctor/RegisterDetailMedicationProf.svelte";
import RegisterDetailQuestion from "./Routes/Home/Doctor/RegisterDetailQuestion.svelte";
import RegisterDetailQuestionPat from "./Routes/Home/Patient/RegisterDetailQuestion.svelte";
import CreateActivity from "./Routes/Home/Doctor/CreateActivity.svelte";
//import Session from "./Routes/Home/Doctor/Session.svelte";
import MySession from "./Routes/Home/Doctor/MySession.svelte";
import ClinicHistory from "./Routes/Home/Doctor/ClinicHistory.svelte";
import DiagnosisByProf from "./Routes/Home/Doctor/DiagnosisByProf.svelte";
import ProfessionalProfile from "./Routes/Home/Doctor/ProfessionalProfile.svelte";

import PatientSession from "./Routes/Home/Doctor/PatientSession.svelte"; // Don't remember when used!
import PatientSessions from "./Routes/Home/Doctor/PatientSessions.svelte";
//import PatientSessionsPendings from "./Routes/Home/Doctor/PatientSessionPendings.svelte";

// Patient
import PatientPlan from "./Routes/Home/Patient/Plan.svelte";
import SearchDoctor from "./Routes/Home/Patient/SearchDoctor.svelte";
import PatientPending from "./Routes/Home/Patient/Pending.svelte";
import PatientPendingValidation from "./Routes/Home/Patient/PendingValidation.svelte";
import PatientRecords from "./Routes/Home/Patient/Records.svelte";
import PatientRecordsMedication from "./Routes/Home/Patient/RecordMedicationPatient.svelte";
import RegisterDetailPatient from "./Routes/Home/Patient/RegisterDetail.svelte";
import RegisterDetailMedicationPatient from "./Routes/Home/Patient/RegisterDetailMedicationPatient.svelte";

//Carer
import CarerSelectPerfil from "./Routes/Home/Carer/CarerSelectPerfil.svelte";
//C:\Users\Usuario\OneDrive\Documentos\GitHub\rdcom-app-front\src\components\HelpFomr.svelte
import Dashboard_Helps from "./components/HelpFomr.svelte";

const routes = {
	// Login
	"/": Login,
	"/ResetPassword": ResetPassword,
	"/NewPassword/:email": NewPassword,
	"/EmailCodeVerify/:email": EmailCodeVerfy,
	"/LoginError": LoginError,

	// Register
	"/Register": Register,
	"/PersonalInformation/:rol": PersonalInformation,
	"/PatientRecord": PatientRecord,
	"/ProfessionalRecord": ProfessionalRecord,

	// Home
	"/Home": Home,
	"/Profile": Profile,
	"/Forbidden": Forbidden,
	"/GeneralError": GeneralError,
	"/Success": Success,

	// Doctor
	"/Doctor": Dashboard_Doctor,
	"/Altas": Dashboard_Altas,
	"/Patient": Patient,
	"/ProfessionalProfile": ProfessionalProfile,
	"/AssignPlan": AssignPlan,
	"/SelectPlan": SelectPlan,
	"/Records": Records,
	"/RecordsMedicationProf": RecordsMedicationProf,
	"/Plan": Plan,
	"/RegisterDetail": RegisterDetail,
	"/RegisterDetailMedicationProf": RegisterDetailMedicationProf,
	"/RegisterDetailQuestion": RegisterDetailQuestion,
	"/CreateActivity/:type": CreateActivity,
	//"/Session": Session,
	"/MySession": MySession,
	"/ClinicHistory": ClinicHistory,
	"/DiagnosisByProf": DiagnosisByProf,
	"/PatientSession": PatientSession,
	"/PatientSessions": PatientSessions,
	//"/PatientSessionsPendings": PatientSessionsPendings,
	"/ViewCuestionarioProf": ViewCuestionarioProf,

	// Home Patient
	"/Patient/Plan": PatientPlan,
	"/Patient/SearchDoctor": SearchDoctor,
	"/Patient/Pending": PatientPending,
	"/Patient/PendingValidation/:id": PatientPendingValidation,
	"/Patient/RecordMedicationPatient": PatientRecordsMedication,
	"/Patient/Records": PatientRecords,
	"/Patient/RegisterDetail": RegisterDetailPatient,
	"/Patient/RegisterDetailMedicationPatient": RegisterDetailMedicationPatient,
	"/Patient/ViewCuestionarioPatient": ViewCuestionarioPatient,
	"/Patient/RegisterDetailQuestion": RegisterDetailQuestionPat,

	// Home Carer
	"/Carer/Perfil": CarerSelectPerfil,
	"/FormInit": FormInit,
	"/Helps": Dashboard_Helps,
};

export default routes;
