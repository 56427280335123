<script>
	import { onMount, afterUpdate } from "svelte";
	import Select from "svelte-select";
	//import data from "../../../helpers/prov.json";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { _ } from "svelte-i18n";
	import { myRecords } from "../../../helpers/mock/records.js";
	import { currentSession } from "../../../helpers/storage/sessions.js";
	import { capitalizeFirstLetter, filterArray } from "../../../helpers/helpers.js";
	import {
		patient_professional_search,
		patient_professional_head_add,
		utils_resources_get,
	} from "../../../helpers/apiBackend";
	import { user, language } from "../../../helpers/storage/stores";

	// Endpoint
	// Parametros de busqueda
	// /patients/patient_search_doctor?Limit=100&Offset=0&Specialization=&BillingState=Misiones&Name=
	// Requeridos
	//  Limit / Offset
	// Especializacion (Recurso)
	// BillinState* / Name Inputs
	//const AWS_HOST = process.env.HOST_DEV;
	let [myLimit, myOffset, myEspecialization, myBillingState, myName] = [10, 0, "", "", ""];
	let searchedDoctors = [];
	let NameProfessional = "";
	let country = "";
	let myToken, errorMessage, myHeaders, myUrl;
	let filteredData = [];
	let especialitiesList = [];
	let speciality_id = "";
	let state_id = "";
	let country_id;
	let speciality = "";
	let myAction = null;
	let doctorModal = false;
	let doctorSelected;
	let professionalData;
	let ProfilePictureLink = "./img/perfil_none.png";
	let cabecera = true;
	let countryList = [];
	let modalError = false;
	let searching = false;
	let searched = false;
	let stateList = [];
	let filters = {
		name: "",
		specialty: "",
		country: "",
		state: "",
	};

	function applyFilters(filters) {
		// Obtener una copia de todos los profesionales
		let filteredData = [...searchedDoctors];

		// Filtrar por nombre
		if (filters.name) {
			filteredData = filteredData.filter((item) => {
				return item.NameProfessional.toLowerCase().includes(filters.name.toLowerCase());
			});
		}

		// Filtrar por especialidad
		if (filters.specialty) {
			// Verificar si se seleccionaron especialidades
			console.log("Filtrar por especialidades", filters.specialty);
			filteredData = filteredData.filter((item) => {
				return item.Speciality.some((s) => s.Speciality === filters.specialty); // Verificar si al menos una especialidad coincide
			});
		}

		// Filtrar por país
		if (filters.countryLabel) {
			console.log("Filtrar por país", filters.countryLabel);
			filteredData = filteredData.filter((item) => {
				return item.Country === filters.countryLabel;
			});
		}

		// Filtrar por provincia (estado)
		if (filters.state) {
			filteredData = filteredData.filter((item) => {
				return item.State === filters.state;
			});
			console.log("Filtrar por provincia", filters.state);
			console.log({ filteredData });
		}

		console.log({ filteredData });

		return filteredData;
	}

	const handleCountryChange = async (event) => {
		console.log("handleCountryChange Init");
		//country_id = event.target.value;
		console.log({ country_id });
		if (country_id != "") {
			stateList = await utils_resources_get("state", $language, country_id);
		} else {
			state_id = "";
			stateList = [];
		}
		console.log("handleCountryChange");
	};

	const searchDoctors = async () => {
		searched = true;
		searchedDoctors = [];

		console.log({ especialitiesList });
		console.log({ stateList });
		console.log({ country });
		country_id = country ? country.Id : "";
		console.log({ country_id });
		let data = {
			IdSpecialization: speciality_id,
			IdState: state_id,
			IdCountry: country_id,
			NameProfessional,
			Cabecera: cabecera,
			lang: $language,
		};
		filters = {
			name: NameProfessional,
			specialty: speciality_id,
			country: country_id,
			state: state_id,
		};
		filteredData = applyFilters(filters);
		if (filteredData.length == 0) {
			await patient_professional_search(data).then((result) => {
				console.log({ result });
				if (result.status == 400) {
					errorMessage = "No se encontrado ningun profesional";
					modalError = true;
				} else {
					searchedDoctors = result.data;
					filteredData = result.data;
				}
				searching = false;
				searched = false;
				console.log(result.data);
			});
		}
	};

	// Assign Doctor
	let myAssignUrl;
	let assignConfirmModal = false;
	$: assignDoctorRequestFinish = false;
	$: assignDoctorRequest = false;
	let assignDoctorResponseMessage = "";
	let doctorToSend_tmp;
	const assignDoctor = async (dotorToAssignId) => {
		console.log("dotorToAssignId", dotorToAssignId);
		console.log("$user.Id", $user.Id);
		assignDoctorRequest = true;
		if (Object.keys($currentSession).length > 0) {
			if ($currentSession.hasOwnProperty("attributes")) {
				if ($currentSession["attributes"]["profile"] == "Paciente") {
					await patient_professional_head_add(dotorToAssignId, $user.Id).then((data) => {
						console.log({ data });
						if (data.statusCode == 200) {
							assignDoctorResponseMessage = data.message;
							assignDoctorRequestFinish = true;
							assignDoctorRequest = false;
							assignConfirmModal = false;
							window.location.href = "#/Patient/Pending";
						} else {
							modalError = true;
							errorMessage = data.message;
						}
					});
				}
			}
		}
	};

	// onMount

	// afterUpdate(async () => {});
	onMount(async () => {
		console.log("SearchDoctor");
		especialitiesList = await utils_resources_get("specialties", $language);
		countryList = await utils_resources_get("country", $language);
		if (Object.keys($user).length > 0) {
			if ($user["Estado_Medico_de_cabecera__c"] == "Pendiente de aprobación") {
				window.location.href = "#/Patient/Pending";
			} else {
				try {
					myAction = document.location.href.split("?")[1].split("=")[1];
				} catch (error) {}
				console.log("utils_resources_get - SearchDoctor");

				//stateList = await utils_resources_get("state", $language);
			}
		}
	});
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	<div class="border shadow-sm rounded bg-white" style="width: 90%;margin: 2%;padding: 2%;">
		<div>
			<h3>{$_("SearchDoctor.text_title")}</h3>
		</div>
		<div name="search">
			<div class="d-flex justify-content-between rounded">
				<div class="fg form-group mr-2">
					<label for="doctor-name" class="m-0">{$_("SearchDoctor.text_name")}</label>
					<input
						placeholder={$_("SearchDoctor.text_name")}
						bind:value={NameProfessional}
						type="text"
						class="form-control form-control-sm"
						name="doctor-name"
						style="height: calc(2.4em + .5rem + 2px);" />
				</div>
				<div class="fg form-group mr-2">
					<label for="doctor-speciality" class="m-0">{$_("SearchDoctor.text_Specialties")}</label>
					<Select
						class="form-control form-control-sm"
						id="select-rol"
						items={especialitiesList}
						placeholder={$_("SearchDoctor.text_Select")}
						bind:value={myEspecialization} />
				</div>
				<div class="fg form-group mr-2">
					<label for="doctor-name" class="m-0">{$_("SearchDoctor.text_country")}</label>
					<Select
						class="form-control form-control-sm"
						id="select-rol"
						items={countryList}
						placeholder={$_("SearchDoctor.text_Select")}
						on:change={handleCountryChange}
						bind:value={country} />
					<!-- <select id="country" value={country_id} on:change={handleCountryChange}>
							<option value="">Todos</option>
							{#each countryList as country}
								<option value={country.Id}>
									{country.label}
								</option>
							{/each}
						</select> -->
				</div>
				<div class="fg form-group mr-2">
					<label for="doctor-name" class="m-0">{$_("SearchDoctor.text_provinces")}</label>
					<Select
						class="form-control form-control-sm"
						id="select-rol"
						items={stateList}
						placeholder={$_("SearchDoctor.text_Select")}
						bind:value={myBillingState} />
				</div>

				<div class="fg d-flex align-items-center mr-2">
					<button
						on:click={() => {
							searchDoctors();
						}}
						class="btnBlue py-1 mt-2">{$_("SearchDoctor.text_filter")}</button>
				</div>
			</div>
			{#if searchedDoctors.length > 0}
				<div class="barraName d-flex border rounded justify-content-between">
					<span class="spName">{$_("SearchDoctor.spName")} | {$_("SearchDoctor.spArea")}</span>
					<!-- <span class="spArea">{$_("SearchDoctor.spArea")}</span> -->
					<span class="spType">{$_("SearchDoctor.spcountry")}</span>
					<span class="spAction">{$_("SearchDoctor.spBillingState")}</span>
					<!-- <span class="spAction">{$_("SearchDoctor.spAction")}</span> -->
				</div>
			{/if}

			<div class="record">
				{#if searching}
					<div>
						<Loader text={false} />
						<p class="text-muted">{$_("SearchDoctor.loading")}</p>
					</div>
				{:else if searchedDoctors.length > 0}
					<div class="professional-list">
						{#each filteredData as profesional}
							<div
								class="professional-card"
								on:click={() => {
									assignConfirmModal = true;
									doctorToSend_tmp = profesional.Id;
									NameProfessional = profesional.NameProfessional;
								}}>
								<img
									src={profesional.ProfilePictureLink
										? profesional.ProfilePictureLink
										: ProfilePictureLink}
									alt="Foto de perfil" />
								<div class="professional-info" style="display: flex;justify-content: space-between;">
									<div style="width: 100%;">
										<p class="info-item" style="font-weight: 600;">
											{profesional.NameProfessional}
										</p>
										{#if profesional.Speciality && profesional.Speciality.length > 0}
											<p class="info-item text-muted">
												{profesional.Speciality[0].Speciality}
											</p>
										{/if}
									</div>

									<p class="info-item">
										{profesional.Country ? profesional.Country : "Sin datos"}
									</p>
									<p class="info-item">{profesional.State ? profesional.State : "Sin datos"}</p>

									<!-- Ajusta esta parte según la estructura real de tus datos -->
								</div>
							</div>
						{/each}
					</div>
				{:else}
					{#if !searched}
						<div class="text-center">
							<p class="text-muted">{$_("SearchDoctor.text_not_profession")}...</p>
						</div>
					{/if}
					{#if searched}
						<div class="text-center">
							<p class="text-muted">{$_("SearchDoctor.text_Searching")}..</p>
						</div>
					{/if}
				{/if}
			</div>
			<div name="actions">
				{#if myAction == null && searching}
					<a href="#/Patient/Plan">{$_("SearchDoctor.text_myplan")}</a>
				{/if}
			</div>
		</div>
	</div>
	<!-- </LayoutNav> -->
</section>

<!-- Modal Confirm -->
<!-- FALTA TESTEAR! -->
<LayoutModal
	tam="30"
	isOpenModal={assignConfirmModal}
	on:closeModal={() => {
		assignConfirmModal = false;
	}}>
	<div>
		{#if assignDoctorRequest}
			<div class="text-center p-3">
				<Loader text={false} />
				<p class="text-muted">{$_("SearchDoctor.text_requesting")}...</p>
			</div>
		{:else if assignDoctorRequestFinish}
			<p>{assignDoctorResponseMessage}</p>
		{:else}
			<div>
				<br />
				<p class="Title-detail">
					{$_("SearchDoctor.text_message_request")}{NameProfessional}{$_(
						"SearchDoctor.text_message_request_te"
					)}
				</p>
				<div class="conteiner">
					<button
						on:click={() => {
							doctorToSend_tmp = undefined;
							assignConfirmModal = false;
						}}
						class="btnWhite">{$_("SearchDoctor.button_Cancel")}</button>
					<button
						on:click={() => {
							assignDoctor(doctorToSend_tmp);
						}}
						class="btnBlue">{$_("SearchDoctor.button_acept")}</button>
				</div>
			</div>
		{/if}
	</div>
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="text-center">
		<p>{errorMessage}</p>
		<button
			class="btnBlue py-0 rounded-0"
			on:click={() => {
				modalError = false;
			}}>{$_("SearchDoctor.button_close")}</button>
	</div>
</LayoutModal>

<style>
	.searched-doctor:hover {
		transform: scale(0.99);
		transition: 0.2s;
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
		cursor: pointer;
	}
	.professional-card {
		width: 100%;
		padding: 20px;
		background-color: white;
		border-radius: 8px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.professional-card:hover {
		transform: scale(0.99);
		transition: 0.2s;
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
	}
	.professional-card img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin-right: 10px;
	}

	.professional-info {
		flex: 1;
	}
	.info-item {
		width: 100%;
		text-align: start;
		margin-bottom: 5px;
	}

	.variableA {
		width: 200px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.searched-doctor {
		height: 95px;
		align-items: center;
	}
	.form-group {
		width: 400px;
	}
	.namefilter {
		width: 100%;
	}
	.Title-detail {
		color: #215273;
		font-family: "Roboto", sans-serif;
		/* font-weight: bold; */
		/* font-size: 25px; */
		text-align: center;
	}
	.spName {
		margin-left: 2%;
	}
	.spAction {
		margin-right: 2%;
	}
	.barraName {
		background-color: #215273;
		height: 45px;
		color: #ffffff;
		align-items: center;
	}
	.conteiner {
		text-align: center;
	}
	.border {
		width: 100%;
	}
	.record {
		max-height: 50vh;
		overflow: auto;
	}
</style>
