<script>
    import { onMount } from "svelte";
    import { currentSession } from "../../../helpers/storage/sessions";
    import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";

    //Mock Datos del doctor
    let doctName = 'Julio Toloza'

    onMount(() => {
        //console.log({$currentSession})
    })
</script>
<section>
    <LayoutNav secure={true} rol="general">
        <div class="text-center border shadow p-2 m-3 bg-white">
            <div class="border-bottom text-muted">
                <h3>{doctName}</h3>
            </div>
            <div class=" my-3">
                <img
                    class="rounded-circle"
                    style="width: 100px; heigth:auto;"
                    src="./img/perfil_none.png"
                    alt=""/>
            </div>
            <a href="#/Home">Volver al inicio</a>
        </div>
    </LayoutNav>
</section>