<script>
  import { closeModal, modals } from "svelte-modals";
  import { fly } from "svelte/transition";

  export let isOpen;
  export let title;
  export let errors;
  export let message;

  
</script>

{#if isOpen}
  <div
    role="dialog"
    class="modal"
    transition:fly={{ y: 50 }}
    on:introstart
    on:outroend
  >
    <div class="contents">
      <h2 style="text-transform: underline;">{title}</h2>
      <p style="">{message}</p>
      {#if errors}
        <ul>
          {#each errors as error}
            <li style="color: red;">{error}</li>
          {/each}
        </ul>
      {/if}
      <div class="actions">
        <button on:click={closeModal}>OK</button>
      </div>
    </div>
  </div>
{/if}

<style>
  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width:60%;
    margin: 0 auto;
    /* allow click-through to backdrop */
    pointer-events: none;    
  }

  .contents {
    min-width: 240px;
    border-radius: 6px;
    padding: 16px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: auto;
    box-shadow: -1px 1px 24px 1px rgba(0,0,0,0.54);
    -webkit-box-shadow: -1px 1px 24px 1px rgba(0,0,0,0.54);
    -moz-box-shadow: -1px 1px 24px 1px rgba(0,0,0,0.54);
  }

  h2 {
    text-align: center;
    font-size: 24px;
  }

  p {
    text-align: center;
    margin-top: 16px;
  }

  .actions {
   /* margin-top: 32px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;*/
    display: flex;
		justify-content: space-evenly;
		margin-left: auto;
		width: 35%;
  }
  button{
    width: 50%;
    background: #112C4A;
    color: #fff;
    border-radius: 5px;
  } 
  
  
</style>
