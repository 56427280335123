<script>
	// Components / Layouts
	import Loader from "../../../components/Loader.svelte";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";

	// Helpers
	import { alta } from "../../../helpers/storage/stores";
	//import { unAssignPlan } from "../../../helpers/apiBackend";

	// Svelte
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";

	let patient_id = "";
	let modal_confirm = false;
	let load = false;

	/**
	 * @description
	 */
	function updateAssign() {
		//load = true;
		// unAssignPlan(patient_id).then((data) => {
		// 	if (data.statusCode == 200) {
		// 		window.location.href = "#/Altas";
		// 	}
		// });
		console.log("TODO: updateAssign");
	}

	/**
	 * @description
	 */
	onMount(() => {
		patient_id = $alta.Cuenta__r.Id;
	});
</script>

<section>
	<LayoutNav secure={true} rol="Profesional_de_Salud">
		<div class="border rounded shadow-sm p-3 bg-white">
			<div class="text-center">
				<div class="perfilIMG my-3">
					{#if $alta.fotoPerfil != null}
						<img
							class="imgPerfil cursor-pointer"
							style="width:90px; height:90px;"
							src={$alta.fotoPerfil}
							alt="" />
					{:else}
						<img
							class="imgPerfil cursor-pointer"
							style="width:90px; height:90px;"
							src="./img/perfil_none.png"
							alt="" />
					{/if}
				</div>
				<h3>{$alta.Cuenta__r.Name}</h3>
			</div>
			<div class="d-flex my-3 justify-content-around border py-2">
				<div class="">
					<div class="text-center">
						<h4>{$_("AssignPlan.title_page")}</h4>
					</div>

					<p class="m-0">
						{$_("AssignPlan.text_country")}:&nbsp;&nbsp;
						{#if $alta.Cuenta__r.BillingCountry != null}{$alta.Cuenta__r.BillingCountry}
						{:else}<span class="text-muted">{$_("AssignPlan.text_not_register")}</span>{/if}
					</p>
					<p class="m-0">
						{$_("AssignPlan.text_billingState")}:&nbsp;&nbsp;
						{#if $alta.Cuenta__r.BillingState != null}{$alta.Cuenta__r.BillingState}
						{:else}<span class="text-muted">{$_("AssignPlan.text_not_register")}</span>{/if}
					</p>
					<p class="m-0">
						{$_("AssignPlan.text_addres")}:&nbsp;&nbsp;
						{#if $alta.Cuenta__r.BillingStreet != null}{$alta.Cuenta__r.BillingStreet}
						{:else}<span class="text-muted">{$_("AssignPlan.text_not_register")}</span>{/if}
					</p>
					<p class="m-0">
						{$_("AssignPlan.text_birthdate")}:&nbsp;&nbsp;
						{#if $alta.Cuenta__r.PersonBirthdate != null}{$alta.Cuenta__r.PersonBirthdate}
						{:else}<span class="text-muted">{$_("AssignPlan.text_not_register")}</span>{/if}
					</p>
					<p class="m-0">
						{$_("AssignPlan.text_phone")}:&nbsp;&nbsp;
						{#if $alta.Cuenta__r.Phone != null}{$alta.Cuenta__r.Phone}
						{:else}<span class="text-muted">{$_("AssignPlan.text_not_register")}</span>{/if}
					</p>
					<p class="m-0">
						{$_("AssignPlan.text_cell_phone")}:&nbsp;&nbsp;
						{#if $alta.Cuenta__r.PersonMobilePhone != null}{$alta.Cuenta__r.PersonMobilePhone}
						{:else}<span class="text-muted">{$_("AssignPlan.text_not_register")}</span>{/if}
					</p>
					<p class="m-0">
						{$_("AssignPlan.text_coverage")}:&nbsp;&nbsp;
						{#if $alta.Cuenta__r.Cobertura_Salud__c != null}{$alta.Cuenta__r.Cobertura_Salud__c}
						{:else}<span class="text-muted">{$_("AssignPlan.text_not_register")}</span>{/if}
					</p>
					<p class="m-0">
						{$_("AssignPlan.text_affiliateNumber")}:&nbsp;&nbsp;
						{#if $alta.Cuenta__r.Nro_de_afiliado__c != null}{$alta.Cuenta__r.Nro_de_afiliado__c}
						{:else}<span class="text-muted">{$_("AssignPlan.text_not_register")}</span>{/if}
					</p>
				</div>
				<div class="">
					<div class="text-center">
						<h4>{$_("AssignPlan.text_Pathologies")}</h4>
					</div>
					{#each $alta.Patologias_Detalles__r.records as patologias}
						<p class="m-0">
							<span class="text-muted">&#8226;&nbsp;&nbsp;</span>{patologias.Name}
						</p>
					{/each}
					{#if $alta.adjuntos.length > 0}
						<div class="adjuntos">
							<div class="text-center">
								<h4>{$_("AssignPlan.text_attach")}</h4>
							</div>

							{#each $alta.adjuntos as data}
								{#if data.Tipo__c != "Foto de perfil"}
									<div class="border p-3">
										<img
											class="cursor pointer"
											on:click={() => {}}
											src="img/pdfIcon.png"
											alt="PDF"
											width="5%"
											height="5%" />
										<a href={data.Link__c} download>{$_("AssignPlan.button_download")}</a>
									</div>
								{/if}
							{/each}
						</div>
					{/if}
				</div>
			</div>
			<div class="button">
				<div class="text-center my-3">
					<button
						on:click={() => {
							window.location.href = "#/SelectPlan";
						}}
						class="btnWhite">{$_("AssignPlan.button_accept")}</button>
					<!-- "py-0 rounded-0 btn btn-outline-info" -->
					<button
						on:click={() => {
							modal_confirm = true;
						}}
						class="btnBlue">{$_("AssignPlan.button_reject")}</button>
					<!-- "py-0 rounded-0 btn btn-outline-secondary"     -->
				</div>
			</div>
			<div class="text-center">
				<a href="#/Altas">{$_("AssignPlan.loanbutton_returnding")}</a>
			</div>
		</div>
	</LayoutNav>
</section>

<!-- Modal Rechazar -->
<LayoutModal
	tam="30"
	isOpenModal={modal_confirm}
	on:closeModal={() => {
		modal_confirm = false;
	}}>
	{#if load}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("AssignPlan.loading")}</p>
		</div>
	{:else}
		<div class="text-center p-3">
			<p>{$_("AssignPlan.text_message_reyect")}</p>
			<div class="d-flex justify-content-center">
				<button
					on:click={() => {
						modal_confirm = false;
					}}
					class="btnWhite">
					{$_("AssignPlan.button_not")}
				</button>
				<br />
				<button
					on:click={() => {
						updateAssign();
					}}
					class="btnBlue">
					{$_("AssignPlan.button_yes")}
				</button>
			</div>
		</div>
	{/if}
</LayoutModal>

<!-- CSS -->
<style>
	.btnWhite {
		margin-right: 1%;
		width: 20%;
	}
	.btnBlue {
		margin-left: 1%;
		width: 20%;
	}

	.imgPerfil {
		width: 150px;
		border-radius: 50%;
		background-size: 120%;
	}
</style>
