<script>
	import DoctorListHeader from "../../components/Layouts/Layout_DoctorListHeader.svelte";
	import { _ } from "svelte-i18n";
</script>

<section>
	<div class="text-center text-white myCustom-patientHeader p-3">
		<!-- <h3>Paciente P</h3> -->
	</div>
	<DoctorListHeader
		myPage={$_("PlanPatient.button_patient")}
		buttomHC={$_("PlanPatient.button_history")}
		buttomHCHref="#/ClinicHistory"
		buttomPlan="Plan"
		buttomPlanHref="#/Patient/Plan"
		selected={window.location.href.split("#/")[1]} />
	<!-- buttomDiag={$_("PlanPatient.button_diagnosis")}
		buttomDiagHref="#/DiagnosisByProf" -->
</section>

<style>
	.myCustom-patientHeader {
		background-color: #215273;
	}
</style>
