<script>
	import LayoutNav from "../../components/Layouts/Layout_Nav.svelte";
</script>

<section>
	<LayoutNav secure={true} rol="general">
		<div class="container text-center text-muted py-4">
			<h1>404</h1>
			<p>La acción solicitada no se pudo completar, por favor intente nuevamente</p>
			<a
				on:click|preventDefault={() => {
					window.location.href = "#/Home";
				}}
				href="/">Volver</a>
		</div>
	</LayoutNav>
</section>
