<!-- 
    IMPORTANT - TO USE ON DOCTORS PLATFORM
 -->
<script>
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";
	import AddDetailsMedications from "../../components/addDetailsMedications.svelte";
	import { user, language, registerDetail } from "../../helpers/storage/stores";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import { formatDate } from "../../helpers/helpers";
	//import { profesional_register_schema_referente_patologia } from "../../Routes/schema";
	import {
		professional_interview_detail_add,
		professional_exam_detail_add,
		professional_exam_pdf_add,
		utils_resources_get,
	} from "../../helpers/apiBackend";
	import { _ } from "svelte-i18n";
	import Loader from "../Loader.svelte";
	const dispatch = createEventDispatcher();
	export let theRecord = undefined;

	let collapsed = true;
	let status_green = 0;
	let status_red = 0;
	let status_blue = 0;
	let status_note = "";
	let status_gray = 0;
	let status_record = true;
	let modalBibliogra = false;
	//let myCustom = "";
	let modalSuccess = false;
	//let profName = "";
	let modaladd = false;
	let modaladdCita = false;
	let name = "";
	let structMed = false;
	//let doctorId;
	let patientId;
	let suggestedDate = new Date().toISOString().split("T")[0];
	let editedBy = "";
	let planId;
	let actividadCabeceraId;
	let loader = false;
	let flagError = false;
	let modaladdStudy = false;
	let profesionalSugerido;
	let CentroMedicoSugerido;
	let specification;
	let aclaraciones = "";
	let diagnostico = "";
	let myType = document.location.href.split("?")[1].split("=")[1];

	const handleButtonClick = async () => {
		loader = true;
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			const data = {
				doctorId: user.Id,
				interviewId: theRecord.Id,
				suggestedProfessional: profesionalSugerido,
				suggestedDate,
				editedBy: user.Id,
			};

			const response = await professional_interview_detail_add(data);

			if (response.statusCode === 200) {
				window.location.reload();
			} else {
				flagError = true;
			}
		} catch (error) {
			console.error(error);
			// Maneja el error de manera adecuada, por ejemplo, mostrando un mensaje de error al usuario.
		} finally {
			loader = false;
		}
	};

	//let frecuencias = {};
	//let frecuencia = "";
	const statusbutton = $_("List_Records_prof.statusbutton");
	onMount(async () => {
		console.log({ theRecord });
		name = theRecord.name;
		status_record = theRecord.completed == null ? false : theRecord.completed;
		status_green = theRecord.requestedDetailsOnTime ? theRecord.requestedDetailsOnTime : 0;
		status_red = theRecord.requestedDetailsExpired ? theRecord.requestedDetailsExpired : 0;
		status_blue = theRecord.completedDetails ? theRecord.completedDetails : 0;
		status_note = theRecord.withNotes ? theRecord.withNotes : false;
		status_gray = theRecord.requestedDetailsInactive ? theRecord.requestedDetailsInactive : 0;
		editedBy = $user.Id;
		specification = theRecord["specification"] ? theRecord["specification"] : "";
	});
</script>

<section>
	<div class="myCustom-box">
		<div
			class="{collapsed
				? `myCustom-header-closed-${theRecord['active']}`
				: 'myCustom-header-open'} d-flex align-items-center p-2">
			<div class="d-flex myCustom-name">
				<p class="text_name m-0 variableA" title={name}>{name}</p>
				{#if theRecord.modality}
					<p
						class="m-0 modalidad"
						style="
    width:45%;
">
						({theRecord.modality ? theRecord.modality : ""})
					</p>
				{/if}
			</div>

			<div class="cont_modality">
				{#if theRecord.hasOwnProperty("frecuency")}
					<p class=" m-0">{theRecord.frecuency}</p>
				{:else}
					<p class=" m-0">{$_("List_Records_prof.text_frequency")}</p>
				{/if}
			</div>

			<div class="cabeceraID">
				{#if theRecord.details.length > 0 && theRecord.active && status_record}
					<small class="headDetailC py-0">{$_("List_Records_prof.text_completed")}</small>
				{:else if !theRecord.active}
					<small class="headDetailI py-0">{$_("List_Records_prof.text_inactive")}</small>
				{/if}

				{#if theRecord.details.length > 0 && theRecord.active && !status_record}
					{#if status_gray > 0}
						<span class="badge_gray">{status_gray}</span>
					{/if}
					{#if status_green > 0}
						<span class="badge_green">{status_green}</span>
					{/if}
					{#if status_red > 0}
						<span class="badge">{status_red}</span>
					{/if}
					{#if status_blue > 0}
						<span class="badge_blue">{status_blue}</span>
					{/if}
				{/if}
			</div>

			<!-- <div class="statusbadge" /> -->
			{#if theRecord.active}
				<div class="cont_details">
					<div>
						{#if status_note}
							<span class="badge_red"><img src="./img/notes.svg" alt="Menú"></span>
						{:else}
							<!-- <span class="badge_transp"><img src="./img/notes.svg" alt="Menú"></span> -->
						{/if}
					</div>

					<div
						class="cursor-pointer"
						on:click={() => {
							console.log("llamar modal para ver data");
							modalBibliogra = !modalBibliogra;
						}}>
						<!-- <i data-toggle="tooltip" data-placement="top" title="view" class="text-muted mx-2 gg-eye" /> -->
						<img src="./img/eye.svg" alt="Menú">
					</div>
					<div
						class="d-flex myCustom-collapse cursor-pointer"
						style="align-items: center;"
						data-toggle="collapse"
						data-target="#{theRecord.Id}"
						aria-expanded="false"
						aria-controls={theRecord.Id}>
						<!-- <p class="m-0 mx-2">Mas</p> -->

						<div
							class="cursor-pointer"
							on:click={() => {
								dispatch("editHeader");
								// collapsed = false;
							}}>
							<!-- <i
								data-toggle="tooltip"
								data-placement="top"
								title="Editar"
								class="text-muted mx-2 gg-pen cursor-pointer" />
								<img src="./img/pen.svg" alt="Menú"> -->

								<button
						on:click={() => {
							dispatch("editHeader");
							collapsed = false;
						}}
						style="background: none; border: none; padding: 0;">
						<img src="./img/pen.svg" alt="Flecha hacia abajo" style="width: 24px; height: 24px;">

						</div>
						

						<!-- <i
							class="gg-arrow-down-o"
							on:click={() => {
								collapsed = !collapsed;
							}} /> -->
							 <div>
						<button
						on:click={() => {
							collapsed = !collapsed;
						}}
						style="background: none; border: none; padding: 0;">
						<img src="./img/arrow-down-o.svg" alt="Flecha hacia abajo" style="width: 24px; height: 24px;">
					</button>
				</div>
							
					</div>
				</div>
			{:else}
				<div
					on:click={() => {
						dispatch("editHeader");
					}}
					class="d-flex myCustom-collapse"
					style="
    width: 10%;
    justify-content: flex-end;
">
					<button class="btnActivar">{$_("List_Records_prof.button_Request")}</button>
				</div>
			{/if}
		</div>

		<!-- Detail -->
		<div class="collapse" id={theRecord.Id}>
			<table class="myCustom-table">
				<thead>
					<tr>
						{#if !structMed}
							<th>{$_("List_Records_prof.text_th_createdby")}</th>
							{#if myType !== "Estudios"}
							<th>
								{$_("List_Records_prof.text_th_assigned")}
							</th>
							{/if}
							<th>
								{$_("List_Records_prof.text_th_session_date")}
							</th>
							<th>{$_("List_Records_prof.text_th_requested")}</th>
							<th>{$_("List_Records_prof.text_th_evolved")}</th>

							<th
								><button
									on:click={() => {
										if (myType == "Estudios") {
											modaladdStudy = true;
										} else {
											modaladdCita = true;
										}
									}}
									class="btnWhite">{$_("List_Records_prof.button_add")}</button
								></th>
						{:else}
							<!-- Detail Medicamento -->
							<th>Fecha </th>
							<th>Dosis </th>
							<th>Frecuencia </th>
							<th>Orden </th>
							{#if theRecord.requested}
								<th
									><button
										on:click={() => {
											modaladd = true;
										}}
										class="btnWhite">{$_("List_Records_prof.button_add")}</button
									></th>
							{/if}
						{/if}
					</tr>
				</thead>
				<tbody class="py-2">
					<!-- {#if theRecord.hasOwnProperty("details")} -->
					{#if theRecord.hasOwnProperty("details")}
						{#each theRecord["details"] as myDetail}
							<tr class="bg-white py-2" style="height:35px;">
								<!-- {#if !structMed} -->
								<!-- Creado_Por__r.Name -->
								<td>
									{#if myDetail.hasOwnProperty("createdByName")}
										<span>{myDetail["createdByName"]}</span>
									{:else}
										<span class="text-muted">...</span>
									{/if}
								</td>
								{#if myType !== "Estudios"}
									  <!-- Profesional Asignado o Sugerido -->
									  <td>
										{#if myDetail.hasOwnProperty("assignedProfessional") && myDetail["assignedProfessional"] != null}
											<span>{myDetail["assignedProfessional"]}</span>
										{:else if myDetail.hasOwnProperty("assignedProfessionalSuggestion") && myDetail["assignedProfessionalSuggestion"] != null}
											<span>{myDetail["assignedProfessionalSuggestion"]}</span>
										{:else}
											<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
										{/if}
									</td>
								{/if}

								<!-- Fecha_Sugerida__c -->
								{#if myDetail.hasOwnProperty("dateCompleted") && myDetail["dateCompleted"] != null}

									<td>
										<small class="dateSuggested py-0">
											{formatDate(myDetail["dateCompleted"], $language)}
										</small>
									</td>
								{:else}
									{#if !myDetail.requested}
										<td>
											<small class="dateSuggested_requeted py-0">
												{formatDate(myDetail["dateSuggested"], $language)}</small>
										</td>
									{:else}
										<td>
											<small class="dateSuggested_{myDetail.expired} py-0">
												{formatDate(myDetail["dateSuggested"], $language)}</small>
										</td>
									{/if}

								{/if}

								<!-- Solicitado__c -->
								<td>
									{#if myDetail["requested"]}
										<!-- <i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
										
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
										
									{:else}
										<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
										
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
										
									{/if}
								</td>
								<!-- Completado__c -->
								<td>
									{#if myDetail["completed"]}
									<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

									{:else}
									<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

									{/if}
								</td>

								<!-- Button Detalles -->
								<td>
									<button
										on:click={() => {
											dispatch("editDetail", { myDetail });
										}}
										class="btn btn-info py-1 myCustom-button"
										>{#if myDetail["requested"] && myDetail["completed"]}
											{statusbutton.detalle}
										{:else if myDetail["requested"]}
											{statusbutton.evolucionar}
										{:else}
											{statusbutton.solicitar}
										{/if}
									</button>
								</td>
								<!-- {:else}
									<td>
										{#if myDetail.hasOwnProperty("Fecha__c")}
											<span>{myDetail["Fecha__c"].split("-").reverse().join("-")}</span>
										{:else}
											<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
										{/if}
									</td>

									<td>
										{#if myDetail.hasOwnProperty("Dosis__c")}
											<span>{myDetail["Dosis__c"]}</span>
										{:else}
											<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
										{/if}
									</td>

									<td>
										{#if myDetail.hasOwnProperty("Frecuencia_texto__c")}
											<span>{myDetail["Frecuencia_texto__c"]}</span>
										{:else}
											<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
										{/if}
									</td>
									<td>
										{#if myDetail.hasOwnProperty("Link__c")}
											<a href={myDetail.Link__c} download
												>{$_("List_Records_prof.button_download")}</a>
										{/if}
									</td>

									<td>
										<button
											on:click={() => {
												dispatch("editDetail", { myDetail });
											}}
											class="btnBlue">{$_("List_Records_prof.button_detail")}</button>
									</td>
								{/if} -->
							</tr>
						{/each}
					{:else}
						<small class="text-muted">{$_("List_Records_prof.text_not_details")}</small>{/if}
					<!-- {:else}
						<small class="text-muted">{$_("List_Records_prof.text_not_details")}</small>{/if} -->
				</tbody>
			</table>
		</div>
	</div>
</section>
<LayoutModal
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	<div class="text-center">
		<div class="commentsRdcom-cont">
			{#if theRecord.hasOwnProperty("commentsRdcom")}
				<p>{theRecord.commentsRdcom}</p>
			{:else}
				<p>{$_("List_Records_prof.text_commentsRdcom_no")}</p>
			{/if}
		</div>

		<button
			on:click={() => {
				modalSuccess = false;
			}}
			class="btnWhite">{$_("List_Records_prof.button_close")}</button>
	</div>
</LayoutModal>

<LayoutModal
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<div class="medication-d">
		<AddDetailsMedications
			nameMed={name}
			{patientId}
			{planId}
			{actividadCabeceraId}
			on:cancel={() => {
				console.log("modaladd");
				modaladd = false;
			}} />
	</div>
</LayoutModal>
<!-- jtoloza23 -->
<LayoutModal
	isOpenModal={modaladdCita}
	on:closeModal={() => {
		modaladdCita = false;
	}}>
	<div class="interview-d">
		{#if loader == false}
			<div class="title">
				<h3>{name}</h3>
			</div>
			<div class="cont_sugeted">
				<div class="text_sugetedDate">
					<div>
						<label for="sugetedDate">{$_("List_Records_prof.text_th_date_planned")}:</label>
					</div>
					<div>
						<input
							class="input-suggestedDate"
							type="date"
							id="sugetedDate"
							pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
							name="trip-start"
							bind:value={suggestedDate} />
					</div>
				</div>
				<div class="text_sugetedProf">
					<div>
						<label for="profesionalSugerido">{$_("List_Records_prof.text_th_suggested")}:</label>
					</div>
					<div>
						<input id="profesionalSugerido" type="text" bind:value={profesionalSugerido} />
					</div>
				</div>
			</div>

			<br />
			<div class="button_send">
				<button class="py-2 mx-2 btnBlue_send" on:click={handleButtonClick} disabled={loader}>
					{$_("List_Records_prof.button_Send")}
				</button>
				<!-- <button
					class="py-0 mx-2 py-2 btnBlue_send"
					on:click={() => {
						loader = true;
						let data = {
							doctorId: JSON.parse(localStorage.getItem("user"))["Id"],
							interviewId: theRecord.id,
							suggestedProfessional: profesionalSugerido,
							suggestedDate,
							editedBy: $user.Id,
						};
						professional_interview_detail_add(data).then((response) => {
							response.statusCode == 200 ? window.location.reload() : (flagError = true);
						});
					}}>{$_("List_Records_prof.button_Send")}</button> -->
				<button
					class="py-0 mx-2 py-2 btnWhite_cancel"
					on:click={() => {
						modaladdCita = false;
					}}>{$_("List_Records_prof.button_cancel")}</button>
			</div>
		{:else}
			<Loader text={false} />
			<p>
				{flagError == false
					? $_("List_Records_prof.text_add_activity")
					: $_("List_Records_prof.text_message_error")}
			</p>
		{/if}
	</div>
</LayoutModal>
<LayoutModal
	isOpenModal={modaladdStudy}
	on:closeModal={() => {
		modaladdStudy = false;
	}}>
	<div class="interview-d">
		{#if loader == false}
			<!-- <h3>{$_("List_Records_prof.button_Request")} {name}</h3>
			<br />
			<div class="text_sugetedDate">
				<div>
					<label for="sugetedDate">{$_("List_Records_prof.text_th_date_planned")}:</label>
				</div>
				<div style="width: 46%;">
					<input
						class="input-log"
						type="date"
						id="sugetedDate"
						pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
						name="trip-start"
						bind:value={suggestedDate} />
				</div>
			</div>
			<label for="profesionalSugerido">{$_("List_Records_prof.text_SuggestedMedical")}s:</label>
			<input id="profesionalSugerido" type="text" bind:value={CentroMedicoSugerido} />
			<br />
			<label for="profesionalSugerido">{$_("List_Records_prof.text_clarifications")}:</label>
			<input id="profesionalSugerido" type="text" bind:value={aclaraciones} />
			<span class="text-muted">{$_("List_Records_prof.text_mandatory")}</span>
			<br />
			<label for="profesionalSugerido">{$_("List_Records_prof.text_diagnosis")}:</label>
			<input id="profesionalSugerido" type="text" bind:value={diagnostico} />
			<span class="text-muted">{$_("List_Records_prof.text_mandatory")}</span>
			<br /> -->
			<div class="record-request-section">
				<h3 class="record-heading">{$_("List_Records_prof.button_Request")} {name}</h3>
				<br />

				<div class="form-group text_sugetedDate">
					<div class="label-container">
						<label for="sugetedDate" class="input-label">
							{$_("List_Records_prof.text_th_date_planned")}:
						</label>
					</div>

					<input
						class="input-log"
						type="date"
						id="sugetedDate"
						pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
						name="trip-start"
						bind:value={suggestedDate} />
				</div>

				<div class="form-group input-label-container">
					<label for="profesionalSugerido" class="input-label">
						{$_("List_Records_prof.text_SuggestedMedical")}s:
					</label>
					<input id="profesionalSugerido" type="text" class="input-field" bind:value={CentroMedicoSugerido} />
				</div>

				<div class="form-group input-label-container">
					<label for="clarifications" class="input-label">
						{$_("List_Records_prof.text_specification")} :
					</label>
					<textarea bind:value={specification} class="form-control" id="evolucion" rows="3" maxlength="255" />
				</div>

				<div class="form-group input-label-container">
					<label for="aclaraciones" class="input-label">
						{$_("List_Records_prof.text_clarifications")}:
					</label>
					<!-- <input id="aclaraciones" type="text" class="input-field" bind:value={aclaraciones} /> -->
					<textarea bind:value={aclaraciones} class="form-control" id="evolucion" rows="3" maxlength="255" />
				</div>

				<div class="form-group input-label-container">
					<label for="diagnostico" class="input-label">
						{$_("List_Records_prof.text_diagnosis")}:
					</label>
					<!-- <input id="diagnostico" type="text" class="input-field" bind:value={diagnostico} /> -->
					<textarea bind:value={diagnostico} class="form-control" id="evolucion" rows="3" maxlength="255" />
				</div>
			</div>

			{#if aclaraciones != "" && diagnostico != ""}
				<button
					class="btnBlue text-center"
					on:click={() => {
						loader = true;
						let data = {
							examId: theRecord.Id,
							doctorId: JSON.parse(localStorage.getItem("user"))["Id"],
							suggestedMedicalCenter: CentroMedicoSugerido,
							clarifications: aclaraciones,
							diagnosis: diagnostico,
							specification,
							suggestedDate,
							// lang: $language,
							// role: "Profesional_de_Salud",
						};
						professional_exam_detail_add(data).then(async (response) => {
							if (response.statusCode == 200) {
								window.location.reload();
								await professional_exam_pdf_add(
									response.data[0].created,
									JSON.parse(localStorage.getItem("user"))["Id"],
									aclaraciones,
									diagnostico
								).then(async (data) => {
									console.log({ data });
									if (data.statusCode == 200) {
										console.log("Se cargo algo");
										window.location.reload();
									} else {
										flagError = true;
									}
								});
							} else {
								flagError = true;
							}
						});
					}}>{$_("List_Records_prof.button_Request")}</button>
			{:else}
				<button class="btnWhite_cancel text-center">{$_("List_Records_prof.button_Request")}</button>
			{/if}
		{:else}
			<Loader text={false} />
			<p>
				{flagError == false
					? $_("List_Records_prof.text_add_activity")
					: $_("List_Records_prof.text_message_error")}
			</p>
		{/if}
	</div>
</LayoutModal>
<LayoutModal tam="50" isOpenModal={modalBibliogra}>
	<div class="title">
		<h3>{name}</h3>
	</div>
	<div class="p-2 text-center">
		{#if theRecord.hasOwnProperty("commentsRdcom") && theRecord["commentsRdcom"] != null && theRecord["commentsRdcom"] != ""}
			<div class="class_commentsRdcom">
				<label for="for_commentsRdcom" class="label_commentsRdcom"
					>{$_("RegisterDetailProf.text_commentsRdcom")}:</label>
				<p
					style="
    text-align: initial;
	font-size: 14px;
">
					{theRecord["commentsRdcom"]}
				</p>
			</div>
		{/if}
		{#if theRecord.hasOwnProperty("bibliographicReference") && theRecord["bibliographicReference"] != null && theRecord["bibliographicReference"] != ""}
			<div class="class_bibliographicReference">
				<label for="" class="label_bibliographicReference"
					>{$_("RegisterDetailProf.text_bibliographicReference")}:</label>
				<p
					style="
    text-align: initial;
	    font-size: 14px;
">
					{theRecord["bibliographicReference"]}
				</p>
			</div>
		{/if}
		{#if theRecord.hasOwnProperty("specification") && theRecord["specification"] != null && theRecord["specification"] != ""}
			<div class="class_specification">
				<label for="" class="label_specification">{$_("RegisterDetailProf.text_specification")}:</label>
				<p
					style="
    text-align: initial;
	    font-size: 14px;
">
					{theRecord["specification"]}
				</p>
			</div>
		{/if}
		<div class="text-centrar">
			<button
				on:click={() => {
					modalBibliogra = false;
					//modalSolicitar = true;
				}}
				class="btnWhite_close py-0 mx-2 py-2">{$_("List_Records_prof.button_close")}</button>
		</div>
	</div>
</LayoutModal>

<style>
	:root {
		--color1: #112c4a;
	}
	.interview-d {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.form-group {
		display: flex;
		margin-bottom: 1rem;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
	}
	/* CONTAINER CSS */
	.myCustom-box {
		border: solid 1px var(--color1);
		border-radius: 5px;
	}
	.commentsRdcom-cont {
		margin: 5%;
	}
	.record-request-section {
		width: 80%;
	}
	.cont_modality {
		width: 34%;
		display: flex;
		justify-content: space-between;
	}

	.btnBlue_send {
		letter-spacing: 1px;
		border-radius: 10px;
		color: #ffffff;
		background-color: var(--my-dark-blue);
		width: 20%;
	}

	/* HEADER CSS */
	.myCustom-header-closed-false {
		color: var(--color1);
	}
	.medication-d {
		margin-top: 0%;
	}
	.record-heading {
		text-align: center;
	}
	.text_name {
		font-weight: 600;
	}

	.myCustom-header-open {
		background-color: #6a7e93;
		color: #ffffff;
	}
	.cont_details {
		display: flex;
		align-items: end;
		width: 10%;
		justify-content: flex-end;
	}
	.button_send {
		width: 100%;
		text-align: center;
	}

	.myCustom-header-inactive {
		background-color: #adb1b5;
		color: #ffffff;
	}

	.myCustom-header-complet {
		background-color: #116ac9;
		color: #ffffff;
	}
	.title {
		text-align: center;
		width: 95%;
	}

	.myCustom-name {
		justify-content: inherit;
		min-width: 30vw;
		max-width: 45vw;
		width: 46%;
	}

	/* .myCustom-toggle {
		transform: scale(1.25);
	} */
	.variableA {
		width: 450px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.statusbadge {
		display: flex;
		width: 10%;
		justify-content: space-between;
	}
	.text_sugetedProf {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 3%;
	}
	.input-log {
		width: 41%;
	}
	.cont_sugeted {
		width: 90%;
	}
	.text_sugetedDate {
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* margin: 2%; */
	}
	/* .modalidad {
		padding-left: 30%;
	} */
	.badge {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(255, 0, 0, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.btnWhite {
		width: 100%;
	}
	.btnWhite_close {
		letter-spacing: 1px;
		color: var(--my-dark-blue);
		border-radius: 10px;
		width: 20%;
		background-color: #ffffff;
	}
	.btnWhite_cancel {
		letter-spacing: 1px;
		color: var(--my-dark-blue);
		border-radius: 10px;
		width: 20%;
		background-color: #ffffff;
	}
	.badge_green {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(28, 187, 7, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_red {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(243, 2, 2, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_transp {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(28, 187, 7, 0);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.class_commentsRdcom {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: flex-start;
	}
	.label_commentsRdcom {
		font-weight: 700;
		text-align: initial;
	}
	.class_bibliographicReference {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: flex-start;
	}
	.label_bibliographicReference {
		font-weight: 700;
		text-align: initial;
	}
	.class_specification {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: flex-start;
	}
	.label_specification {
		font-weight: 700;
		text-align: initial;
	}
	.badge_blue {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(16, 48, 234, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_gray {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(146, 146, 146, 0.8);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}
	.btn-info {
		width: 100%;
	}
	.btn-send {
		width: 100%;
		color: white;
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	}

	.myCustom-collapse:hover {
		cursor: pointer;
	}

	/* DETAIL CSS */
	.collapse {
		padding: 5px;
		/* background-color: #6A91AB; */
	}
	.btnActivar {
		padding: 1%;
		border: none;
		background-color: transparent;
	}
	.gg-pen {
		border-color: #6c757d;
		background-color: antiquewhite;
	}

	.myCustom-table {
		width: 100%;
		color: var(--color1);
	}

	.myCustom-table tbody tr {
		width: 100%;
	}

	.myCustom-table thead tr th {
		font-size: 15px;
		font-weight: 700;
	}
	.myCustom-header-closed-true {
		color: var(--color1);
		background-color: #fbfbfb;
	}

	.myCustom-header-closed-false {
		color: var(--color1);
		background-color: #918f8f73;
	}

	.myCustom-button {
		background-color: var(--color1);
		border: none;
	}
	.circle {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: green;
	}
	.statusRegister {
		margin: 0%;
	}
	.statusID {
		align-items: center;
		justify-content: space-around;
	}
	.cabeceraID {
		width: 10%;
		display: flex;
		justify-content: space-between;
	}
	.headDetailC {
		background-color: cornflowerblue;
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.dateSuggested_false {
		background-color: rgba(28, 187, 7, 0.645);
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.dateSuggested_true {
		background-color: rgba(255, 0, 0, 0.645);
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.dateSuggested {
		background-color: cornflowerblue;
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.dateSuggested_requeted {
		border: none;
		color: rgb(8, 8, 8);
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.headDetailI {
		background-color: rgb(132, 132, 132);
		border: none;
		color: azure;
		border-radius: 10px;
		padding: 25%;
		text-align: center;
	}
</style>
