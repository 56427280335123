<script>
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	import { language } from "../../../helpers/storage/stores";
	import { _ } from "svelte-i18n";

	localStorage.setItem("language", JSON.stringify($language));
	let langSelect = "es";
	if ($language == "es" || $language == "en") {
		console.log("ingreso");
		langSelect = $language;
	}
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<!-- <LayoutPatient> -->
	<div class="border rounded shadow bg-white container">
		<div class="p-3 mx-1 text-center border-bottom">
			<h3 class="text-muted">{$_("Plan.title")}</h3>
		</div>
		<div class="py-4">
			<div class="d-flex justify-content-center p-2 flex-wrap">
				<button
					on:click={() => {
						window.location.href = "#/Records?type=Citas/Entrevistas";
					}}
					class="btnCitas">{$_("Plan.btnCitas")}</button>
				<button
					on:click={() => {
						window.location.href = "#/Records?type=Estudios";
					}}
					class="btnEstudios">{$_("Plan.btnEstudios")}</button>
				<button
					disabled
					on:click={() => {
						window.location.href = "#/Records?type=Estudios";
					}}
					class="btnRegistros">{$_("Plan.btnRegistros")}</button>
			</div>
			<div class="d-flex justify-content-center p-2 flex-wrap">
				<button
					on:click={() => {
						window.location.href = "#/RecordsMedicationProf";
					}}
					class="btnMedicinas">{$_("Plan.btnMedicinas")}</button>
				<button
					on:click={() => {
						window.location.href = "#/ViewCuestionarioProf?type=Cuestionarios";
						// alert('Sitio en construccion Cuestionarios')
					}}
					class="btnCuestionarios">{$_("Plan.btnCuestionarios")}</button>
				<button
					disabled
					on:click={() => {
						window.location.href = "#/Records?type=Estudios";
					}}
					class="btnOtros">{$_("Plan.btnOtros")}</button>
			</div>
		</div>
		<div class="d-flex justify-content-center mx-1 border-top">
			<p
				class="texlink mx-2 cursor-pointer"
				on:click={() => {
					window.location.href = "#/Doctor";
				}}>
				{$_("Plan.button_return")}
			</p>
			<!-- <a href="#/Doctor">Volver a mis pacientes</a> -->
		</div>
	</div>
	<!-- </LayoutPatient> -->
	<!-- </LayoutNav> -->
</section>

<style>
	/* .activity-button{
        width: 150px;
    } */
	.btnCitas {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #112c4a;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnEstudios {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #112c4a;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnOtros {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #112c4a;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnOtros:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}
	.container {
		max-width: 98%;
		height: auto;
	}
	.btnMedicinas {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #112c4a;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnCuestionarios {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #112c4a;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnCuestionarios:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}
	.btnMedicinas:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}
	.btnRegistros {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #112c4a;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnRegistros:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}
	button:hover {
		transform: scale(1.1);
		transition: 0.2s;
		-webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
	}
</style>
