<!-- 
  Campos
  https://docs.google.com/spreadsheets/d/1YqntIvLV1kA61a1R_l4sZZ23Fmyv5_dG6rDtvKJA4G8/edit#gid=0
 -->
<script>
	// Components / Layouts
	//import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import LayoutPatientHeader from "../../../components/Layouts/Layout_Patient_Header.svelte";

	import Loader from "../../../components/Loader.svelte";
	import SearchDoctorGral from "../../../components/SearchDoctorGral.svelte";
	import ModalComplete from "../../../components/ModalComplete.svelte";
	import ModalNotePatient from "../../../components/ModalNotePatient.svelte";
	import Modal from "../../../components/Modal.svelte";

	// Helpers
	import { registerDetail, user, language } from "../../../helpers/storage/stores";
	import {
		requestDoctor,
		utils_files_get,
		//update_activity_detail,
		generic_exam_detail_complete,
		generic_exam_detail_update,
		generic_interview_detail_complete,
		generic_interview_detail_notes_add,
		generic_interview_detail_update,
		generic_exam_detail_get,
		generic_interview_detail_get,
	} from "../../../helpers/apiBackend";
	import { currentSession } from "../../../helpers/storage/sessions.js";
	import { formatDate } from "../../../helpers/helpers";

	// Svelte
	import { _ } from "svelte-i18n";
	import { onMount } from "svelte";

	// Variables
	let type = "";
	let dataAdjunto = [];
	let [modalLoading, modalSuccess, modalError] = [false, false, false];
	let dateSession = "";
	let suggestedDate = "";
	let datesuggested_data = "";
	let clarifications = "";
	let modalBibliogra = false;
	let diagnosis = "";
	let statusS = false;
	let assignDoctorHeader = false;
	let modalComplete = false;
	let selectedDoctor = "";
	let modalSolicitar = false;
	let modalEdit = false;
	let sendChanges = false;
	let modalPatch = false;
	let patching = false;
	let myBody = {};
	let complexItemsRol = [];
	let complexItemsStatus = [];
	let myType, myScope, myParams, originalRecord, token, myRecomendedDoctor;
	let [editRealDate, editFrecuencia, modalSugerir] = [false, false, false];
	let assignDoctor = {
		idDoctor: "",
		idActivity: "",
		suggestedDateTime: "",
	};
	let bodySolicitar = {};
	let modalLoadingAnimation = false;
	let select_activity = $_("RegisterDetailPatient.select_activity");

	// [REFACTOR]
	// [REFACTOR]
	// [REFACTOR]
	token = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
	let myHeaders = { Authorization: token };
	const AWS_HOST = process.env.HOST_DEV;
	// [REFACTOR]
	// [REFACTOR]
	// [REFACTOR]

	/**
	 * @description
	 */
	const btnRequestDoctor = async () => {
		let result = "";
		try {
			if (selectedDoctor.Id && $registerDetail["detail"]["Id"] && suggestedDate) {
				assignDoctor.idDoctor = selectedDoctor.Id;
				assignDoctor.idActivity = $registerDetail["detail"]["Id"];
				assignDoctor.suggestedDateTime = suggestedDate;
				result = await requestDoctor(assignDoctor, $currentSession);
				console.log({ assignDoctor });
				console.log({ result });
				if ((result.status = 204)) {
					modalSolicitar = false;
					statusS = true;
				}
			}
		} catch (error) {}
	};

	/**
	 * @description
	 */
	const completeSession = async (e) => {
		modalComplete = false;
		if (e.detail.hasOwnProperty("body")) {
			let dataBody = e.detail.body;
			console.log({ e });
			console.log({ dataBody });
			modalLoading = true;

			if (myType == "Estudios") {
				let myBody = {
					Id: $registerDetail["detail"].Id,
					completed: true,
					evolvedBy: $user.Id,
					evolvedByType: "P", //P or D
					evolution: dataBody.Evolucion__c,
					dateCompleted: dataBody.Fecha_de_sesion__c,
				};
				await generic_exam_detail_complete(myBody).then((data) => {
					console.log({ data });
					if (data.status == 200) {
						modalComplete = false;
						modalLoading = false;
						modalSuccess = true;
					} else {
						//modalComplete = false;
						modalError = true;
					}
					// modalLoading = false;
				});
			} else {
				// await generic_interview_detail_complete(myBody).then((data) => {
				// 	console.log({ data });
				// 	if (data.status == 200) {
				// 		modalComplete = false;
				// 		modalSuccess = true;
				// 	} else {
				// 		modalError = true;
				// 	}
				// });
				try {
					//const data = await generic_interview_detail_complete(myBody);
					let data = {
						id: $registerDetail["detail"].Id,
						typeNote: "Note_patient",
						evolvedBy: $user.Id,
						description: dataBody.notePatient,
					};
					console.log({ data });
					const result = await generic_interview_detail_notes_add(data);

					console.log({ result });

					if (result.status === 200) {
						modalComplete = false;
						modalLoading = false;
						modalSuccess = true;
					} else {
						//modalComplete = false;
						modalError = true;
					}
					// modalLoading = false;
				} catch (error) {
					console.error("Error al completar la entrevista:", error);
					modalError = true;
					// modalLoading = false;
				}
			}
			//console.log(e.detail)
			// await update_activity_detail(myBody, $registerDetail["detail"].id).then((data) => {
			// 	console.log({ data });
			// 	if (data.status == 204) {
			// 		modalSuccess = true;
			// 	} else {
			// 		modalError = true;
			// 	}
			// 	modalLoading = false;
			// });
			// let myCompleteUrl = `${AWS_HOST}/generic/update_activity_detail?activity_id=${$registerDetail['detail'].Id}`
			// await fetch(myCompleteUrl, {
			//   method:'PATCH',
			//   headers:myHeaders,
			//   body:JSON.stringify(myBody)
			// })
			//   .then(patchResponse => {
			//     console.log({patchResponse})
			//     if(patchResponse.status==204){
			//       modalSuccess = true
			//     }else{
			//       modalError = true
			//     }
			//   })
			//   .catch(patchError => { console.log({patchError}) })
			//   .finally(() => { modalLoading=false })
		}
	};

	const solicitarCall = async () => {
		modalLoadingAnimation = true;
		modalEdit = false;
		modalSolicitar = false;

		if (myType == "Estudios") {
			// bodySolicitar = {
			// 	id: $registerDetail["detail"]["id"],
			// 	suggestedMedicalCenter: myRecomendedDoctor,
			// 	dateSuggested: datesuggested_data,
			// 	clarifications,
			// 	diagnosis,
			// };
			bodySolicitar = {
				id: $registerDetail["detail"]["Id"],
				suggestedMedicalCenter: myRecomendedDoctor,
				dateSuggested: datesuggested_data,
				role: "Paciente",
			};
			console.log({ bodySolicitar });
			await generic_exam_detail_update(bodySolicitar)
				.then(async (data) => {
					if (data.status == 200) {
						modalLoadingAnimation = false;
						modalSuccess = true;
						//window.location.href = "#/Patient/Records?type=" + myType;
					} else {
						modalLoadingAnimation = false;
					}
				})
				.catch((solicitarError) => {
					console.log({ solicitarError });
				})
				.finally(() => {});
		} else {
			bodySolicitar = {
				id: $registerDetail["detail"]["Id"],
				suggestedProfessional: myRecomendedDoctor,
				dateSuggested: datesuggested_data,
			};
			await generic_interview_detail_update(bodySolicitar)
				.then(async (data) => {
					console.log({ data });
					if (data.statusCode == 200) {
						window.location.href = "#/Patient/Records?type=" + myType;
					} else {
						modalLoadingAnimation = false;
					}
				})
				.catch((solicitarError) => {
					console.log({ solicitarError });
				})
				.finally(() => {});
		}
	};

	/**
	 * @description
	 */
	onMount(async () => {
		patching = true;
		if (Object.keys($registerDetail).length > 0) {
			//Busca si tienen adjuntos
			dataAdjunto = await utils_files_get($registerDetail["detail"]["Id"], $currentSession);
			if (dataAdjunto.data.length > 0 && dataAdjunto.data[0].hasOwnProperty("Link__c")) {
				type = dataAdjunto.data[0].Link__c.split("/")[3];
				type = type.split(".")[1];
				console.log({ type });
			}
			// Validate Parameters (RecordType / Scope)
			delete $registerDetail["Actividad_Detalles__r"];
			myParams = document.location.href.split("?")[1];
			if (myParams.indexOf("&") > -1) {
				myParams = myParams.split("&");
				myType = myParams[0].split("=")[1];
				myScope = myParams[1].split("=")[1];
			}
			if (myScope == undefined || myScope == "" || myType == undefined || myType == "") {
				window.location.href = "#/GeneralError";
			}

			// Delete nulls
			Object.keys($registerDetail["register"]).forEach((key) => {
				if ($registerDetail["register"][key] == null || $registerDetail["register"][key] == undefined) {
					$registerDetail["register"][key] = "";
				}
			});
			if ($registerDetail.hasOwnProperty("detail")) {
				Object.keys($registerDetail["detail"]).forEach((key) => {
					if ($registerDetail["detail"][key] == null || $registerDetail["detail"][key] == undefined) {
						$registerDetail["detail"][key] = "";
					}
				});
			}

			if (myType == "Estudios") {
				console.log("generic_exam_detail_get");
				await generic_exam_detail_get($registerDetail["detail"]["Id"], $language).then((result) => {
					console.log({ result });
					if (result.data.hasOwnProperty("data")) {
						$registerDetail["detail"] = result.data.data;
					}
				});
			} else {
				console.log("generic_interview_detail_get");
				await generic_interview_detail_get($registerDetail["detail"]["Id"], $language).then((result) => {
					console.log({ result });
					if (result.data.hasOwnProperty("data")) {
						$registerDetail["detail"] = result.data.data;
					}
				});
			}

			originalRecord = JSON.parse(JSON.stringify($registerDetail));
			complexItemsStatus = $_("RegisterDetailProf.select_status_detail");
			complexItemsRol = $_("RegisterDetailProf.select_rol");
			patching = false;
		}
	});

	/**
	 * @description
	 */
	const createBody = (key, value) => {
		myBody[key] = value;
	};

	/**
	 * @description
	 */
	// const updateRecord = async () => {
	// 	patching = true;
	// 	myHeaders = { Authorization: token };

	// 	// Update Header
	// 	if (myScope == "header") {
	// 		let myUrlHeader = `${AWS_HOST}/generic/update_activity?activity_id=${$registerDetail["register"]["id"]}`;
	// 		//console.log({myUrlHeader})
	// 		await fetch(myUrlHeader, {
	// 			method: "PATCH",
	// 			headers: myHeaders,
	// 			body: myBody,
	// 		})
	// 			.then((data) => {
	// 				data.json();
	// 			})
	// 			.then((response) => {}) //console.log({response})})
	// 			.catch((error) => {
	// 				window.location.href = "#/GeneralError";
	// 			})

	// 			// Finally
	// 			.finally(() => {
	// 				// patching = false
	// 				// modalPatch = false
	// 				// window.location.href="#/Success?origen=editRecord"
	// 			});

	// 		// Update Detail
	// 	} else if (myScope == "detail") {
	// 		console.log({ myScope });
	// 		let myUrlDetail = `${AWS_HOST}/generic/update_activity_detail?activity_id=${$registerDetail["detail"]["Id"]}`;
	// 		await fetch(myUrlDetail, {
	// 			method: "PATCH",
	// 			headers: myHeaders,
	// 			body: myBody,
	// 		})
	// 			.then((data) => {
	// 				data.json();
	// 			})
	// 			.then((response) => {})
	// 			.catch((error) => {
	// 				window.location.href = "#/GeneralError";
	// 			})

	// 			// Finally
	// 			.finally(() => {
	// 				// patching = false
	// 				// modalPatch = false
	// 				// window.location.href="#/Success?origen=editRecord"
	// 			});
	// 	}
	// };

	$: {
		if (originalRecord != undefined) {
			if (JSON.stringify(originalRecord) != JSON.stringify($registerDetail)) {
				sendChanges = true;
			} else {
				sendChanges = false;
			}
		}
		if (myScope == "detail") {
			if (dateSession !== "") {
				$registerDetail["detail"]["Fecha_de_sesion__c"] = dateSession;
			}
		}
	}
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	<LayoutPatientHeader />
	{#if patching}
		<div class="p-5 text-center">
			<Loader text={false} />
			<p class="text-muted">{$_("RegisterDetailPatient.loading")}</p>
		</div>
	{:else}
		<div class="conteiner-detail py-2 border rounded shadow-sm my-3 bg-white">
			<div class="myType_cont" style="width: 100%; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);">
				<h5
					class="text-reduce text-muted"
					style="
    margin-left: 2%;
">
					{myType} | {$registerDetail["register"]["name"]}
				</h5>
			</div>

			<!-- <div class="Title-detail">
					{#if myType == "Estudios"}
						<p class="title">{$registerDetail["register"]["name"]}</p>
					{/if}
					{#if myType == "Citas/Entrevistas"}
						<p class="title">
							{$_("RegisterDetailProf.text_date_with")}
							{$registerDetail["register"]["name"]}
						</p>
					{/if}
				</div> -->
			<div class="form-detail">
				<div class="row">
					<!-- requested -->
					<div class="column">
						<p class="Completado_p">{$_("RegisterDetailProf.text_required")}</p>
						{#if $registerDetail["detail"]["requested"]}
							<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
							<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{:else}
							<!-- <i class="myCustom-toggle gg-goggle-on myCustom-toggleOff" /> -->
							<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{/if}
					</div>
					<!-- completed -->
					<div class="column">
						<p class="Completado_p">{$_("RegisterDetailProf.text_complete")}</p>
						{#if $registerDetail["detail"]["completed"]}
							<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
							<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

							
						{:else}
							<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
							<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

						{/if}
					</div>
					<!-- createdByName -->
					<div class="column">
						<p class="Creado_p">{$_("RegisterDetailProf.text_th_createdby")}</p>
						{#if $registerDetail["detail"].hasOwnProperty("createdByName")}
							{$registerDetail["detail"]["createdByName"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailProf.text_not_State")}</span>
						{/if}
					</div>
				</div>
			</div>
			<!-- CITAS -->
			{#if $registerDetail["detail"].hasOwnProperty("assignedProfessionalName")}
				<div class="form-detail">
					<div class="row">
						<!-- dateSuggested -->
						<div class="column">
							<p class="Completado_p">{$_("RegisterDetailProf.text_date_suggested")}</p>
							{#if $registerDetail["detail"]["dateSuggested"] && $registerDetail["detail"]["dateSuggested"] !== ""}
								{formatDate($registerDetail["detail"]["dateSuggested"], $language)}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_not_State")}</span>
							{/if}
						</div>
						<!-- assignedProfessionalStatus -->
						<div class="column">
							<p class="Creado_p">{$_("RegisterDetailProf.text_th_suggested")}</p>
							{#if $registerDetail["detail"].hasOwnProperty("assignedProfessionalSuggestion") && $registerDetail["detail"]["assignedProfessionalSuggestion"] != "" && $registerDetail["detail"]["assignedProfessionalSuggestion"] != null}
								{$registerDetail["detail"]["assignedProfessionalSuggestion"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
							{/if}
						</div>
						{#if $registerDetail["detail"]["evolvedByName"] && $registerDetail["detail"]["evolvedByType"] == "D"}
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_evolved_by")}</p>

								{$registerDetail["detail"]["evolvedByName"]} - {complexItemsRol[
									$registerDetail["detail"]["evolvedByType"]
								]}
								<!-- {:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span> -->
							</div>
						{/if}
					</div>
				</div>
				<div class="form-detail">
					<div class="row">
						<!-- assignedProfessionalStatus -->
						{#if $registerDetail["detail"].hasOwnProperty("dateCompleted") && $registerDetail["detail"]["dateCompleted"] != "" && $registerDetail["detail"]["dateCompleted"] != null}
							<div class="column">
								<p class="Creado_p">{$_("RegisterDetailProf.text_th_session_date")}</p>

								{formatDate($registerDetail["detail"]["dateCompleted"], $language)}
								<!-- {:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_not_State")}</span> -->
							</div>
						{/if}
						<!-- dateSuggested -->
						{#if $registerDetail["detail"]["assignedProfessionalName"]}
							<div class="column">
								<p class="Completado_p">
									{$_("RegisterDetailProf.text_th_assigned")}
								</p>

								{$registerDetail["detail"]["assignedProfessionalName"]}
							</div>
						{/if}
						<!-- assignedProfessionalStatus -->
						{#if $registerDetail["detail"].hasOwnProperty("assignedProfessionalStatus") && $registerDetail["detail"]["assignedProfessionalStatus"] != "" && $registerDetail["detail"]["assignedProfessionalStatus"] != null}
							<div class="column">
								<p class="Creado_p">{$_("RegisterDetailProf.text_State")}</p>

								{complexItemsStatus[$registerDetail["detail"]["assignedProfessionalStatus"]]}
							</div>
						{/if}
					</div>
				</div>
			{/if}

			{#if $registerDetail["detail"].hasOwnProperty("suggestedMedicalCenter")}
				<!-- ESTUDIO -->
				<div class="form-detail">
					<div class="row">
						<!-- dateSuggested -->
						<div class="column">
							<p class="Completado_p">{$_("RegisterDetailProf.text_date_suggested")}</p>
							{#if $registerDetail["detail"]["dateSuggested"] && $registerDetail["detail"]["dateSuggested"] !== ""}
								{formatDate($registerDetail["detail"]["dateSuggested"], $language)}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
							{/if}
						</div>
						<!-- assignedProfessionalStatus -->
						<div class="column">
							<p class="Creado_p">{$_("RegisterDetailProf.text_Suggested_Medical")}</p>
							{#if $registerDetail["detail"].hasOwnProperty("suggestedMedicalCenter") && $registerDetail["detail"]["suggestedMedicalCenter"] != "" && $registerDetail["detail"]["suggestedMedicalCenter"] != null}
								{$registerDetail["detail"]["suggestedMedicalCenter"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
							{/if}
						</div>
						<!-- 
								<div class="column">
									
								</div> -->
						{#if $registerDetail["detail"]["evolvedByName"]}
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_evolved_by")}</p>

								{$registerDetail["detail"]["evolvedByName"]} - {complexItemsRol[
									$registerDetail["detail"]["evolvedByType"]
								]}
								<!-- {:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span> -->
							</div>
						{/if}
					</div>
				</div>

				{#if $registerDetail["detail"]["completed"]}
					<div class="form-detail">
						<div class="row">
							<!-- assignedProfessionalStatus -->
							<div class="column">
								<p class="Creado_p">{$_("RegisterDetailProf.text_th_session_date")}</p>
								{#if $registerDetail["detail"].hasOwnProperty("dateCompleted") && $registerDetail["detail"]["dateCompleted"] != "" && $registerDetail["detail"]["dateCompleted"] != null}
									{formatDate($registerDetail["detail"]["dateCompleted"], $language)}
								{:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
								{/if}
							</div>
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_evolution_date")}</p>
								{#if $registerDetail["detail"]["dateReal"]}
									{formatDate($registerDetail["detail"]["dateReal"], $language)}
								{:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span>
								{/if}
							</div>
							<!-- suggestedMedicalCenter -->
							<!-- <div class="column">
									<p class="Completado_p">
										{$_("RegisterDetailProf.text_Assign_Medical")}
									</p>
									{#if $registerDetail["detail"]["suggestedMedicalCenter"]}
										{$registerDetail["detail"]["suggestedMedicalCenter"]}
									{:else}
										<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
									{/if}
								</div> -->
							<!-- assignedProfessionalStatus -->
							<!-- <div class="column">
									<p class="Creado_p">{$_("RegisterDetailProf.text_State")}</p>
									{#if $registerDetail["detail"].hasOwnProperty("assignedProfessionalStatus") && $registerDetail["detail"]["assignedProfessionalStatus"] != "" && $registerDetail["detail"]["assignedProfessionalStatus"] != null}
										{complexItemsStatus[$registerDetail["detail"]["assignedProfessionalStatus"]]}
									{:else}
										<span class="text-muted">{$_("RegisterDetailProf.text_not_State")}</span>
									{/if}
								</div> -->
						</div>
					</div>
				{/if}
			{/if}

			{#if $registerDetail["detail"]["status"] == "WithNote"}
				<div class="form-detail">
					<div class="row">
						<!-- notesPatient -->
						{#if $registerDetail["detail"]["notesPatient"] != null}
							<div class="column">
								<p class="Creado_p">{$_("ModalComplete.text_title_note_center")}</p>
								{#if $registerDetail["detail"].hasOwnProperty("notesPatient")}
									{$registerDetail["detail"]["notesPatient"]}
								{:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
								{/if}
							</div>
						{/if}
						{#if $registerDetail["detail"]["evolvedByName"] && $registerDetail["detail"]["evolvedByType"] !== "D"}
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_note_by")}</p>

								{$registerDetail["detail"]["evolvedByName"]} - {complexItemsRol[
									$registerDetail["detail"]["evolvedByType"]
								]}
							</div>
						{/if}
					</div>
				</div>
			{/if}

			{#if $registerDetail["detail"]["notesProfessional"]}
				<div class="form-detail">
					<div class="row">
						<!-- notesPatient -->
						<div class="column">
							<p class="Creado_p">{$_("RegisterDetailProf.text_th_session_Note_D")}</p>
							{#if $registerDetail["detail"].hasOwnProperty("notesProfessional")}
								{$registerDetail["detail"]["notesProfessional"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
							{/if}
						</div>
						{#if $registerDetail["detail"]["evolvedByName"] && $registerDetail["detail"]["evolvedByType"] !== "D"}
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_note_by")}</p>

								{$registerDetail["detail"]["evolvedByName"]} - {complexItemsRol[
									$registerDetail["detail"]["evolvedByType"]
								]}
							</div>
						{/if}
					</div>
				</div>
			{/if}

			{#if $registerDetail["detail"]["completed"] == false && $registerDetail["detail"]["notesPatient"] == null}
				<br />
				<div class="button_cont">
					<button
						on:click={() => {
							modalComplete = true;
						}}
						class="btnAdd ml-3">+ {$_("RegisterDetailPatient.button_add_note")}</button>

					<button
						on:click={() => {
							//modalSolicitar = true;
							modalEdit = true;
							datesuggested_data = new Date($registerDetail.detail.dateSuggested)
								.toISOString()
								.split("T")[0];
							console.log("datesuggested_data", datesuggested_data);
							myRecomendedDoctor = $registerDetail["detail"].assignedProfessionalSuggestion
								? $registerDetail["detail"].assignedProfessionalSuggestion
								: $registerDetail["detail"].suggestedMedicalCenter;
							suggestedDate = datesuggested_data;
							clarifications = $registerDetail["detail"].clarifications;
							diagnosis = $registerDetail["detail"].diagnosis;
						}}
						class="btnWhite ml-3">{$_("RegisterDetailPatient.button_Edit")}</button>
					{#if (myType != "Estudios" && !patching && $registerDetail["detail"].assignedProfessionalStatus == null) || $registerDetail["detail"].assignedProfessionalStatus == "r"}
						<button
							on:click={() => {
								modalSolicitar = true;
							}}
							class="btnBlue ml-3">{$_("RegisterDetailPatient.button_Request")}</button>
					{/if}
				</div>
			{/if}
			<!-- {#if $registerDetail["detail"]["evolution"]}
					<div class="form-detail">
						<p class="Completado_p">{$_("RegisterDetailProf.text_title_Evolution")}</p>
						{#if $registerDetail["detail"]["evolution"]}
							{$registerDetail["detail"]["evolution"]}
						{:else}
							<span class="text-muted">...</span>
						{/if}
					</div>
				{/if} -->
		</div>
		<div class="my-3 d-flex justify-content-center">
			{#if myType}
				<a class="mx-2" href="#/Patient/Records?type={myType}"
					>{$_("RegisterDetailPatient.button_return")}{select_activity[myType]}</a>
			{/if}
			<a class="mx-2" href="#/Patient/Plan">{$_("RegisterDetailPatient.button_return_plan")}</a>

			<!-- Changes -->
			{#if sendChanges}
				<button
					on:click={() => {
						modalPatch = true;
					}}
					class="btn btn-success py-0 mx-2">{$_("RegisterDetailPatient.button_Send")}sss</button>
				<button
					class="btn btn-outline-danger py-0 mx-2"
					on:click|preventDefault={() => {
						$registerDetail = JSON.parse(JSON.stringify(originalRecord));
						myBody = {};
						//console.log({myBody})
					}}>{$_("RegisterDetailPatient.button_Discard_changes")}</button>
			{/if}
		</div>

		{/if}
	 <!-- </LayoutNav> -->
</section>

<LayoutModal
	tam="30"
	isOpenModal={modalLoadingAnimation}
	on:closeModal={() => {
		modalLoadingAnimation = false;
	}}>
	<div class="p-5 text-center">
		<Loader text={false} />
		<p class="text-muted">{$_("RegisterDetailProf.loading")}</p>
	</div>
</LayoutModal>

<LayoutModal
	tam="40"
	isOpenModal={modalEdit}
	on:closeModal={() => {
		modalEdit = false;
	}}>
	<div class="p-3 text-center">
		<div class="my-2">
			{#if myType !== "Estudios"}
				<br />
				<div class="form-group-interview">
					<div class="container border shadow">
						<div class="container-title">
							<i
								class="gg-details-less cursor pointer"
								on:click={() => {
									modalBibliogra = !modalBibliogra;
								}} />
							<h2 class="Title-detail">
								{modalEdit
									? $_("RegisterDetailProf.button_Edit")
									: $_("RegisterDetailProf.button_Request")}
								{$registerDetail["register"]["name"]}
							</h2>
						</div>
						{#if modalBibliogra}
							<span class="bibliographic"> {$registerDetail["register"]["bibliographicReference"]}</span>
						{/if}
					</div>
					<div class="datesuggested">
						<label for="datesuggested">{$_("RegisterDetailProf.text_date_suggested")}:</label>
						<input
							name="datesuggested"
							bind:value={datesuggested_data}
							class="form-control form-control-sm"
							type="date" />
					</div>
					<br />
					<div class="profSuggested">
						<label for="sugerir-doctor">{$_("RegisterDetailProf.text_prof_suggested")}:</label>
						<input
							name="sugerir-doctor"
							bind:value={myRecomendedDoctor}
							class="form-control form-control-sm"
							type="text" />
					</div>
				</div>
			{:else}
				<div class="form-group-exam">
					<div class="record-request-section">
						<div class="container">
							<!-- <div class="container-title">
								<i
									class="gg-details-less cursor pointer"
									on:click={() => {
										modalBibliogra = !modalBibliogra;
									}} />
								
							</div> -->
							<h2 class="Title-detail">
								{modalEdit
									? $_("RegisterDetailProf.button_Edit")
									: $_("RegisterDetailProf.button_Request")}
								{$registerDetail["register"]["name"]}
							</h2>
							{#if modalBibliogra && $registerDetail["register"]["bibliographicReference"]}
								<span class="bibliographic">
									{$registerDetail["register"]["bibliographicReference"]}</span>
							{/if}
							{#if modalBibliogra && $registerDetail["register"]["specification"]}
								<span class="bibliographic"> {$registerDetail["register"]["specification"]}</span>
							{/if}
						</div>

						<div class="form-group text_sugetedDate">
							<div class="label-container">
								<label for="sugetedDate" class="input-label">
									{$_("List_Records_prof.text_th_date_planned")}:
								</label>
								<input
									class="input-log"
									type="date"
									id="sugetedDate"
									pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
									name="trip-start"
									bind:value={datesuggested_data} />
							</div>
						</div>

						<div class="form-group input-label-container">
							<label for="profesionalSugerido" class="input-label">
								{$_("List_Records_prof.text_SuggestedMedical")}s:
							</label>
							<input
								id="profesionalSugerido"
								type="text"
								class="input-field"
								bind:value={myRecomendedDoctor} />
						</div>

						<!-- <div class="form-group input-label-container">
							<label for="clarifications" class="input-label">
								{$_("List_Records_prof.text_clarifications")}:
							</label>
							<textarea
								bind:value={clarifications}
								class="form-control"
								id="evolucion"
								rows="3"
								maxlength="255" />
						</div> -->

						<!-- <div class="form-group input-label-container">
							<label for="diagnosis" class="input-label">
								{$_("List_Records_prof.text_diagnosis")}:
							</label>

							<textarea
								bind:value={diagnosis}
								class="form-control"
								id="evolucion"
								rows="3"
								maxlength="255" />
						</div> -->
					</div>

					<br />
				</div>
			{/if}
		</div>
		<br />

		<div class="text-centrar">
			<br />
			{#if datesuggested_data != ""}
				<button
					on:click={() => {
						solicitarCall();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("RegisterDetailProf.button_Send")}</button>
			{/if}
			<button
				on:click={() => {
					modalEdit = false;
				}}
				class="btnWhite py-0 mx-2 py-2">{$_("RegisterDetailProf.button_Cancel")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Confirm -->
<LayoutModal isOpenModal={modalPatch} on:closeModal={(modalPatch = false)}>
	<div class="p-3 text-center">
		<p class="m-0">{$_("RegisterDetailPatient.text_request_change")}</p>
		<div class="d-flex justify-content-between my-3">
			<button
				on:click={() => {
					//updateRecord();
				}}
				class="mx-2 btnBlue py-0">{$_("RegisterDetailPatient.button_Send")}</button>
			<button
				on:click={() => {
					modalPatch = false;
				}}
				class="mx-2 btnWhite py-0">{$_("RegisterDetailPatient.button_Cancel")}</button>
		</div>
	</div>
</LayoutModal>
{#if modalSolicitar} 
<LayoutModal
	tam="80"
	isOpenModal={modalSolicitar}
	on:closeModal={() => {
		modalSolicitar = false;
	}}>
	<div class="text-center">
		<div class="text-center">
			<SearchDoctorGral
				specialty={$registerDetail["register"]["name"]}
				country={$user["BillingCountry"]}
				state={$user["BillingState"]}
				on:dateProf={(e) => {
					console.log("EVENTO RECIBIDO: ", e.detail);
					if (e.detail.hasOwnProperty("doctor")) {
						//modalSolicitar = false
						assignDoctorHeader = true;
						selectedDoctor = e.detail.doctor;
					}
				}}
				stateCabecera={false} />
		</div>

		{#if selectedDoctor}
			<div class="border shadow-sm rounded">
				<div>
					<p>{$_("RegisterDetailPatient.text_Professional")}</p>
					<!-- svelte-ignore a11y-label-has-associated-control -->
					<label>{selectedDoctor.Name}</label>
					<p>{$_("RegisterDetailPatient.text_date_suggested")}</p>
					<input
						class="input-log"
						type="date"
						pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
						name="trip-start"
						bind:value={suggestedDate} />
				</div>
			</div>
		{/if}

		<!-- <div class="text-center">
			<br />
			<button
				on:click={() => {
					modalSolicitar = false;
				}}
				class="btnWhite">{$_("RegisterDetailPatient.button_Cancel")}</button>
			
		</div> -->
	</div>
</LayoutModal>
{/if}
<!-- Modal Complete Session -->
<LayoutModal
	tam="30"
	isOpenModal={modalComplete}
	on:closeModal={() => {
		modalComplete = false;
	}}>
	<ModalNotePatient
		idTypeAttachment={$registerDetail["detail"]["Id"]}
		objectName={"C"}
		on:complete={(e) => {
			completeSession(e);
		}}
		on:cancel={() => {
			modalComplete = false;
		}} />
</LayoutModal>

<Modal isOpenModal={modalLoading}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div class="text-center">
			<p class="text-muted">{$_("RegisterDetailPatient.loading")}</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<LayoutModal
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	<div class="text-center p-3">
		{#if myType == "Estudios"}
			<p>{$_("RegisterDetailPatient.text_message_succes_studi")}</p>
		{:else}
			<p>{$_("RegisterDetailPatient.text_message_succes_gral")}{select_activity[myType]}</p>
		{/if}
		<a href="#/Patient/Records?type={myType}"
			>{$_("RegisterDetailPatient.button_return")} {select_activity[myType]}</a>
	</div>
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="text-center p-3">
		<p>{$_("RegisterDetailPatient.text_message_error")}</p>
		<a href="#/Patient/Records?type=Citas/Entrevistas">{$_("RegisterDetailPatient.button_return_interviews")}</a>
	</div>
</LayoutModal>

<style>
	.form-detail {
		margin: 1%;
		margin-left: 3%;
		width: 100%;
	}
	.row {
		display: flex;
		width: 100%;
	}
	.column {
		flex-basis: 33.33%; /* Para dividir equitativamente en tres columnas */
		padding: 10px;
		box-sizing: border-box;
	}
	.conteiner-detail {
		display: flex;
		flex-wrap: wrap;
		margin-left: 10%;
		width: 80%;
		max-height: 65vh;
		overflow: auto;
	}
	.my-record {
		overflow: auto;
	}
	.btnAdd {
		color: #215273;
		border-color: #215273;
		font-weight: 500;
		border-radius: 10px;
		background-color: #ffffff;
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.conteiner {
		padding-left: 5%;
		width: 90%;
	}
	.button_cont {
		width: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
	}
	.Title-detail {
		color: #215273;
		margin-left: 2%;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 25px;
	}
	.title {
		font-size: 25px;
	}
	.Completado {
		width: auto;
		border-bottom: 1px solid rgb(2, 2, 2);
		height: 3.2rem;
	}
	.adjuntos {
		width: auto;
		height: 3.2rem;
	}
	.Completado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.profSuggested {
		display: flex;
		flex-wrap: wrap;
		margin-left: 5%;
		width: 80%;
	}
	.datesuggested {
		display: flex;
		flex-wrap: wrap;
		margin: 5%;
		width: 80%;
	}
	.adjuntos_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
	}
	.Creado {
		width: auto;
		border-bottom: 1px solid rgb(2, 2, 2);
		height: 3.2rem;
	}
	.Creado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}
	.variableA {
		width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	}
</style>
