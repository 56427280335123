<script>
	//import Modal from "../../components/Modal.svelte";
	//import Loader from "../../components/Loader.svelte";
	// Components
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import LoadingModal from "./LoadingModal.svelte";

	// Helpers
	import { Auth } from "aws-amplify";
	import awsconfig from "../../aws-exports";
	import { currentSession } from "../../helpers/storage/sessions";
	import { language, user, patient } from "../../helpers/storage/stores";
	import { login_schema } from "../schema";
	import { getUserInfo } from "../../helpers/helpers.js";
	import { generic_account_check, generic_account_email_check } from "../../helpers/apiBackend";

	// Svelte
	import { onMount } from "svelte";
	import { Modals, closeModal } from "svelte-modals";
	import { replace } from "svelte-spa-router";
	import { fade } from "svelte/transition";
	import { _ } from "svelte-i18n";

	Auth.configure(awsconfig);

	const MIN_PASSWORD_LENGTH = 8;

	// VARIABLES
	let storedLanguage = JSON.parse(localStorage.getItem("language"));
	let [message, status_reg, username, password, rol, statusSF] = ["", "", "", "", "", ""];
	let dni_cognito = "";
	let statusAccount = "";
	let IdAccount = "";
	let [code_verify, log] = [false, false];
	let message_error = {};
	let errorCognito = $_("Login.select_error_cognito");
	let langSelect = "en";
	let error_modal = false;
	let rememberMe = false;
	//let isLoading = true; // Controla la visibilidad del modal de carga
	//let progress = 0;

	if (storedLanguage == "es" || storedLanguage == "en") {
		langSelect = storedLanguage;
	}

	// Cerrar Sesion
	const closeSession = () => {
		window.sessionStorage.clear();
		[$currentSession, $user, $patient] = [{}, {}, {}, {}];
	};

	/**
	 * @description
	 */
	async function updateUserStatus(status, StatusAccountSF) {
		let user;
		// console.log("updateUserStatus inicio");
		//console.log({ status });
		await Auth.currentAuthenticatedUser()
			.then(async (resultado) => {
				//console.log("updateUserStatus ingreso", resultado);
				user = resultado;
				// console.log("UpdateUserStatus", user)
				await Auth.updateUserAttributes(user, {
					"custom:StatusAccountSF": StatusAccountSF,
					"custom:StatusReg": status.toString(),
				}).then((r) => {
					// console.log("UpdateUserStatus",{ r });
				});
			})
			.catch((err) => {
				user = $currentSession;
			});
		//console.log("updateUserStatus finalizo");
	}

	function rememberUser() {
		if (typeof localStorage !== "undefined" && localStorage.getItem("rememberMe") === "true") {
			// Retrieve the stored credentials
			const storedUsername = localStorage.getItem("rememberedUsername") || "";
			//const storedPassword = localStorage.getItem("rememberedPassword") || "";

			if (storedUsername) {
				//signIn(storedUsername, storedPassword);
				username = storedUsername;
				rememberMe = localStorage.getItem("rememberMe");
			}
		}
	}

	// Función para manejar la opción "Mantenerme logueado"
	function handleRememberMe() {

		if (rememberMe) {
			localStorage.setItem("rememberMe", "true");
			localStorage.setItem("rememberedUsername", username);
			// localStorage.setItem("rememberedPassword", password);
		} else {
			localStorage.setItem("rememberMe", "false");
			localStorage.removeItem("rememberedUsername");
			// localStorage.removeItem("rememberedPassword");
		}
		//console.log("FINALIZO handleRememberMe");
	}

	async function validateDocument(dni) {
		console.time("validateDocument");
		console.log("Ingreso validateDocument");
		return await generic_account_check(dni).then(async (data) => {
			console.log(generic_account_check, { data });
			//console.log("Return : ", data);
			console.timeEnd("validateDocument");
			return data;
		});
	}

	async function validateEmailStatusAccount(dni, email, rol) {
		return await generic_account_email_check(dni, email, rol).then(async (data) => {
			console.log("validateEmailStatusAccount - validateEmailStatusAccount",{ data });
			if (data.statusCode == 200) {
				console.log("Prueba");
				statusAccount = data.accountStatus;
				IdAccount = data.Id;
			}
			return data;
		});
	}

	/**
	 * @description
	 */
	async function signInValidate(status_reg, statusSF, statusAccount, rol) {
		// console.log("status_reg",status_reg);
		// console.log("statusSF",statusSF);
		// console.log("statusAccount",statusAccount);	
		// console.log("ROL",rol);
		if (statusAccount === "Blocked" || statusAccount === "Deceased" || statusAccount === "Canceled") {
			//console.log("Su cuenta está bloqueada. Por favor, póngase en contacto con support@example.com");
			let error = {};
			error.name = "User is blocked.";
			signInErrorHandler(error);
			return;
		} else {
			if (status_reg && statusSF) {
				// console.log("Entro al if de home");
				if (statusAccount == "Active" || statusAccount == "In Registration Process") {
					// console.log({ statusAccount });
					let dni = $currentSession.attributes["custom:dni"].split("-")[1];
					//console.log({ dni });
					let result = await getUserInfo(rol, username, dni);
					// console.log("Resultadom del getUserInfo: ",{ result });
					if (result) {
						// console.log("Home");
						replace("/Home");
					} else {
						// console.log("Error");
						window.location.href = "#/LoginError";
					}
				}
			} else if (!status_reg && !statusSF && statusAccount == "In Registration Process") {
				// console.log({ statusAccount });
				replace("/PersonalInformation/" + rol);
			}
			// if (
			// 	(!status_reg && statusSF) ||
			// 	(status_reg && !statusSF && rol == "Patient" && statusAccount == "Pending Verification")
			// ) {
			if (
				status_reg && statusSF && rol == "Patient" && statusAccount == "Pending Verification"
			) {
				//const statusAcc = await validateEmailStatusAccount(dni_cognito, username, rol);
				// console.log("PendingValidation");
				$user = $currentSession;
				$user.statusAccount = statusAccount;
				//IdAccount = statusAcc.Id;
				//replace("/Patient/PendingValidation/" + IdAccount);
				replace("/Patient/PendingValidation");

			}

			// console.log("FIN");
			// console.log({ status_reg });
			// console.log({ statusSF });
			// console.log({ statusAccount });
		}
	}

	async function signIn() {
		try {

			let errorsignIn = false;
			//console.log("ingreso a signIn");
			window.localStorage.clear();
			localStorage.setItem("language", JSON.stringify(langSelect));
			handleRememberMe();

			const validationResult = await login_schema.validate({ username, password }, { abortEarly: false });
			//console.log({ validationResult });
			log = true;

			const response = await Auth.signIn(username.trim(), password.trim());
			//VALIDA QUE ESTE EN COGNITO
			if (Object.keys(response).length !== 0) {
				status_reg = response.attributes["custom:StatusReg"] === "true";
				statusSF = status_reg;
				statusAccount = response.attributes["custom:StatusAccountSF"];
				dni_cognito = response.attributes["custom:dni"];
				rol = response.attributes["profile"];

				if (rol == "Profesional_de_Salud") {
					rol = "Professional";
				} else {
					if (rol == "Paciente") {
						rol = "Patient";
					}
				}

				$currentSession = response;
				$user = response;
				// console.log({ response });
				// console.log({ status_reg });

				if (!status_reg) {
					// VALIDA QUE ESTE EN SALESFORCE EL DNI
					statusSF = await validateDocument(dni_cognito);
					// console.log("StatusSF",{ statusSF });

					// VALIDA QUE ESTE EN SALESFORCE EL DNI + EMAIL
					const statusAcc = await validateEmailStatusAccount(dni_cognito, username, rol);
					// console.log("StatusACC",{ statusAcc });

					if (statusAcc.statusCode == 200) {
						// console.log("llego a upsateuserstats");
						await updateUserStatus(statusAcc.data.exists, statusAcc.data.accountStatus);

						// console.log("paso upsateuserstats");
						status_reg = true;
						statusAccount = statusAcc.data;
						//IdAccount = statusAcc.data.Id;
					} else {
						statusSF = false;
						// errorsignIn = true;
						// console.timeEnd("signIn");
						// return signInErrorHandler(statusAcc.message);
					}
				}

				if (!errorsignIn) {
					// console.log("Llego al signinvalidate");
					await Promise.all([signInValidate(status_reg, statusSF, statusAccount, rol)]);
			
				}
			} else {
			
				signInErrorHandler("Error en cognito");
			}
		} catch (error) {
			// console.log("ingreso a error login_schema");
			console.log(error);
			
			signInErrorHandler(error);
		}
	}

	/**
	 * @description
	 */
	function signInErrorHandler(error) {
	
		//console.log({ error });
		error_modal = true;
		//console.log({ error_modal });
		log = false;
		if (error && error.errors) {
			// Acceder a los errores individuales
			const usernameError = error.errors[0].username;
			const passwordError = error.errors[0].password;
			// Realizar acciones basadas en los errores
			if (usernameError) {
				message_error.username = error.errors[0].username;
				error_modal = false;
				// Manejar el error del nombre de usuario
			}
			if (passwordError) {
				// Manejar el error de la contraseña
				message_error.password = error.errors[0].password;
				error_modal = false;
			}
		} else {
			// Otro manejo de errores si no se encuentra en el formato esperado
			//console.log({ error });
			if (error == "ROL_NOT_EQUAL") {
				error_modal = true;
				message = $_("Login.message_error_rol_not_equal");
				//	console.log("ACa error rolEqual");
			}
			if (message == "" || error.name) {
				message = errorCognito[error.name] || errorCognito["default"];
			}
			if (error.name == "UserNotConfirmedException") {
				code_verify = true;
			}
			if (error.name == "User is blocked.") {
				message = $_("Login.message_error_blocked");
			}
		}

	}

	function removeResources() {
		try {
			localStorage.removeItem("pathologies");
		}
		catch {		
		}
		try {
			localStorage.removeItem("monitoringPlans");
		}
		catch {		
		}
		try {
			localStorage.removeItem("prepaidMedicine");
		}
		catch {		
		}
		try {
			localStorage.removeItem("specialties");
		}
		catch {		
		}
		try {
			localStorage.removeItem("frecuencies");
		}
		catch {		
		}
		try {
			localStorage.removeItem("languages");
		}
		catch {		
		}try {
			localStorage.removeItem("country");
		}
		catch {		
		}
		try {
			localStorage.removeItem("state");
		}
		catch {		
		}
	}
	/**
	 * @description
	 */
	function changeLanguage(lang) {
		$language = lang;
		removeResources();
		window.location.reload();
	}

	/**
	 * @description
	 */
	async function resendConfirmationCode() {
		try {
			await Auth.resendSignUp(username);
			replace("/EmailCodeVerify/" + username);
		} catch (err) {}
	}

	/**
	 * @description
	 */
	function showPassword() {
		let tipo = document.getElementById("password");
		let img = document.getElementById("passworImag");
		if (tipo.type == "password") {
			img.src = "/img/show-password1.png";
			tipo.type = "text";
		} else {
			img.src = "/img/show-password.png";
			tipo.type = "password";
		}
	}
	onMount(() => {
		rememberUser();
		closeSession();
		//$currentSession = {};
		// const interval = setInterval(() => {
		// 	if (progress < 100) {
		// 		progress += 5; // Ajusta el incremento del progreso según desees
		// 	} else {
		// 		clearInterval(interval);
		// 		isLoading = false;
		// 	}
		// }, 500); // Ajusta el tiempo del intervalo según desees
	});
</script>

<!-- ---------------------------------------------------------------------------------------------------------- -->
<!-- ---------------------------------------------- HTML- ----------------------------------------------------- -->
<!-- ---------------------------------------------------------------------------------------------------------- -->
<svelte:head>
	<title>RDCOM - LOGIN</title>
	<link rel="icon" href="/img/new_favicon.png">
</svelte:head>
<div class="body_log">
	<!-- svelte-ignore a11y-missing-attribute -->
	<img class="imgBack" src="/img/backrount.jpg" />

	<div class="language-selector">
		<button>
			<img src="./img/globo.png" alt="Icono del mundo" />
		</button>
		<div class="d-flex" style="flex-direction: row-reverse;align-items: center;">
			<select
				class="form-control form-control-sm order-filter"
				id="language-sel"
				bind:value={langSelect}
				on:change={() => {
					changeLanguage(langSelect);
				}}>
				<option value="es">{$_("Login.spanish")}</option>
				<option value="en">{$_("Login.englis")}</option>
			</select>
			<!-- <svg class="" width="24" height="24" viewBox="0 0 24 24" title="Desc" style="position:absolut">
				<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
			</svg> -->
		</div>
	</div>

	<LoadingModal visible={log} />

	<div class="logo">
		<!-- <img src="/img/Frame 106.png" alt="logo" /> -->
		<img src="/img/new_logo.png" alt="logo" width="170" height="138" style="margin: 20px;" />
	</div>

	<div class="login-conteiner">
		<!-- <h1 class="title-welcome">Iniciar sesión</h1> -->

		<div class="login-info-conteiner">
			<form class="login-form" on:submit|preventDefault={signIn}>
				<div class="conteiner-username" style="d-flex justify-content-center">
					<p class="email">{$_("Login.email")}</p>
					<input
						class="input-log"
						id="email"
						type="email"
						placeholder="name@domain.com"
						bind:value={username} />
					{#if message_error.username && username == ""}
						<div class="conteiner-error">
							<small class="message_error">{$_("Login.message_error_username")}</small>
						</div>
					{/if}
				</div>
				<div class="conteiner-password">
					<!-- svelte-ignore a11y-label-has-associated-control {message_error.username} -->
					<label class="contraseña">{$_("Login.password")}</label>
					<div class="c-password">
						<input
							class="input-log"
							id="password"
							type="password"
							placeholder={$_("Login.placeholder_pass")}
							minlength={MIN_PASSWORD_LENGTH}
							bind:value={password} />
						<!-- svelte-ignore a11y-missing-attribute -->
						<img
							id="passworImag"
							src="/img/show-password.png"
							class="showPassword"
							on:click={showPassword} />
					</div>

					{#if message_error.password && password == ""}
						<div class="conteiner-error">
							<small class="message_error">{$_("Login.message_error_password")}</small>
						</div>
					{/if}
					<label class="cbox1"
						><input
							type="checkbox"
							value="first_checkbox"
							id="rememberCheckbox"
							bind:checked={rememberMe}
							on:change={handleRememberMe} />
						<small>{$_("Login.keep_logged")}</small>
					</label>
					<div class="conteiner-restar" />
					<br />
					<button
						class="btnLogin"
						on:click={() => localStorage.setItem("language", JSON.stringify(langSelect))}
						>{$_("Login.button_login")}</button>
					<br />
				</div>

				<div />
			</form>
			<div class="conteiner-register">
				<br />
			</div>

			<p class="span_register cursor-pointer" on:click={() => replace("/ResetPassword")}>
				{$_("Login.forget")}
			</p>
			<button class="btnRegister" on:click={() => replace("/Register")}>{$_("Login.button_sign")}</button>
		</div>
		<div class="conteiner-img">
			<h1 class="title-welcome">{$_("Login.title")}</h1>
			<img class="image-conteiner" src="/img/Frame 92.png" alt="login" />
		</div>
	</div>
</div>
{#if error_modal}
<LayoutModal isOpenModal={error_modal} title="Error" tam="30">
	<div class="p-2">
		<p class="m-0" style="padding: 5%; text-align: center; ">
			{message}
		</p>
		<div class=" text-center">
			<button
				class="btnBlue"
				on:click={() => {
					error_modal = false;
				}}>Aceptar</button>
		</div>
	</div>
</LayoutModal>
{/if}
<!-- <Modal isOpenModal={code_verify}>
<p>{message}</p>
<button class="ok_modal" on:click={resendConfirmationCode}>Reenviar</button>
</Modal> -->

<!-- <Modal isOpenModal={log}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">{$_("Login.loading")}</p>
		</div>
	</div>
</Modal> -->

<!-- Modal code_verify -->
 {#if code_verify}
<LayoutModal
	isOpenModal={code_verify}
	on:closeModal={() => {
		code_verify = false;
	}}>
	<div class="content-message">
		<div class="row">
			<img class="image-conteiner" src="/img/Frame 92.png" alt="login" />
			<h3 class="textMessage">{message}</h3>
		</div>
		<button class="btnReenviar" on:click={resendConfirmationCode}>{$_("Login.button_Resend")}</button>
	</div>
</LayoutModal>
{/if}
<!-- Modal Error -->
<!-- <LayoutModal
	isOpenModal={modal_error}
	on:closeModal={() => {
		modal_error = false;
	}}>
	<div class="messageLog">
		<div>
			<h3 class="textMessage">{message_error}</h3>
			<br />
			<img class="image-conteiner" src="/img/Frame 92.png" alt="login" />
			<br />
		</div>
		<button
			class="btnVolver"
			on:click={() => {
				modal_error = false;
			}}>OK</button>
	</div>
</LayoutModal> -->

<!-- error modal -->
<Modals>
	<div slot="backdrop" class="backdrop" transition:fade on:click={closeModal} />
</Modals>

<!-- ---------------------------------------------------------------------------------------------------------- -->
<!-- ---------------------------------------------- CSS ------------------------------------------------------- -->

<!-- ---------------------------------------------------------------------------------------------------------- -->
<style>
	.content-message {
		text-align: center;
		margin: 5%;
	}

	.imgBack {
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 100%;
	}
	.login-conteiner {
		width: max-content;
		height: max-content;
		position: relative;
		border-radius: 15px;
		padding: 17px;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}
	/*Modifique estilos*/
	.btnBlue {
		width: auto;
	}
	.cbox1 {
		padding-top: 2%;
	}
	.logo {
		width: max-content;
		height: max-content;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
	}

	.showPassword {
		cursor: pointer;
		width: 30px;
		right: 0;
		position: absolute;
		transform: translateY(25%);
	}
	.c-password {
		position: relative;
	}
	.language-selector {
		display: inline-block;
		border: 2px solid #ccc;
		border-radius: 20px;
		padding: 5px;
		position: absolute;
		margin: 1%;
	}

	.language-selector select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent;
		border: none;
		font-size: 14px;
		padding: 5px;
		padding-left: 25px;
		width: 150px;
		cursor: pointer;
		text-align: center;
	}
	.language-selector select:focus {
		outline: none;
	}

	.language-selector button:focus {
		outline: none;
	}

	.language-selector button {
		position: absolute;
		top: 50%;
		left: 5px;
		transform: translateY(-50%);
		background: none;
		border: none;
		cursor: pointer;
	}

	.language-selector button:focus {
		outline: none;
	}

	.language-selector button img {
		width: 40px;
		height: 40px;
	}

	.showPassword {
		cursor: pointer;
		width: 30px;
		right: 0;
		transform: translateY(25%);
	}

	.row {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}
	.login-info-conteiner {
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 0.5rem;
		overflow: hidden;
	}
	.login-form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.conteiner-register {
		text-align: start;
		padding-left: 30px;
		width: 100%;
		align-items: flex-start;
		overflow: hidden;
	}

	.conteiner-error {
		text-align: start;
		width: 100%;
		align-items: flex-start;
		overflow: hidden;
	}
	.conteiner-username {
		width: 90%;
	}
	.conteiner-password {
		width: 90%;
		position: relative;
	}
	.conteiner-restar {
		text-align-last: right;
		width: 100%;
	}
	.conteiner-img {
		text-align: center;
		padding-top: 3rem;
	}

	.title-welcome {
		color: #112c4a;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		margin: 10%;
		font-size: 25px;
	}

	.email {
		width: 90%;
		color: #112c4a;
		font-weight: 600;
		font-family: "Roboto", sans-serif;
	}
	.textMessage {
		color: #112c4a;
		font-family: "Roboto", sans-serif;
		font-size: 20px;
	}
	.contraseña {
		padding-top: 5%;
		width: 90%;
		color: #112c4a;
		font-weight: 600;
		font-family: "Roboto", sans-serif;
	}

	.span_register {
		padding-left: 8px;
		font-size: small;
		align-items: flex-end;
	}
	.input-log {
		width: 100%;
		height: 3.125rem;
		padding-left: 9px;
		border-radius: 5px;
		/* letter-spacing: 1px; */
	}
	.btnLogin {
		width: 100%;
		height: 3.2rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		border-radius: 10px;
		color: #f7fafc;
		background-color: #112c4a;
	}
	.btnReenviar {
		width: 30%;
		height: 3.2rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		border-radius: 20px;
		color: #f7fafc;
		background-color: #112c4a;
	}
	.btnRegister {
		margin: 10px;
		width: 90%;
		height: 3.2rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		color: #112c4a;
		border-radius: 10px;
		background-color: #fff;
	}
	.message_error {
		color: red;
	}

	/* Estilos para la versión de escritorio (Web) */
	@media screen and (min-width: 1200px) {
		/* Agrega aquí los estilos para la versión de escritorio */
	}

	/* Estilos para tablets */
	@media screen and (max-width: 1024px) {
		/* Agrega aquí los estilos para tablets en orientación horizontal */
		.conteiner-img {
			display: none;
		}
		.btnBlue {
		display: flex;
		justify-content: space-evenly;
		margin-left: auto;
		width: auto;
	}
		.imgBack {
			opacity: 0.5;
			position: fixed;
			height: 100%;
		}

		.login-conteiner {
			width: 90%;
			position: relative;
			padding: 50px;
			background: #fff;
			display: flex;
			border-radius: 15px;
			justify-content: space-between;
			overflow: hidden;
			box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
		}
		.login-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
	}

	/* Estilos para dispositivos móviles */
	@media screen and (max-width: 480px) {
		/* Agrega aquí los estilos para dispositivos móviles */
		.conteiner-img {
			display: none;
			width: auto;
		}

		.imgBack {
			opacity: 0.5;
			position: fixed;
			height: 100%;
		}

		.login-conteiner {
			width: 90%;
			position: relative;
			padding: 50px;
			background: #fff;
			display: flex;
			border-radius: 15px;
			justify-content: space-between;
			overflow: hidden;
			box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
		}

		.login-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
	}

	/* Estilos adicionales para tablets en orientación vertical */
	@media screen and (max-width: 768px) {
		.conteiner-img {
			display: none;
		}
		.imgBack {
			opacity: 0.5;
			position: fixed;
			height: 100%;
		}

		.login-conteiner {
			width: 90%;
			position: relative;
			padding: 50px;
			background: #fff;
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			border-radius: 15px;
			box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
		}

		.login-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
		/* Agrega aquí los estilos para tablets en orientación vertical */
	}
</style>
