<!-- 
    IMPORTANT - TO USE ON PATIENTS PLATFORM
 -->
<script>
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../components/Loader.svelte";
	import AddDetailsMedications from "../../components/addDetailsMedications.svelte";
	import { generic_medication_delete } from "../../helpers/apiBackend";

	import { _ } from "svelte-i18n";
	import { formatDate } from "../../helpers/helpers";
	import { language } from "../../helpers/storage/stores";
	//import { loadingBar } from "aws-amplify";

	const dispatch = createEventDispatcher();
	export let theRecord = undefined;
	//console.log({ theRecord });
	let collapsed = true;
	let status_green = 0;
	let status_red = 0;
	let status_blue = 0;
	let status_gray = 0;
	let status_record = true;
	let loadingDelete = false;
	let modaladd = false;
	let modaldelete = false;
	let name = "";
	let patientId;
	let planId;
	let actividadCabeceraId;
	let structMed = false;

	console.log({ theRecord });

	const deleteHeadM = async (id) => {
		loadingDelete = true;
		console.log("Llamar a Borrar : ", id);
		await generic_medication_delete(id).then((result) => {
			if (result.statusCode == 200) {
				window.location.reload();
			} else {
				loadingDelete = false;
				console.log("error");
			}
		});
	};

	onMount(async () => {
		console.log({ theRecord });
		name = theRecord.name;
		status_record = theRecord.completed == null ? false : theRecord.completed;
		status_green = theRecord.requestedDetailsOnTime ? theRecord.requestedDetailsOnTime : 0;
		status_red = theRecord.requestedDetailsExpired ? theRecord.requestedDetailsExpired : 0;
		status_blue = theRecord.completedDetails ? theRecord.completedDetails : 0;
		status_gray = theRecord.requestedDetailsInactive ? theRecord.requestedDetailsInactive : 0;
		if (status_blue == theRecord.details.length - theRecord.requestedDetailsInactive) {
			status_record = true;
		}
	});
</script>

<!-- VISTA DESDE EL PACIENTE Citas/Entrevistas-->

<section>
	{#if theRecord.active}
		<div class="myCustom-box">
			<div
				class="{collapsed
					? 'myCustom-header-closed'
					: 'myCustom-header-open'} d-flex justify-content-between align-items-center p-2">
				<div class="d-flex myCustom-name">
					{#if structMed}
						{#if theRecord.hasOwnProperty("Actividad_Detalles__r") && theRecord.Actividad_Detalles__r.totalSize > 0}
							<i class="gg-remove" style="margin-right: 2%; color:lightslategrey" />
						{:else}
							<i
								class="gg-remove"
								on:click={() => {
									modaldelete = true;
								}}
								style="margin-right: 2%;" />
						{/if}
					{/if}
					<p class="m-0 variableA">{name}</p>
					{#if theRecord.Modalidad__c}
						<p class="m-0 modalidad">({theRecord.Modalidad__c ? theRecord.Modalidad__c : ""})</p>
					{/if}
					&nbsp;
					<!-- {#if theRecord.Modalidad__c}
						<small class="">({theRecord.Modalidad__c})</small>
					{/if} -->
				</div>

				<div>
					{#if theRecord.hasOwnProperty("frecuency")}
						<p class="variableA m-0">{theRecord.frecuency}</p>
					{:else}
						<p class="variableA m-0">{$_("List_Records_prof.text_frequency")}</p>
					{/if}
				</div>

				<div class="cabeceraID">
					{#if theRecord.details.length > 0 && theRecord.active && status_record}
						<small class="headDetailC py-0">{$_("List_Records_prof.text_completed")}</small>
					{:else if !theRecord.active}
						<small class="headDetailI py-0">{$_("List_Records_prof.text_inactive")}</small>
					{/if}

					{#if theRecord.details.length > 0 && theRecord.active && !status_record}
						{#if status_green > 0}
							<span class="badge_green">{status_green}</span>
						{/if}
						{#if status_red > 0}
							<span class="badge">{status_red}</span>
						{/if}
						{#if status_blue > 0}
							<span class="badge_blue">{status_blue}</span>
						{/if}
						{#if status_gray > 0}
							<span class="badge_gray">{status_gray}</span>
						{/if}
					{/if}
				</div>

				<div
					on:click={() => {
						collapsed = !collapsed;
					}}
					class="d-flex myCustom-collapse"
					data-toggle="collapse"
					data-target="#{theRecord.Id}"
					aria-expanded="false"
					aria-controls={theRecord.Id}>
					<!-- <p class="m-0 mx-2">Mas</p> -->
					<i class="gg-arrow-down-o" />
				</div>
			</div>

			<!-- Detail -->
			<div class="collapse" id={theRecord.Id}>
				<table class="myCustom-table">
					<thead>
						<tr>
							<!-- {#if !structMed} -->
							<th>{$_("List_Records.text_th_createdby")}</th>
							<th>{$_("List_Records.text_th_suggested")}</th>
							<th>{$_("List_Records.text_th_assigned")}</th>
							<th>{$_("List_Records.text_th_date_planned")}</th>
							<th>{$_("List_Records.text_th_session_date")}</th>
							<th>{$_("List_Records.text_th_requested")}</th>
							<th>{$_("List_Records.text_th_evolved")}</th>
							<!-- {:else}
								<th>{$_("List_Records.text_date")}</th>
								<th>{$_("List_Records.text_doce")}</th>
								<th>{$_("List_Records.text_frequency_info")} </th>
								<th>{$_("List_Records.text_Order")} </th>
								{#if theRecord.active}
									<th
										><button
											on:click={() => {
												modaladd = true;
											}}
											class="btnWhite">{$_("List_Records.button_add")}</button
										></th>
								{/if}
							{/if} -->
						</tr>
					</thead>
					<tbody class="py-2">
						{#if theRecord.hasOwnProperty("details") && theRecord.details}
							{#each theRecord["details"] as myDetail}
								{#if myDetail["requested"]}
									<tr class="bg-white py-2" style="height:35px;">
										<!-- createdByName.Name -->
										<td>
											{#if myDetail.hasOwnProperty("createdByName")}
												<span>{myDetail["createdByName"]}</span>
											{:else}
												<span class="text-muted">{$_("List_Records.text_Undefined")}</span>
											{/if}
										</td>

										<!-- Profesional Sugerido -->
										<td>
											{#if myDetail.hasOwnProperty("assignedProfessionalSuggestion") && myDetail["assignedProfessionalSuggestion"] != "" && myDetail["assignedProfessionalSuggestion"] != null}
												<span>{myDetail["assignedProfessionalSuggestion"]}</span>
											{:else}
												<span class="text-muted">{$_("List_Records.text_Undefined")}</span>
											{/if}
										</td>

										<!-- Profesional Asignado -->
										<td>
											{#if myDetail.hasOwnProperty("assignedProfessional") && myDetail["assignedProfessional"] != "" && myDetail["assignedProfessional"] != null}
												<span>{myDetail["assignedProfessional"]}</span>
											{:else}
												<span class="text-muted">{$_("List_Records.text_Undefined")}</span>
											{/if}
										</td>

										<!-- dateSuggested -->
										{#if myDetail.hasOwnProperty("dateSuggested") && myDetail["dateSuggested"] != null}
											{#if !myDetail.requested}
												<td
													><small class="dateSuggested_requeted py-0"
														>{formatDate(myDetail["dateSuggested"], $language)}</small
													></td>
											{/if}
											{#if myDetail.completed}
												<td
													><small class="dateSuggested py-0"
														>{formatDate(myDetail["dateSuggested"], $language)}</small
													></td>
											{:else if myDetail.requested}
												<td
													><small class="dateSuggested_{myDetail.expired} py-0"
														>{formatDate(myDetail["dateSuggested"], $language)}</small
													></td>
											{/if}
										{:else}
											<td
												><span class="text-muted"
													>{$_("List_Records_prof.text_Undefined")}
												</span></td>
										{/if}

										<!-- dateCompleted -->
										{#if myDetail.hasOwnProperty("dateCompleted") && myDetail["dateCompleted"] !== null}
											<td>{formatDate(myDetail["dateCompleted"], $language)}</td>
										{:else}
											<td><span class="text-muted">{$_("List_Records.text_Undefined")}</span></td>
										{/if}

										<!-- requested -->
										<td>
											{#if myDetail["requested"]}
												<!-- <i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

											{:else}
												<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

											{/if}
										</td>
										<!-- completed -->
										<td>
											{#if myDetail["completed"]}
												<!-- <i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

											{:else}
												<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

											{/if}
										</td>

										<!-- Button Detalles -->
										<td>
											<button
												on:click={() => {
													dispatch("editDetail", { myDetail });
												}}
												class="btn btn-info py-1 myCustom-button"
												>{$_("List_Records.button_detail")}</button>
										</td>
									</tr>
								{/if}
							{/each}
						{:else}
							<small class="text-muted">{$_("List_Records.text_not_details")}...</small>{/if}
					</tbody>
				</table>
			</div>
		</div>
	{/if}
</section>

<LayoutModal
	tam="50"
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<AddDetailsMedications
		nameMed={name}
		{patientId}
		{planId}
		{actividadCabeceraId}
		on:cancel={() => {
			console.log("modaladd");
			modaladd = false;
		}} />
</LayoutModal>

<LayoutModal
	isOpenModal={modaldelete}
	on:closeModal={() => {
		modaldelete = false;
	}}>
	{#if !loadingDelete}
		<div class="conteiner">
			<p>{$_("List_Records.text_message_medication")}</p>
			<div class="Block">
				<div class="text-center">
					<button
						class="btnBlue"
						on:click={() => {
							deleteHeadM(theRecord.Id);
						}}>{$_("List_Records.button_accept")}</button>
					<button
						on:click={() => {
							modaldelete = false;
						}}
						class="btnWhite">{$_("List_Records.button_cancel")}</button>
				</div>
			</div>
		</div>
	{:else}
		<div class="text-center py-3">
			<Loader text={false} />
			<p class="text-muted">{$_("List_Records.button_removing")}...</p>
		</div>
	{/if}
</LayoutModal>

<style>
	:root {
		--color1: #112c4a;
	}

	/* CONTAINER CSS */
	.myCustom-box {
		border: solid 1px var(--color1);
		border-radius: 5px;
	}

	/* HEADER CSS */
	.myCustom-header-closed {
		color: var(--color1);
		background-color: #fbfbfb;
	}

	.myCustom-header-open {
		background-color: #6a7e93;
		color: #ffffff;
	}

	.myCustom-name {
		min-width: 30vw;
	}

	/* .myCustom-toggle {
		transform: scale(1.25);
	} */

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
		/* transform: scale(1.25);*/
	}

	.myCustom-collapse:hover {
		cursor: pointer;
	}

	.circle {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: green;
	}

	/* DETAIL CSS */
	.collapse {
		padding: 5px;
		/* background-color: #6a91ab; */
	}
	/* .modalidad {
		padding-left: 30%;
	} */

	.myCustom-table {
		width: 100%;
		color: var(--color1);
	}

	.myCustom-table tbody tr {
		width: 100%;
	}

	.myCustom-table thead tr th {
		font-size: 15px;
		font-weight: 700;
	}

	.variableA {
		width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.myCustom-button {
		background-color: var(--color1);
		border: none;
	}
	.cabeceraID {
		width: 8%;
		display: flex;
		justify-content: space-between;
	}
	.headDetailC {
		background-color: cornflowerblue;
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.dateSuggested_false {
		background-color: rgba(28, 187, 7, 0.645);
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.dateSuggested_true {
		background-color: rgba(255, 0, 0, 0.645);
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.dateSuggested {
		background-color: cornflowerblue;
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.dateSuggested_requeted {
		border: none;
		color: rgb(8, 8, 8);
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.headDetailI {
		background-color: rgb(132, 132, 132);
		border: none;
		color: azure;
		border-radius: 10px;
		padding: 25%;
		text-align: center;
	}
	.badge {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(255, 0, 0, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_green {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(28, 187, 7, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_blue {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(16, 48, 234, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_gray {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(146, 146, 146, 0.8);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
</style>
