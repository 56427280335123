<script>
	import LayoutPatient from "../../components/Layouts/Layout_Patient.svelte";
	import LayoutNav from "../../components/Layouts/Layout_Nav.svelte";
	import { currentSession } from "../../helpers/storage/sessions";
	import { alta } from "../../helpers/storage/stores";
	import { onMount } from "svelte";

	// onMount
	let myRol;
	let render = false;
	let dinamicOptions = true;
	let params = "";
	let myOrigin = "";
	onMount(() => {
		if ($currentSession.hasOwnProperty("attributes")) {
			if ($currentSession["attributes"].hasOwnProperty("profile")) {
				myRol = $currentSession["attributes"]["profile"];
				if (document.location.href.indexOf("?") > -1) {
					params = document.location.href.split("?")[1].split("=")[1];
					if (params == "assignPlan") {
						myOrigin = params;
					}
					if (params == "editRecord") {
						dinamicOptions = true;
					}
				}
				render = true;
			}
		}
	});
</script>

{#if render}
	<section>
		<LayoutNav secure={true} rol="general">
			{#if myRol == "Profesional_de_Salud"}
				{#if myOrigin == "assignPlan"}
					<div class="p-5 text-center border shadow-sm rounded bg-white">
						<p class="m-0 text-muted">{$alta.Cuenta__r.Name} es un nuevo paciente</p>
						<div class="d-flex justify-content-center my-2">
							<p
								class="texlink mx-2 cursor-pointer"
								on:click={() => {
									window.location.href = "#/Doctor";
								}}>
								Mis pacientes
							</p>
							<p
								class="texlink mx-2 cursor-pointer"
								on:click={() => {
									window.location.href = "#/Altas";
								}}>
								Mis Altas
							</p>
							<!-- <a class="mx-2" href="#/Doctor">Mis pacientes</a>
                        <a class="mx-2" href="#/Altas">Mis altas</a> -->
						</div>
					</div>
				{:else}
					<!-- <LayoutPatient> -->
					<div class="p-5 text-center border shadow-sm rounded bg-white container">
						<p class="m-0 text-muted">{$alta.Cuenta__r.Name} es un nuevo paciente</p>
						<div class="d-flex justify-content-center my-2">
							<a class="mx-2" href="#/Doctor">Mis pacientes</a>
							<a class="mx-2" href="#/Altas">Mis altas</a>
							{#if dinamicOptions}
								{#if params == "editRecord"}
									<a class="mx-2" href="#/Plan">Plan</a>
								{/if}
							{/if}
						</div>
					</div>
					<!-- </LayoutPatient> -->
				{/if}
			{:else if myRol == "Paciente"}
				<span>En desarrollo...</span>
			{/if}
		</LayoutNav>
	</section>
{:else}
	<span>Asignando plan</span>
{/if}
