<script>
	import { _ } from "svelte-i18n";
	export let data;
	console.log({ data });
</script>

<main>
	<div class="container py-2 border rounded shadow-sm my-3 bg-white">
		<div class="title">
			<h3 class="title-detail">{data.nameMed}</h3>
		</div>
		<div class="conteiner-d">
			<div class="fecha">
				<label for="fecha" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_date")}:</strong>
				</label>
				<input type="text" id="fecha" readonly value={data.fecha} class="base_input" />
			</div>
			<div class="nombreComercial">
				<label for="nombreComercial" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_name_ecommer")}</strong>
				</label>
				<input type="text" id="nombreComercial" readonly value={data.nombreComercial} class="base_input" />
			</div>
			<div class="presentacion">
				<label for="presentacion" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_presentation")}:</strong>
				</label>
				<input type="text" id="presentacion" readonly value={data.presentacion} class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="diagnostico">
				<label for="diagnostico" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_diagnostis")}</strong>
				</label>
				<input type="text" id="diagnostico" readonly value={data.diagnostico} class="base_input" />
			</div>
			<div class="cantidadCajas">
				<label for="cantidadCajas" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_box")}:</strong>
				</label>
				<input type="text" id="cantidadCajas" readonly value={data.cantidadCajas} class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="dosis">
				<label for="dosis" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_dosis")}:</strong>
				</label>
				<input type="text" id="dosis" readonly value={data.dosis} class="base_input" />
			</div>
			<div class="frecuencia">
				<label for="frecuencia" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_Frecuency")}:</strong>
				</label>
				<input type="text" id="frecuencia" readonly value={data.frecuencia} class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="laboratorio">
				<label for="laboratorio" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_lab")}:</strong>
				</label>
				<input type="text" id="laboratorio" readonly value={data.laboratorio} class="base_input" />
			</div>
			<div class="indicacionToma">
				<label for="indicacionToma" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_Indication")}:</strong>
				</label>
				<input type="text" id="indicacionToma" readonly value={data.indicacionToma} class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="prescritoPor">
				<label for="prescritoPor" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_create_by")}:</strong>
				</label>
				<input type="text" id="prescritoPor" readonly value={data.prescritoPor} class="base_input" />
			</div>
			<div class="justificacion">
				<label for="justificacion" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_justify")}:</strong>
				</label>
				<input type="text" id="justificacion" readonly value={data.justificacion} class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="tratamientoProlongado">
				<label for="tratamientoProlongado" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_prolong")}:</strong>
				</label>
				<input
					type="text"
					id="tratamientoProlongado"
					readonly
					value={data.tratamientoProlongado
						? $_("DiagnosisByProf.button_yes")
						: $_("DiagnosisByProf.button_not")}
					class="base_input" />
			</div>
			<div class="duplicado">
				<label for="duplicado" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>{$_("AddDetailsMedication.text_duplicate")}:</strong>
				</label>
				<input
					type="text"
					id="duplicado"
					readonly
					value={data.duplicado ? $_("DiagnosisByProf.button_yes") : $_("DiagnosisByProf.button_not")}
					class="base_input" />
			</div>
		</div>
	</div>
	{#if data.rolProf}
		<div class="alert">
			<p>{$_("AddDetailsMedication.text_message_prof_alert")}</p>
		</div>
	{/if}
</main>

<style>
	/* Estilos CSS aquí */
	.title-detail {
		text-align: center;
		color: #112c4a;
	}
	.base_input {
		width: 90%;
		padding: 5px;
		border: none;
		border-bottom: 1px solid #112c4a;
		color: gray;
	}
	.alert {
		background-color: #a6c8de; /* Color de fondo rojo */
		color: #112c4a; /* Texto en color blanco */
		padding: 10px; /* Espacio interno */
		border-radius: 5px; /* Bordes redondeados */
		margin-top: 10px; /* Margen superior para separar el mensaje de otros elementos */
		text-align: center; /* Alineación centrada del texto */
	}

	.alert p {
		margin: 0; /* Elimina el margen predeterminado del párrafo */
	}
	.diagnostico {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.fecha {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.nombreComercial {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.presentacion {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.cantidadCajas {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.dosis {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.frecuencia {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.laboratorio {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.prescritoPor {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.indicacionToma {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.justificacion {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.duplicado,
	.tratamientoProlongado {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
</style>
