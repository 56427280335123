<script>
	import { createEventDispatcher } from "svelte";
	//import FileAttach from "../components/FileAttach.svelte";
	import Loader from "../components/Loader.svelte";
	//import LayoutModal from "../components/Layouts/Layout_Modal.svelte";
	import { onMount } from "svelte";
	import {
		generic_medication_detail_add,
		generic_medication_detail_update,
		utils_files_add,
		professional_medications_pdf_add,
	} from "../helpers/apiBackend";
	import { language, user } from "../helpers/storage/stores.js";
	import { _ } from "svelte-i18n";
	import ConfirmationMediation from "../components/ConfirmationMediation.svelte";

	export let nameMed;
	export let patientId;
	export let planId;
	export let actividadDetalleId;
	export let actividadCabeceraId;
	export let detailEdit;

	let loderlod = false;
	let modalError = false;
	let idvalDosis = false;
	let idvalpresentacion = false;
	let idvalIndicaciones = false;
	let idvaldiagnostico = false;
	let idvalfecha = false;
	let idvalcajas = false;
	let profName = undefined;
	let modalViewMedication = false;
	let file_type = "Documento";
	let idTypeAttachment = "";
	let fileAttPatologia = {};
	let body;
	let cpsr = {};
	let editar = false;
	const dispatch = createEventDispatcher();

	const closeModal = () => {
		console.log("closeModal");
		dispatch("cancel");
	};

	const btnAddMedic = async () => {
		console.log({ detailEdit });
		if (detailEdit && detailEdit.hasOwnProperty("requested")) {
			editar = true;
			cpsr.actividadDetalleId = detailEdit.Id;
		}
		cpsr.patientId = patientId;
		cpsr.planId = planId;
		cpsr.prescritoPor = profName;
		delete cpsr.rolProf;
		delete cpsr.nameMed;
		let error = false;
		body = { cpsr };
		if (!editar) {
			if (cpsr.dosis == "") {
				idvalDosis = true;
				error = true;
				console.log("Error idDosis_", cpsr.dosis);
			}
			if (cpsr.presentacion == "") {
				console.log("Error idFrecuencia");
				idvalpresentacion = true;
				error = true;
			}
			if (cpsr.diagnostico == "") {
				console.log("Error idvaldiagnostico");
				idvaldiagnostico = true;
				error = true;
			}
			if (cpsr.indicacionToma == "") {
				console.log("Error idIndicaciones");
				idvalIndicaciones = true;
				error = true;
			}
			if (cpsr.fecha == "") {
				console.log("Error idIndicaciones");
				idvalfecha = true;
				error = true;
			} else {
				cpsr.fecha = cpsr.fecha.split("-").reverse().join("-").replace(/-/g, "/");
			}
			if (cpsr.cantidadCajas == "") {
				console.log("Error idIndicaciones");
				idvalcajas = true;
				error = true;
			}
			if (!error) {
				// console.log("Sin errores", body);
				cpsr.actividadCabeceraId = actividadCabeceraId;
				cpsr.creadoPor = $user.Id;
				//modalViewMedication = true;
				await generic_medication_detail_add(body).then(async (res) => {
					if (res.statusCode == 201) {
						console.log("Se grabo el medicamento");
						idTypeAttachment = res.data.id;
						if ($user.hasOwnProperty("Especialidades__r")) {
							await professional_medications_pdf_add(idTypeAttachment, $user.Id, $language).then(
								(data) => {
									console.log({ data });
									// se comenta recarga ventana para que avance con carga utils files add
									window.location.reload();
								}
							);
						}
						if (fileAttPatologia.hasOwnProperty("detail")) {
							let BAR = false;
							let filePatologia = fileAttPatologia.detail.fileData;
							filePatologia.forEach(async (element) => {
								await utils_files_add(element, idTypeAttachment, file_type).then(async (result) => {
									console.log({ result });
									BAR = true;
								});
							});
						}
						// window.location.reload();
					} else {
						loderlod = false;
						modalError = true;
						console.log({ res });
					}
				});
			} else {
				loderlod = false;
			}
		} else {
			cpsr.idUser = $user.Id;
			await generic_medication_detail_update(body).then((response) => {
				console.log({ response });
				if (response.statusCode == 200) {
					window.location.reload();
				} else {
					loderlod = false;
					modalError = true;
				}
			});
		}
		setTimeout(() => {
			idvalDosis = false;
			idvalpresentacion = false;
			idvalIndicaciones = false;
			idvaldiagnostico = false;
			idvalcajas = false;
			modalError = false;
			idvalfecha = false;
			loderlod = false;
		}, 6000);
	};

	onMount(async () => {
		try {
			console.log("Prueba");
			console.log({ detailEdit });
			if (detailEdit && detailEdit.hasOwnProperty("requested")) {
				editar = true;
			}

			let fecha = new Date().toISOString().split("T")[0];
			let inputFecha = document.getElementById("startDate");
			let fechaFormateada = fecha;
			cpsr.fecha = fechaFormateada;
			// console.log({ fechaFormateada });
			// console.log(cpsr.fecha);
			inputFecha.value = cpsr.fecha;
			// console.log({ editar });
			cpsr = {
				frecuencia: editar ? detailEdit.frecuency : document.querySelector("#idFrecuencia").value,
				dosis: editar ? detailEdit.dose : document.querySelector("#idDosis").value,
				laboratorio: editar ? detailEdit.lab : document.querySelector("#idLaboratorio").value,
				indicacionToma: editar
					? detailEdit.comsumptionInstructions
					: document.querySelector("#idIndicaciones").value,
				justificacion: editar ? detailEdit.justification : document.querySelector(".idJustificacion").value,
				tratamientoProlongado: editar ? detailEdit.prolongedTreatment : false,
				duplicado: editar ? detailEdit.duplicated : false,
				cantidadCajas: editar ? detailEdit.boxAmmount : document.querySelector("#idCantidad").value,
				prescritoPor: editar ? detailEdit.prescribedBy : document.querySelector("#idPrescrito").value,
				patientId: patientId,
				planId: planId,
				actividadDetalleId: actividadDetalleId,
				nombreComercial: editar ? detailEdit.commertialName : document.querySelector("#idcommercialName").value,
				presentacion: editar ? detailEdit.presentation : document.querySelector("#idPresentacion").value,
				diagnostico: editar ? detailEdit.diagnosis : "",
				fecha: fecha,
			};
			console.log({ cpsr });
			if (profName == undefined && $user.Especialidades__r) {
				profName = $user.FirstName + " " + $user.LastName;
			} else {
				profName = "";
			}
		} catch (error) {
			console.log("Error addMedical", error);
		}
	});
</script>

<!-- HTML init-->
<section>
	{#if !loderlod && !modalViewMedication}
		<div class="content">
			<div class="title_cont">
				<h3 class="Title">{nameMed}</h3>
			</div>

			<div class="conteinert">
				<div class="fecha-cont">
					<label for="fecha" class="Title-detail">{$_("AddDetailsMedication.text_date")}:</label>
					<input
						class="input-date"
						type="date"
						id="startDate"
						pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
						name="trip-start"
						bind:value={cpsr.fecha} />
					{#if idvalfecha}
						<small id="idvalDosis" style="color: red;">{$_("AddDetailsMedication.text_mandatory")}</small>
					{/if}
				</div>

				<div class="commercialName-cont">
					<label for="commercialName" class="Title-detail"
						>{$_("AddDetailsMedication.text_name_ecommer")}:</label>
					<input
						id="idcommercialName"
						bind:value={cpsr.nombreComercial}
						placeholder=""
						type="text"
						class="form-control form-control-sm"
						name="commercialName" />
					<!-- <small id="idvalCantidad" style="color: red;"></small> -->
				</div>

				<div class="Presentacion-cont">
					<label for="Presentacion" class="Title-detail"
						>{$_("AddDetailsMedication.text_presentation")}:</label>
					<input
						id="idPresentacion"
						bind:value={cpsr.presentacion}
						placeholder=""
						type="text"
						class="form-control form-control-sm"
						name="Presentacion" />
					{#if idvalpresentacion}
						<small id="idvalDosis" style="color: red;">{$_("AddDetailsMedication.text_mandatory")}</small>
					{/if}
					<!-- <small id="idvalCantidad" style="color: red;">Este campo es obligatorio</small> -->
				</div>
			</div>
			<div class="diagnostico-cont">
				<label for="diagnostico" class="Title-detail">{$_("AddDetailsMedication.text_diagnostis")}:</label>
				<textarea
					id="iddiagnostico"
					class="iddiagnostico"
					bind:value={cpsr.diagnostico}
					placeholder=""
					name="Text1"
					cols="40"
					rows="2"
					maxlength="255" />
				{#if idvaldiagnostico}
					<small id="idvalDosis" style="color: red;">{$_("AddDetailsMedication.text_mandatory")}</small>
				{/if}
			</div>

			<div class="d-flex">
				<div class="cantidad-cont">
					<label for="Cantidad" class="Title-detail">{$_("AddDetailsMedication.text_box")}:</label>
					<input
						id="idCantidad"
						bind:value={cpsr.cantidadCajas}
						placeholder=""
						type="number"
						min="0"
						class="form-control form-control-sm"
						name="Cantidad" />
					{#if idvalcajas}
						<small id="idvalDosis" style="color: red;">{$_("AddDetailsMedication.text_mandatory")}</small>
					{/if}
					<!-- <small id="idvalCantidad" style="color: red;">Este campo es obligatorio</small> -->
				</div>
				<div class="dosis-cont">
					<label for="Dosis" class="Title-detail">{$_("AddDetailsMedication.text_dosis")}:</label>
					<input
						id="idDosis"
						bind:value={cpsr.dosis}
						placeholder=""
						type="text"
						class="form-control form-control-sm"
						name="Dosis" />
					{#if idvalDosis}
						<small id="idvalDosis" style="color: red;">{$_("AddDetailsMedication.text_mandatory")}</small>
					{/if}
				</div>
				<div class="frecuencia-cont">
					<label for="Frecuencia" class="Title-detail">{$_("AddDetailsMedication.text_Frecuency")}:</label>
					<input
						id="idFrecuencia"
						bind:value={cpsr.frecuencia}
						placeholder=""
						type="text"
						class="form-control form-control-sm"
						name="Frecuencia" />
					<!-- {#if idvalFrecuencia}
          <small id="idvalFrecuencia" style="color: red;">Este campo es obligatorio</small>
          {/if} -->
				</div>
			</div>
		</div>
		<div class="d-flex">
			<div class="laboratorio-cont">
				<label for="Laboratorio" class="Title-detail">{$_("AddDetailsMedication.text_lab")}:</label>
				<input
					id="idLaboratorio"
					bind:value={cpsr.laboratorio}
					placeholder=""
					type="text"
					class="form-control form-control-sm"
					name="Laboratorio" />
				<small id="idvalLaboratorio" style="color: red;" hidden="true"
					>{$_("AddDetailsMedication.text_mandatory")}</small>
			</div>

			<!-- <div class="indicaciones-cont">
				<label for="Indicaciones" class="Title-detail">{$_("AddDetailsMedication.text_Indication")}:</label>
				<input
					id="idIndicaciones"
					bind:value={cpsr.indicacionToma}
					placeholder=""
					type="text"
					class="form-control form-control-sm"
					name="Indicaciones" />
				{#if idvalIndicaciones}
					<small id="idvalIndicaciones" style="color: red;"
						>{$_("AddDetailsMedication.text_mandatory")}</small>
				{/if}
			</div> -->

			<div class="prescripto-cont">
				<label for="medic-name" class="Title-detail">{$_("AddDetailsMedication.text_create_by")}:</label>
				<input
					id="idPrescrito"
					placeholder=""
					type="text"
					class="form-control form-control-sm"
					bind:value={profName} />
			</div>
		</div>

		<label for="Indicaciones" class="Title-detail">{$_("AddDetailsMedication.text_Indication")}:</label>
		<div style="d-flex justify-content-center">
			<textarea
				class="idJustificacion"
				bind:value={cpsr.indicacionToma}
				placeholder=""
				name="Text1"
				cols="40"
				rows="2"
				maxlength="15000" />
			{#if idvalIndicaciones}
				<small id="idvalIndicaciones" style="color: red;">{$_("AddDetailsMedication.text_mandatory")}</small>
			{/if}
		</div>


		<label for="Indicaciones" class="Title-detail">{$_("AddDetailsMedication.text_justify")}:</label>
		<div style="d-flex justify-content-center">
			<textarea
				class="idJustificacion"
				bind:value={cpsr.justificacion}
				placeholder=""
				name="Text1"
				cols="40"
				rows="2"
				maxlength="255" />
		</div>
		<!-- {#if !$user.hasOwnProperty("Especialidades__r")}
			<div class="Block" style="d-flex justify-content-center">
				<label for="medic-name" class="Title-detail">{$_("AddDetailsMedication.text_attachment")}:</label>
				<FileAttach
					{idTypeAttachment}
					{file_type}
					multipleState={true}
					photoState={true}
					on:dataFile={async (e) => {
						console.log(e.detail.body);
						fileAttPatologia = e.detail;
						console.log("Cargar imagen");
					}} />
			</div>
		{:else}
			<div class="text-center">
			</div>
		{/if} -->
		<br />

		<div class="fg form-group mr-2">
			<div>
				<label class="cbox1">
					{$_("AddDetailsMedication.text_prolong")}
					<input class="refProlongado" type="checkbox" bind:checked={cpsr.tratamientoProlongado} /></label>
			</div>
			<div>
				<label class="cbox1"
					>{$_("AddDetailsMedication.text_duplicate")}
					<input class="refDuplicado" type="checkbox" bind:checked={cpsr.duplicado} /></label>
			</div>
		</div>
		<div class="Block">
			<div class="text-center">
				<button
					on:click={() => {
						closeModal();
					}}
					class="btnAcpetar btnWhite">{$_("AddDetailsMedication.button_cancel")}</button>
				<button
					class="py-0 mx-2 btnBlue"
					on:click={() => {
						cpsr.nameMed = nameMed;
						cpsr.prescritoPor = profName;
						if ($user.hasOwnProperty("Especialidades__r")) {
							cpsr.rolProf = true;
						}
						modalViewMedication = true;
						//btnAddMedic((body = { cpsr }));
					}}>{$_("AddDetailsMedication.button_acept")}</button>
			</div>
		</div>
	{:else if loderlod}
		<div class="text-center">
			<Loader text={false} />
			{#if !editar}
				<p class="text-muted">{$_("AddDetailsMedication.text_addMedication")}...</p>
			{:else}
				<p class="text-muted">{$_("AddDetailsMedication.text_edit_medication")}...</p>
			{/if}
		</div>
	{/if}
	{#if modalViewMedication}
		<ConfirmationMediation data={cpsr} />
		<div class="text-center">
			<button
				on:click={() => {
					modalViewMedication = false;
					//replace("/PersonalInformation/Profesional_de_Salud");
				}}
				class="btnWhite">{$_("ProfessionalRecord.button_update")}</button>
			<button
				class="btnBlue"
				on:click={() => {
					//deleteHeadM(theRecord.Id);
					loderlod = true;
					modalViewMedication = false;
					btnAddMedic((body = { cpsr }));
				}}>{$_("ProfessionalRecord.button_confirm")}</button>
		</div>
	{/if}
	{#if modalError}
		<div class="border p-3">
			<div>
				<p class="m-0">{$_("AddDetailsMedication.text_error")}</p>
			</div>
			<div />
		</div>
	{/if}
</section>

<!-- <LayoutModal
	tam="70"
	isOpenModal={modalViewMedication}
	on:closeModal={() => {
		modalViewMedication = false;
	}}>
	{#if modalViewMedication}
		<ConfirmationMediation body={cpsr} />
	{/if}
	<div class="text-center">
		<button
			on:click={() => {
				modalViewMedication = false;
				//replace("/PersonalInformation/Profesional_de_Salud");
			}}
			class="btnWhite">{$_("ProfessionalRecord.button_update")}</button>
		<button
			class="btnBlue"
			on:click={() => {
				//deleteHeadM(theRecord.Id);
				//modalLoadingAnimation = true;
				modalViewMedication = false;
				btnAddMedic((body = { cpsr }));
			}}>{$_("ProfessionalRecord.button_confirm")}</button>
	</div>
</LayoutModal> -->

<!-- HTML end-->
<style>
	.Title {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 20px;
		text-align: center;
	}
	.Title-detail {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
	}
	.input-date {
		border: none;
	}
	.btnBlue {
		height: 2.5rem;
	}
	.cantidad-cont {
		margin: 1%;
	}

	.diagnostico-cont {
		margin: 1%;
	}
	.dosis-cont {
		margin: 1%;
	}
	.frecuencia-cont {
		margin: 1%;
	}
	.laboratorio-cont {
		margin: 1%;
	}
	.indicaciones-cont {
		margin: 1%;
	}
	.prescripto-cont {
		margin: 1%;
	}
	.idJustificacion {
		width: 100%;
	}
	.iddiagnostico {
		width: 100%;
	}
	.title_cont {
		width: 95%;
	}
	/* .custom-class {
		max-height: 200px !important;
		max-width: 200px !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 15px;
	}
	.custom-class p {
		width: 100%;
	} */
</style>
