<script>
	// Svelte
	//import Select from "svelte-select";
	import { replace } from "svelte-spa-router";
	//import Keydown from "svelte-keydown";
	import { onMount } from "svelte";
	import { Modals, closeModal, openModal, modals } from "svelte-modals";
	import Modal from "../../components/Modal.svelte";
	import { fade } from "svelte/transition";
	import { _ } from "svelte-i18n";

	// AWS
	import { Auth } from "aws-amplify";
	import awsconfig from "../../aws-exports";

	// Helpers
	//import data from "../../helpers/prov.json";
	import { getUserInfo } from "../../helpers/helpers.js";
	import { personal_information_schema } from "../schema";
	import { getErrorsMessages } from "../../helpers/helpers";
	import { saveCaregiver } from "../../helpers/apiBackend";
	//utils_resources_get
	import { currentSession } from "../../helpers/storage/sessions";
	//user
	import { patient, doctor, carer, language } from "../../helpers/storage/stores";
	//import { validateOnlyLetters, validateOnlyNumbers, validateOnlyDate } from "../../helpers/validation";
	// import dataLabel from "../../helpers/dataLabel.json";
	// const PersonalData = dataLabel.PersonalData;

	// Components
	import Loader from "../../components/Loader.svelte";
	//import FileAttach from "../../components/FileAttach.svelte";
	//import MultiSelect from "../../components/MultiSelect.svelte";
	//import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import PersonalInformation from "../../components/Register/Personal-Information.svelte";

	// See if we can use this across the hole project
	const ROLES = {
		patient: "Paciente",
		professional: "Profesional_de_Salud",
		caregiver: "Familiar_Cuidador",
	};

	let langSelect = "es";

	if ($language == "es" || $language == "en") {
		console.log("ingreso");
		langSelect = $language;
	}

	//let value;
	let complexItems = [];
	let idiomasExtra = [];
	let credentials = {};
	let modalLoadingAnimation = false;

	//let typeAttachment = "Account__c";
	//let idTypeAttachment = "";
	//let file_type = "Foto de perfil";

	export let params;
	Auth.configure(awsconfig);
	let username = "";
	//let password = "";
	let rol = params.rol || "";
	let myRol = "";
	let provincias;
	//let token = "";
	//let coberturasList = [];

	let errorMessages = null;

	//TODO
	let posibles = [
		"nombre",
		"apellido",
		"genero",
		"fecha",
		"DNI",
		"celular",
		"ciudad/provincia",
		"dirección",
		"cobertura",
		"rol-cuidador",
	];
	// const complexItems = [
	// 	{ value: "M", label: "Masculino" },
	// 	{ value: "F", label: "Femenino" },
	// ];

	/**
	 * @description
	 */
	let rolItems = [];
	let listLanguage = [];
	const openCalendar = (e) => {
		console.log({ e });
		console.log("openCalendar");
		const calendar = e.target;
		console.log({ calendar });
		calendar.click();
	};

	/**
	 * @description
	 * Update user status
	 */
	async function updateUserStatus(status) {
		let user;
		await Auth.currentAuthenticatedUser()
			.then(async (resultado) => {
				user = resultado;
				await Auth.updateUserAttributes(user, {
					"custom:StatusReg": status,
				});
			})
			.catch((err) => {
				user = $currentSession;
			});
	}

	/**
	 * @description
	 * Next
	 */
	async function next() {
		console.log({ credentials });
		console.log({ rol });
		try {
			const dataUser = $currentSession;
			await personal_information_schema.validate(credentials, { abortEarly: false }).then(async (response) => {
				if (idiomasExtra.length > 0) {
					credentials.idiomas.push(idiomasExtra);
				}
				if (rol === ROLES.patient || rol === "Patient") {
					$patient = credentials;
					replace("/PatientRecord");
				}
				if (rol === ROLES.professional || rol === "Professional") {
					$doctor = credentials;
					replace("/ProfessionalRecord");
				}
				if (rol === ROLES.caregiver) {
					modalLoadingAnimation = true;
					rol = $currentSession.attributes.profile;
					username = $currentSession.attributes.email;
					$carer = credentials;
					console.log("Llamando a saveCaregiver");
					await saveCaregiver(credentials, dataUser)
						.then((data) => {
							return data;
						})
						.then(async (result) => {
							if (result.statusCode == 201) {
								updateUserStatus("true");
								let result = await getUserInfo(rol, username);
								if (result) {
									replace(`/Home`);
								} else {
									window.location.href = "#/LoginError";
								}
							} else {
								openModal(() => import("../../components/ModalValidationsForm.svelte"), {
									title: "Error",
									message: result.message,
								});
							}
						});
				}
			});
		} catch (error) {
			errorMessages = getErrorsMessages(error.errors, posibles);
		}
	}

	/**
	 * @description
	 * Render File on Screen and Get Attributes
	 */
	let avatar, fileVar, fileAttr;
	const onFileSelected = (e) => {
		fileAttr = {
			Name: e.target.files[0].name,
			Type: e.target.files[0].type,
		};
		let image = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = (e) => {
			avatar = e.target.result;
			fileVar = e.target.result;
		};
	};

	/**
	 * @description
	 * Mostrar
	 */
	function mostrar() {
		var archivo = document.getElementById("file").files[0];
		var reader = new FileReader();
		if (archivo) {
			reader.readAsDataURL(archivo);
			reader.onloadend = function () {
				document.getElementById("img").src = reader.result;
			};
		}
	}

	/**
	 * @description
	 * onMount
	 */
	onMount(async () => {
		if ($currentSession == {}) {
			replace("/");
		} else {
			let dataUser = $currentSession;
			complexItems = $_("PersonalData.select_rol");
			username = dataUser["attributes"] ? dataUser["attributes"].email : "";
			// rol = dataUser.attributes ? dataUser.attributes["profile"] : "";
			console.log(rol);
			if (rol === ROLES.caregiver) {
				myRol = complexItems[ROLES.caregiver];
				//document.getElementById("nextButton").innerHTML = "Finalizar";
				console.log(myRol, credentials);
			}
			if (rol === ROLES.patient || rol === "Patient") {
				myRol = complexItems[ROLES.patient];
				credentials.rol_cuidador = { value: "s", label: "s" };
				console.log(myRol, credentials);
			}
			if (rol === ROLES.professional || rol === "Professional") {
				myRol = complexItems[ROLES.professional];
				credentials.cobertura = { value: "s", label: "s" };
				credentials.rol_cuidador = { value: "s", label: "s" };
				console.log(myRol, credentials);
			}

			if ($doctor.name) {
				console.log("valida que no existan", $doctor);
				credentials = $doctor;
			}
			console.log("utils_resources_get - PersonalInfo");
			//rolItems = await utils_resources_get("caregiverRoles");
			console.log({ rolItems });
			//coberturasList = await utils_resources_get("prepaidMedicine");
			provincias = {};
			console.log({ provincias });
			//listLanguage = await utils_resources_get("languages");
		}
	});
</script>

<svelte:head>
	<title>RDCOM - REGISTRARSE</title>
	<link rel="icon" href="/img/new_favicon.png">
</svelte:head>
<div>
	<!-- svelte-ignore a11y-missing-attribute -->
	<img class="imgBack" src="/img/backrount.jpg" />
	<div class="register-conteiner">
		<div class="conteiner-data">
			<div
				class="conteinet svelte-yvtl96"
				style="display: flex; align-content: space-around; align-items: center;">
				<!-- <img class="logo-img" src="./img/Frame 106.png" alt="logo" /> -->
				<img src="img/new_logo_redondo.png" alt="logo" width="117" height="117" />
				<h4 style="color: rgb(17, 44, 74);text-align: center;width: 75%;">
					{$_("PersonalData.title_one")}
					{myRol}, {$_("PersonalData.title_two")}:
				</h4>
				<br />
			</div>
		</div>
		<!-- {#if listLanguage.length > 0} -->
		<div />
		<PersonalInformation />
	</div>
</div>
<!-- Modal Cargando 
<Modal isOpenModal={modalLoadingAnimation}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">GUARDANDO...</p>
		</div>
	</div>
</Modal>-->

<!-- <LayoutModal
	isOpenModal={modalLoadingAnimation}
	on:closeModal={() => {
		modalLoadingAnimation = false;
	}}>
	<div class="p-3">
		<Loader text={false} />
		<p class="text-muted">GUARDANDO...</p>
	</div>
</LayoutModal> -->

<Modals>
	<div slot="backdrop" class="backdrop" transition:fade on:click={closeModal} />
</Modals>

<!-- CSS -->
<style>
	.imgBack {
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 100%;
	}

	.register-conteiner {
		width: 65%;
		height: max-content;
		padding: 17px;
		background: #fff;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		justify-content: space-between;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}

	.conteiner-data {
		align-items: flex-start;
		padding-left: 30px;
	}

	/* Estilos para la versión de escritorio (Web) */
	@media screen and (min-width: 1200px) {
		/* Agrega aquí los estilos para la versión de escritorio */
		.conteiner-data {
			text-align: center;
			padding-left: 0px;
		}

		.register-conteiner {
			width: 70%;
			height: max-content;
			padding: 17px;
			background: #fff;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			justify-content: space-between;
			box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
		}
	}

	/* Estilos para tablets */
	@media screen and (max-width: 1024px) {
		/* Agrega aquí los estilos para tablets en orientación horizontal */

		.conteiner-data {
			text-align: center;
			padding-left: 0px;
		}

		.register-conteiner {
			width: 100%;
			height: max-content;
			padding: 17px;
			background: #fff;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			justify-content: space-between;
			box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
		}
	}

	/* Estilos para dispositivos móviles */
	@media screen and (max-width: 480px) {
		/* Agrega aquí los estilos para dispositivos móviles */


		.conteiner-data {
			text-align: center;
			padding-left: 0px;
		}

		.register-conteiner {
			width: 100%;
			height: max-content;
			padding: 17px;
			background: #fff;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			justify-content: space-between;
			box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
		}
	}

	/* Estilos adicionales para tablets en orientación vertical */
	@media screen and (max-width: 768px) {
		/* Agrega aquí los estilos para tablets en orientación vertical */


		.conteiner-data {
			text-align: center;

			padding-left: 0px;
		}

		.conteinet {
			justify-content: center;
		}

		.register-conteiner {
			width: 100%;
			height: max-content;
			padding: 17px;
			background: #fff;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			justify-content: space-between;
			box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
		}
	}

</style>
