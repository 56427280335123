<script>
	import { onMount } from "svelte";
	import { professional_patient_accept_reject } from "../../../helpers/apiBackend";
	import { getEdad } from "../../../helpers/helpers";
	import { patient, user, language } from "../../../helpers/storage/stores";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../../components/Loader.svelte";
	import PersonalInformation from "../../../components/Register/Personal-Information.svelte";
	import { _ } from "svelte-i18n";

	localStorage.setItem("language", JSON.stringify($language));

	let monitoringPlans = [];
	let fotoPerfil = "";
	let edad = "";
	let fecha = "";
	let dataProfessional = [];
	let load = false;
	let modalDecline = false;
	let modalAccept = false;
	let modalEditPatient = false;
	let selectedPlan = "none";
	let myConfirm = false;
	let doctorId = $user;
	/**
	 * @description
	 */
	const handleSessionSolicitude = (header, status, selectedPlan) => {
		console.log("Plan Seleccionado", selectedPlan)
		return new Promise(async function (resolve, reject) {
			let data = {
				patientId: dataProfessional.Id,
				doctorId: doctorId.Id,
				planId: selectedPlan,
				header: header,
				accept: status,
				sessionId: $patient.session,
				suggestedProfessional: "Sin dato",
			};
			let body = { data };
			await professional_patient_accept_reject(body).then((data) => {
				console.log({ data });
				if (data.statusCode == 201) {
					if (status) {
						$patient.careplan_id = data.plan;
						window.location.href = "#/Plan";
					} else {
						window.location.href = "#/Altas";
					}

					resolve();
				} else {
					reject(data);
					//TODO: manejo de error
				}
			});
		});
	};
	/**
	 * @description
	 */
	// function updateAssign() {
	// 	unAssignPlan($patient.id).then((data) => {
	// 		if (data.statusCode == 200) {
	// 			window.location.href = "#/Altas";
	// 		}
	// 	});
	// }
	/**
	 * @description
	 */
	// async function asignar() {
	// 	console.log("call asignar()");
	// 	try {
	// 		if (myConfirm) {
	// 			console.log("call ", myConfirm);
	// 			let cpsr = {
	// 				plan: selectedPlan,
	// 				doctor: $user.Id,
	// 				patient: dataProfessional.Id,
	// 			};
	// 			console.log({ cpsr });
	// 			if (cpsr.plan) {
	// 				console.log("paso el if");
	// 				await assignPlan(cpsr, $currentSession).then((result) => {
	// 					console.log({ result });
	// 					if (result.statusCode == 201) {
	// 						window.location.href = "#/Doctor";
	// 					} else {
	// 						window.location.href = "#/loginError";
	// 					}
	// 				});
	// 			}
	// 		}
	// 	} catch (assignError) {
	// 		console.log({ assignError });
	// 	} //console.log({assignError}) }
	// }

	/**
	 * @description
	 */
	onMount(async () => {
		let Id = $user.Id;

		console.log({ Id });

		//dataProfessional = await Promise.all([generic_account_get("", Id, ROLES.professional, "")]);
		//$user = dataProfessional[0];
		dataProfessional = $user;
		console.log({ dataProfessional });
		fecha = dataProfessional.PersonBirthdate.split("-").reverse().join("-");
		edad = await getEdad(fecha);

		console.log({ edad });
		if (dataProfessional.hasOwnProperty("Archivos_S3__r")) {
			dataProfessional.Archivos_S3__r.records.map((element) => {
				console.log(element);
				if (element.Link__c && element.Tipo__c == "Foto de perfil") {
					fotoPerfil = element.Link__c;
				}
			});
		}
		console.log({ dataProfessional });
	});
</script>

<section>
	{#await dataProfessional}
		<div class="container text-center py-5 bg-white">
			<Loader text={false} />
			<p class="text-muted">{$_("ProfessionalProfile.loading")}</p>
		</div>
	{:then dataProfessional}
		{#if dataProfessional.FirstName}
			<div class="border p-2 bg-white my-2 rounded">
				<div class="d-flex my-3 justify-content-between">
					<div class="border shadow-sm rounded my-2 p-3 justify-content-around" style="width: 49%;">
						<div class="text-center">
							<h4 class="title">{$_("ProfessionalProfile.title_page")}</h4>
						</div>
						<div style="display: inline-flex; width: 100%;">
							<div class="col-sm-6">
								<div class="perfilIMG text-center my-3">
									<img
										class="imgPerfil"
										style="width:190px; height:190px;"
										src={fotoPerfil ? fotoPerfil : "./img/perfil_none.png"}
										alt="" />
								</div>
							</div>
							<div class="col-sm-6">
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("ProfessionalProfile.text_name")}:</p>
									<br />
									{#if dataProfessional.FirstName != null}
										<span class="text-muted">
											{" " + dataProfessional.FirstName + " " + dataProfessional.LastName}</span>
									{:else}<span class="text-muted">{$_("ProfessionalProfile.text_not_data")}</span
										>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("ProfessionalProfile.text_birthdate")}:</p>
									<br />
									{#if dataProfessional.PersonBirthdate != null}
										<span class="text-muted"> {fecha}</span>
									{:else}<span class="text-muted">{$_("ProfessionalProfile.text_not_data")}</span
										>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("ProfessionalProfile.text_age")}:</p>
									<br />
									{#if edad != ""}
										<span class="text-muted"> {edad}</span>
									{:else}<span class="text-muted">{$_("ProfessionalProfile.text_not_data")}</span
										>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("ProfessionalProfile.text_addres")}:</p>
									<br />
									{#if dataProfessional.BillingStreet != null}
										<span class="text-muted"> {dataProfessional.BillingStreet}</span>
									{:else}<span class="text-muted">{$_("ProfessionalProfile.text_not_data")}</span
										>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("ProfessionalProfile.text_billingState")}:</p>
									<br />
									{#if dataProfessional.BillingState != null}
										<span class="text-muted"> {dataProfessional.BillingState}</span>
									{:else}<span class="text-muted">{$_("ProfessionalProfile.text_not_data")}</span
										>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("ProfessionalProfile.text_phone")}:</p>
									<br />
									{#if dataProfessional.PersonMobilePhone != null}
										<span class="text-muted"> {dataProfessional.PersonMobilePhone}</span>
									{:else}<span class="text-muted">{$_("ProfessionalProfile.text_not_data")}</span
										>{/if}
								</div>

								<div class="text-center border shadow-sm rounded my-2 p-3">
									<div>
										<button
											class="btnBlue"
											on:click={() => {
												modalEditPatient = true;
											}}>{$_("ProfessionalProfile.button_edit")}</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="border shadow-sm rounded my-2 p-3" style="width: 49%;">
						<div class="text-center">
							<h4 class="title">{$_("ProfessionalProfile.title_page_path")}</h4>
						</div>
						{#if dataProfessional.hasOwnProperty("Patolog_as2__r") && dataProfessional.Patolog_as2__r.hasOwnProperty("records")}
							{#each dataProfessional.Patolog_as2__r.records as patologias}
								<div
									class="name d-flex"
									style="
    justify-content: space-between;
">
									{#if $language == "en"}
										<span class="text-muted"> {patologias.Name_EN__c}</span>
									{:else}
										<span class="text-muted"> {patologias.Name_ES__c}</span>
									{/if}

									{#if patologias.Estado_patologia__c != null}
										<span class="text-muted"> {patologias.Estado_patologia__c}</span>
									{:else}<span class="text-muted">{$_("ProfessionalProfile.text_not_data")}</span
										>{/if}
								</div>
							{/each}
						{:else}
							<p class="m-0"><span class="text-muted">&#8226;&nbsp;&nbsp;</span>Sin EPOF</p>
						{/if}
						{#if dataProfessional.hasOwnProperty("Archivos_S3__r") && dataProfessional.Archivos_S3__r.hasOwnProperty("records")}
							<div class="text-center">
								<h4 class="title">{$_("ProfessionalProfile.text_attach")}</h4>
							</div>
							{#each dataProfessional.Archivos_S3__r.records as data}
								{#if data.Tipo__c != "Foto de perfil" && data.Tipo__c != "Firma"}
									<div class="Completado">
										<img
											class="cursor pointer"
											on:click={() => {
												console.log({ data });
											}}
											src="img/pdfIcon.png"
											style="width:50px; height:50px;"
											alt="PDF"
											width="auto" /><a href={data.Link__c} download
											>{$_("ProfessionalProfile.button_download")}</a>
										<br />
									</div>
								{/if}
							{/each}
							{#if !dataProfessional.Archivos_S3__r.records[1]}
								<div style="margin: 10%; text-align: center;">
									<p class="m-0"><span class="text-muted" />Sin adjuntos</p>
								</div>
							{/if}
						{/if}
					</div>
				</div>
				<div class="d-flex justify-content-center mx-1 border-top">
					<a href="#/Altas">{$_("ProfessionalProfile.button_return")}</a>
				</div>
			</div>
		{:else}
			<div class="container text-center py-5 bg-white">
				<Loader text={false} />
				<p class="text-muted">{$_("ProfessionalProfile.loading")}</p>
			</div>
		{/if}
	{/await}
</section>

<LayoutModal
	isOpenModal={modalDecline}
	on:closeModal={() => {
		modalDecline = false;
	}}>
	{#if load}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("ProfessionalProfile.loading")}</p>
		</div>
	{:else}
		<div class="text-center p-3">
			<p>{$_("ProfessionalProfile.text_reject_res")}</p>
			<div class="d-flex justify-content-center">
				<button
					on:click={() => {
						modalDecline = false;
					}}
					class="btnWhites">
					{$_("ProfessionalProfile.text_not")}
				</button>
				<button
					on:click={() => {
						load = true;
						handleSessionSolicitude($patient.head, false, null, dataProfessional);
						// if ($patient.head) {
						// 	updateAssign();
						// } else {
						// 	handleSessionSolicitude($patient.head, false, dataProfessional);
						// }
						modalDecline = false;
					}}
					class="btnBlues">
					{$_("ProfessionalProfile.text_yes")}
				</button>
			</div>
		</div>
	{/if}
</LayoutModal>

<LayoutModal
	tam="70"
	isOpenModal={modalEditPatient}
	on:closeModal={() => {
		modalDecline = false;
		modalEditPatient = false;
	}}>
	{#if load}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("ProfessionalProfile.loading")}</p>
		</div>
	{:else}
		<h4 style="color: rgb(17, 44, 74);text-align: center;">
			<!-- Iniciemos tu registro como Profesional de la Salud, por favor completá los siguientes datos: -->
			{$_("ProfessionalProfile.title_page")}
		</h4>
		<PersonalInformation edit={true} />
		<div class="d-flex justify-content-center mx-1 border-top">
			<a
				href="#/ProfessionalProfile"
				on:click={() => {
					//Prueba
					modalEditPatient = false;
				}}>Volver</a>
		</div>
	{/if}
</LayoutModal>

<LayoutModal
	isOpenModal={modalAccept}
	tam="40"
	on:closeModal={() => {
		modalAccept = false;
	}}>
	{#if load}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("ProfessionalProfile.loading")}</p>
		</div>
	{:else}
		<h4 class="title">Asignar plan a {dataProfessional.FirstName}</h4>
		<div class="my-4">
			<!-- <p class="m-0">Paciente:&nbsp;&nbsp;{dataProfessional.FirstName}</p> -->

			<!-- Monitoring Plans List -->
			{#if monitoringPlans.length == 0}
				<Loader text={false} />
				<p class="text-muted">{$_("ProfessionalProfile.loading")}</p>
			{:else if monitoringPlans.length == 0}
				<p class="text-muted">No hay planes para asignar</p>
			{:else}
				<p class="m-0">Seleccione un plan de la lista</p>
				<select bind:value={selectedPlan} class="form-control rounded-0" name="plan-select" id="plan-select">
					<option value="none"><span class="text-muted">Seleccionar... </span></option>
					{#each monitoringPlans as plan}<option class="py-0" value={plan.value}>{plan.label}</option>{/each}
				</select>
			{/if}
		</div>
		{#if selectedPlan != "none"}
			<div class="text-center">
				<label> <input type="checkbox" bind:checked={myConfirm} />Confirmo que deseo asignar el plan</label>
			</div>
		{/if}
		<!-- Actions -->
		<div class="mt-4 d-flex" style="justify-content: center;">
			<button
				on:click={() => {
					modalAccept = false;
				}}
				class="py-1 btnBlues">Cancelar</button>
			{#if selectedPlan != "none"}
				{#if myConfirm}
					<button
						class="btnWhites"
						on:click={() => {
							load = true;
							//asignar();
							handleSessionSolicitude($patient.head, true, selectedPlan, dataProfessional);
						}}>Asignar</button>
				{:else}
					<button class="btnWhites" disabled style="color:darkgray">Asignar</button>
				{/if}
			{/if}
		</div>
	{/if}
</LayoutModal>

<style>
	.perfilIMG {
		right: 10%;
	}
	.title {
		color: #215273;
		text-align: center;
	}
	.imgPerfil {
		width: 150px;
		border-radius: 50%;
		background-size: 120%;
	}
	.btnWhites {
		letter-spacing: 1px;
		color: var(--my-dark-blue);
		border-radius: 10px;
		background-color: #ffffff;
		width: 30%;
		margin: 1%;
	}
	.btnBlues {
		letter-spacing: 1px;
		border-radius: 10px;
		color: #ffffff;
		background-color: var(--my-dark-blue);
		width: 30%;
		margin: 1%;
	}
</style>
