<script>
	import { _ } from "svelte-i18n";
	import { language } from "../helpers/storage/stores";
	export let body;
	let genderOptions = $_("PersonalData.select_gender");
	console.log({ body });

	function formatDate(fecha, idioma) {
		const formatos = {
			es: { day: "2-digit", month: "2-digit", year: "numeric" },
			en: { month: "2-digit", day: "2-digit", year: "numeric" },
		};
		const opcionesFecha = formatos[idioma];
		if (opcionesFecha) {
			return new Date(fecha).toLocaleDateString(idioma, opcionesFecha);
		} else {
			console.log("Idioma no soportado");
			return null;
		}
	}
</script>

<main>
	<div class="title-conf">
		<h2 class="title-confirm">{$_("PersonalData.text_title_confirm")}</h2>
	</div>

	<div class="container py-2 border rounded shadow-sm my-3 bg-white">
		<div class="title">
			<h3 class="title-detail">{$_("PersonalData.text_title_information")}:</h3>
		</div>
		<div class="conteiner-d">
			<div class="FirstName">
				<label for="FirstName" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_name")}:</strong></label>
				<input type="text" id="FirstName" readonly value={body.FirstName} class="base_input" />
			</div>
			<div class="LastName">
				<label for="LastName" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_lastname")}:</strong></label>
				<input type="text" id="LastName" readonly value={body.LastName} class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="DNI__c">
				<label for="DNI__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>DNI:</strong></label>
				<input type="text" id="DNI__c" readonly value={body.DNI__c} class="base_input" />
			</div>
			<div class="PersonBirthdate">
				<label for="PersonBirthdate" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_birthdate")}:</strong></label>
				<input
					type="text"
					id="PersonBirthdate"
					readonly
					value={formatDate(body.PersonBirthdate, $language)}
					class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="HealthCloudGA__Gender__pc">
				<label for="HealthCloudGA__Gender__pc" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_gender")}:</strong></label>
				<input
					type="text"
					id="HealthCloudGA__Gender__pc"
					readonly
					value={genderOptions.find((option) => option.id === body.HealthCloudGA__Gender__pc)?.text || ""}
					class="base_input" />
			</div>
			<!-- <div class="PersonMobilePhone">
				<label for="PersonMobilePhone" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>Celular:</strong></label>
				<input
					type="text"
					id="PersonMobilePhone"
					readonly
					value={body.PersonMobilePhone}
					style="width: 90%; padding: 5px; border: none; border-bottom: 1px solid #112c4a;" />
			</div>  -->
		</div>
		<div class="conteiner-d">
			{#if body.Phone}
				<div class="Phone">
					<label for="Phone" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("PersonalData.text_phone")}:</strong></label>
					<input type="text" id="Phone" readonly value={body.Phone} class="base_input" />
				</div>
			{/if}
			{#if body.PersonMobilePhone}
				<div class="PersonMobilePhone">
					<label for="PersonMobilePhone" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("PersonalData.text_cell")}:</strong></label>
					<input
						type="text"
						id="PersonMobilePhone"
						readonly
						value={body.PersonMobilePhone}
						class="base_input" />
				</div>
			{/if}
		</div>
	</div>

	<div class="container py-2 border rounded shadow-sm my-3 bg-white">
		<div class="title">
			<h3 class="title-detail">{$_("PersonalData.text_title_addres")}:</h3>
		</div>
		<div class="conteiner-d">
			<div class="R1_streetName__c">
				<label for="R1_streetName__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_title_addres")}:</strong></label>
				<input type="text" id="R1_streetName__c" readonly value={body.R1_streetName__c} class="base_input" />
			</div>
			{#if body.R1_streetNumber__c}
				<div class="R1_streetNumber__c">
					<label for="R1_streetNumber__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_addres_number")}:</strong></label>
					<input
						type="text"
						id="R1_streetNumber__c"
						readonly
						value={body.R1_streetNumber__c}
						class="base_input" />
				</div>
			{/if}
			{#if body.R1_streetDetail__c}
				<div class="R1_streetDetail__c">
					<label for="R1_streetDetail__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_addresApartment")}:</strong></label>
					<input
						type="text"
						id="R1_streetDetail__c"
						readonly
						value={body.R1_streetDetail__c}
						class="base_input" />
				</div>
			{/if}
		</div>
		<div class="conteiner-d">
			<div class="BillingCountry">
				<label for="BillingCountry" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_country")}:</strong></label>
				<input type="text" id="BillingCountry" readonly value={body.BillingCountry.label} class="base_input" />
			</div>
			<div class="BillingState">
				<label for="BillingState" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_BillingState")}:</strong></label>
				<input type="text" id="BillingState" readonly value={body.BillingState.label} class="base_input" />
			</div>
		</div>
	</div>

	<div class="container py-2 border rounded shadow-sm my-3 bg-white">
		<div class="title">
			<h3 class="title-detail">{$_("ProfessionalRecord.text_title_formation")}:</h3>
		</div>
		<div class="conteiner-d">
			<div class="PersonTitle">
				<label for="PersonTitle" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_title_education")}:</strong></label>
				<input type="text" id="PersonTitle" readonly value={body.PersonTitle} class="base_input" />
			</div>
			<div class="Universidad__c">
				<label for="Universidad__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_Universidad")}:</strong></label>
				<input type="text" id="Universidad__c" readonly value={body.Universidad__c} class="base_input" />
			</div>
			{#if body.Postgrado__c}
				<div class="Postgrado__c">
					<label for="Postgrado__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_doctorate")}:</strong></label>
					<input type="text" id="Postgrado__c" readonly value={body.Postgrado__c} class="base_input" />
				</div>
			{/if}
		</div>
		<div class="conteiner-d">
			{#if body.Matricula__c}
				<div class="Matricula__c">
					<label for="Matricula__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_Tuition")}:</strong></label>
					<input type="text" id="Matricula__c" readonly value={body.Matricula__c} class="base_input" />
				</div>
			{/if}
			{#if body.Matricula_provincial__c}
				<div class="Matricula_provincial__c">
					<label for="Matricula_provincial__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_Tuition_prov")}:</strong></label>
					<input
						type="text"
						id="Matricula_provincial__c"
						readonly
						value={body.Matricula_provincial__c}
						class="base_input" />
				</div>
			{/if}
		</div>
		<!-- <div class="conteiner-d">
			<div class="specializations">
				<label for="specializations" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
					<strong>Especializaciones:</strong>
				</label>
				<ul>
					{#each body.specializations as specialization (specialization.id)}
						<li>{specialization.label}</li>
					{/each}
				</ul>
			</div>
		</div> -->
		{#if body.structlistLanguage && body.structlistLanguage.length > 0}
			<div class="conteiner-d">
				<div class="language">
					<label for="language" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;">
						<strong>{$_("PersonalData.text_title_language")}:</strong>
					</label>
					<ul>
						{#each body.structlistLanguage as language}
							<li>{language.label}</li>
						{/each}
					</ul>
				</div>
			</div>
		{/if}
	</div>
	{#if body.countryMedical && body.R1_consultorioStreetName__c}
		<div class="container py-2 border rounded shadow-sm my-3 bg-white">
			{#if body.countryMedical && body.R1_consultorioStreetName__c}
				<div class="title">
					<h3 class="title-detail">{$_("ProfessionalRecord.text_title_consulting_room")}:</h3>
				</div>
				<div class="conteiner-d">
					<div class="Nombre_de_consultorio__c">
						<label
							for="Nombre_de_consultorio__c"
							style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
							><strong>{$_("ProfessionalRecord.text_title_consulting_room_name")}:</strong></label>
						<input
							type="text"
							id="Nombre_de_consultorio__c"
							readonly
							value={body.Nombre_de_consultorio__c}
							class="base_input" />
					</div>
				</div>

				<div class="conteiner-d">
					<div class="R1_consultorioStreetName__c">
						<label
							for="R1_consultorioStreetName__c"
							style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
							><strong>{$_("PersonalData.text_title_addres")}:</strong></label>
						<input
							type="text"
							id="R1_consultorioStreetName__c"
							readonly
							value={body.R1_consultorioStreetName__c}
							class="base_input" />
					</div>
					{#if body.R1_consultorioStreetNumber__c}
						<div class="R1_consultorioStreetNumber__c">
							<label
								for="R1_consultorioStreetNumber__c"
								style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
								><strong>{$_("ProfessionalRecord.text_addres_number")}:</strong></label>
							<input
								type="text"
								id="R1_consultorioStreetNumber__c"
								readonly
								value={body.R1_consultorioStreetNumber__c}
								class="base_input" />
						</div>
					{/if}
					{#if body.R1_consultorioStreetDetail__c}
						<div class="R1_consultorioStreetDetail__c">
							<label
								for="R1_consultorioStreetDetail__c"
								style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
								><strong>{$_("ProfessionalRecord.text_addresApartment")}:</strong></label>
							<input
								type="text"
								id="R1_consultorioStreetDetail__c"
								readonly
								value={body.R1_consultorioStreetDetail__c}
								class="base_input" />
						</div>
					{/if}
				</div>

				<div class="conteiner-d">
					<div class="BillingCountry">
						<label for="BillingCountry" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
							><strong>{$_("PersonalData.text_country")}:</strong></label>
						<input
							type="text"
							id="BillingCountry"
							readonly
							value={body.countryMedical.label}
							class="base_input" />
					</div>
					<div class="BillingState">
						<label for="BillingState" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
							><strong>{$_("ProfessionalRecord.text_BillingState")}:</strong></label>
						<input
							type="text"
							id="BillingState"
							readonly
							value={body.BillingStateMedical.label}
							class="base_input" />
					</div>
				</div>

				<!-- <div class="conteiner-d">
			<div class="Direccion_consultorio__c">
				<label for="Direccion_consultorio__c" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_addres")}:</strong></label>
				<input
					type="text"
					id="Direccion_consultorio__c"
					readonly
					value={body.Direccion_consultorio__c}
					class="base_input" />
			</div>
		</div> -->
				<div class="conteiner-d">
					{#if body.Celular_consultorio__c != "-"}
						<div class="Celular_consultorio__c">
							<label
								for="Celular_consultorio__c"
								style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
								><strong>{$_("ProfessionalRecord.text_cell_phone")}:</strong></label>
							<input
								type="text"
								id="Celular_consultorio__c"
								readonly
								value={body.Celular_consultorio__c}
								class="base_input" />
						</div>
					{/if}
					{#if body.Telefono_consultorio__c != "-"}
						<div class="Telefono_consultorio__c">
							<label
								for="Telefono_consultorio__c"
								style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
								><strong>{$_("ProfessionalRecord.text_phone")}:</strong></label>
							<input
								type="text"
								id="Telefono_consultorio__c"
								readonly
								value={body.Telefono_consultorio__c}
								class="base_input" />
						</div>
					{/if}
				</div>
			{/if}
			{#if body.coberturas && body.coberturas.length > 0}
				<div class="conteiner-d">
					<div class="Cobertura_de_Salud">
						<label for="Cobertura_de_Salud" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
							><strong>{$_("PersonalData.text_Coverage_data")}:</strong></label>
						<input
							type="text"
							id="Cobertura de Salud"
							readonly
							value={body.coberturas.join(", ")}
							class="base_input" />
					</div>
				</div>
			{/if}
		</div>
	{/if}

	{#if body.refPatologia}
		<div class="container py-2 border rounded shadow-sm my-3 bg-white">
			<div class="title">
				<h3 class="title-detail">{$_("ProfessionalRecord.text_title_referent")}:</h3>
			</div>
			<div class="conteiner-d">
				<!-- <div class="refPatologia">
				<label for="refPatologia" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_title_referent")}:</strong></label>
				<input type="text" id="refPatologia" readonly value={body.refPatologia} class="base_input" />
			</div> -->
				<div class="patologia">
					<label for="patologia" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_select_referent")}:</strong></label>
					<input
						type="text"
						id="patologia"
						readonly
						value={body.pathology.label ? body.pathology.label : ""}
						class="base_input" />
				</div>
				<div class="cantPaciente">
					<label for="cantPaciente" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_cant_patient")}:</strong></label>
					<input type="text" id="cantPaciente" readonly value={body.cantPaciente} class="base_input" />
				</div>
			</div>
		</div>
	{/if}
	<div class="container py-2 border rounded shadow-sm my-3 bg-white">
		<div class="title">
			<h3 class="title-detail">{$_("ProfessionalRecord.text_title_extra")}:</h3>
		</div>
		<div class="Realiza_investigacion">
			<label for="Realiza_investigacion" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
				><strong>{$_("ProfessionalRecord.text_publications")}:</strong></label>
			<input
				type="text"
				id="Realiza_investigacion"
				class="base_input"
				readonly
				value={body.realizado_publicaciones} />
		</div>
		{#if body.realizado_publicaciones}
			<div class="realizado_publicaciones">
				<label for="realizado_publicaciones" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_link_publications")}:</strong></label>
				<input
					type="text"
					id="realizado_publicaciones"
					class="base_input"
					readonly
					value={body.realizado_publicaciones} />
			</div>
		{/if}
		{#if body.Link_de_publicaciones}
			<div class="Link_de_publicaciones">
				<label for="Link_de_publicaciones" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_link_publications")}:</strong></label>
				<input
					type="text"
					id="Link_de_publicaciones"
					class="base_input"
					readonly
					value={body.Link_de_publicaciones} />
			</div>
		{/if}
		{#if body.linkedin}
			<div class="linkedin">
				<label for="linkedin" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_networks_Linkedin")}:</strong></label>
				<input type="text" id="linkedin" class="base_input" readonly value={body.linkedin} />
			</div>
		{/if}
		<!-- <div class="medicoCabecera">
			<label for="medicoCabecera" style="font-size: 11px; color: #112c4a; margin-bottom: 5px;"
				><strong>{$_("ProfessionalRecord.text_medico_cabecera")}:</strong></label>
			<input type="text" id="medicoCabecera" class="base_input" readonly value={body.medicoCabecera} />
		</div> -->
	</div>
</main>

<style>
	.title-conf {
		text-align: center;
		color: #112c4a;
	}
	.conteiner-d {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-evenly;
	}
	.realizado_publicaciones {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Realiza_investigacion {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Link_de_publicaciones {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.FirstName {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	/* .medicoCabecera {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	} */
	.linkedin {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.LastName {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.PersonTitle {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.language {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.DNI__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.PersonBirthdate {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.HealthCloudGA__Gender__pc {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Phone {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Universidad__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Postgrado__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	/* .specializations {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	} */
	.patologia {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.PersonMobilePhone {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.BillingState {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.base_input {
		width: 90%;
		padding: 5px;
		border: none;
		border-bottom: 1px solid #112c4a;
		color: gray;
	}

	.BillingCountry {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.R1_consultorioStreetName__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.R1_consultorioStreetNumber__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Nombre_de_consultorio__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	/* .Direccion_consultorio__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	} */
	.Cobertura_de_Salud {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Telefono_consultorio__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Celular_consultorio__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	/* .refPatologia {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	} */
	.cantPaciente {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Matricula_provincial__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Matricula__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.R1_streetName__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.R1_streetDetail__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.R1_streetNumber__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.title-detail {
		font-size: 16px;
		color: rgb(17, 44, 74);
		margin-left: 2%;
	}
</style>
