<script>
	// Components
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../../components/Loader.svelte";

	// Helpers
	import { language, patient, user } from "../../../helpers/storage/stores";
	import { currentSession } from "../../../helpers/storage/sessions";
	import { formatDate } from "../../../helpers/helpers";
	import { groupedResultsFormat } from "../../../helpers/helperQuestsResults";
	import {
		utils_files_get,
		generic_clinical_history_get,
		generic_exam_detail_get,
		generic_interview_detail_get,
		// generic_questionnaire_results_get,
	} from "../../../helpers/apiBackend";

	// Svelte
	import { _ } from "svelte-i18n";
	import { onMount } from "svelte";
	import { createEventDispatcher } from 'svelte';
	// Export to PDF
	import { jsPDF } from "jspdf";
	import { each } from "svelte/internal";
	// import ModalQuestResponse from "../../../components/ModalQuestResponse.svelte";

	// Variables
	let loading = true;
	let complexItemsStatus = [];
	let type = "";
	let dataAdjunto = [];
	let modalOpen = false;
	let modalContent = '';
	let myHistoryClinicList = [];
	let HistoryClinical2Show = [];
	let HistoryClinicFiltered = [];
	let selectedEvolution = "";
	let myPatientId = "";
	let errorMessage = "";
	let resultsAnswer = [];
	let modalViewAnswer = false;
	let error = null;
	let patientFullName = "";

	let sortingParameter = "";
	let columnSorting = "";
	let ascendente = true;

	/**
	 * @description
	 */
	onMount(async () => {
		if ($user.hasOwnProperty("Especialidades__r")) {
			myPatientId = $patient.Id;
		} else {
			myPatientId = $user.Id;
		}
		patientFullName = $patient.name || "patient"; // Asegúrate de ajustar según el nombre correcto en el store
		complexItemsStatus = $_("ClinicHistory.select_status_detail");
		console.log("ComplexItem", complexItemsStatus);
		document.querySelector("#buscar").click();
	});

	// FN para generar pdf pero desde un json
	/* function generateHistoryPDF_FromJSon(historyData = null) {
		const doc = new jsPDF();
		const margin = 10; // Margen en la página
		const pageWidth = doc.internal.pageSize.width; // Ancho de la página
		const pageHeight = doc.internal.pageSize.height; // Altura de la página
		let y = margin; // Posición vertical inicial
		const lineHeight = 8; // Altura de línea
		const contentWidth = pageWidth - 2 * margin;

		function splitByLength(str, length) {
			let result = [];
			for (let i = 0; i < str.length; i += length) {
				result.push(str.slice(i, i + length));
			}
			return result;
		}

		// Función para agregar texto con opciones de alineación
		function addText(text, x, lineHeight, fontSize = 12, bold = false, align = "left") {
			if (text.length > 100) {
				let array = splitByLength(text, 100);
				array.forEach((newText) => addText(newText, x, lineHeight, fontSize, bold, align));
			} else {
				if (y + lineHeight > pageHeight - margin) {
					doc.addPage();
					y = margin;
				}
				if (bold) {
					doc.setFont("helvetica", "bold");
				} else {
					doc.setFont("helvetica", "normal");
				}
				doc.setFontSize(fontSize);

				let textX = x; // Posición inicial del texto

				// Alinear texto según la opción dada
				if (align === "center") {
					textX = pageWidth / 2;
					doc.text(text, textX, y, { align: "center" });
				} else if (align === "right") {
					textX = pageWidth - margin;
					doc.text(text, textX, y, { align: "right" });
				} else {
					doc.text(text, textX, y);
				}

				y += lineHeight;
			}
		}

		// Agregar título centrado
		doc.setFontSize(16);
		addText("Historia Clínica: " + patientFullName, margin, lineHeight, 16, true, "center");
		addText(
			"------------------------------------------------------------------------------------------------",
			margin,
			lineHeight,
			12,
			false,
			"center"
		);
		y += 4; // Espacio para el título

		// Agregar contenido del JSON alineado a la izquierda
		historyData.forEach((item) => {
			let activity_label = "";
			if (item.type == "i") {
				activity_label = "Cita / Entrevista";
			}
			if (item.type == "e") {
				activity_label = "Estudio";
			}
			if (item.type == "q") {
				activity_label = "Cuestionario";
			}
			if (item.type == "m") {
				activity_label = "Medicamento";
			}

			addText(`Actividad: ${activity_label}`, margin, lineHeight, 12, false, "left");
			y -= lineHeight;
			addText(`Fecha: ${item.dateCompleted}`, margin, lineHeight, 12, false, "right");

			addText(`Detalle: ${item.name}`, margin, lineHeight, 12, false, "left");
			y -= lineHeight;
			addText(`Evolucionado por: ${item.evolvedByName}`, margin, lineHeight, 12, false, "right");

			addText(`Evolución: ${item.evolution}`, margin, lineHeight, 12, false, "left");

			addText(
				"------------------------------------------------------------------------------------------------",
				margin,
				lineHeight,
				12,
				false,
				"center"
			);
		});

		// Descargar el PDF
		doc.save(`Historia Clinica - ${patientFullName}.pdf`);
	}	 */

		function generateHistoryPDF_FromJSon(historyData = null + patientFullName) {
			const doc = new jsPDF();
			const margin = 10;
			const pageWidth = doc.internal.pageSize.width;
			const pageHeight = doc.internal.pageSize.height;
			let y = margin;
			const lineHeight = 5; //espacio entre líneas
			const contentWidth = pageWidth - 2 * margin;

			/* function splitByLength(str, length) {
				let result = [];
				for (let i = 0; i < str.length; i += length) {
					result.push(str.slice(i, i + length));
				}
				return result;
			} */
			// Función para agregar texto con opciones de alineación
			function addText(text, x, fontSize = 12, bold = false, align = "left") {
				doc.setFontSize(fontSize);
				doc.setFont("helvetica", bold ? "bold" : "normal");

				/* let textX = x;
				// Alinear texto según la opción dada
				if (align === "center") {
					textX = pageWidth / 2;
				} else if (align === "right") {
					textX = pageWidth - margin;
				}

				if (text.length > 100) {
					let array = splitByLength(text, 100);
					array.forEach((newText) => {
						if (y + lineHeight > pageHeight - margin) {
							doc.addPage();
							y = margin;
						}
						doc.text(newText, textX, y, { align: align });
						y += lineHeight;
					});
				} else {
					doc.text(text, textX, y, { align: align });
					y += lineHeight;
				} */
				let textLines = doc.splitTextToSize(text, contentWidth);
				
				textLines.forEach((line, index) => {
					if (y + lineHeight > pageHeight - margin) {
						doc.addPage();
						y = margin;
					}

					let xPosition;
					switch (align) {
						case "center":
							xPosition = pageWidth / 2;
							break;
						case "right":
							xPosition = pageWidth - margin;
							break;
						default:
							xPosition = margin;
					}
					
            doc.text(line, xPosition, y, { align: align });
            y += index < textLines.length - 1 ? lineHeight : lineHeight + 2; // más de espacio de renglón entre act y detalle
        });
    }

			function addLine() {
				if (y + lineHeight > pageHeight - margin) {
					doc.addPage();
					y = margin;
				}
				doc.setLineWidth(0.5);
				doc.line(margin, y, pageWidth - margin, y);
				y += lineHeight + 2;  //separación después de la línea
			}

			// Agregar título centrado
			addText("Historia Clínica: " + patientFullName, margin, 16, true, "center");
			addLine();

			// Contenido
			historyData.forEach((item) => {
				const activityLabels = {
					"i": "Cita / Entrevista",
					"e": "Estudio",
					"q": "Cuestionario",
					"m": "Medicamento"
				};

				const activity_label = activityLabels[item.type] || "Actividad Desconocida";

				addText(`Actividad: ${activity_label}`, margin, 12, false, "left");
				addText(`Fecha: ${item.dateCompleted}`, margin, 12, false, "right");
				addText(`Detalle: ${item.name}`, margin, 12, false, "left");
				addText(`Evolucionado por: ${item.evolvedByName}`, margin, 12, false, "right");
				addText(`Evolución: ${item.evolution}`, margin, 12, false, "left");

				addLine();
			});

			// Descargar el PDF
			doc.save(`Historia Clinica - ${patientFullName}.pdf`);
		}


	    function generatePDF() {
		const doc = new jsPDF();
		const margin = 10;
		const pageWidth = doc.internal.pageSize.width;
		const pageHeight = doc.internal.pageSize.height;
		let y = margin; // margin top
		const lineHeight = 4;  //espacio entre lineas
		const questionWidth = pageWidth - 2 * margin;
		const answerMargin = 5;

		// Definir el ancho de las columnas
		const columnWidths = {
			question: questionWidth * 0.85, 
			answer: questionWidth * 0.15    
		};

		// Función para centrar texto
		function centerText(text, y, fontSize = 16) {
			doc.setFontSize(fontSize);
			const textWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;
			const textOffset = (pageWidth - textWidth) / 2;
			doc.text(text, textOffset, y);
    }

		// Título centrado con menos espacio
		centerText("Resultados del cuestionario", y);
		y += 10; // Separación a la siguiente línea

		function addText(text, x, maxWidth, fontSize = 12, bold = false) {
			doc.setFontSize(fontSize);
			doc.setFont("helvetica", bold ? "bold" : "normal");
			

			//algoritmo de ajuste de palabras que evita / palabras innecesariamente. Con esto las palabras se agregan a la línea actual solo si caben completamente
			const words = text.split(' ');
			let line = '';
			let firstLine = true;
			
			words.forEach(word => {
				const testLine = line + (line ? ' ' : '') + word;
				const testWidth = doc.getStringUnitWidth(testLine) * fontSize / doc.internal.scaleFactor;
				
				if (testWidth > maxWidth) {
					if (y + lineHeight > pageHeight - margin) {
						doc.addPage();
						y = margin;
					}
					doc.text(line, x, y);
					y += lineHeight * (bold && firstLine ? 1.2 : 1); // Más espacio después del título - firstLine 1- línea en negrita
					line = word;
					firstLine = false;
				} else {
					line = testLine;
				}
			});

				if (line) {
					if (y + lineHeight > pageHeight - margin) {
						doc.addPage();
						y = margin;
				}
				doc.text(line, x, y);
				y += lineHeight * (bold ? 1 : 1); // con el primer # doy espacio después de títulos en negrita
			}
		}

		function addQuestionAndAnswer(question, answers) {
			const initialY = y;
			addText(question, margin, columnWidths.question, 12, true);
			const questionHeight = y - initialY;
       		y += lineHeight * 0.5; // Espacio extra después de la pregunta
			
			answers.forEach(answer => {
				if (answer !== "Continuar." && answer !== "Continuar") {
					const questionHeight = y - initialY;
					addText(`- ${answer}`, margin + columnWidths.question + answerMargin, columnWidths.answer);
					const answerHeight = y - answerInitialY;
					
					 // Ajustar y si la respuesta es más alta que la pregunta
					if (answerHeight > questionHeight) {
                  		y += lineHeight * 0.5;
                }
            }
        });
			y += lineHeight; // Espacio extra después de las respuestas, pero con 0.5 lo reduzco a la mitad
		}

		function addTable(questions, answers) {
			const cellPadding = 3; // espacio interno de las celdas de tabla
			let tableY = y;
			let maxY = y;
			const rowHeights = [];

			// Reduce el espacio antes de la tabla
			y -= lineHeight * 0.5;

			questions.forEach((question, index) => {
				const answer = answers[index];
				
				// Calcular altura necesaria para la pregunta y la respuesta
				const questionLines = doc.splitTextToSize(question, columnWidths.question - 2 * cellPadding); //columnWidths.question - 2  controla cantidad de texto en ese ancho
				const answerLines = doc.splitTextToSize(answer, columnWidths.answer - 2 * cellPadding);
				rowHeights[index] = Math.max(questionLines.length, answerLines.length) * lineHeight + 1.5 * cellPadding; //lineHeight da separación entre líneas de cada pregunta y resp
			});
				// Verifica si hay espacio suficiente para al menos una fila para no cortar el texto por mitad
				const totalHeight = rowHeights.reduce((sum, height) => sum + height, 0);
				if (y + Math.min(...rowHeights) > pageHeight - margin || y + totalHeight > pageHeight - margin) {
					doc.addPage();
					y = margin;
					tableY = margin;
					maxY = margin;
   			     }
				 
			questions.forEach((question, index) => {
					const answer = answers[index];
					const rowHeight = rowHeights[index];

				if (tableY + rowHeight > pageHeight - margin) {
					// Dibujar líneas de la tabla antes de cambiar de página
					doc.line(margin, y, margin + questionWidth, y); // Línea superior
					doc.line(margin, y, margin, tableY); // Línea izquierda
					doc.line(margin + questionWidth, y, margin + questionWidth, tableY); // Línea derecha
					doc.line(margin + columnWidths.question, y, margin + columnWidths.question, tableY); // Línea divisoria

					doc.addPage();
					y = margin;
					tableY = margin;
					maxY = margin; 
				}

				// Agregar la pregunta
				doc.setFontSize(10);
				doc.setFont("helvetica", "normal");
				
				const questionLines = doc.splitTextToSize(question, columnWidths.question - 2 * cellPadding);
				const answerLines = doc.splitTextToSize(answer, columnWidths.answer - 2 * cellPadding);

				questionLines.forEach((line, lineIndex) => {
                doc.text(line, margin + cellPadding, tableY + lineHeight * (lineIndex + 1.5)); //lindeIndex ajusta el margin top d pregunta
            });

				// Agregar la respuesta
				answerLines.forEach((line, lineIndex) => {
					doc.text(line, margin + columnWidths.question + cellPadding, tableY + lineHeight * (lineIndex + 1.5)); //lineIndex ajusta el margin top de la respuesta
				});

				tableY += rowHeight;
				maxY = Math.max(maxY, tableY);
			});

			// Dibujar líneas de la tabla
			doc.line(margin, y, margin + questionWidth, y); // Línea superior
			doc.line(margin, y, margin, maxY); // Línea izquierda
			doc.line(margin + questionWidth, y, margin + questionWidth, maxY); // Línea derecha
			doc.line(margin + columnWidths.question, y, margin + columnWidths.question, maxY); // Línea divisoria
			doc.line(margin, maxY, margin + questionWidth, maxY); // Línea inferior

			y = maxY + lineHeight * 3; // Actualizar la posición Y global / da separación entre final de tabla con título siguiente
		}
		
		if (resultsAnswer && resultsAnswer.length > 0) {
			resultsAnswer
				.filter(resultado => resultado.questionType !== "inform")
				.forEach(resultado => {
					if (resultado.questionType === "rta" && resultado.answerTexts.length > 0) {
						addQuestionAndAnswer(resultado.questionText, resultado.answerTexts);
					}

					if (resultado.questionType === "grid" && resultado.options.length > 0) {
						addText(resultado.questionDescription, margin, questionWidth, 12, true);
						y += lineHeight;
						const questions = resultado.options.map(option => option.questionText);
						const answers = resultado.options.map(option => option.answerTexts[0] || '');
						addTable(questions, answers);
					}
				});

			const informResults = resultsAnswer.filter(resultado => resultado.questionType === "inform");
			if (informResults.length > 0) {
				addText("Informe de resultados:", margin, questionWidth, 14, true);
				y += lineHeight;
				informResults.forEach(resultado => {
					if (resultado.answerTexts && resultado.answerTexts.length > 0) {
						addTable([resultado.questionText.slice(1)], [resultado.answerTexts[0]]);
					}
				});
			}
		} else {
			addText("No hay resultados disponibles.", margin, questionWidth);
		}

		doc.save("resultados_cuestionario.pdf");
	}
	



	function sortBy(lista, sortingParameter, columnSorting, asc = true) {
		return lista.sort((a, b) => {
			let fechaA, fechaB, textoA, textoB;

			if (sortingParameter === "Fecha") {
				fechaA = new Date(a.dateCompleted);
				fechaB = new Date(b.dateCompleted);
				return asc ? fechaA - fechaB : fechaB - fechaA;
			} else if (columnSorting === "Activity") {
				// Sublógica de ordenamiento por tipo
				if (a.type !== b.type) {
					const typeOrder = { i: 1, e: 2, q: 3 };
					return asc ? typeOrder[a.type] - typeOrder[b.type] : typeOrder[b.type] - typeOrder[a.type];
				}
				// Si los tipos son iguales, usamos el nombre para ordenar
				textoA = a.name;
				textoB = b.name;
			} else if (columnSorting === "createdBy") {
				textoA = a.createdByName;
				textoB = b.createdByName;
			} else if (columnSorting === "evolved_by") {
				textoA = a.evolvedByName || "N/A";
				textoB = b.evolvedByName || "N/A";
			} else {
				textoA = a.name;
				textoB = b.name;
			}

			// Convertir a minúsculas para comparación de texto
			textoA = String(textoA).toLowerCase();
			textoB = String(textoB).toLowerCase();

			// Comparación final
			if (textoA < textoB) return asc ? -1 : 1;
			if (textoA > textoB) return asc ? 1 : -1;
			return 0;
		});
	}

	function ordenarLista() {
		let datosFiltrados = (HistoryClinical2Show);
  
  		HistoryClinical2Show = sortBy(datosFiltrados, sortingParameter, columnSorting, ascendente);
	}	


	let fechaDesde = "";
	let fechaHasta = "";
	let profesional = "";
	let loadingList = false;
	let errorMessageList = "";

	// Este es un ejemplo, puedes agregar más lógica aquí
	function parseDate(fecha) {
		if (!fecha) return "";
		const [year, month, day] = fecha.split("-");
		return `${day}/${month}/${year}`;
	}

	async function buscar() {
		console.time("buscarHistoryList");
		loadingList = true;

		let fecha_desde_parseada = parseDate(fechaDesde);
		let fecha_hasta_parseada = parseDate(fechaHasta);

		try {
			console.time("gchg_callback");
			const data = await generic_clinical_history_get(
				$patient.Id,
				fecha_desde_parseada,
				fecha_hasta_parseada,
				"",
				"",
				"",

				$language
			);
			console.timeEnd("gchg_callback");
			// if (data.statusCode == 404 || data.error == true) {
			// 	errorMessageList = data.message;
			// } else {
			console.log(data.message);
			myHistoryClinicList = data.data;
			HistoryClinical2Show = data.data;

			console.log("JSON Historia", { myHistoryClinicList });

			getData4Filters(myHistoryClinicList);

			// }
		} catch (getClinicHistoryError) {
			console.log({ getClinicHistoryError });
		} finally {
			loading = false;
		}
		console.timeEnd("buscarHistoryList");
	}

	let filterActivity = [];

	let filterSubCategory = [];
	let filterProfessional = [];
	let selectedProfessional = new Set();
	let selectedSubcategories = new Set();
	let selectActivities = new Set();
	let dropdownLabelActivity = 'Seleccione...';
	let dropdownSubcategoryLabel = 'Seleccione...';
	let dropdownProfessionalLabel = 'Seleccione...'; 

	const dispatch = createEventDispatcher();

	$: {
	updateDropdownsLabel();
	}

	function handleChangeActivity(event) {
	const { value, checked } = event.target;
	if (checked) {
		selectActivities.add(value);
	} else {
		selectActivities.delete(value);
	}
	selectActivities = new Set(selectActivities); // Force update
	dispatch('change', { selectActivities });
	updateDropdownsLabel();
	}

	
	function handleChangeSubCategories(event) {
		const { value, checked } = event.target;
		if (checked) {
			selectedSubcategories.add(value);
		} else {
			selectedSubcategories.delete(value);
		}
		updateDropdownsLabel();
	}
	function handleChangeProfessional(event) {
		const { value, checked } = event.target;
		if (checked) {
			selectedProfessional.add(value);
		} else {
			selectedProfessional.delete(value);
		}
		updateDropdownsLabel();
	}

	function updateDropdownsLabel() {
		if (selectActivities.size > 0) {
			const firstSelected = filterActivity.find(activity => selectActivities.has(activity[0]));
			if (firstSelected) {
				dropdownLabelActivity = firstSelected[1];
			}
		} else {
			dropdownLabelActivity = 'Seleccione...';
		}
		if (selectedSubcategories.size > 0) {
			const firstSelectedSubcategory = filterSubCategory.find(subcategory => selectedSubcategories.has(subcategory));
			if (firstSelectedSubcategory) {
				dropdownSubcategoryLabel = firstSelectedSubcategory; // Usa directamente la subcategoría
			}
		} else {
			dropdownSubcategoryLabel = 'Seleccione...';
		}

		if (selectedProfessional.size > 0) {
			const firstSelectedProfessional = filterProfessional.find(professional => selectedProfessional.has(professional));
			if (firstSelectedProfessional) {
				dropdownProfessionalLabel = firstSelectedProfessional; // Usa directamente el profesional
			}
		} else {
			dropdownProfessionalLabel = 'Seleccione...';
		}
	}
	
	function getData4Filters(dataHC) {
		console.log("llego a filters fetData4Filters");

		// Filtramos los datos y añadimos los tipos únicos a filterActivity

		// Recorro la HC (que viene por parametro) s
		dataHC.forEach((item) => {
			let activity_label;

			if (item.type === "i") {
				activity_label = "Cita / Entrevista";
			} else if (item.type === "e") {
				activity_label = "Estudio";
			} else if (item.type === "q") {
				activity_label = "Cuestionario";
			} else if (item.type === "m") {
				activity_label = "Medicamento";
			} else {
				activity_label = item.type;
			}

			if (!filterActivity.some(subArray => subArray[0] === item.type)) {
				filterActivity.push([item.type, activity_label]);
			}

			if (!filterSubCategory.includes(item.name)) {
				filterSubCategory.push(item.name); // Agrego la subcategoria (name) a el array filterSubCategory
			}
			let itemValue;
			if (item.evolvedByName === "" || item.evolvedByName == null) {
				itemValue = "N/A";
			} 
			else {
				itemValue= item.evolvedByName
			}
			if (!filterProfessional.includes(itemValue)) {
				filterProfessional.push(itemValue); // Agrego el profesional a el array filterProffessional
			}
		});

		filterActivity = [...new Set(filterActivity)];
		filterSubCategory = [...new Set(filterSubCategory)];
		filterProfessional = [...new Set(filterProfessional)];
		console.log("filter Activity", filterActivity)
	}

	function filterHC() {
		//TODO: Recorrer la historia clinica e ir filtrando segun los array de selected x
		console.log("Filtro de HC", myHistoryClinicList);
		let arraySubcategories = [...selectedSubcategories];
		let arrayProfessionals = [...selectedProfessional];
		
		if (selectActivities.size === 0 && selectedSubcategories.size === 0 && selectedProfessional.size === 0) {
            HistoryClinical2Show = myHistoryClinicList;
        } else {
		HistoryClinicFiltered = myHistoryClinicList.filter((item) => {
			let itemValue;
			if (item.evolvedByName === "" || item.evolvedByName == null) {
				itemValue = "N/A";
			} 
			else {
				itemValue= item.evolvedByName
			}
            return (
                (selectActivities.size === 0 || selectActivities.has(item.type)) && 
                (arraySubcategories.length === 0 || arraySubcategories.includes(item.name)) && 
                (arrayProfessionals.length === 0 || arrayProfessionals.includes(itemValue)) 
            );
        });
	
    

		// HistoryClinicFiltered = myHistoryClinicList.filter((item) => item.type === "i");
		console.log("Filtro de HC", HistoryClinicFiltered);
		
		HistoryClinical2Show = HistoryClinicFiltered;
	}	
	}

	 let viewMode = 'grid'; // 'grid' para la vista de grilla, 'list' para la vista de lista

	function toggleView() {
		viewMode = viewMode === 'grid' ? 'list' : 'grid';
	}
	async function handleViewDetails(story) {
		modalContent = 'loading';
		try{		
			if (story.type === 'q') {
			let dataResponses = story.resultsQuest;
			if (dataResponses.response.length > 0) {
				let auxResult = groupedResultsFormat(dataResponses.response[0].responseSet);
				resultsAnswer = auxResult;
				modalViewAnswer = true;
			}
			} else {
				modalOpen = true;
				selectedEvolution = story;
				modalContent = 'details';
				loading = false;
			}
		} catch (error) {
			console.error("Error en handleViewDetails:", error);
			modalContent = 'error';
   		 }	
		loading = false;
	}


	/* async function handleViewAttachment(story) {
		selectedEvolution = story;
		dataAdjunto = await utils_files_get(story.Id);
		console.log("dataAdjunto", {dataAdjunto})
		if (dataAdjunto.data.length > 0 && dataAdjunto.data[0].hasOwnProperty('link')) {
			type = dataAdjunto.data[0].link.split('/')[3].split('.')[1];
		} else {
			type = 'none';
		}
		modalContent = 'attachment';
		modalOpen = true;
	}

	function closeModal() {
		modalOpen = false;
		modalContent = '';
	} */

	async function handleViewAttachment(story) {
		modalContent = 'loading';
		modalOpen = true;
		selectedEvolution = story;		
		
		try{
			let dataAdjuntoAux = [];
			dataAdjuntoAux = await utils_files_get(story.Id);
			dataAdjunto = dataAdjuntoAux.data.data.files;			
			if (!dataAdjunto || dataAdjunto.length === 0) {
			
			modalContent = 'attachment'; 
			return; // si no hay adjuntos
			}
		} catch (error) {
			console.error("Error en handleViewAttachment:", error);
			modalContent = 'error';
		}

		//console.log("dataAdjunto", {dataAdjunto});
		
	/* 	if (dataAdjunto.data.length > 0 && dataAdjunto.data.hasOwnProperty('link')) { */
			/* type = dataAdjunto.data[0].link.split('/')[3].split('.')[1]; */
		/* 	console.log("Tiene link", {files})
			let files = dataAdjunto.data.files;
			type =  files.link.includes(".pdf") ? 'pdf' : 'document';
		} else {
			type = 'none';
		} */
		modalContent = 'attachment';
		loading = false; 
		return dataAdjunto;
	}

	function closeModal() {
		modalOpen = false;
		modalContent = '';
		dataAdjunto = [];
	}


</script>

<section>
	<div class="d-flex justify-content-between form-group shadow p-4 rounded myCustom-colorBlue">
		<div class="container">
			<div class="border-bottom text-muted">
				<h3>{$_("ClinicHistory.text_title")}</h3>
			</div>

			<div class="container_filter">
				<div class="date-info-clinic">
					<div style="display: flex;align-items: center;width: 80%; margin-right:10px">
						<label
							for="filtro-altas"
							style="
									width: 100px;
								">{$_("ClinicHistory.text_date_from")}:</label>

						<input
							id="fecha_desde"
							class="input-log"
							type="date"
							pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
							name="trip-start"
							bind:value={fechaDesde} />
						<!-- <input
							class="input-log"
							type="date"
							id="fecha_desde"
							pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
							name="trip-start" /> -->
					</div>
					<div style="display: flex;align-items: center;width: 80%;margin-right: 2%;">
						<label
							for="filtro-altas"
							style="
									width: 100px;
								">{$_("ClinicHistory.text_date_to")}:</label>

						<input
							class="input-log"
							id="fecha_hasta"
							type="date"
							pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
							name="trip-start"
							bind:value={fechaHasta} />
						<!-- <input
							class="input-log"
							type="date"
							id="fecha_hasta"
							pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
							name="trip-start" /> -->
					</div>
					<div style="display: flex;align-items: center;width: 80%;margin-right: 2%;">
			   <!-- <button id="buscar" class="btnBlue" style="padding: 10px 20px;" on:click={buscar()} disabled={loading}>
						{loading ? "Cargando..." : $_("ClinicHistory.button_search")}
					</button> -->
					<button id="buscar" class="buscar-btn btnBlue" on:click={buscar()}>
						{$_("ClinicHistory.button_search")}
					    </button>

					</div>
					
				</div>

				<div class="date-info" style="width: 30%;">
						
					<div style="display: flex; justify-content: flex-end; gap:5px;">
					
						<!-- <button
							id="buscar"
							class="btnBlue"
							on:click={async () => {
								//Invalid date: 2022-12-23
								let fecha_desde_parseada =
									document.querySelector("#fecha_desde").value.length > 0
										? `${document.querySelector("#fecha_desde").value.split("-")[2]}/${
												document.querySelector("#fecha_desde").value.split("-")[1]
											}/${document.querySelector("#fecha_desde").value.split("-")[0]}`
										: "";
								let fecha_hasta_parseada =
									document.querySelector("#fecha_hasta").value.length > 0
										? `${document.querySelector("#fecha_hasta").value.split("-")[2]}/${
												document.querySelector("#fecha_hasta").value.split("-")[1]
											}/${document.querySelector("#fecha_hasta").value.split("-")[0]}`
										: "";
								await generic_clinical_history_get(
									$patient.Id,
									fecha_desde_parseada,
									fecha_hasta_parseada,
									"",
									"",
									//document.querySelector("#tipo_actividad").value,
									//document.querySelector("#sub_categoria").value,
									document.querySelector("#profesional").value,
									$language
								)
									.then((data) => {
										console.log(data);
										if (data.statusCode == 404 || data.error == true) {
											myHistoryClinicList = [];
											errorMessage = data.message;
										} else {
											myHistoryClinicList = data.data;
											console.log("JSON Historia", { myHistoryClinicList });
										}
									})
									.catch((getClinicHistoryError) => {
										console.log({ getClinicHistoryError });
									})
									.finally(() => {
										loading = false;
									});
							}}>{$_("ClinicHistory.button_filter")}</button> -->
										
					</div>
				</div>
			</div>
			<div class="container_filter">
				<div class="date-info-clinic">
					<div style="display: flex;align-items: center;width: 80%;margin-right: 28px;">
						<label for="filtro-altas" style="width: 100px;">{$_("ClinicHistory.text_activity")}:</label>
						<div class="dropdown">
							<button
								class="input-log"
								type="button"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								{dropdownLabelActivity}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownSubcategories"
								style="width: 200px; max-height: 120px; overflow-y: auto;">
								{#each filterActivity as activity}
									<div class="dropdown-item">
										<label>
											<input
												type="checkbox"
												value={activity[0]}
												checked={selectActivities.has(activity[0])}
												on:change={handleChangeActivity} />
											{activity[1]}
										</label>
									</div>
								{/each}
							</div>
						</div>
					</div>


					<div style="display: flex; align-items: center; width: 80%; margin-right: 10px;">
						<label for="subcategories" style="width: 100px;">Subcategoría:</label>
						<div class="dropdown">
							<button
								class="input-log"
								type="button"
								id="dropdownSubcategories"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								{dropdownSubcategoryLabel}
							</button>
							<div
								class="dropdown-menu"
								aria-labelledby="dropdownSubcategories"
								style="width: 350px; max-height: 240px; overflow-y: auto;">
								{#each filterSubCategory as subcategory}
									<div class="dropdown-item">
										<label>
											<input
												type="checkbox"
												value={subcategory}
												checked={selectedSubcategories.has(subcategory)}
												on:change={handleChangeSubCategories} />
											{subcategory}
										</label>
									</div>
								{/each}
							</div>
						</div>
					</div>
				</div>
				<div style="display: flex;align-items: center;width: 80%;margin-right: 2%; gap:4px">
					<label for="filtro-altas">{$_("ClinicHistory.text_Professional")}:</label>
					<div class="dropdown">
						<button
							class="input-log"
							type="button"
							id="dropdownMenuButton"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false">
							{dropdownProfessionalLabel}
						</button>
						<div
							class="dropdown-menu"
							aria-labelledby="dropdownProfessional"
							style="width: 200px; max-height: 120px; overflow-y: auto;">
							{#each filterProfessional as professional}
								<div class="dropdown-item">
									<label>
										<input
											type="checkbox"
											value={professional}
											checked={selectedProfessional.has(professional)}
											on:change={handleChangeProfessional} />
										{professional}
									</label>
								</div>
							{/each}
						</div>
					</div>
					<div class="filter-hist" style="margin:10px;">
						<button id="filter-hist" class="filter-hist-btn btnBlue" on:click={filterHC}>
							{$_("ClinicHistory.button_filter")}
						</button>
					</div>
				</div>
				<div class="date-info" style="width: 30%;">
					<div style="display: flex; justify-content: flex-end; gap:5px;">	
						<div class="view-toggle">
							<button class="viewGrid-list-btn btnBlue" on:click={toggleView}>
							   {viewMode === 'grid' ? $_("ClinicHistory.button_viewList") : $_("ClinicHistory.button_viewGrid")}
							</button>
						</div>
						
						<button
							id="download-hist"
							class="download-hist-btn btnBlue"
							on:click={async () => {
								console.log("Descargar Historial");
								generateHistoryPDF_FromJSon(myHistoryClinicList); // Llama a la función para generar el PDF del historial clínico
							}}>
							{$_("ClinicHistory.button_download")}
						</button>
					</div>
				</div>
			</div>
		</div>
			
	</div>

	{#if viewMode === 'grid'}
		<div class="container container-history-grid">
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<!-- list -->
			{#if myHistoryClinicList.length > 0}
				<div class="header-container-sticky">
					<div style="width: 17vw;">
						<small
							class="text-muted"
							style="margin-left: 4%;
							"
							>{$_("ClinicHistory.text_activity")}

							{#if !ascendente}
								<svg
									class="cursor-pointer"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									title="Desc"
									on:click={() => {
										sortingParameter = "txt";
										columnSorting = "Activity";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
								</svg>
							{:else}
								<svg
									class="cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									title="Asc"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									on:click={() => {
										sortingParameter = "txt";
										columnSorting = "Activity";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
								</svg>
							{/if}
						</small>
					</div>
					<div style="width: 15vw;">
						<small class="text-muted"
							>{$_("ClinicHistory.text_Subcategory")}

							{#if !ascendente}
								<svg
									class="cursor-pointer"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									title="Desc"
									on:click={() => {
										sortingParameter = "txt";
										columnSorting = "Subcategory";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
								</svg>
							{:else}
								<svg
									class="cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									title="Asc"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									on:click={() => {
										sortingParameter = "txt";
										columnSorting = "Subcategory";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
								</svg>
							{/if}
						</small>
					</div>

					<div style="width: 16vw;">
						<small class="text-muted"
							>{$_("ClinicHistory.text_date")}
							{#if !ascendente}
								<svg
									class="cursor-pointer"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									title="Desc"
									on:click={() => {
										sortingParameter = "Fecha";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
								</svg>
							{:else}
								<svg
									class="cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									title="Asc"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									on:click={() => {
										sortingParameter = "Fecha";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
								</svg>
							{/if}
						</small>
					</div>
					<div style="width: 17vw;">
						<small class="text-muted"
							>{$_("ClinicHistory.text_createdby")}

							{#if !ascendente}
								<svg
									class="cursor-pointer"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									title="Desc"
									on:click={() => {
										sortingParameter = "txt";
										columnSorting = "createdby";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
								</svg>
							{:else}
								<svg
									class="cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									title="Asc"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									on:click={() => {
										sortingParameter = "txt";
										columnSorting = "createdby";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
								</svg>
							{/if}
						</small>
					</div>
					<div style="width: 14vw;">
						<small class="text-muted"
							>{$_("ClinicHistory.text_evolved_by")}

							{#if !ascendente}
								<svg
									class="cursor-pointer"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									title="Desc"
									on:click={() => {
										sortingParameter = "txt";
										columnSorting = "evolved_by";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
								</svg>
							{:else}
								<svg
									class="cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									title="Asc"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									on:click={() => {
										sortingParameter = "txt";
										columnSorting = "evolved_by";
										ascendente = !ascendente;
										ordenarLista();
									}}>
									<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
								</svg>
							{/if}
						</small>
					</div>

					<div>
						<small class="text-muted">{$_("ClinicHistory.text_viewDetails")} </small>
					</div>

				</div>
			{/if}
			<div>
				{#if loading}
					<div class="p-2 text-center">
						<Loader text={false} />
						<p class="text-muted">{$_("ClinicHistory.loading")}</p>
					</div>
				{:else if HistoryClinical2Show.length == 0}
					<div class="py-3 text-center">
						<p class="m-0 text-muted">{$_("ClinicHistory.text_not_history")}</p>
					</div>
				{:else}
					<div class="my-2">
						{#each HistoryClinical2Show as story}
							<div class="d-flex justify-content-between myCustom-clinicHistory p-2 my-1">
								<p class="m-0" style="width: 14vw;">
									{complexItemsStatus[story.type]}
								</p>
								<p class="m-0" style="width: 13vw;">
									{story.name ? story.name : "not data"}
								</p>
								<p class="m-0" style="width: 14vw;">{formatDate(story.dateCompleted, $language)}</p>

								<p class="m-0" style="width: 14vw;">
									{story.createdByName ? story.createdByName : "Sin comentarios"}
								</p>
								<p class="m-0" style="width: 14vw;">
									{story.evolvedByName ? story.evolvedByName : "Sin definir"}
								</p>
								
								
								<!-- 	<button
										on:click={async () => {
											if (story.type == "q") {
												let dataResponses = "";
												dataResponses = story.resultsQuest;
												if (dataResponses.response.length > 0) {
													let auxResult = groupedResultsFormat(dataResponses.response[0].responseSet);
													resultsAnswer = auxResult;
													modalViewAnswer = true;
												}
											} else {
												type = "";
												selectedEvolution = story;
												modalEvolution = true;
												dataAdjunto = await utils_files_get(story.Id);
												console.log("dataAdjunto", {dataAdjunto})
												if (
													dataAdjunto.data.length > 0 &&
													dataAdjunto.data[0].hasOwnProperty("Link__c")
												) {
													type = dataAdjunto.data[0].Link__c.split("/")[3];
													type = type.split(".")[1];
													console.log("typeData",{ type });
												} else {
													type = "none";
												}
											}
										}}
										style="background: none; border: none; padding: 0; cursor: pointer;">
										<img
											class="cursor-pointer"
											id="passworImag"
											src="/img/show-password1.png"
											alt="#!"
											style="heigth:25px;" />
									</button> -->

									<!-- <button
									on:click={async () => {
										if(type= "pdf")
										selectedEvolution = story;
										modalDownload = true;
										dataAdjunto = await utils_files_get(story.Id);
												console.log("dataAdjunto", {dataAdjunto})
												if (
													dataAdjunto.data.length > 0 &&
													dataAdjunto.data[0].hasOwnProperty("Link__c")
												) {
													type = dataAdjunto.data[0].Link__c.split("/")[3];
													type = type.split(".")[1];
													console.log("typeData",{ type });
												} else {
													type = "none";
												}
											
									}}
									style="background: none; border: none; padding: 0; cursor: pointer;">
									<img
										class="cursor-pointer"
										id="attach-clip"
										src="/img/attach-clip.png"
										alt="#!"
										style="height:20px;" />
									</button> -->
								<div>
									<button on:click={() => handleViewDetails(story)} style="background: none; border: none; padding: 0; cursor: pointer;">
										<img class="cursor-pointer" id="passworImag" src="/img/show-password1.png" alt="View Details" style="height:25px;" />
									  </button>
									  <button on:click={() => handleViewAttachment(story)} style="background: none; border: none; padding: 0; cursor: pointer;">
										<img src="/img/attach-clip.png" alt="View Attachment" style="height:20px;" />
									  </button>
								</div>		  
									
							</div>
						{/each}
					</div>
				{/if}
			</div>

			<div class="my-2 border-top">
				<!-- <a class="mx-2" href="#/Plan">Plan</a> -->
			</div>
		</div>
			{:else}
			<div class="container container-history-list">

			{#if loading}
				<div class="p-2 text-center">
				<Loader text={false} />
				<p class="text-muted">{$_("ClinicHistory.loading")}</p>
				</div>
			{:else if HistoryClinical2Show.length == 0}
				<div class="py-3 text-center">
				<p class="m-0 text-muted">{$_("ClinicHistory.text_not_history")}</p>
				</div>
			{:else}

			{#each HistoryClinical2Show as story}
				<div style="position: relative;"> 
					
					<!-- {#if viewMode === 'list' && story.type === 'q'} -->
					{#if viewMode === 'list'}
						<div class="item-actions"> 
							<!-- <div>
								<button
									on:click={async () => {
										if (story.type == "q") {
												let dataResponses = "";
												dataResponses = story.resultsQuest;
												if (dataResponses.response.length > 0) {
													let auxResult = groupedResultsFormat(dataResponses.response[0].responseSet);
													resultsAnswer = auxResult;
													modalViewAnswer = true;
												}
											} else {
												type = "";
												selectedEvolution = story;
												modalEvolution = true;
												dataAdjunto = await utils_files_get(story.Id);
												if (
													dataAdjunto.data.length > 0 &&
													dataAdjunto.data[0].hasOwnProperty("Link__c")
												) {
													type = dataAdjunto.data[0].Link__c.split("/")[3];
													type = type.split(".")[1];
													console.log({ type });
												} else {
													type = "none";
												}
											}
									}}
									class="view-details-btn"
									style="background: none; border: none; padding: 0; cursor: pointer;">
										<img
											class="cursor-pointer"
											id="passworImag"
											src="/img/show-password1.png"
											alt="#!"
											style="heigth:25px;"/>							
								</button>
							</div>
							<div>
								<button
									on:click={async () => {
									    type = ""; 
										selectedEvolution = story;
										modalEvolution = true;

										const dataAdjunto = await utils_files_get(story.Id);
										
										if (
											dataAdjunto.data.length > 0 &&
											dataAdjunto.data[0].hasOwnProperty("Link__c")
										) {
											type = dataAdjunto.data[0].Link__c.split("/")[3];
											type = type.split(".")[1];
											console.log({ type });
										} else {
											type = "none";
										} 
									}}
									class="view-details-btn"
									style="background: none; border: none; padding: 0; cursor: pointer;">
										<img
											class="cursor-pointer"
											id="attach-clip"
											src="/img/attach-clip.png"
											alt="#!"
											style="height:20px"/>							
								</button>
							</div> -->
							{#if story.type === 'q'}
								<button on:click={() => handleViewDetails(story)} style="background: none; border: none; padding: 0; cursor: pointer;">
									<img src="/img/show-password1.png" alt="View Details" style="height:25px;" />
								  </button>
							{/if}
								<button on:click={() => handleViewAttachment(story)} style="background: none; border: none; padding: 0; cursor: pointer;">
								  <img src="/img/attach-clip.png" alt="View Attachment" style="height:20px;" />
								</button>
								
						</div>
					{/if}
						<div class="list-item" style="display: flex; justify-content: space-between;">
							<div class="item-content" style="flex: 1; padding-right:20px;">
								<p><strong>Fecha:</strong> {formatDate(story.dateCompleted, $language)}</p>
								<p><strong>{$_("ClinicHistory.text_activity")}:</strong> {complexItemsStatus[story.type]}</p>
							</div>
							<div class="item-content" style="flex: 1; padding-right:20px;">
								<p><strong>{$_("ClinicHistory.text_createdby")}:</strong>{story.createdByName || "Sin comentarios"}</p>
								<p><strong>{$_("ClinicHistory.text_Subcategory")}:</strong>{story.name || "Sin datos"}</p>
							</div>
						</div>
						<div>
							<p><strong>{$_("ClinicHistory.text_evolved_by")}:</strong> {story.evolvedByName || "Sin definir"}</p>
						</div>
						<div>
							<p><strong>{$_("ClinicHistory.text_title_Evolution")}</strong> {story.evolution || "Sin definir"}</p>
						</div>					
						
						<hr style="margin: 10px 0; border: 1px solid #ccc;" />
					</div>					
			{/each}
			{/if}

			</div>
		{/if}

</section>
{#if modalOpen}
	<LayoutModal isOpenModal={modalOpen} tam="40" on:closeModal={closeModal}>
		<div class="p-3">
			{#if modalContent === 'loading'}
					<div class="p-2 text-center">
						<Loader text={false} />
						<p class="text-muted">{$_("ClinicHistory.loading")}</p>
					</div>
				{:else if modalContent === 'details'}
					<h4>{$_("ClinicHistory.text_title_Evolution")}:</h4>
					<small>{$_("ClinicHistory.text_Made_by_the")} {selectedEvolution.evolvedByName || "no data"}:</small>
					<div class="container bg-white border p-2 rounded shadow">
					{#if selectedEvolution.diagnosis || selectedEvolution.evolution}
						<p class="m-0">{selectedEvolution.evolution || selectedEvolution.diagnosis}</p>
					{:else}
						<p class="m-0">{$_("ClinicHistory.text_not_evolution")}</p>
					{/if}
					</div>
				{:else if modalContent == 'attachment'}
					<div class="container bg-white border p-2 rounded shadow">
					<h4>{$_("ClinicHistory.text_attach")}:</h4>
					<!-- {#if type !== "pdf" && dataAdjunto.data.length > 0 && dataAdjunto.data[0].Link__c}
						<img src={dataAdjunto.data[0].Link__c} alt="Document" width="20%" height="20%" />
						<a href={dataAdjunto.data[0].Link__c} download>{$_("ClinicHistory.button_download")}</a>
					{:else if type === "pdf" && dataAdjunto.data.length > 0 && dataAdjunto.data[0].Link__c}
						<img src="img/pdfIcon.png" alt="PDF" width="5%" height="5%" />
						<a href={dataAdjunto.data[0].Link__c} download>{$_("ClinicHistory.button_download")}</a>
					{:else}
						<small>{$_("ClinicHistory.text_not_attach")}</small>
					{/if} -->

						{#if dataAdjunto && dataAdjunto.length > 0}
							{#each dataAdjunto as file}
								<img
								class="cursor pointer"
								on:click={() => {}}
								src="img/pdfIcon.png"
								alt="PDF"
								width="3%" />
							<a href={file.link} download>{$_("ClinicHistory.button_download")}</a>
						{/each}
					{:else}
						<small>{$_("ClinicHistory.text_not_attach")}</small>
					{/if}
				</div>
			{/if}
		<div>
			<br />
			<button on:click={closeModal} class="btnWhite py-2">{$_("ClinicHistory.button_close")}</button>
		</div>
		</div>
	</LayoutModal>
{/if}


{#if modalViewAnswer}
	<LayoutModal
		tam="60"
		isOpenModal={modalViewAnswer}
		on:closeModal={() => {
			modalViewAnswer = false;
		}}>
		<div id="pdf-content" class="p-3 text-start">
			<!-- {#if resultsAnswer && resultsAnswer.length > 0} -->
			<div>
				<h2 class="text-color" style="color: #112c4a;">Resultados del cuestionario</h2>
				<hr style="border: 1px dashed #ccc;" />

				<h4 class="text-color" style="color: #112c4a;">Respuestas:</h4>
				<!-- modificar este bloque para que utilice la estructura nueva -->
				{#each resultsAnswer.filter((resultado) => resultado.questionType != "inform") as resultado}
					{#if resultado.questionType === "rta" && resultado.answerTexts.length > 0}
						<div><strong>{resultado.questionText}</strong></div>
						{#each resultado.answerTexts as answer}
							{#if answer != "Continuar." && answer != "Continuar"}
								<div>>>> {answer}</div>
							{/if}
						{/each}
						<hr style="border: 1px dashed #ccc;" />
					{/if}

					{#if resultado.questionType === "grid" && resultado.options.length > 0}
						<div><strong>{resultado.questionDescription}</strong></div>

						<table class="table table-bordered table-striped">
							<tbody>
								{#each resultado.options as option}
									<tr>
										<td>
											<div class="card-shadow rounded-xl" style="font-size: 10px;">
												<div class="inline-flex items-start">
													<div class="flex items-center gap-3">
														<div>
															<div
																class="w-7 h-7 rounded-full flex items-center justify-center"
																style="width: 20px; background-color: rgb(40, 114, 161);">
															</div>
														</div>
														<div
															class="text-color"
															style="
														color: #215273;
														font-family: &quot;Roboto&quot;, sans-serif;
														font-size: 12px;
														font-weight: bold;
													">
															{option.questionText}
														</div>
													</div>
												</div>
											</div>
										</td>
										<td>
											<div class="">
												<div class="inline-flex items-start">
													<div class="flex items-center gap-3">
														<div
															class="text-color"
															style="margin-left: 4%; font-size: 12px;">
															<!-- Mostrar cada respuesta en una nueva línea -->
															{option.answerTexts[0]}
														</div>
													</div>
												</div>
											</div>
										</td>
									</tr>
								{/each}
							</tbody>
						</table>
						<hr style="border: 1px dashed #ccc;" />
					{/if}
				{/each}

				{#if resultsAnswer.filter((resultado) => resultado.questionType === "inform").length > 0}
					<h4 class="text-color" style="color: #112c4a;">Informe de resultados:</h4>

					{#each resultsAnswer.filter((resultado) => resultado.questionType === "inform") as resultado}
						{#if resultado.answerTexts && resultado.answerTexts.length > 0}
							<div><strong>{resultado.questionText.slice(1)}</strong></div>
							{#each resultado.answerTexts as answer}
								{#if answer != "Continuar." && answer != "Continuar"}
									<div>>>> {answer}</div>
								{/if}
							{/each}
						{/if}
					{/each}
				{/if}

				<div class="button-container">
					<button
						on:click={() => {
							modalViewAnswer = false;
						}}
						class="btnClose btn btn-secondary">Cerrar</button>
					<button on:click={generatePDF} class="btnClosepdf">Descargar PDF</button>
					<!-- Botón para generar el PDF -->
				</div>
			</div>
			<!-- {:else if error !== null}
			<p>Ocurrió un error al obtener los resultados: {error}</p>
		{:else}
			<p>Cargando...</p>
		{/if} -->
		</div>
	</LayoutModal>
{/if}

<style>
	.button-container {
		display: flex;
		justify-content: center; /* Centra los botones horizontalmente */
		gap: 15px; /* Añade separación entre los botones */
		margin-top: 20px; /* Opcional: Añade margen superior para separación del contenido anterior */
	}

	.btnClose,
	.btnClosepdf {
		margin-top: 10px;
		letter-spacing: 1px;
		border-radius: 10px;
		color: #ffffff;
		background-color: var(--my-dark-blue);
	}
	.btnBlue {
		padding: 10px;
		min-width: 110px;
	}
	.header-container-sticky {
		position: sticky;
		top: 0;
		background-color: white;
		display:flex; justify-content: space-between;
	}
	.container-history-grid,
	.container-history-list {
		max-height: 700px;
		overflow-y: scroll;
		position: relative;
		padding-top: 0 !important;
		background-color: #fff !important;
		border-radius: 0.25rem !important;
		box-shadow: 0.5rem rgba(0, 0, 0, .15) !important;
		border: 1px solid #dee2e6;
	}
	#passworImag {
		height: 25px;
	}
	.myCustom-clinicHistory {
		border: solid 1px var(--my-dark-blue);
		border-radius: 10px;
	}
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}
	.input-log {
		margin: 1%;
		height: var(--height, 42px);
		padding-left: 9px;
		border-radius: 20px;
		letter-spacing: 1px;
		min-width: 150px; /* Ancho mínimo */
        white-space: nowrap; /* Evita que el texto se divida en varias líneas */
        overflow: hidden; /* Oculta el desbordamiento */
        text-overflow: ellipsis; /* Muestra "..." si el texto es demasiado largo */
	}

	.container_filter {
		display: flex;
		align-items: center;
		gap:10px;
	}
	.date-info-clinic {
		width: 100;
		margin: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		align-content: center;
	}

	.item-actions {
		display: flex;
		align-items: center;
		justify-content: end;
		position: absolute;
		top:5%;
		right: 10px;
		transform: translateX(-50%);
	}

</style>
