import { writable } from "svelte/store";

/**
 * User (Pacientes/Doctores)
 * Informacion personal del usuario que se encuentra logueado en la plataforma
 */

// src/helpers/storage/patientStore.js

export const dataPatient = writable({});

const storedUser = JSON.parse(localStorage.getItem("user")) || {};
export const user = writable(storedUser);
user.subscribe((val) => (localStorage.user = JSON.stringify(val)));

/* -------------------------------------------------------------------------------------------------- */
/* ----------------------------------------- APP DOCTORES ------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */

/**
 * Logged Doctor
 * Informacion del doctor que se encuentra logueado en la plataforma
 */
// const storedLoggedDoctor = localStorage.getItem("loggedDoctor") || "{}";
// export const loggedDoctor = writable(storedLoggedDoctor)
// loggedDoctor.subscribe((val) => { localStorage.loggedDoctor = JSON.stringify(val) })

/**
 * Doctor
 * En esta store guardaremos la data correspondiente a doctores seleccionados para asignar a pacientes como actividades
 */
const storedDoctor = JSON.parse(localStorage.getItem("doctor")) || {};
export const doctor = writable(storedDoctor);
doctor.subscribe((val) => (localStorage.doctor = JSON.stringify(val)));

/**
 * Language
 *
 */
const storedLanguage = JSON.parse(localStorage.getItem("language")) || {};
export const language = writable(storedLanguage);
language.subscribe((val) => (localStorage.language = JSON.stringify(val)));

/**
 * Alta
 */
const storedAlta = JSON.parse(localStorage.getItem("alta")) || {};
export const alta = writable(storedAlta);
alta.subscribe((val) => (localStorage.alta = JSON.stringify(val)));

/**
 * Alta
 */
const storedCantAlta = JSON.parse(localStorage.getItem("CantAlta")) || {};
export const CantAlta = writable(storedCantAlta);
alta.subscribe((val) => (localStorage.CantAlta = JSON.stringify(val)));

/**
 * Paciente
 */
const storedPatient = JSON.parse(localStorage.getItem("patient")) || {};
export const patient = writable(storedPatient);
patient.subscribe((val) => (localStorage.patient = JSON.stringify(val)));

/**
 * Paciente
 */
const storedPatientLayout = JSON.parse(localStorage.getItem("patientLayout")) || {};
export const patientLayout = writable(storedPatientLayout);
patient.subscribe((val) => (localStorage.patientLayout = JSON.stringify(val)));

/**
 * Carer
 */
const storedCarer = JSON.parse(localStorage.getItem("carer")) || {};
export const carer = writable(storedCarer);
carer.subscribe((val) => (localStorage.carer = JSON.stringify(val)));

/**
 * RegisterDetail
 * Save detail with header to edit
 */
const storedRegisterDetail = JSON.parse(localStorage.getItem("registerDetail")) || {};
export const registerDetail = writable(storedRegisterDetail);
registerDetail.subscribe((val) => (localStorage.registerDetail = JSON.stringify(val)));

/**
 * RegisterDetail
 * Save detail with header to edit
 */
const storedSession = JSON.parse(localStorage.getItem("selectedSession")) || {};
export const selectedSession = writable(storedSession);
selectedSession.subscribe((val) => (localStorage.selectedSession = JSON.stringify(val)));

/**
 * Selected Patient (Session)
 */
const storedSessionPatient = JSON.parse(localStorage.getItem("selectedSessionPatient")) || {};
export const selectedSessionPatient = writable(storedSessionPatient);
selectedSessionPatient.subscribe((val) => (localStorage.selectedSessionPatient = JSON.stringify(val)));

/* -------------------------------------------------------------------------------------------------- */
/* ----------------------------------------- APP PACIENTES ------------------------------------------ */
/* -------------------------------------------------------------------------------------------------- */

const storedRememberMe = localStorage.getItem("rememberMe") === "true";
export const rememberMe = writable(storedRememberMe);
rememberMe.subscribe((val) => (localStorage.rememberMe = val));

const storedRememberedUsername = localStorage.getItem("rememberedUsername") || "";
export const rememberedUsername = writable(storedRememberedUsername);
rememberedUsername.subscribe((val) => (localStorage.rememberedUsername = val));

const storedRememberedPassword = localStorage.getItem("rememberedPassword") || "";
export const rememberedPassword = writable(storedRememberedPassword);
rememberedPassword.subscribe((val) => (localStorage.rememberedPassword = val));
