<script>
	import { onMount } from "svelte";
	import Modal from "../../../components/Modal.svelte";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import { patient, registerDetail, user, language } from "../../../helpers/storage/stores";
	//TODODELETE import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	import AddMedications from "../../../components/addMedications.svelte";
	import Loader from "../../../components/Loader.svelte";
	import Subit from "./Subit.svelte";
	import {
		utils_resources_get,
		generic_activities_get,
		professional_interview_update,
		professional_exam_update,
		professional_questionnaire_update,
		//TODODELETE generic_medication_get_List,
		//TODODELETE ROLES,
	} from "../../../helpers/apiBackend";
	import { _ } from "svelte-i18n";
	import ListRecord from "../../../components/Lists/List_Records.svelte";

	let myRecords = [];
	let dataPatient = [];
	let complexItems = [];
	let optionsSubit = [];
	let specialtyOptions = [];
	let frequencyOptions = [];
	let modalityOptions = [];

	let myType, modalEditHeader, type;
	let headerToEdit = {};

	let frequencies = {};
	let loading = true;
	let myPatient = "";
	let myUserId = $user.Id;
	let account_id = $user.Id;
	let myPlan = "";
	let [modalLoading, modalError, modalSuccess, modaladd, modalAddSubit] = [false, false, false, false, false];

	function closeSubForm() {
		modalAddSubit = false;
	}

	onMount(async () => {
		try {
			// console.log({ $patient });
			//const result = await generic_account_get("", $patient.Id, ROLES.patient);
			modalSuccess = false;
			//dataPatient = result;

			// console.log("utils_resources_get - Records");
			frequencies = await utils_resources_get("frecuencies", $language);
		
			complexItems = $_("RecordDoctor.select_activity");

			myPatient = $patient.Id;
			myPlan = $patient.careplan_id;

			// console.log({ myPatient });
			// console.log({ myPlan });
			// console.log({ myUserId });

			$registerDetail = {};
			myType = document.location.href.split("?")[1].split("=")[1];
			//console.log(myType);

			if (myType == "Citas/Entrevistas") {
				type = myType.replace("/", "");
			} else if (myType == "Medicamentos") {
				type = "Medicacion";
			} else {
				type = myType;
			}

			const res = await generic_activities_get(myPatient, type, myPlan, account_id, $language);
			//const res2 = await generic_activities_get(myPatient, type, myPlan, $language);
			// console.log({ res });
			//console.log({ res2 });

			let auxRecord = [];

			if (res.hasOwnProperty("data")) {
				myRecords = res.data;
				// console.log({ myRecords });
				// console.log('pre carga Actividad_Detalles__r');
				if (res.data.hasOwnProperty("archivos")) {
					const fileA = res.data.archivos;
					//console.log({ fileA });

					myRecords.forEach((element) => {
						if (element.hasOwnProperty("Actividad_Detalles__r")) {
							//console.log("Actividad_Detalles__r");
							const fileAd = element.Actividad_Detalles__r.records;
							//console.log({ fileAd });
							// console.log('bloque  Actividad_Detalles__r fileAd.forEach');
							fileAd.forEach((item) => {
								fileA.forEach((itemD) => {
									if (itemD.Id == item.Id) {
										//console.log({ itemD });
										const ad = itemD.Archivos_S3__r.records;
										ad.forEach((element) => {
											item.Link__c = element.Link__c;
										});
									}
								});
							});
						}
					});
				}
			} else {
				myRecords = res;
			}

			//console.log({ myRecords });

			// if (myType == "Citas/Entrevistas" && myRecords.length > 0) {
			// 	myRecords.forEach((record) => {
			// 		//console.log({ record });

			// 		if (record.hasOwnProperty("Actividad_Detalles__r")) {
			// 			const actividad = record.Actividad_Detalles__r.records;
			// 			const auxelemnt = [];
			// 			//console.log({ actividad });

			// 			actividad.forEach((element) => {
			// 				//console.log(element.Creado_Por__r.Id);

			// 				if (
			// 					element.Creado_Por__r.Id == myUserId ||
			// 					(element.hasOwnProperty("Profesional_Asignado__r") &&
			// 						element.Profesional_Asignado__r.Id == myUserId)
			// 				) {
			// 					auxelemnt.push(element);
			// 				}
			// 			});

			// 			record.Actividad_Detalles__r.records = auxelemnt;

			// 			if (record.Actividad_Detalles__r.records.length > 0) {
			// 				auxRecord.push(record);
			// 			}
			// 		}
			// 	});

			// 	myRecords = auxRecord;
			// }
			optionsSubit = myRecords.map((record) => ({
				Id: record.Id,
				name: record.name,
				modality: record.modality,
				frecuency: record.frecuency,
				specification: record.specification,
			}));

			// specialtyOptions = Array.from(
			// 	new Set(optionsSubit.map((record) => ({ id: record.id, name: record.name })))
			// );
			// frequencyOptions = Array.from(
			// 	new Set(
			// 		optionsSubit.map((record) => ({
			// 			id: record.id,
			// 			name: record.frecuency,
			// 			specialtyName: record.name,
			// 		}))
			// 	)
			// );
			// modalityOptions = Array.from(
			// 	new Set(optionsSubit.map((record) => ({ id: record.id, name: record.modality })))
			// );

			loading = false;
			// console.log({ myRecords });
			// console.log({ frequencyOptions });
			// console.log({ modalityOptions });
		} catch (error) {
			console.error(error);
		}
	});

	// Edit Header
	const editHeader = async () => {
		modalLoading = true;
		modalEditHeader = false;
		// console.log("Llamado a UPDATE", headerToEdit);
		let bodyPatchHeader = {
			Id: headerToEdit["Id"],
			frecuency: headerToEdit["frecuency"],
			active: headerToEdit["active"],
		};

		if (myType == "Citas/Entrevistas") {
			// console.log("professional_interview_update");
			// console.log({ bodyPatchHeader });
			professional_interview_update(bodyPatchHeader)
				.then((dataPacht) => {
					console.log("Tipo de Rta Edit Header",dataPacht);
					if (dataPacht.statusCode == 200) { 
						modalSuccess = true;
						modalLoading = false;
					} else {
						modalError = true;
					}
					// console.log({ dataPacht });
				})
				.catch((errorPatch) => {
					console.log({ errorPatch });
					modalError = true;
				});
		}
		if (myType == "Estudios") {
			professional_exam_update(headerToEdit["id"], bodyPatchHeader)
				.then((dataPacht) => {
					if (dataPacht.statusCode == 200) {
						modalSuccess = true;
						modalLoading = false;
					} else {
						modalError = true;
					}
					// console.log("Data estudios",{ dataPacht });
				})
				.catch((errorPatch) => {
					console.log({ errorPatch });
					modalError = true;
				});
		}
		if (myType == "Cuestionarios") {
			professional_questionnaire_update(headerToEdit["Id"], bodyPatchHeader)
				.then((dataPacht) => {
					if (dataPacht.status == 200) {
						modalSuccess = true;
						modalLoading = false;
					} else {
						modalError = true;
					}
					// console.log({ dataPacht });
				})
				.catch((errorPatch) => {
					console.log({ errorPatch });
					modalError = true;
				});
		}
	};

	// Handle Modal Edit Header
	let originalHeader;
	let diffChanges = false;
	$: {
		if (modalEditHeader) {
			if (originalHeader == undefined) {
				originalHeader = JSON.parse(JSON.stringify(headerToEdit));
				// console.log({ originalHeader });
				// console.log({ headerToEdit });
			} else {
				if (JSON.stringify(originalHeader) != JSON.stringify(headerToEdit)) {
					diffChanges = true;
				} else {
					diffChanges = false;
				}
			}
		} else {
			if (originalHeader != undefined) {
				originalHeader = undefined;
			}
		}
	}
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<!-- <LayoutPatient> -->
	<div class="container">
		<div>
			<div class="border-bottom text-center">
				{#if myType}
					<h3 class="text-muted">{complexItems[myType]}</h3>
				{/if}
			</div>
		<!--	COMENTADO PARA QUE NO SE VEA BOTON CREAR CITAS/ENTREVISTAS 
			<div class="border-bottom d-flex" style="justify-content: space-between;">
				{#if complexItems[myType]}
					<div style="text-align: end;">
						<button
							on:click={() => {
								window.location.href = `#/CreateActivity/${type}`;
							}}
							class="btnWhite"
							>{$_("RecordDoctor.textCreate")}
							{complexItems[myType]}</button>
					</div>
				{/if}
		-->
				{#if optionsSubit.length > 0}
					<div style="text-align: end;">
						<button
							on:click={() => {
								modalAddSubit = true;
							}}
							class="btnWhite">{$_("Subit.text_title_evolution")}</button>
					</div>
				{/if}
				<!-- {#if myType == "Citas/Entrevistas"}
							<h3 class="text-muted">{complexItems[myType]}</h3>
						{/if}
						{#if myType == "Medicamentos"}
							
						{:else}
							<div style="text-align: end;">
								{#if myType}
									<button
										on:click={() => {
											window.location.href = `#/CreateActivity/${type}`;
										}}
										class="btnWhite"
										>{$_("RecordDoctor.textCreate")}
										{complexItems[myType]}</button>
									
								{/if}
							</div>
						{/if} -->
			<!--</div>-->

			<div class="d-flex justify-content-between" />

			<div class="barraName d-flex border rounded">
				<span class="spName">{$_("ViewCuestionarioPatient.spName")}</span>
				<span class="spArea">{$_("ViewCuestionarioPatient.text_frequency")}</span>
				<span class="spStatus">{$_("ViewCuestionarioPatient.spStatus")}</span>
				<span class="spAction">{$_("ViewCuestionarioPatient.spAction")}</span>
			</div>

			<div class="d-flex justify-content-between" />

			<div class="records-list">
				{#if loading}
					<div class="p-3 text-center">
						<Loader text={false} />
						<p class="text-muted">{$_("RecordDoctor.loading")}</p>
					</div>
				{:else if myRecords.length > 0}
					{#each myRecords as record, i}
						<!-- New Component - Start -->
						<div class="my-2">
							<ListRecord
								theRecord={record}
								on:editHeader={() => {
									headerToEdit = record;
									modalEditHeader = true;
								}}
								on:editDetail={(e) => {
									$registerDetail["register"] = record;
									$registerDetail["detail"] = e.detail.myDetail;
									window.location.href = `#/RegisterDetail?type=${myType}&scope=detail`;
								}} />
						</div>
						<!-- New Component - End -->
					{/each}
				{:else}
					<div class="text-center p-2">
						<p class="m-0 text-muted">
							{$_("RecordDoctor.textNotcontain")}
							{complexItems[myType]}
						</p>
					</div>
				{/if}
			</div>
			<div class="d-flex m-3 border rounded justify-content-center">
				<!-- <p class="mx-2 m-0">Total: {myRecords.length}</p> -->
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Plan";
					}}>
					{$_("RecordDoctor.button_plan")}
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Doctor";
					}}>
					{$_("RecordDoctor.button_patient")}
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Altas";
					}}>
					{$_("RecordDoctor.button_Reception")}
				</p>
			</div>
		</div>
	</div>
	<!-- </LayoutPatient> -->
	<!-- </LayoutNav> -->
</section>

<!-- Modal Editar Header -->
<LayoutModal
	isOpenModal={modalEditHeader}
	on:closeModal={() => {
		modalEditHeader = false;
	}}>
	<div class="container">
		{#if headerToEdit.hasOwnProperty("name")}
			<div class="header-section p-3 text-center">
				<h5 class="text-muted">{$_("RecordDoctor.text_Edit")} {headerToEdit["name"]}</h5>

				<div class="status-section p-2">
					<div class="form-group">
						<div
							style="
    text-align: initial;
    width: 40%;
">
							<p class="m-0">{$_("RecordDoctor.text_status")}</p>
						</div>
						<div class="d-flex" style="display: flex;justify-content: space-between;margin-top: 2%;">
							<p class="m-0 mx-2">
								{#if headerToEdit.hasOwnProperty("active")}
									{#if headerToEdit["active"]}
										<!-- <i
											on:click={() => {
												headerToEdit["active"] = false;
											}}
											class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->

											<button
												on:click={() => {
													headerToEdit["active"] = false;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleon.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
									{:else}
										<!-- <i
											on:click={() => {
												headerToEdit["active"] = true;
											}}
											class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<button
												on:click={() => {
													headerToEdit["active"] = true;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleoff.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
									{/if}
								{/if}
							</p>
						</div>
					</div>
					{#if !headerToEdit.hasOwnProperty("Farmaco__c")}
						<div class="form-group">
							<div
								style="
    text-align: initial;
    width: 40%;
">
								<label for="frecuencia-edit">{$_("RecordDoctor.text_frequency")} &nbsp; &nbsp;</label>
							</div>
							<div>
								<!-- <small class="text-muted">{headerToEdit["frecuency"]}</small> -->
								<select
									bind:value={headerToEdit["frecuency"]}
									class="form-control form-control-sm"
									name="frecuencia-edit"
									id=""
									size="1">
									{#each frequencies as frecuencia}
										<option value={frecuencia.Id}>
											{frecuencia.label}
										</option>
									{/each}
									<!-- <option value="Al nacer/ Ingreso">Al nacer/ Ingreso</option> -->
								</select>
							</div>
						</div>
					{/if}
				</div>
			</div>
		{/if}
		<div class="button-section d-flex justify-content-center">
			{#if diffChanges}
				<button
					on:click={() => {
						editHeader();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("RecordDoctor.button_Send")}</button>
			{/if}
			<button
				class="py-0 mx-2 py-2 btnWhite"
				on:click={() => {
					modalEditHeader = false;
				}}>{$_("RecordDoctor.button_cancel")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Layout Modal -->
<Modal isOpenModal={modalLoading}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">{$_("RecordDoctor.loading")}</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<!-- <Modal isOpenModal={modalSuccess}>
	<div class="p-2 text-center">
		<p>{$_("RecordDoctor.text_message_succes")}</p>
		<button
			on:click={() => {
				window.location.reload();
			}}
			class="btnBlue py-0">{$_("RecordDoctor.button_close")}</button>
	</div>
</Modal> -->
<LayoutModal isOpenModal={modalSuccess}>
	<div class="p-2 text-center">
		<p>{$_("RecordDoctor.text_success")}</p>
		<div
			class="text-center"
			style="
    width: 100%;
    display: flex;
    /* text-align: center; */
    align-items: center;
    justify-content: center;
">
			<button
				on:click={() => {
					window.location.reload();
				}}
				class="btnBlueClosed py-0">{$_("RecordDoctor.button_close")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="p-3">
		<div>
			<p class="m-0">{$_("RecordDoctor.text_message_error")}</p>
		</div>
		<div>
			<br />
			<div class="text-alingt-center" style="text-align: center">
				<button
					class="btnWhite"
					on:click={() => {
						window.location.reload();
					}}>{$_("RecordDoctor.button_close")}</button>
			</div>
		</div>
	</div>
</LayoutModal>

<!-- Modal add medicines -->
<LayoutModal
	tam="20"
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<AddMedications {myUserId} {myPlan} {myPatient} />
</LayoutModal>

<!-- Modal add medicines -->
<LayoutModal
	tam="60"
	isOpenModal={modalAddSubit}
	on:closeModal={() => {
		modalAddSubit = false;
	}}>
	{#if optionsSubit.length > 0}
		<div>
			<Subit {optionsSubit} {myType} on:closeSubForm={closeSubForm} />
		</div>
	{/if}
</LayoutModal>

<!-- 
    [
    {
        "attributes": {
            "type": "RDCom_ActividadDetail__c",
            "url": "/services/data/v55.0/sobjects/RDCom_ActividadDetail__c/a1K8D000000I7ydUAC"
        },
        "Actividad__c": "a1M8D000000HmkFUAS",
        "Id": "a1K8D000000I7ydUAC",
        "Name": "Psiquiatra",
        "Paciente__c": "0018D00000FwAGhQAN",
        "Fecha_Sugerida__c": "2022-07-30",
        "Completado__c": false,
        "Creado_Por__c": "0018D00000Fw787QAB",
        "Solicitado__c": false,
        "nueva_Accion_sugerida__c": "Solicitar",
        "Etapa__c": "1",
        "Estado_Medico_de_cabecera__c": "Rojo",
        "Archivar__c": false,
        "Expirado__c": false,
        "Creado_Por__r": {
            "attributes": {
                "type": "Account",
                "url": "/services/data/v55.0/sobjects/Account/0018D00000Fw787QAB"
            },
            "Id": "0018D00000Fw787QAB",
            "Name": "Ignacio Salimeno"
        }
    }
]
 -->
<style>
	.records-list {
		max-height: 45vh;
		overflow: auto;
	}
	.form-group {
		width: 100%;
		display: flex;
	}
	.texlink {
		color: #215273;
	}

	.myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.barraName {
		background-color: #215273;
		height: 45px;
		color: #ffffff;
		align-items: center;
	}
	.spName {
		margin-left: 1%;
		width: 46%;
	}
	.btnBlueClosed {
		color: #215273;
		border-color: #215273;
		font-weight: 500;
		border-radius: 10px;
		background-color: #ffffff;
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		height: 38px;
	}
	.spArea {
		width: 34%;
	}
	.spStatus {
		width: 10%;
	}

	.myCustom-toggleOff {
		color: #ffffff;
		background-color: #6a91ab;
	}
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}
	@media only screen and (min-width: 960px) {
		/* styles for browsers larger than 960px; */
		.records-list {
			max-height: 40vh;
			overflow: auto;
		}
	}
	@media only screen and (min-width: 1440px) {
		/* styles for browsers larger than 1440px; */
		.records-list {
			max-height: 45vh;
			overflow: auto;
		}
	}
</style>
