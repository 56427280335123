<!-- 
  Validaciones Formulario Paciente
 -->
<script>
	// AWS
	import awsconfig from "../../aws-exports";
	import { Auth } from "aws-amplify";
	// import { user } from "../../helpers/storage/sessions";

	// Svelte
	import { replace } from "svelte-spa-router";
	import { onMount } from "svelte";
	//import Select from "svelte-select";
	//import { Modals, closeModal, openModal, modals } from "svelte-modals";

	// Helpers
	import { patient, language } from "../../helpers/storage/stores";
	import { currentSession } from "../../helpers/storage/sessions";
	import { getUserInfo } from "../../helpers/helpers.js";
	import { utils_files_add, utils_resources_get, patient_patient_add } from "../../helpers/apiBackend";
	import { patient_register_schema } from "../schema";
	import { getErrorsMessages } from "../../helpers/helpers";
	//import data from "../../helpers/prov.json";
	import { _ } from "svelte-i18n";

	// Components
	import Loader from "../../components/Loader.svelte";
	import FileAttach from "../../components/FileAttach.svelte";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import ConfirmationPatient from "../../components/ConfirmationPatient.svelte";

	// Config / Variables
	Auth.configure(awsconfig);
	let rol = ""; //params.rol;
	let username = "";
	let provincias;
	let patologias = [];
	let idTypeAttachment = "";
	let file_type = "Certificado patologia";
	let modalLoadingAnimation = false;
	let modalViewPatient = false;
	let myDiseases = "";
	let myDiseasesStatus = "";
	let dataArray = [];
	let complexItems = [];
	let finish = true;
	let imagStatus = true;
	//let typeAttachment = "Patologia_Detalle__c";
	// $_("PatientPathology.select_status");
	

	// Nico Validaciones ---------------------------------------------------------------------------------------------
	let posibles = ["diseases"];
	//let file_tmp;
	//let checkbox;
	const epofChange = (event) => {
		credentials.epofChange = event;
	};
	// Nico Validaciones ---------------------------------------------------------------------------------------------

	// Update User Status
	/**
	 * @description
	 */
	async function updateUserStatus(status) {
		//console.log("updateUserStatus");
		let user;
		await Auth.currentAuthenticatedUser()
			.then(async (resultado) => {
				user = resultado;
				await Auth.updateUserAttributes(user, {
					"custom:StatusReg": status,
				});
				//console.log('Resultado bueno: ',user);
			})
			.catch((err) => {
				//console.log('error', err);
				user = $currentSession;
			});
	}

	// Butoon Finish
	let errorMessages;
	let errorModal = false;
	/**
	 * @description
	 */
	async function btnFinish() {
		console.log({ $patient });
		try {
			$patient.diseases = dataArray;
			console.log("$patient.diseases");
			console.log($patient.diseases);
			modalLoadingAnimation = true;
			rol = $currentSession.attributes.profile;
			if (rol == "Paciente") {
				rol = "Patient";
			}
			username = $currentSession.attributes.email;
			console.log("Paciente: ", $patient);
			await patient_register_schema.validate($patient, { abortEarly: false }).then((response) => {
				(async () => {
					let saveData = await patient_patient_add($patient, $currentSession);
					console.log("saveData : ", saveData);
					return saveData;
				})().then(async (data) => {
					console.log({ data });
					if (data.statusCode == 201) {
						const id = data.data.AccountId;
						if ($patient.hasOwnProperty("fileAttPatologia")) {
							let fileAttPatologia = $patient.fileAttPatologia.detail.fileData;
							fileAttPatologia.forEach(async (element) => {
								console.log({ id });
								element.creator = id;
								element.attachment = id;
								//element.context.attachment.type = "Account__c";
								console.log("fileAttPatologia : ", element);
								await utils_files_add(element).then((data) => {
									console.log("utils_files_add : ", data);
									if (!data.hasOwnProperty("message")) {
										imagStatus = false;
									}
								});
							});
						}
						if ($patient.hasOwnProperty("fileAttFotoPerfil")) {
							let fileAttFotoPerfil = $patient.fileAttFotoPerfil.detail.fileData;
							fileAttFotoPerfil.forEach(async (element) => {
								element.creator = id;
								element.attachment = id;
								//element.context.attachment.type = "Account__c";
								console.log("fileAttFotoPerfil : ", element);
								await utils_files_add(element).then((data) => {
									if (!data.hasOwnProperty("message")) {
										imagStatus = false;
									}
								});
							});
						}

						try {updateUserStatus("true").then(async (data) => {
							let pat = $patient;
							console.log("DNIS",pat.DNI__c);
							let result = await getUserInfo(rol, username, pat.DNI__c);
							if (result) {
								replace("/Home");
							} else {
								window.location.href = "#/LoginError";
							}
							// $user["Estado_Medico_de_cabecera__c"] = "Aún no se eligió medico";
							// replace("/Home");
							// replace("/Patient/SearchDoctor?action=assignHeaderDoctor");
						});} catch (e) {
							window.location.href = "#/LoginError";
						}
						

						// Register Error
					} else {
						//console.log('There is an error with the user')
						modalLoadingAnimation = false;
						errorModal = true;
						//El dni ingresado ya se encuentra registrado en nuestra base de datos
						console.log("Errores a modificar");
						console.log({ data });
						errorMessages = data.message;
					}
				});
			});
		} catch (error) {
			console.log({ error });
			errorMessages = getErrorsMessages(error.errors, posibles);
		}
	}

	const onFileSelected = (e) => {
		fileAttr = {
			Name: e.target.files[0].name,
			Type: e.target.files[0].type,
		};
		let image = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = (e) => {
			avatar = e.target.result;
			fileVar = e.target.result;
		};
	};

	function addPathology(myDiseases, myDiseasesStatus) {
		console.log({ myDiseases });
		if (myDiseasesStatus != "" && myDiseases != "") {
			const nuevoObjeto = {
				label: myDiseases.label,
				code: myDiseases.code,
				Estado_patologia__c: myDiseasesStatus,
			};
			let condi = dataArray.find((elem) => elem.label == nuevoObjeto.label);
			if (!condi) {
				dataArray = [...dataArray, nuevoObjeto];
			}
			console.log({ dataArray });
		} else {
			errorMessages = true;
			setTimeout(function () {
				errorMessages = false;
			}, 3000);
		}
		document.getElementById("patologStatus").value = "";
		document.getElementById("patologID").value = "";
		finish = false;
	}
	function deletePathology(datamyDiseases) {
		console.log("delete", datamyDiseases);
		console.log("patologID", document.getElementById("patologID"));
		//console.log("delete", datamyDiseases);
		let result = dataArray.find((elem) => elem.label == datamyDiseases);
		if (result) {
			//document.getElementById("patologStatus").value = "";
			document.getElementById("patologID").text = datamyDiseases;
			//var e = document.getElementById("patologID");
			let newarray = [];
			dataArray.forEach((item) => {
				if (item.label != result.label) {
					newarray.push(item);
				}
			});
			dataArray = newarray;
		}
		console.log("result", result);
		if (dataArray.length != 0) {
			finish = false;
		} else {
			finish = true;
		}
	}

	// On Mount
	onMount(async () => {
		$patient.epofChange = true;
		complexItems = $_("PatientPathology.select_status");
		console.log({ complexItems });
		console.log("utils_resources_get - PatientRecord");
		patologias = await utils_resources_get("pathologies", $language);
		console.log({ patologias });
		//TODO: loadStates Y setSelectDisabled
		provincias = {};
		if (!currentSession) {
			replace("/");
		} else {
			//console.log(JSON.stringify(currentSession));
		}
	});
</script>

<!-- HTML -->
<svelte:head>
	<title>RDCOM - {$_("PatientPathology.text_title_head")}</title>
	<link rel="icon" href="/img/new_favicon.png">
</svelte:head>
<!-- svelte-ignore a11y-missing-attribute -->
<img class="imgBack" src="/img/backrount.jpg" />
<div class="register-conteiner">
	<div class="conteiner-data">
		<div class="conteinet svelte-yvtl96" style="display: flex; align-content: space-around; align-items: center;">
			<!-- <img class="logo-img" src="./img/Frame 106.png" alt="logo" /> -->
			<img src="img/new_logo_redondo.png" alt="logo" width="117" height="117" />
			<h4 style="color: rgb(17, 44, 74);text-align: center;">
				{$_("PatientPathology.text_title_patient")}:
			</h4>
			<br />
		</div>
	</div>
	<form class="register-form" on:submit|preventDefault>
		<div class="register-info-conteiner">
			<!-- 
			<div class="EPOF" style="d-flex justify-content-center">
				<small class="titulo">¿Esta usted diagnosticado con una EPOF?</small>
				<label class="cbox1"
					><input
						on:change={() => {
							$patient.epofChange = true;
						}}
						name="epof"
						type="radio"
						value="true"
						checked /> SI</label>
				<label class="cbox1"
					><input
						on:change={() => {
							$patient.epofChange = false;
							finish = false;
						}}
						name="epof"
						type="radio"
						value="false" /> NO</label>
			</div>-->
			{#if $patient.epofChange && patologias.length > 0}
				<div class="border shadow-sm m-3 p-4 rounded bg-white">
					<h1 class="title">{$_("PatientPathology.text_title_pathology")}</h1>
					<div class="row">
						<div id="patologiaID" class="data-patologia d-flex">
							<!-- Diagnostico -->
							<div class="diagnostico" style="d-flex justify-content-center">
								<small class="titulo">{$_("PatientPathology.text_diagnosis")}:</small>
								<select bind:value={myDiseases} class="form-control" name="" id="patologID">
									{#if Array.isArray(patologias)}
										{#each patologias as myDisease}
											<option value={myDisease}>{myDisease.label}</option>
										{/each}
									{/if}
								</select>
							</div>
							<div class="statusDi" style="d-flex justify-content-center">
								<small class="titulo">{$_("PatientPathology.text_State")}</small>
								<select
									bind:value={myDiseasesStatus}
									on:change={addPathology(myDiseases, myDiseasesStatus)}
									class="form-control"
									name=""
									id="patologStatus">
									{#if Array.isArray(complexItems)}
										{#each complexItems as myDiseaseState}
											<option value={myDiseaseState.value}>{myDiseaseState.label}</option>
										{/each}
									{/if}
								</select>
							</div>
							<div style="margin-block:3%">
								<!-- <button class="btnAdd" on:click={addPathology(myDiseases, myDiseasesStatus)}>
									<svg
										width="30"
										height="30"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
										class="feather feather-plus-circle">
										<circle cx="12" cy="12" r="10" />
										<line x1="12" y1="8" x2="12" y2="16" />
										<line x1="8" y1="12" x2="16" y2="12" />
									</svg>
								</button> -->
							</div>
						</div>
					</div>
					{#if dataArray.length > 0}
						<div id="tabla-patologias">
							<table>
								<thead>
									<tr>
										<th>{$_("PatientPathology.text_th_pathology")}</th>
										<th>{$_("PatientPathology.text_th_State")}</th>
										<th>{$_("PatientPathology.text_th_action")}</th>
									</tr>
								</thead>
								<tbody>
									{#each dataArray as diseases}
										<tr>
											<td>{diseases.label}</td>

											{#if diseases.Estado_patologia__c != null}
												{#each complexItems as myDiseaseState}
													{#if myDiseaseState.value == diseases.Estado_patologia__c}
														<td>{myDiseaseState.label}</td>
													{/if}
												{/each}
											{:else}
												<td
													><span class="text-muted"
														>{$_("PatientPathology.text_not_data")}</span
													></td>
											{/if}
											<td
												><button class="btnDelete" on:click={deletePathology(diseases.label)}
													>{$_("PatientPathology.button_delete")}
													<!--<svg
														width="30"
														height="30"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
														class="feather feather-plus-circle">
														<circle cx="12" cy="12" r="10" />
														<line x1="8" y1="12" x2="16" y2="12" />
													</svg>-->
												</button></td>
										</tr>
									{/each}
								</tbody>
							</table>
						</div>
						<br />
						<div class="adjuntos" style="d-flex justify-content-center">
							<small class="titulo">{$_("PatientPathology.text_attach")}:</small>
							<!-- <p>ESTUDIOS QUE CONFIRMEN SU PATOLOGIA:</p> -->
							<!-- <input class="input-log" type="file" id="file" accept="image/*" on:click={mostrar}/> -->
							<FileAttach
								{idTypeAttachment}
								{file_type}
								multipleState={true}
								photoState={true}
								on:dataFile={async (e) => {
									$patient.fileAttPatologia = e.detail;
								}} />
							<!-- svelte-ignore a11y-missing-attribute -->
						</div>
					{/if}
					<!-- File -->
				</div>
			{:else}
				<div class="text-center">
					<br />
					<Loader text={false} />
					<div>
						<p class="text-muted">{$_("PatientPathology.loading")}</p>
					</div>
				</div>
			{/if}
		</div>
		<br />

		<br />
		<div class="block">
			<button class="btnWhite" on:click={() => replace("#/PersonalInformation/Paciente")}
				>{$_("PatientPathology.button_return")}</button>
			<button
				disabled={finish}
				class="btnBlue"
				on:click={() => {
					//btnFinish();
					$patient.diseases = dataArray;
					modalViewPatient = true;
				}}>{$_("PatientPathology.button_finish")}</button>
		</div>
	</form>
</div>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={errorModal}
	on:closeModal={() => {
		errorModal = false;
	}}>
	<div class="p-2">
		<p class="title-error text-center">Error</p>
		<div class="line" />

		<p
			class="m-0"
			style="
    padding: 5%;
    text-align: center;
    /* margin: 5%; */
">
			{errorMessages}
		</p>

		<div class="line" />
		<!-- <p class="m-0">Por favor comuniquese con un administrador</p> -->
		<div class=" text-center">
			<small
				class="cursor-pointer text-center"
				on:click={() => {
					errorModal = false;
				}}>{$_("PatientPathology.button_return")}</small>
		</div>
	</div>
</LayoutModal>

<!-- Modal Cargando -->
- <LayoutModal
	tam="30"
	isOpenModal={modalLoadingAnimation}
	on:closeModal={() => {
		modalLoadingAnimation = false;
	}}>
	<div class="p-3 text-center">
		<Loader text={false} />
		<p class="text-muted">{$_("PatientPathology.save")}</p>
	</div>
</LayoutModal>

<LayoutModal
	tam="60"
	isOpenModal={modalViewPatient}
	on:closeModal={() => {
		modalViewPatient = false;
	}}>
	{#if modalViewPatient}
		<ConfirmationPatient body={$patient} />
	{/if}
	<div class="text-center">
		<button
			on:click={() => {
				modalViewPatient = false;
				replace("/PersonalInformation/Paciente");
			}}
			class="btnWhite">{$_("ProfessionalRecord.button_update")}</button>
		<button
			class="btnBlue"
			on:click={() => {
				//deleteHeadM(theRecord.Id);
				modalLoadingAnimation = true;
				modalViewPatient = false;
				btnFinish();
			}}>{$_("ProfessionalRecord.button_confirm")}</button>
	</div>
</LayoutModal>

<!-- CSS -->
<style>
	.btnDelete {
		letter-spacing: 1px;
		border-radius: 10px;
		color: #ffffff;
		background-color: var(--my-dark-blue);
	}
	.line {
		height: 1px;
		width: 100%;
		background-color: black;
		margin-bottom: 5px;
	}
	.statusDi {
		width: 30%;
		margin: 1%;
	}

	table {
		border-collapse: collapse;
		width: 100%;
	}

	th,
	td {
		text-align: left;
		padding: 8px;
	}

	th {
		background-color: #f2f2f2;
		color: #666666;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	.register-conteiner {
		width: 70%;
		height: max-content;
		padding: 17px;
		position: relative;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		justify-content: space-between;
		overflow: hidden;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}
	.data-patologia {
		width: 100%;
	}
	.block {
		width: 90%;
		text-align: end;
	}
	.titulo {
		color: #112c4a;
		font-weight: bold;
	}
	.register-info-conteiner {
		width: 95%;
		padding-left: 3%;
	}
	.register-form {
		width: 100%;
		display: block;
	}
	.title-error {
		color: #112c4a;
		font-weight: bold;
		width: 100%;
		text-align: initial;
		margin: initial;
	}
	.title {
		width: 100%;
		text-align: center;
		margin: auto;
		color: #112c4a;
	}

	.diagnostico {
		width: 100%;
		color: #112c4a;
		font-weight: 600;
		margin: 1%;
	}
	.adjuntos {
		width: 100%;
		color: #112c4a;
		font-weight: 600;
	}

	/* .btnRegister {
    margin: 10px;
    width: 90%;
    height: 3.2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #f7fafc;
    border-radius: 5px;
    background-color: #112C4A;
  } */

	.imgBack {
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 100%;
	}
	.title {
		font-size: 20px;
		text-align: center;
	}
	@media screen and (max-width: 1000px) {
		.register-form {
			width: 100%;
			display: inline;
			flex-direction: initial;
			align-items: flex-start;
		}
		.register-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
	}
</style>
