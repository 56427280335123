<script>
	export let visible = false; // Propiedad para controlar la visibilidad del modal
</script>

{#if visible}
	<div class="outside-content">
		<div class="loading-modal">
			<div class="loading-image">
				<!-- <img src="/img/LogoRDCom.png" alt="Loading" /> -->
				<img src="/img/new_logo.png" alt="Loading" />
			</div>
			<div class="loading-bar">
				<div class="loading-progress"></div>
				<!-- Corrección: cierre del tag div -->
			</div>
		</div>
	</div>
{/if}

<style>
	.outside-content {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.7);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9998;
	}

	.loading-modal {
		position: relative;
		width: 300px;
		height: 200px;
		background-color: white;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 8px !important;
		z-index: 9999;
	}

	.loading-image img {
		max-width: 100px;
	}

	.loading-bar {
		width: 80%;
		height: 10px;
		background-color: #ccc;
		margin-top: 10px;
		border-radius: 5px;
		overflow: hidden;
	}

	.loading-progress {
		width: 200%; /* Modificación: duplicamos el tamaño para asegurarnos de que la barra se pinte completamente */
		height: 100%;
		background-color: #2980b9;
		animation: progress 4s infinite linear; /* Modificación: ajustamos la duración de la animación */
		transform: translateX(-50%); /* Modificación: ahora comienza desde el centro */
	}

	@keyframes progress {
		0% {
			transform: translateX(-100%);
		}
		50% {
			transform: translateX(0); /* Modificación: pinta toda la barra de azul */
			background-color: white; /* Modificación: cambia a blanco */
		}
		100% {
			transform: translateX(-50%);
			background-color: #2980b9; /* Modificación: vuelve a azul */
		}
	}
</style>
