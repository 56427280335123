<script>
	import { _ } from "svelte-i18n";
	import { user } from "./../helpers/storage/stores";
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	function closeHelpForm() {
		dispatch("closeHelpForm");
	}

	const fechaYHoraActual = new Date();
	const hora = fechaYHoraActual.getHours();
	const minutos = fechaYHoraActual.getMinutes();
	const segundos = fechaYHoraActual.getSeconds();
	let date = fechaYHoraActual;
	let email = $user?.PersonEmail;
	let userId = $user?.Id;
	let subject = "";
	let message = "";

	async function handleSubmit() {
		const formData = { date, userId, email, subject, message };
		console.log({ formData });

		// Aquí deberías hacer una solicitud POST a tu servidor backend
		// para manejar el envío de correo electrónico.
		// Puedes usar la función fetch() o una librería como Axios para hacerlo.

		try {
			const response = await fetch("/api/sendEmail", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				// Correo enviado con éxito
				alert("Correo enviado con éxito");
				// Puedes redirigir o hacer algo más aquí
			} else {
				// Error al enviar el correo
				alert("Hubo un error al enviar el correo");
			}
		} catch (error) {
			console.error("Error:", error);
			alert("Hubo un error al enviar el correo");
		}
	}
</script>

<!-- <div class="help-form">
	<h2>{$_("HelpForm.title")}</h2>
	<form on:submit|preventDefault={handleSubmit}>
		<label>
			{$_("HelpForm.text_Subject")}:
			<input type="text" bind:value={subject} required />
		</label>
		<label>
			{$_("HelpForm.text_message")}:
			<textarea rows="4" bind:value={message} required />
		</label>
		<div class="d-submit">
			<button type="submit">{$_("HelpForm.button_Send")}</button>
		</div>
	</form>
</div> -->
<div class="help-form">
	<!-- svelte-ignore a11y-missing-content -->
	<p>{$_("HelpForm.text_messageHelp")} <a href="mailto:info@rdcom.global">info@rdcom.global</a></p>
	<div class="d-submit">
		<button on:click={closeHelpForm}>{$_("HelpForm.button_ok")}</button>
	</div>
</div>

<style>
	.help-form {
		max-width: 400px;
		margin: 0 auto;
		padding: 20px;
		/* border: 1px solid #ccc; */
		border-radius: 5px;
		text-align: center;
	}
	.d-submit {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
	}

	button {
		background-color: #112c4a;
		color: #fff;
		border: none;
		border-radius: 3px;
		padding: 10px 20px;
		cursor: pointer;
	}

	button:hover {
		background-color: #133862;
	}
</style>
