<script>
	// Svelte
	import { onMount } from "svelte";

	// Helpers
	//import { myPlans } from "../../../helpers/mock/planes";
	import { currentSession } from "../../../helpers/storage/sessions";
	import { alta, language, user } from "../../../helpers/storage/stores";
	import { professional_careplan_add, utils_resources_get } from "../../../helpers/apiBackend";
	import { _ } from "svelte-i18n";

	// Components
	import Loader from "../../../components/Loader.svelte";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";

	// Replace here!!!
	let monitoring_plans = [];
	let result;
	let selected_plan = "none";
	let [api_done, my_confirm, assign_call] = [true, false, false];

	// Assign Call
	/**
	 * @description
	 */
	async function asignar() {
		if (my_confirm) {
			try {
				if (my_confirm) {
					assign_call = true;
					let cpsr = {
						plan: selected_plan,
						doctor: $user.Id,
						patient: $alta.Cuenta__r.Id ? $alta.Cuenta__r.Id : "",
					};
					result = await professional_careplan_add(cpsr, $currentSession).then((result) => {
						if ((result.statusCode = 201)) {
							window.location.href = "#/Success?origen=assignPlan";
						} else {
							console.log("Algo malio Sal");
						}
					});
				}
			} catch (assignError) {} //console.log({assignError}) }
		}
	}

	// Load Monitoring Plans
	/**
	 * @description
	 */
	onMount(async () => {
		console.log("utils_resources_get - RecordsMedications");
		monitoring_plans = await utils_resources_get("monitoringPlans", $language);
	});
</script>

<section>
	<LayoutNav secure={true} rol="Profesional_de_Salud">
		{#if monitoring_plans.length == 0}
			<div class="container py-5 text-center bg-white">
				<Loader text={false} />
				<p class="text-muted">{$_("SelectPlan.text_no_plans")}</p>
				<button
					on:click={() => {
						window.location.href = "#/AssignPlan";
					}}
					class="py-1 btnBlue">{$_("SelectPlan.button_return")}</button>
			</div>
		{:else if assign_call}
			<div class="container text-center py-5 bg-white">
				<Loader text={false} />
				<p class="text-muted">{$_("SelectPlan.text_loading_plan")}</p>
			</div>
		{:else}
			<div class="m-3 p-4 border shadow-sm rounded bg-white">
				<h3>{$_("SelectPlan.text_assign")}</h3>
				<div class="my-4">
					<p class="m-0">{$_("SelectPlan.text_patient")}:&nbsp;&nbsp;{$alta.Cuenta__r.Name}</p>

					<!-- Monitoring Plans List -->
					{#if monitoring_plans.length == 0 && !api_done}
						<Loader text={false} />
						<p class="text-muted">{$_("SelectPlan.loading")}</p>
					{:else if monitoring_plans.length == 0 && api_done}
						<p class="text-muted">{$_("SelectPlan.text_not_assign")}</p>
					{:else}
						<p class="m-0">{$_("SelectPlan.text_select_plan")}</p>
						<select
							bind:value={selected_plan}
							class="form-control rounded-0"
							name="plan-select"
							id="plan-select">
							<option value="none"
								><span class="text-muted">{$_("SelectPlan.text_select")}... </span></option>
							{#each monitoring_plans as plan}<option class="py-0" value={plan.value}>{plan.label}</option
								>{/each}
						</select>
					{/if}
				</div>

				<!-- Actions -->
				<div class="mt-5 d-flex">
					<button
						on:click={() => {
							window.location.href = "#/AssignPlan";
						}}
						class="py-1 btnWhite">{$_("SelectPlan.button_Cancel")}</button>
					{#if selected_plan != "none"}
						<div class="d-flex">
							{#if my_confirm}
								<button
									class="py-1 mx-3 btnBlue"
									on:click={() => {
										asignar();
									}}>{$_("SelectPlan.button_Assign")}</button>
							{:else}
								<button class="py-1 mx-3 btnBlue" disabled>{$_("SelectPlan.button_Assign")}</button>
							{/if}
							<label
								><input type="checkbox" bind:checked={my_confirm} />{$_(
									"SelectPlan.text_message_succes"
								)}</label>
						</div>
					{/if}
				</div>
			</div>
		{/if}
	</LayoutNav>
</section>
