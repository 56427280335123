<script>
	// Components
	import ListRecordPatient from "../../../components/Lists/List_Records_Patients.svelte";
	import AddMedications from "../../../components/addMedications.svelte";
	import Loader from "../../../components/Loader.svelte";
	// Layouts
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutPatientHeader from "../../../components/Layouts/Layout_Patient_Header.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";

	// Helpers
	import { currentSession } from "../../../helpers/storage/sessions.js";
	import { generic_activities_get } from "../../../helpers/apiBackend";
	import { user, registerDetail, language } from "../../../helpers/storage/stores.js";

	// Svelte
	//import { element } from "svelte/internal";
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";

	let myType, myUrl, type;
	let myRecords = [];
	let modaladd = false;
	let render = false;
	let myPatient = $user.Id;
	let myUserId = $user.Id;
	let account_id = $user.Id;
	let myPlan = $user.HealthCloudGA__CarePlan__c;
	let select_activity = $_("RegisterDetailPatient.select_activity");
	//const AWS_HOST = process.env.HOST_DEV;
	onMount(async () => {
		console.log({ $user });
		if (document.location.href.indexOf("?") > -1) {
			let myPatient = $user["Id"];
			let myPlan = $user["Careplan2__r"].Id;
			myType = document.location.href.split("?")[1].split("=")[1];
			if (myType == "Citas/Entrevistas") {
				type = myType.replace("/", "");
			} else {
				type = myType;
				if (type == "Medicamentos") {
					type = "Medicacion";
				}
			}
			await generic_activities_get(myPatient, type, myPlan, account_id, $language).then((res) => {
				//let auxRecord = [];
				console.log({ res });
				myRecords = res.data;
				const filteredData = myRecords.filter(
					(item) => item.active && item.details.some((detail) => detail.requested)
				);
				myRecords = filteredData;
				render = true;
			});
		} else {
			window.location.href = "#/GeneralError";
		}
	});
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	<LayoutPatientHeader />
	{#if render}
		<div class="p-3 m-3 rounded shadow-sm bg-white">
			<div class="border-bottom">
				<!-- <h3>{select_activity[myType]}</h3> -->
				<div class="border-bottom text-center">
					{#if myType}
						<h3 class="text-muted">{select_activity[myType]}</h3>
					{/if}
				</div>
				{#if myType == "Medicamentos"}
					<div>
						<button
							on:click={() => {
								modaladd = true;
							}}
							class="btnWhite">Agregar {myType}</button>
					</div>
				{/if}
			</div>
			<div class="d-flex justify-content-between">
				<!-- <span class="text-muted">Nombre&nbsp;<small class="">(Modalidad)</small></span> -->
				<div class="d-flex text-muted">
					<!-- <span>Frecuencia</span> -->
					<!-- <span>Activo-</span>
                    <span>Acciones</span> -->
				</div>
			</div>
			<div class="d-flex justify-content-between" />

			<div class="barraName d-flex border rounded">
				<span class="spName">{$_("ViewCuestionarioPatient.spName")}</span>
				<span class="spArea">{$_("ViewCuestionarioPatient.text_frequency")}</span>
				<span class="spStatus">{$_("ViewCuestionarioPatient.spStatus")}</span>
				<span class="spAction">{$_("ViewCuestionarioPatient.spAction")}</span>
			</div>

			<div class="d-flex justify-content-between" />
			{#if myRecords.length > 0}
				<div class="my-plan-dashboard">
					{#each myRecords as record, i}
						<div class="my-1">
							<ListRecordPatient
								theRecord={record}
								on:editDetail={(e) => {
									$registerDetail["register"] = record;
									$registerDetail["detail"] = e.detail.myDetail;
									window.location.href = `#/Patient/RegisterDetail?type=${myType}&scope=detail`;
								}} />
						</div>
					{/each}
				</div>
			{:else}
				<div class="text-center p-2">
					<p class="m-0 text-muted">No contiene {myType}</p>
				</div>
			{/if}
			<div class="my-4 border-top text-center">
				<!-- <small class="text-muted">Total:&nbsp{myRecords.length}</small> -->
				<a href="#/Patient/Plan">&nbsp;&nbsp;Plan&nbsp;&nbsp;</a>
			</div>
		</div>
	{:else}
		<div class="p-3 text-center bg-white my-2 rounded shadow">
			<Loader text={false} />
			<p class="text-muted">{$_("ViewCuestionarioProf.loading")}</p>
		</div>
	{/if}
	<!-- </LayoutNav> -->
</section>

<LayoutModal
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<AddMedications {myUserId} {myPlan} {myPatient} />
</LayoutModal>

<!-- CSS -->
<style>
	.my-plan-dashboard {
		max-height: 40vh !important;
		overflow: auto;
	}
	.barraName {
		background-color: #215273;
		height: 45px;
		color: #ffffff;
		align-items: center;
	}
	.spName {
		margin-left: 1%;
		width: 46%;
	}
	.spArea {
		width: 34%;
	}
	.spStatus {
		width: 10%;
	}
</style>
