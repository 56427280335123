<script>
	// Components / Layouts
	//import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	//import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../../components/Loader.svelte";
	import Modal from "../../../components/Modal.svelte";

	// Helpers
	import { language, patient, user } from "../../../helpers/storage/stores";
	import {
		professional_interview_add,
		professional_activities_master_get,
		professional_exam_add,
	} from "../../../helpers/apiBackend.js";

	// Svelte
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";

	// Variables
	export let params;
	let activityTypes = params.type;
	let listActivityTypes = [];
	let careplan_id = $patient.careplan_id;
	let selectedActivity = "*";
	let loader = true;
	let [checkConfirm, modalSuccess, modalPost, modalError] = [false, false, false, false];
	let messageError = "";
	let myBodyCreate = {
		masterId: "",
		patientId: $patient.Id,
		planId: "",
		doctorId: $user.Id,
	};

	/**
	 * @description
	 */
	const NewActivity = async (selectedActivity) => {
		console.log({ params });
		myBodyCreate.masterId = selectedActivity;
		myBodyCreate.planId = careplan_id;
		if (activityTypes == "Estudios") {
			await professional_exam_add(myBodyCreate).then((result) => {
				if (result.statusCode == 201) {
					modalSuccess = true;
				} else {
					modalError = true;
					messageError = result.message || "Error";
				}
				console.log(result);
			});
			REFACTORIZAR;
		} else {
			console.log("Body al crear nueva", myBodyCreate)
			await professional_interview_add(myBodyCreate).then((result) => {
				if (result.statusCode == 201) {
					modalSuccess = true;
				} else {
					modalError = true;
					messageError = result.message || "Error";
				}
				console.log(result);
			});
		}
	};

	/**
	 * @description
	 */
	onMount(async () => {
		console.log({ $user });
		listActivityTypes = await professional_activities_master_get(careplan_id, activityTypes, $language);
		listActivityTypes = listActivityTypes.data;
		console.log({ listActivityTypes });
		loader = false;
	});
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<!-- <LayoutPatient> -->
	<div class="container border p-3 shadow-sm rounded my-2 bg-white">
		<div class="text-muted border-bottom">
			<h3>{$_("CreateActivity.text_new_activity")}</h3>
		</div>
		{#if loader}
			<div class="text-center p-3">
				<Loader text={false} />
				<p class="text-muted">{$_("CreateActivity.loading")}</p>
			</div>
		{:else}
			<div class="py-3">
				{#if listActivityTypes.length == 0}
					<div class="text-muted">
						<p>{$_("CreateActivity.text_not_activities_to_add")}...</p>
					</div>
				{:else}
					<div class="form-group d-flex">
						<label for="activity-type">{$_("CreateActivity.text_type_activities")}</label>
						<select bind:value={selectedActivity} class="form-control" name="activity-type" id="">
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listActivityTypes as myActivity}
								<option value={myActivity.Id}>{myActivity.label}</option>
							{/each}
						</select>
					</div>
					<div>
						{#if selectedActivity != "*"}
							<div class="d-flex align-items-center">
								{#if checkConfirm}
									<button
										on:click={() => {
											NewActivity(selectedActivity);
										}}
										class="btnBlue">{$_("CreateActivity.button_Create")}</button>
								{:else}
									<button class="btnWhite" disabled>{$_("CreateActivity.button_Create")}</button>
								{/if}
								<div class="mx-2">
									<input class="" type="checkbox" bind:checked={checkConfirm} />
									<label class="" for="">{$_("CreateActivity.text_message_activity")}</label>
								</div>
							</div>
						{/if}
					</div>
				{/if}
			</div>
		{/if}
		<div class="my-3 border-top">
			<div class="my-3 d-flex justify-content-center">
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Plan";
					}}>
					{$_("CreateActivity.button_return")}
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Doctor";
					}}>
					{$_("CreateActivity.button_return_patient")}
				</p>
				<!-- <a class="mx-2" href="#/Plan">Volver al Plan</a>
                        <a class="mx-2" href="#/Doctor">Volver a mis pacientes</a> -->
			</div>
		</div>
	</div>
	<!-- </LayoutPatient> -->
	<!-- </LayoutNav> -->
</section>

<!-- Modal Loading -->
<!-- <LayoutModal
	isOpenModal={modalPost}
	on:closeModal={() => {
		modalPost = false;
	}}>
	<div class="p-3 text-center">
		<Loader text={false} />
		<p class="text-muted">...</p>
	</div>
</LayoutModal> -->

<Modal isOpenModal={modalPost}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">{$_("CreateActivity.loading")}</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<LayoutModal
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	<div class="p-3 text-center">
		<p>{$_("CreateActivity.text_message_succes")}</p>
		<a href="/#/Records?type=Citas/Entrevistas">{$_("CreateActivity.text_activities")}</a>
	</div>
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="p-3 text-center">
		<p>{messageError}</p>
		<a href="/#/Records?type=Citas/Entrevistas">{$_("CreateActivity.text_activities")}</a>
	</div>
</LayoutModal>
