<script>
	import { hasContext, onMount } from "svelte";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Modal from "../../../components/Modal.svelte";
	import { patient, registerDetail, user, language } from "../../../helpers/storage/stores";
	import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	import AddMedications from "../../../components/addMedications.svelte";
	//import { currentSession } from "../../../helpers/storage/sessions";
	import Loader from "../../../components/Loader.svelte";
	import {
		generic_activities_get,
		professional_questionnaire_update,
		utils_resources_get,
	} from "../../../helpers/apiBackend";
	import { _ } from "svelte-i18n";

	// New Component
	import List_Question_Professional from "../../../components/Lists/List_Question_Professional.svelte";

	// Get Data
	let myRecords = [];
	let myType, modalEditHeader, type;
	let frecuencias = [];
	let headerToEdit = {};
	let loading = true;
	let dataPatient = "";
	let myPatient = "";
	let myUserId = $user.Id;
	let account_id = $user.Id;
	let myPlan = ""; //$patient.Cuenta__r.HealthCloudGA__CarePlan__c;
	let [modalLoading, modalError, modalSuccess, modaladd] = [false, false, false, false];
	// const AWS_HOST = process.env.HOST_DEV; // Fix ENVS

	onMount(async () => {
		$registerDetail = {};
		headerToEdit = {};
		//myType = document.location.href.split("?")[1].split("=")[1];
		myType = $_("ViewCuestionarioPatient.text_title");
		console.log(myType);
		type = "Cuestionarios";

		if ($user.hasOwnProperty("Especialidades__r")) {
			myPatient = $patient.Id;
		} else {
			myPatient = $user.Id;
		}
		frecuencias = await utils_resources_get("frecuencies", $language);
		//[dataPatient] = await Promise.all([generic_account_get("", myPatient, ROLES.patient)]);
		//console.log({ dataPatient });
		await generic_activities_get(myPatient, type, $patient.careplan_id, account_id, $language).then((res) => {
			myRecords = res.data;
			loading = false;
		});
	});

	// Edit Header
	const editHeader = async () => {
		let bodyPatchHeader = {
			id: headerToEdit["Id"],
			frecuency: headerToEdit["frecuency"],
			active: headerToEdit["active"],
		};
		console.log({ bodyPatchHeader });
		modalLoading = true;
		modalEditHeader = false;
		professional_questionnaire_update(headerToEdit["Id"], bodyPatchHeader)
			.then((dataPacht) => {
				if (dataPacht.status == 200) {
					modalSuccess = true;
					modalLoading = false;
				} else {
					modalError = true;
				}
				console.log({ dataPacht });
			})
			.catch((errorPatch) => {
				console.log({ errorPatch });
				modalError = true;
			});
		// let myToken = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
		// let myHeaders = { Authorization: myToken };
		// let myEditUrlHeader = `${AWS_HOST}/generic/update_activity?activity_id=${headerToEdit["Id"]}`;
		// await fetch(myEditUrlHeader, {
		// 	headers: myHeaders,
		// 	method: "PATCH",
		// 	body: JSON.stringify(bodyPatchHeader),
		// })
		// 	.then((dataPacht) => {
		// 		console.log({ dataPacht });
		// 		modalSuccess = true;
		// 	})
		// 	.catch((errorPatch) => {
		// 		console.log({ errorPatch });
		// 		modalError = true;
		// 	})
		// 	.finally(() => {
		// 		modalLoading = false;
		// 	});
	};

	// Handle Modal Edit Header
	let originalHeader;
	let diffChanges = false;
	$: {
		if (modalEditHeader) {
			if (originalHeader == undefined) {
				originalHeader = JSON.parse(JSON.stringify(headerToEdit));
				console.log({ originalHeader });
			} else {
				if (JSON.stringify(originalHeader) != JSON.stringify(headerToEdit)) {
					diffChanges = true;
				} else {
					diffChanges = false;
				}
			}
		} else {
			if (originalHeader != undefined) {
				originalHeader = undefined;
			}
		}
	}
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<!-- <LayoutPatient> -->
	<div class="container">
		<div>
			<div class="border-bottom">
				<h3 class="text-muted">{myType}</h3>
			</div>
			<div class="barraName d-flex border rounded justify-content-between">
				<span class="spName">{$_("ViewCuestionarioPatient.spName")}</span>
				<span class="spArea">{$_("ViewCuestionarioPatient.text_frequency")}</span>
				<span class="spVar">{$_("RegisterDetailQuestion.text_variable")}</span>
				<span class="spStatus">{$_("ViewCuestionarioPatient.spStatus")}</span>
				<span class="spAction">{$_("ViewCuestionarioPatient.spAction")}</span>
			</div>

			<div class="d-flex justify-content-between" />
			<div class="records-list">
				{#if loading}
					<div class="p-3 text-center">
						<Loader text={false} />
						<p class="text-muted">{$_("ViewCuestionarioProf.loading")}</p>
					</div>
				{:else if myRecords.length > 0}
					{#each myRecords as record, i}
						<!-- New Component - Start -->
						<div class="my-2">
							<List_Question_Professional
								theRecord={record}
								idpatient={myPatient}
								on:editHeader={() => {
									headerToEdit = record;
									modalEditHeader = true;
								}}
								on:editDetail={(e) => {
									$registerDetail["register"] = record;
									$registerDetail["detail"] = e.detail.myDetail;
									window.location.href = `#/RegisterDetailQuestion?type=${myType}&scope=detail`;
								}} />
						</div>
						<!-- New Component - End -->
					{/each}
				{:else}
					<div class="text-center p-2">
						<p class="m-0 text-muted">No contiene {myType}</p>
					</div>
				{/if}
			</div>
			<div class="d-flex m-3 border rounded justify-content-center">
				<!-- <p class="mx-2 m-0">Total: {myRecords.length}</p> -->
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Plan";
					}}>
					Plan
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Doctor";
					}}>
					Mis pacientes
				</p>
				<p
					class="texlink mx-2 cursor-pointer"
					on:click={() => {
						window.location.href = "#/Altas";
					}}>
					Mis Altas
				</p>
			</div>
		</div>
	</div>
	<!-- </LayoutPatient> -->
	<!-- </LayoutNav> -->
</section>

<!-- Modal Editar Header -->
<LayoutModal
	isOpenModal={modalEditHeader}
	on:closeModal={() => {
		modalEditHeader = false;
	}}>
	<div class="">
		{#if headerToEdit.hasOwnProperty("name")}
			<div class="p-3 text-center">
				<h5 class="text-muted">{$_("ViewCuestionarioProf.text_edit")} {headerToEdit["name"]}</h5>
				<div
					class="p-2"
					style="
    display: flex;
    flex-direction: column;
">
					<div class="d-flex" style="display: flex;justify-content: space-between;margin-top: 2%;">
						<small class="text-muted">{$_("ViewCuestionarioProf.text_active")}</small>
						<p class="m-0 mx-2">
							{#if headerToEdit.hasOwnProperty("active")}
								{#if headerToEdit["active"]}
									<!-- <i
										on:click={() => {
											headerToEdit["active"] = false;
										}}
										class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->

										<button
												on:click={() => {
													headerToEdit["active"] = false;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleon.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
										
								{:else}
									<!-- <i
										on:click={() => {
											headerToEdit["active"] = true;
										}}
										class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
										<button
												on:click={() => {
													headerToEdit["active"] = true;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleoff.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
								{/if}
							{/if}
						</p>
					</div>

					<div class="d-flex" style="display: flex;justify-content: space-between;margin-top: 2%;">
						<small class="text-muted">{$_("ViewCuestionarioProf.text_dateSuggested")}</small>
						<select
							bind:value={headerToEdit["frecuency"]}
							class="form-control form-control-sm"
							name="frecuencia-edit"
							id=""
							size="1"
							style="
    width: 60%;
">
							{#each frecuencias as frecuencia}
								<option value={frecuencia.Id}>
									{frecuencia.label}
								</option>
							{/each}
							<!-- <option value="Al nacer/ Ingreso">Al nacer/ Ingreso</option> -->
						</select>
					</div>
				</div>
			</div>
		{/if}
		<div class="d-flex justify-content-center">
			{#if diffChanges}
				<button
					on:click={() => {
						editHeader();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("ViewCuestionarioProf.button_send")}</button>
			{/if}
			<button
				class="py-0 mx-2 py-2 btnWhite"
				on:click={() => {
					modalEditHeader = false;
				}}>{$_("ViewCuestionarioProf.button_Cancel")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Layout Modal -->
<!-- <LayoutModal
	isOpenModal={modalLoading}
	on:closeModal={() => {
		modalLoading = false;
	}}>
	<div class="text-center p-3">
		<Loader text={false} />
		<p class="text-muted">...</p>
	</div>
</LayoutModal> -->
<Modal isOpenModal={modalLoading}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">CARGANDO...</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<LayoutModal
	isOpenModal={modalSuccess}
	on:closeModal={() => {
		modalSuccess = false;
	}}>
	<div class="p-2 text-center">
		<p>Tu solicitud ha sido enviada</p>
		<div class="d-flex justify-content-center">
			<button
				on:click={() => {
					window.location.reload();
				}}
				class="btnClose">Cerrar</button>
		</div>
	</div>
</LayoutModal>

<!-- Modal Error -->
<LayoutModal
	isOpenModal={modalError}
	on:closeModal={() => {
		modalError = false;
	}}>
	<div class="border p-3">
		<div>
			<p class="m-0">La acción solicitada no se pudo completar, por favor intente nuevamente</p>
		</div>
		<div>
			<button
				class="btn btn-outline-info py-0"
				on:click={() => {
					window.location.reload();
				}}>Cerrar</button>
		</div>
	</div>
</LayoutModal>

<!-- Modal add medicines -->
<LayoutModal
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<AddMedications {myUserId} {myPlan} {myPatient} />
</LayoutModal>

<!-- 
      [
      {
          "attributes": {
              "type": "RDCom_ActividadDetail__c",
              "url": "/services/data/v55.0/sobjects/RDCom_ActividadDetail__c/a1K8D000000I7ydUAC"
          },
          "Actividad__c": "a1M8D000000HmkFUAS",
          "Id": "a1K8D000000I7ydUAC",
          "Name": "Psiquiatra",
          "Paciente__c": "0018D00000FwAGhQAN",
          "Fecha_Sugerida__c": "2022-07-30",
          "Completado__c": false,
          "Creado_Por__c": "0018D00000Fw787QAB",
          "Solicitado__c": false,
          "nueva_Accion_sugerida__c": "Solicitar",
          "Etapa__c": "1",
          "Estado_Medico_de_cabecera__c": "Rojo",
          "Archivar__c": false,
          "Expirado__c": false,
          "Creado_Por__r": {
              "attributes": {
                  "type": "Account",
                  "url": "/services/data/v55.0/sobjects/Account/0018D00000Fw787QAB"
              },
              "Id": "0018D00000Fw787QAB",
              "Name": "Ignacio Salimeno"
          }
      }
  ]
   -->
<style>
	/* .my-detail {
      background-color: rgb(242, 242, 242);
    }
    .cursor-hover {
      background-color: rgb(233, 233, 233);
      transition: 0.2s;
    }
    .cursor-hover:hover {
      background-color: rgb(170, 170, 170);
      transition: 0.2s;
      color: white;
    } */
	.records-list {
		max-height: 50vh;
		overflow: auto;
	}
	.texlink {
		color: #215273;
	}
	.barraName {
		background-color: #215273;
		height: 45px;
		color: #ffffff;
		align-items: center;
	}
	.spName {
		width: 520px;
		margin-left: 2%;
	}
	.spArea {
		width: 190px;
	}
	.btnClose {
		color: #215273;
		border-color: #215273;
		font-weight: 500;
		border-radius: 10px;
		background-color: #ffffff;
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.spVar {
		width: 190px;
	}
	.spAction {
		margin-right: 2%;
	}
	.spStatus {
		margin-right: 2%;
	}
	.spType {
		width: 190px;
	}
	.myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #ffffff;
		background-color: #787f87;
	}
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}

	/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */
	@media only screen and (min-width: 960px) {
		/* styles for browsers larger than 960px; */
		.records-list {
			max-height: 50vh;
			overflow: auto;
		}
	}
	@media only screen and (min-width: 1440px) {
		/* styles for browsers larger than 1440px; */
		.records-list {
			max-height: 50vh;
			overflow: auto;
		}
	}
	@media only screen and (min-width: 2000px) {
		/* for sumo sized (mac) screens */
		.records-list {
			max-height: 30vh;
			overflow: auto;
		}
	}
	@media only screen and (max-device-width: 480px) {
		/* styles for mobile browsers smaller than 480px; (iPhone) */
	}
	@media only screen and (device-width: 768px) {
		/* default iPad screens */
	}
	/* different techniques for iPad screening */
	@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
		/* For portrait layouts only */
	}

	@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
		/* For landscape layouts only */
	}
</style>
